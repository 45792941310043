<template>
  <div class="card-content contrast">
    <h2 class="title is-2">Algorithm Settings</h2>
    <div class="columns" v-if="settings">
      <div class="column is-half">
        <ValidationProvider rules="required" name="Algorithm Version" v-slot="{ errors }">
          <b-field label="Rule Type" :message="errors" :type="{ 'is-danger': errors[0] }">
            <b-select v-model="settings.algorithm_version" placeholder="Select an Algorithm Version" expanded @input="input">
              <option value="a_baseline">a_baseline</option>
              <option value="b_tit_for_tat">b_tit_for_tat</option>
              <option value="c_exponential">c_exponential</option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>
      <div class="column is-half">
        <ValidationProvider :rules="getRules({param})" :name="param" v-slot="{ errors }" v-for="(param, paramIndex) in hyperParameterKeys" :key="paramIndex" >
          <b-field :label="param" :message="errors" :type="{ 'is-danger': errors[0] }">
            <b-input v-model.number="settings.hyper_parameters[param]" @input="input" :customId="param"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AlgorithmEditor',
  props: ['value', 'disabled'],
  data () {
    return {
      settings: this.settingsFromValue(this.value)
    }
  },
  watch: {
    'settings.algorithm_version' (newValue, oldValue) {
      // Only watch after initial page load
      if (oldValue !== undefined) {
        this.settings.hyper_parameters = this.defaultHyperParameters(this.settings.algorithm_version)
        this.input()
      }
    },
    value () {
      this.settings = this.settingsFromValue(this.value)
    }
  },
  computed: {
    hyperParameterKeys () {
      return Object.keys(this.defaultHyperParameters(this.settings.algorithm_version))
    }
  },
  methods: {
    defaultHyperParameters (algorithmVersion) {
      switch (algorithmVersion) {
        case 'a_baseline': return {
          walkaway_relaxation: 0.80,
          accommodative_low: 0.50,
          accommodative_high: 0.75,
          aggressive_low: 0.75,
          aggressive_high: 0.90,
          considerate_low: 0.97,
          considerate_high: 0.99,
          break_even_assumption: 0.50,
          break_even_increase: 0.00,
          break_even_randomness: 0.00,
          tolerance: 0.15,
          tiny_discount_redux: 0.02,
          initial_discount_low: 0.375,
          initial_discount_high: 0.625
        }
        case 'b_tit_for_tat': return {
          walkaway_relaxation: 0.80,
          accommodative_low: 0.50,
          accommodative_high: 0.75,
          aggressive_low: 0.75,
          aggressive_high: 0.90,
          considerate_low: 0.97,
          considerate_high: 0.99,
          break_even_assumption: 0.50,
          tolerance: 0.10,
          tiny_discount_redux: 0.02,
          titfortat: 0.5,
          discount_margin: 0.25,
          initial_discount_low: 0.375,
          initial_discount_high: 0.625
        }
        case 'c_exponential': return {
          walkaway_relaxation: 0.80,
          accommodative_low: 0.50,
          accommodative_high: 0.75,
          aggressive_low: 0.75,
          aggressive_high: 0.90,
          considerate_low: 0.97,
          considerate_high: 0.99,
          break_even_assumption: 0.50,
          tolerance: 0.15,
          tiny_discount_redux: 0.02,
          decay_rate: 1,
          decay_base: 0.5,
          decay_walkaway_multiplier: 1.001,
          initial_discount_low: 0.375,
          initial_discount_high: 0.625,
          exp_max_bids: 5,
          exp_increment_spontaneous_bids: 0.5,
          exp_increment_initial_bid: 1
        }
        default: return {}
      }
    },
    settingsFromValue (value) {
      var defaultVersion = 'a_baseline'
      var settings = {}

      if (value !== null && value !== undefined) {
        settings.algorithm_version = value.algorithm_version || defaultVersion
        var defaultHyperParameters = this.defaultHyperParameters(settings.algorithm_version)

        // Initialize with default hyper-parameters and overwrite with loaded values
        settings.hyper_parameters = { ...defaultHyperParameters, ...value.hyper_parameters }

        if (Object.keys(settings.hyper_parameters).length === 0) {
          settings.hyper_parameters = Object.assign({}, defaultHyperParameters)
        }
      } else {
        settings.algorithm_version = defaultVersion
        settings.hyper_parameters = Object.assign({}, this.defaultHyperParameters(defaultVersion))
      }
      return settings
    },
    valueFromSettings (settings) {
      if (undefined === settings) {
        settings = this.settingsFromValue()
      }
      return {
        algorithm_version: settings.algorithm_version,
        hyper_parameters: Object.assign({}, settings.hyper_parameters)
      }
    },
    input () {
      var value = this.valueFromSettings(this.settings)
      this.$emit('input', value)
    },
    getRules (param) {
      const basicRules = { required: true, double: true }
      if (!param) {
        return basicRules
      }
      param = param.param
      if (param === 'break_even_randomness' || param === 'break_even_increase' || param === 'exp_increment_spontaneous_bids' || param === 'exp_increment_initial_bid') {
        basicRules.bea = { name: param }
        basicRules.between = { min: 0, max: 1 }
      } else if (param === 'walkaway_relaxation') {
        basicRules.between = { min: 0, max: 1 }
      } else if (param === 'decay_rate') {
        basicRules.between = { min: 0, max: 1 }
      } else if (param === 'decay_walkaway_multiplier') {
        basicRules.between = { min: 0, max: 10 }
      } else if (param === 'exp_max_bids') {
        basicRules.between = { min: 1, max: 20 }
      } else {
        basicRules.coherent = { name: param }
        basicRules.betweenNi = { min: 0, max: 1 }
      }
      return basicRules
    }
  }
}
</script>
<style lang="scss">
</style>
