import Vue from 'vue'
import Router from 'vue-router'
import QueryString from 'qs'

import Login from '@/pages/Login'
import AutoLogin from '@/pages/AutoLogin'
import RetailerTermsAndConditions from '@/pages/account/RetailerTermsAndConditions'
import AccountHome from '@/pages/account/AccountHome'
import AccountSecurity from '@/pages/account/AccountSecurity'
import ForgotPassword from '@/pages/account/ForgotPassword'
import ResetPassword from '@/pages/account/ResetPassword'
import RetailerMarketQuestionnaire from '@/pages/RetailerMarketQuestionnaire'

import BotTest from '@/pages/admin/BotTest'

import AdminDashboard from '@/pages/admin/Dashboard'
import AdminNewDashboard from '@/pages/admin/NewDashboard'
import RetailerNewDashboard from '@/pages/NewDashboard'
import Retailers from '@/pages/admin/Retailers'
import Retailer from '@/pages/admin/Retailer'
import ShopifyStore from '@/pages/admin/ShopifyStore'
import Search from '@/pages/admin/Search'
import Sessions from '@/pages/Sessions'
import Session from '@/pages/Session'

import Rules from '@/pages/Rules'
import Rule from '@/pages/Rule'
import Campaigns from '@/pages/Campaigns'
import Campaign from '@/pages/Campaign'

import RetailerSettings from '@/pages/RetailerSettings'
import Products from '@/pages/Products'
import Product from '@/pages/Product'
import ProductCategories from '@/pages/ProductCategories'
import ProductCategory from '@/pages/ProductCategory'
import Cohorts from '@/pages/Cohorts'
import Cohort from '@/pages/Cohort'

import Addon from '@/pages/Addon'
import Addons from '@/pages/Addons'

import Theme from '@/pages/Theme'
import Themes from '@/pages/Themes'

import store from '@/store'
import maintenance from '@/utils/maintenance'

import AccountPlanChoice from '@/pages/account/AccountPlanChoice'
import AccountPlan from '@/pages/account/AccountPlan'
import AccountUsage from '@/pages/account/AccountUsage'
import AccountUsageDetails from '@/pages/account/AccountUsageDetails'

import Experiments from '@/pages/Experiments'
import Experiment from '@/pages/Experiment'

import Reasons from '@/pages/Reasons'
import Reason from '@/pages/Reason'

Vue.use(Router)

var router = new Router({
  mode: 'hash',
  parseQuery (query) {
    return QueryString.parse(query, { depth: 50 })
  },
  stringifyQuery (query) {
    const result = QueryString.stringify(query, { depth: 50 })
    return result ? `?${result}` : ''
  },
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '',
      redirect: to => {
        if (store.state.user != null && store.state.user.retailer != null) {
          return `/retailers/${store.state.user.retailer.id}/dashboard`
        } else {
          return '/retailers'
        }
      }
    },
    {
      path: '/session/new',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Nibble Admin Login',
        authenticated: false
      }
    },
    {
      path: '/session/new/:token',
      name: 'AutoLogin',
      component: AutoLogin,
      meta: {
        title: 'Nibble Admin Login',
        authenticated: false
      }
    },
    {
      path: '/account',
      name: 'AccountHome',
      component: AccountHome,
      meta: {
        title: 'My Account',
        authenticated: true
      }
    },
    {
      path: '/account/security',
      name: 'AccountSecurity',
      component: AccountSecurity,
      meta: {
        title: 'My Account',
        authenticated: true
      }
    },
    {
      path: '/account/forgot',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        title: 'Nibble Admin Login',
        authenticated: false
      }
    },
    {
      path: '/account/forgot/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        title: 'Reset Nibble Password',
        authenticated: false
      }
    },
    {
      path: '/retailers/:retailerId/account/plan/choice',
      name: 'AccountPlanChoice',
      component: AccountPlanChoice,
      meta: {
        title: 'Account Plan Choice',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/account/plan',
      name: 'AccountPlan',
      component: AccountPlan,
      meta: {
        title: 'Account Plan',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/account/usage',
      name: 'AccountUsage',
      component: AccountUsage,
      meta: {
        title: 'Account Usage',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/account/usage/:itemId',
      name: 'AccountUsageDetails',
      component: AccountUsageDetails,
      meta: {
        title: 'Account Usage Details',
        authenticated: true
      }
    },
    {
      path: '/admin/dashboard',
      name: 'AdminDashboard',
      component: AdminDashboard,
      meta: {
        title: 'Admin Dashboard',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/admin/newdashboard',
      name: 'AdminNewDashboard',
      component: AdminNewDashboard,
      meta: {
        title: 'Admin New Dashboard',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/admin/search',
      name: 'Search',
      component: Search,
      meta: {
        title: 'Search',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/admin/algorithm',
      name: 'Algorithm Rules',
      component: Rules,
      props: {
        context: 'admin:algorithm'
      },
      meta: {
        title: 'Algorithm Rules',
        authenticated: true
      }
    },
    {
      path: '/admin/themes',
      name: 'Themes',
      component: Themes,
      props: {
        context: 'admin:theme'
      },
      meta: {
        title: 'Themes',
        authenticated: true
      }
    },
    {
      path: '/admin/themes/:itemId',
      name: 'Theme',
      component: Theme,
      props: {
        context: 'admin:theme'
      },
      meta: {
        title: 'Theme',
        authenticated: true
      }
    },
    {
      path: '/admin/rules/:itemId',
      name: 'Admin Rule',
      component: Rule,
      meta: {
        title: 'Rule',
        authenticated: true
      }
    },
    {
      path: '/admin/bot-test',
      name: 'Bot Tester',
      component: BotTest,
      meta: {
        title: 'Bot Test',
        authenticated: true
      }
    },
    {
      path: '/retailers',
      name: 'Retailers',
      component: Retailers,
      meta: {
        title: 'Retailers',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/retailers/:retailerId',
      name: 'Retailer',
      component: Retailer,
      meta: {
        title: 'Retailer',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/retailers/:retailerId/shopify_store',
      name: 'ShopifyStore',
      component: ShopifyStore,
      meta: {
        title: 'Shopify Store',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/retailers/:retailerId/dashboard',
      name: 'RetailerDashboard',
      component: AdminDashboard,
      meta: {
        title: 'Retailer Dashboard',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/newdashboard',
      name: 'RetailerNewDashboard',
      component: RetailerNewDashboard,
      meta: {
        title: 'New Retailer Dashboard',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/terms',
      name: 'RetailerTermsAndConditions',
      component: RetailerTermsAndConditions,
      meta: {
        title: 'Terms &amp; Conditions',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/onboarding/market_questionnaire',
      name: 'RetailerMarketQuestionnaire',
      component: RetailerMarketQuestionnaire,
      meta: {
        title: 'Optimise Nibble',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/settings',
      name: 'RetailerSettings',
      component: RetailerSettings,
      meta: {
        title: 'Store Settings',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/sessions',
      name: 'Sessions',
      component: Sessions,
      props: {
        context: 'archive'
      },
      meta: {
        title: 'Nibbles',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/sessions/:itemId',
      name: 'Session',
      component: Session,
      meta: {
        title: 'Nibble',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/rules',
      name: 'Rules',
      component: Rules,
      props: {
        context: 'ruleset'
      },
      meta: {
        title: 'Ruleset',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/archive',
      name: 'Rules Archive',
      component: Rules,
      props: {
        context: 'archive'
      },
      meta: {
        title: 'Rules Archive',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/rules/:itemId',
      name: 'Rule',
      component: Rule,
      meta: {
        title: 'Rule',
        authenticated: true
      }
    },
    {
      path: '/admin/experiments/:itemId',
      name: 'Experiment',
      component: Experiment,
      meta: {
        title: 'Experiment',
        authenticated: true
      }
    },
    {
      path: '/admin/experiments',
      name: 'Experiments',
      component: Experiments,
      props: {
        context: 'experiments'
      },
      meta: {
        title: 'Experiments',
        authenticated: true
      }
    },
    {
      path: '/admin/experiments-archive',
      name: 'Experiments Archive',
      component: Experiments,
      props: {
        context: 'experiments-archive'
      },
      meta: {
        title: 'Experiments Archive',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/campaigns',
      name: 'Campaigns',
      component: Campaigns,
      meta: {
        title: 'Magic Links',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/campaigns/:itemId',
      name: 'Campaign',
      component: Campaign,
      meta: {
        title: 'Magic Link',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/products',
      name: 'Products',
      component: Products,
      meta: {
        title: 'Products',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/products/:itemId',
      name: 'Product',
      component: Product,
      meta: {
        title: 'Product',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/addon_items',
      name: 'Addons',
      component: Addons,
      meta: {
        title: 'Gift With Purchase',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/addon_items/:itemId',
      name: 'Addon',
      component: Addon,
      meta: {
        title: 'Gift With Purchase',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/product_categories',
      name: 'ProductCategories',
      component: ProductCategories,
      meta: {
        title: 'Product Categories',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/product_categories/:itemId',
      name: 'ProductCategory',
      component: ProductCategory,
      meta: {
        title: 'Product Category',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/cohorts',
      name: 'Cohorts',
      component: Cohorts,
      meta: {
        title: 'Cohorts',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/cohorts/:itemId',
      name: 'Cohort',
      component: Cohort,
      meta: {
        title: 'Cohort',
        authenticated: true
      }
    },
    {
      path: '/sessions',
      name: 'Admin Sessions',
      component: Sessions,
      props: {
        inArchive: false
      },
      meta: {
        title: 'Nibbles',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/sessions/:itemId',
      name: 'Admin Session',
      component: Session,
      meta: {
        title: 'Nibble',
        authenticated: true,
        adminOnly: true
      }
    },
    {
      path: '/retailers/:retailerId/reasons',
      name: 'Reasons',
      component: Reasons,
      props: {
        context: 'reasons'
      },
      meta: {
        title: 'Reasons',
        authenticated: true
      }
    },
    {
      path: '/retailers/:retailerId/reasons/:itemId',
      name: 'Reason',
      component: Reason,
      props: {
        context: 'reasons'
      },
      meta: {
        title: 'Reason',
        authenticated: true
      }
    },
    {
      path: '/admin/reasons/:itemId',
      name: 'Reason global',
      component: Reason,
      props: {
        context: 'admin:global'
      },
      meta: {
        title: 'Reason global',
        authenticated: true
      }
    },
    {
      path: '/admin/reasons/',
      name: 'Reasons global',
      component: Reasons,
      props: {
        context: 'admin:global'
      },
      meta: {
        title: 'Reasons global',
        authenticated: true
      }
    }
  ]
})
router.beforeEach((to, from, next) => {
  if (store.state.isInMaintenanceMode && to.path !== 'maintenance') {
    console.log('Redirect to maintenance page')
    maintenance.redirectToStatusPage()
    return
  }
  if (to.meta.title == null) {
    document.title = 'Nibble'
  } else {
    document.title = to.meta.title
  }
  if (to.meta.authenticated) {
    store.dispatch('ensureLoggedIn').then(() => {
      if (store.state.user.retailer != null && !store.state.user.retailer.retailer_terms_agreed && !store.state.user.isAdmin) {
        // User needs to accept terms & conditions
        if (to.path !== `/retailers/${store.state.user.retailer.id}/terms`) {
          next(`/retailers/${store.state.user.retailer.id}/terms`)
        } else {
          next()
        }
      } else {
        const nextOnboardingTask = store.state.user.retailer != null && store.state.user.retailer.incomplete_onboarding_tasks.length > 0 ? store.state.user.retailer.incomplete_onboarding_tasks[0] : null
        if (nextOnboardingTask != null && !store.state.user.isAdmin) {
          console.log(`Retailer needs to complete onboarding task: ${nextOnboardingTask}`)
          if (to.path !== `/retailers/${store.state.user.retailer.id}/onboarding/${nextOnboardingTask}`) {
            next(`/retailers/${store.state.user.retailer.id}/onboarding/${nextOnboardingTask}`)
          } else {
            next()
          }
        } else if (to.meta.adminOnly) {
          if (store.state.user.is_admin) {
            next()
          } else {
            next('/')
          }
        } else {
          next()
        }
      }
    }).catch((error) => {
      if (error) {
        console.log(error)
      }
      next({ path: '/session/new', query: { return_path: to.path, return_query: to.query } })
    })
  } else {
    store.dispatch('logInIfPossible').then(() => {
      next()
    })
  }
})

export default router
