<template>
  <nav class="level" v-if="totalItemCount > 0">
    <div class="level-left">
      <p class="level-item buttons" v-if="offset > 0">
        <b-button type="is-rounded" @click="navigate(offset - pageSize)">Previous</b-button>
      </p>
    </div>
    <div class="level-item">
      Page
      <div class="field">
        <p class="control">
          <input class="input" type="text" v-model.number="page" style="width:4em;margin:0 1em">
        </p>
      </div>
      of {{Math.ceil(totalItemCount / pageSize)}}
    </div>
    <div class="level-right">
      <p class="level-item buttons" v-if="totalItemCount != null && offset < totalItemCount - pageSize">
        <b-button type="is-rounded" @click="navigate(offset + pageSize)">Next</b-button>
      </p>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'ListPaginator',
  props: {
    totalItemCount: {
      required: false,
      type: Number
    },
    offset: {
      required: true,
      type: Number
    },
    pageSize: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      page: Math.floor(this.offset / this.pageSize) + 1
    }
  },
  watch: {
    page () {
      this.navigate((this.page - 1) * this.pageSize)
    },
    offset () {
      this.page = Math.floor(this.offset / this.pageSize) + 1
    }
  },
  methods: {
    navigate (offset) {
      this.$emit('navigate', offset)
    }
  }
}
</script>
