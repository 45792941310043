<template>
  <page-layout :menu-available="false">
    <main-column :no-header="true">
      <div class="narrow-column">
        <form class="card mb-4" v-on:submit.prevent.stop="submit">
          <div class="card-content">
            <h2 class="title is-2 has-text-centered has-text-primary mb-2">Welcome!</h2>
            <h2 class="title is-2 has-text-centered mb-8">
              Let's configure Nibble for your needs
            </h2>
            <b-notification
              :active="errorMessage != null"
              :message="errorMessage"
              type="is-danger"
              :closable="false"
            ></b-notification>
            <h4 class="title is-4 mb-2">What are your main objectives for your store?</h4>
            <div class="columns">
              <div class="column">
                <b-field>
                  <b-checkbox
                    v-model="objectives"
                    native-value="Reduce discounts"
                  >
                    Reduce discounts
                  </b-checkbox>
                </b-field>
                <b-field>
                  <b-checkbox
                    v-model="objectives"
                    native-value="Increase conversion"
                  >
                    Increase conversion
                  </b-checkbox>
                </b-field>
                <b-field>
                  <b-checkbox
                    v-model="objectives"
                    native-value="Clear stock"
                  >
                    Clear stock
                  </b-checkbox>
                </b-field>
                <b-field>
                  <b-checkbox
                    v-model="objectives"
                    native-value="Reduce customer acquisition costs"
                  >
                    Reduce customer acquisition costs
                  </b-checkbox>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-checkbox v-model="objectives" native-value="Increase average order value">
                    Increase average order value
                  </b-checkbox>
                </b-field>
                <b-field>
                  <b-checkbox
                    v-model="objectives"
                    native-value="Customer engagement / gamification"
                  >
                    Customer engagement / gamification
                  </b-checkbox>
                </b-field>
                <b-field>
                  <b-checkbox
                    v-model="objectives"
                    native-value="Pricing insights"
                  >
                    Pricing insights
                  </b-checkbox>
                </b-field>
                <b-field>
                  <b-checkbox
                    v-model="objectives"
                    native-value="Work with MAP pricing"
                  >
                    Work with MAP pricing
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <h4 class="title is-4 mb-2">What's your market sector?</h4>
                <b-field>
                  <b-select v-model="marketSector"
                          placeholder="Please Choose"
                          expanded>
                  <option v-for="(option, optionIndex) in allMarketSectors" :key="optionIndex" :value="option">{{option}}</option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="field is-grouped-right mt-5 mb-0">
              <p class="control">
                <button
                  type="submit"
                  class="button is-primary is-rounded is-pulled-right"
                  :disabled="!completed"
                >
                  Confirm
                </button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import { ToastProgrammatic as Toast } from 'buefy'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'

export default {
  name: 'RetailerMarketQuestionnaire',
  components: { PageLayout, MainColumn },
  data () {
    return {
      retailerId:
        this.$route.params.retailerId != null
          ? parseInt(this.$route.params.retailerId)
          : null,
      objectives: [],
      marketSector: null,
      allMarketSectors: [
        'Apparel',
        'B2B',
        'Books',
        'Car Parts',
        'Cleaning Products',
        'Collectibles',
        'Consumer Electronics',
        'Education',
        'Entertainment and Events',
        'Estate Sales',
        'Financial Services',
        'Food and Drink',
        'Handbags',
        'Hardware',
        'Health & Beauty',
        'Home and Garden',
        'Home Improvement',
        'Jewellery',
        'Kitchenwares',
        'Medical',
        'Music',
        'Pets',
        'Publication',
        'Shoes',
        'Specialist Retail',
        'Sports Equipment',
        'Toys',
        'Travel',
        'Various',
        'Other'
      ],
      allStockTypes: [
        'New',
        'Resale',
        'Both'
      ],
      allBusinessModels: [
        'Own Brand Store',
        'Marketplace',
        'Both'
      ],
      errorMessage: null
    }
  },
  computed: {
    completed () {
      return (
        this.objectives.length > 0 &&
        this.marketSector != null
      )
    }
  },
  methods: {
    submit () {
      if (!this.completed) {
        this.errorMessage = 'Please complete all questions to continue.'
      } else {
        const params = {
          objectives: this.objectives,
          market_sector: this.marketSector
        }
        nibble
          .put(
            `/retailers/${this.retailerId}`,
            params,
            this.$store.getters.authHeaders
          )
          .then((response) => {
            Toast.open({ message: 'Saved', type: 'is-primary' })
            return this.$store.dispatch('forceUserUpdate')
          })
          .then(() => {
            this.$router.push(`/retailers/${this.retailerId}/rules`)
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errorMessage = error.response.data.errorMessage
            }
          })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card-content {
  display: flex;
  flex-direction: column;
}
</style>
