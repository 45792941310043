import { required, confirmed, email, double, numeric, between } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'This field must be a valid email'
})

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match'
})

extend('double', {
  ...double,
  message: 'This field must be a decimal number'
})

extend('url', {
  validate: (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' +
                            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                            '((\\d{1,3}\\.){3}\\d{1,3}))' +
                            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                            '(\\?[;&a-z\\d%_.~+=-]*)?' +
                            '(\\#[-a-z\\d_]*)?$', 'i')
    return !!pattern.test(str)
  },
  message: 'This is not a valid URL'
})

extend('percentage', {
  validate: (str) => {
    return Number(0) <= Number(str) && Number(100) >= Number(str)
  },
  message: 'This is not a valid {_field_}'
})

extend('numeric', {
  ...numeric,
  message: 'This field must be a number'
})

extend('betweenNi', {
  params: ['min', 'max'],
  validate: (value, { min, max }) => {
    if (value < max && value > min) {
      return true
    }
    return false
  },
  message: 'This field must be between {min} and {max} (not inclusive)'
})

extend('bea', {
  // RULE: Break-even randomness must be ≤ half the break-even increase
  params: ['name'],
  validate: (value, { name }) => {
    if (name === 'break_even_randomness') {
      const targetName = 'break_even_increase'
      const target = document.querySelectorAll('input[customId="' + targetName + '"]')
      if (target[0] !== undefined) {
        if (Number(value) > Number(target[0].value) / 2) {
          return false
        }
      }
    } else if (name === 'break_even_increase') {
      const targetName = 'break_even_randomness'
      const target = document.querySelectorAll('input[customId="' + targetName + '"]')
      if (target[0] !== undefined) {
        if (Number(target[0].value) > Number(value) / 2) {
          return false
        }
      }
    }
    return true
  },
  message: 'break_even_randomness must be ≤ half the break_even_increase'
})

extend('between', {
  ...between,
  message: 'This field must be between {min} and {max}'
})

extend('coherent', {
  params: ['name'],
  validate: (value, { name }) => {
    let targetName = false
    if (name.endsWith('_low')) {
      targetName = name.replace('_low', '_high')
    } else if (name.endsWith('_high')) {
      targetName = name.replace('_high', '_low')
    }
    if (!targetName) {
      return true
    }
    const target = document.querySelectorAll('input[customId="' + targetName + '"]')
    if (target[0] !== undefined) {
      if (name.endsWith('_low')) {
        if (Number(target[0].value) < Number(value)) {
          return false
        }
      }
      if (name.endsWith('_high')) {
        if (Number(target[0].value) > Number(value)) {
          return false
        }
      }
    }
    return true
  },
  message: '*_high field must be higher or equal to *_low'
})
