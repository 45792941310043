<template>
  <b-modal :active.sync="active" :width="480">
    <div class="box">
      <form @submit="submitUpdate">
        <h3 class="title is-3">Spending Limit</h3>
        <p class="mb-3">
          <strong>
            Nibble will charge you {{ usagePercentage }}% of sales it converts,
            subject to your minimum monthly fee.
          </strong>
        </p>
        <p class="mb-3">
          Your plan is billed via Shopify on a 30-day cycle. You can set a limit on how much you wish to spend beyond your minimum
          in each 30-day cycle below:
        </p>
        <b-field class="is-half" label="Monthly Spending Limit (USD)">
          <p class="control">
            <span class="button is-static">$</span>
          </p>
          <input type="text" class="input" v-model.number="usageCap" placeholder="0">
        </b-field>
        <p class="mb-3">
          If you reach your limit, Nibble will be disabled on your site until your next cycle starts, or until you raise your limit.
        </p>
        <div class="field is-grouped is-grouped-right">
          <p class="buttons">
            <b-button type="is-text is-rounded" @click="cancel">Cancel</b-button>
            <b-button type="is-primary is-rounded" native-type="submit">Continue</b-button>
          </p>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import nibble from '@/utils/nibble'

export default {
  name: 'UpdatePlanModal',
  props: {
    retailerId: {
      required: true,
      type: Number
    },
    billingSchemeId: {
      required: false,
      type: Number
    }
  },
  data () {
    return {
      active: false,
      planIdentifier: null,
      usagePercentage: 2,
      usageCap: 0.0
    }
  },
  methods: {
    show (planIdentifier, usagePercentage, minimumUsageLimit) {
      this.planIdentifier = planIdentifier
      this.usagePercentage = usagePercentage
      this.usageCap = minimumUsageLimit
      this.active = true
    },
    cancel () {
      this.active = false
    },
    submitUpdate (e) {
      e.preventDefault()
      var params = { plan_identifier: this.planIdentifier, usage_cap: this.usageCap }
      if (this.billingSchemeId != null) {
        params.pending_billing_scheme_id = this.billingSchemeId
      }
      nibble.put(`/retailers/${this.retailerId}/account/plan`, params, this.$store.getters.authHeaders)
        .then((response) => {
          if (response.data.status === 'redirect') {
            window.location.href = response.data.redirect_uri
          } else {
            this.$emit('completed')
          }
        })
        .catch((error) => { console.log(error) })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
