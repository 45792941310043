<template>
  <table class="table is-narrow is-fullwidth">
    <tbody>
      <tr v-for="feature in featureSettings" :key="feature.code">
        <td class="is-narrow">
          <b-checkbox :native-value="feature.code" v-model="feature.enabled">
            {{ feature.code }}
          </b-checkbox>
        </td>
        <td>
          <b-field>
            <b-input v-model="feature.hyperParameters"></b-input>
          </b-field>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'FeatureList',
  components: {},
  props: ['value', 'availableFeatures'],
  data () {
    return {
      featureSettings: this.value
    }
  },
  watch: {
    value: function (newValue) {
      this.featureSettings = newValue
    }
  },
  methods: {
    input () {
      this.$emit('input', this.featureSettings)
    }
  }
}
</script>
