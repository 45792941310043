<template>
  <div class="page">
    <top-nav :retailerId="retailerId" @toggleMenu="toggleMenu" :menu-available="menuAvailable"></top-nav>
    <div class="columns">
      <div class="column is-narrow menu-column" v-if="menuAvailable">
        <main-menu v-if="retailerId" :retailer-id="retailerId" :expanded="menuExpanded" :active-item="activeMenuItem"></main-menu>
        <admin-menu v-else :expanded="menuExpanded" :active-item="activeMenuItem"></admin-menu>
      </div>
      <div class="column main-column">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/TopNav'
import MainMenu from '@/components/MainMenu'
import AdminMenu from '@/components/AdminMenu'

export default {
  name: 'PageLayout',
  components: { TopNav, MainMenu, AdminMenu },
  props: {
    retailerId: {
      required: false,
      type: Number
    },
    activeMenuItem: {
      required: false,
      type: String
    },
    menuAvailable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      menuExpanded: this.$store.getters.isMenuExpanded && this.menuAvailable
    }
  },
  methods: {
    toggleMenu () {
      this.menuExpanded = !this.menuExpanded
      this.$store.commit('setMenuExpanded', this.menuExpanded)
      console.log(`Menu expanded ${this.menuExpanded}`)
    }
  }
}
</script>
