<template>
  <div class="sankey-chart" :id="id"></div>
</template>

<script>
import { chartSankey } from 'd2b'
import { select } from 'd3'

export default {
  name: 'SankeyChart',
  props: ['id', 'chartData'],
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: 'update'
  },
  mounted () {
    this.deferredUpdate()
  },
  created () {
    window.addEventListener('resize', this.deferredUpdate)
  },
  destroyed () {
    window.removeEventListener('resize', this.deferredUpdate)
  },
  methods: {
    deferredUpdate () {
      setTimeout(this.update, 0)
    },
    update () {
      const container = select(`#${this.id}`)
      if (this.chart == null) {
        if (this.chartData != null) {
          this.chart = chartSankey()
          this.chart.chartFrame.legendEnabled = false
          container.datum(this.chartData).call(this.chart.advanced)
        }
      } else {
        container.call(this.chart.advanced)
      }
    }
  }
}
</script>

<style>
  .sankey-chart {
    width: 100%;
    height: 25rem;
  }
</style>
