<template>
  <page-layout :active-menu-item="menuItem">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle" content-class="main-column-sub-columns columns">
      <template #header-right v-if="items.length > 0">
        <p class="level-item buttons">
          <b-button type="is-text is-icon" :disabled="selectedItems.length === 0" @click="deleteBatch">
            <material-icon icon="delete_forever" size="large"></material-icon>
          </b-button>
          <b-button type="is-primary is-rounded" tag="router-link" :to="newExperimentLink" v-if="canCreateExperiment">New Experiment</b-button>
        </p>
      </template>
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Experiments"
            :showButton="canCreateExperiment"
            buttonText="New Experiment"
            :link="newExperimentLink">
            <template v-slot:text>
              You can create new experment and edit any existing experiment from this page.
            </template>
          </empty-list-indicator>
          <b-table v-else
                  :data="items"
                  :checked-rows.sync="selectedItems"
                  hoverable
                  checkable
                  selectable>
            <b-table-column field="experiment_name" label="Text" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  <strong>
                    {{ props.row.name }}
                  </strong>
                </router-link>
              </template>
            </b-table-column>
            <b-table-column field="description" label="">
              <template #default="props">
                <b-tooltip :label="formatDescription(props.row.description)" v-if="formatDescription(props.row.description) !== ''">
                  <material-icon size="medium" icon="info" status-class="normal"></material-icon>
                </b-tooltip>
              </template>
            </b-table-column>
            <b-table-column field="starts_at" label="Started at">
              <template #default="props">
                {{ formatDate(props.row.starts_at) }}
              </template>
            </b-table-column>
            <b-table-column field="starts_at" label="Ended at" v-if="isArchive">
              <template #default="props">
                {{ formatDate(props.row.ends_at) }}
              </template>
            </b-table-column>
            <b-table-column field="active" label="Cohorts" width="80">
              <template #default="props">
                {{ props.row.cohorts.length }}
              </template>
            </b-table-column>
            <b-table-column field="active" label="Active?" width="80" v-if="canToggleActive">
              <template #default="props">
                <b-switch :value="props.row.active" @input="toggleActive(props.row, $event)"></b-switch>
              </template>
            </b-table-column>
            <b-table-column label="" width="60">
              <template #default="props">
                <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                  <template #trigger>
                    <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                  </template>
                  <b-dropdown-item aria-role="listitem" @click="editItem(props.row)">Edit</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="deleteItem(props.row)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import nibble from '@/utils/nibble'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import MaterialIcon from '@/components/MaterialIcon'
import ItemList from '@/utils/mixins/ItemList'

function fixDate (d = '') {
  if (typeof d === 'string') {
    d = d.replaceAll('-', '/')
    d = d.replaceAll('T', ' ')
  } else {
    d = '-'
  }
  return d
}

export default {
  name: 'Experiments',
  mixins: [ItemList],
  props: ['context'],
  components: { PageLayout, MainColumn, EmptyListIndicator, MaterialIcon },
  data () {
    return {
      loading: false,
      items: [],
      selectedItems: [],
      draggingRow: null,
      draggingRowIndex: null
    }
  },
  computed: {
    newExperimentLink () {
      return '/admin/experiments/new'
    },
    listPath () {
      return '/admin/experiments'
    },
    listApiPath () {
      return '/rules'
    },
    listApiParams () {
      return { context: this.context }
    },
    itemsKey () {
      return 'rules'
    },
    itemIdKey () {
      return 'rule_id'
    },
    itemName () {
      return 'Experiment'
    },
    showEmptyList () {
      console.log(this.items)
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    },
    menuItem () {
      return this.context
    },
    pageTitle () {
      return this.context === 'experiments' ? 'Experiments' : 'Experiments archive'
    },
    canToggleActive () {
      return this.context !== 'experiments-archive'
    },
    canCreateExperiment () {
      return this.context !== 'experiments-archive'
    },
    menuColumnIndex () {
      var index = this.activeColumnIndex
      if (this.canToggleActive) {
        index = index + 1
      }
      return index
    },
    isArchive () {
      return this.context === 'experiments-archive'
    }
  },
  methods: {
    formatDescription (description) {
      if (!description) {
        return ''
      }
      return description
    },
    formatDate (date) {
      return fixDate(date)
    },
    itemPath (item) {
      if (this.context === 'experiments') {
        return `/admin/experiments/${item.rule_id}`
      } else {
        return { path: `/admin/experiments/${item.rule_id}`, query: { context: this.context } }
      }
    },
    deleteItem (item) {
      nibble.delete(this.itemApiPath(item), { context: this.context }, this.$store.getters.authHeaders)
        .then((response) => {
          Toast.open(`${this.itemName} Deleted`, { type: 'is-primary' })
          this.fetchData()
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    toggleActive (item, active) {
      nibble.put(`${this.listApiPath}/${item.rule_id}`, { context: this.context, active: active }, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchData()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        })
    },
    clickCell (row, column, rowIndex, columnIndex) {
      if (this.canToggleActive && columnIndex === this.activeColumnIndex) {
        return
      }
      if (columnIndex === this.menuColumnIndex) {
        return
      }
      this.clickRow(row)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
