<template>
  <div class="stat-values-change is-flex is-flex-direction-row is-align-items-center">
    <material-icon v-if="displayChangeArrowIcon" size="xsmall" :icon="changeArrowIcon" :status-class="changeStatus"/>
    <span :class="['stat-values-change-percentage', 'nibble-icon', `icon-status-${changeStatus}`]">
      {{ change.percentage }}
    </span>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  name: 'StatChange',
  components: { MaterialIcon },
  props: {
    change: {
      required: true,
      type: Object
    },
    reverse: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayChangeArrowIcon: function () {
      switch (this.change.sign) {
        case -1:
          return true
        case 0:
          return true
        case 1:
          return true
        default:
          return false
      }
    },
    changeArrowIcon: function () {
      switch (this.change.sign) {
        case -1:
          return 'south_east'
        case 0:
          return 'horizontal_rule'
        case 1:
          return 'north_east'
        default:
          return null
      }
    },
    changeStatus: function () {
      switch (this.change.sign) {
        case -1:
          return this.reverse ? 'stat-up' : 'stat-down'
        case 0:
          return 'stat-zero'
        case 1:
          return this.reverse ? 'stat-down' : 'stat-up'
        default:
          return 'stat-none'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stat-values-change {
  margin-left: 12.5px;
  font-weight: 400;
  .stat-values-change-percentage {
    margin-left: 2.5px;
  }
  .nibble-icon {
    margin-right: 0 !important;
  }
}
</style>
