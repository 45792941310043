<template>
  <page-layout :retailer-id="retailerId" active-menu-item="cohorts">
    <main-column :breadcrumbs="breadcrumbs" page-title="User Cohorts" content-class="main-column-sub-columns columns">
      <template #header-right>
        <p class="level-item buttons">
          <b-button type="is-text" icon-right="delete" :disabled="selectedItems.length === 0" @click="deleteBatch"></b-button>
          <b-button type="is-primary is-rounded" tag="router-link" :to="newCohortLink">New Cohort</b-button>
        </p>
      </template>
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Cohorts"
            :showButton="true"
            buttonText="New Cohort"
            :link="newCohortLink">
            <template v-slot:text>
              Integrate your user cohorts from Shopify here. Once these are set up, you can set up Rules to offer Nibble to specific users on your store – for example, Nibble could only appear to users who are logged in.
            </template>
          </empty-list-indicator>
          <b-table v-else :data="items" :checked-rows.sync="selectedItems" checkable hoverable>
            <b-table-column field="name" label="Name" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ props.row.name }}
                </strong>
              </router-link>
            </b-table-column>
            <b-table-column label="" v-slot="props">
              <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                <template #trigger>
                  <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                </template>
                <b-dropdown-item aria-role="listitem" @click="editItem(props.row)">Edit</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="deleteItem(props.row)">Delete</b-dropdown-item>
              </b-dropdown>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ItemList from '@/utils/mixins/ItemList'

export default {
  name: 'Cohorts',
  mixins: [ItemList],
  components: { PageLayout, MainColumn, EmptyListIndicator },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      loading: false,
      items: [],
      selectedItems: []
    }
  },
  computed: {
    newCohortLink () {
      return `/retailers/${this.retailerId}/cohorts/new`
    },
    listPath () {
      return `/retailers/${this.retailerId}/cohorts`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/cohorts`
    },
    itemsKey () {
      return 'cohorts'
    },
    itemIdKey () {
      return 'cohort_id'
    },
    itemName () {
      return 'Cohort'
    },
    showEmptyList () {
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
