<template>
  <div class="stat-wrapper">
    <div class="stat is-flex flex-direction-row is-justify-content-center is-align-items-center">
      <div class="stat-icon is-flex is-flex-direction-column is-justify-content-center">
        <div class="icon-container" :class="status">
          <svg-icon v-if="svg" size="large" :icon="icon" :status-class="status" />
          <material-icon v-else size="large" :icon="icon" :status-class="status" />
        </div>
      </div>
      <div class="stat-content is-flex is-flex-direction-column is-justify-content-space-around">
        <span>{{ title }}</span>
        <div class="stat-values is-flex is-flex-direction-row is-align-items-center">
          <div class="stat-values-number title is-2">
            <stat-change v-if="displayChange" :change="changeValue" />
            <span v-else>{{ data.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import SvgIcon from '@/components/SvgIcon'
import StatChange from '@/components/analytics/StatChange'

export default {
  name: 'UpliftStat',
  components: { MaterialIcon, SvgIcon, StatChange },
  props: {
    showChange: {
      required: false,
      type: Boolean,
      default: true
    },
    title: {
      required: true,
      type: String
    },
    data: {
      required: true,
      type: Object
    },
    icon: {
      required: true,
      type: String
    },
    svg: {
      required: false,
      type: Boolean,
      default: false
    },
    status: {
      required: false,
      type: String,
      default: 'normal'
    }
  },
  computed: {
    changeValue: function () {
      return {
        percentage: this.data.value,
        sign: this.data.sign
      }
    },
    displayChange: function () {
      return this.data.sign !== 0
    }
  }
}
</script>

<style lang="scss" scoped>
.stat-wrapper {
  padding: 10px 0px;

  .stat {
    height: 56px;
    justify-content: space-around;

    .stat-content {
      margin-left: 16px;
      height: 56px;

      .stat-values {
        .stat-values-number {
          margin-bottom: 0;
          font-weight: 400 !important;

          .stat-values-change {
            margin-left: 0;
          }
        }
      }
    }

    .stat-icon img {
      height: 1.5rem;
      width: 1.5rem;
      min-height: 1.5rem;
      min-width: 1.5rem;
    }
  }

  &:first-child {
    padding-top: 0px;
  }

  &:last-child {
    padding-bottom: 0px;
  }

  &:first-child:last-child {
    padding: 0;
  }
}</style>
