import Vue from 'vue'
import Vuex from 'vuex'
import addSeconds from 'date-fns/addSeconds'
import isPast from 'date-fns/isPast'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import Cookies from 'cookies-js'

import nibble from '@/utils/nibble'

Vue.use(Vuex)

const clientID = 'XO5QD20YN4T9GJTLWR38C2WFTF87WGG1H6IZKDL9'

function updateUserIntegrations (user) {
  if ('Intercom' in window) {
    if (user != null) {
      window.intercomSettings.name = user.name
      window.intercomSettings.email = user.email
      window.intercomSettings.user_id = user.id
      window.Intercom('update')
    } else {
      window.intercomSettings.name = null
      window.intercomSettings.email = null
      window.intercomSettings.user_id = null
      window.Intercom('update')
    }
  }
  if ('FullStory' in window) {
    if (user != null) {
      window.FullStory.setUserVars({
        displayName: user.name,
        email: user.email,
        uid: user.id
      })
    } else {
      window.FullStory.setUserVars({
        displayName: null,
        email: null,
        uid: null
      })
    }
  }
}

export default new Vuex.Store({
  state: {
    inMaintenanceMode: false,
    auth: {
      access_token: Cookies.get('access_token'),
      access_token_expires: (Cookies.get('access_token_expires') != null ? parseISO(Cookies.get('access_token_expires')) : null),
      refresh_token: Cookies.get('refresh_token')
    },
    user: null,
    retailer: null,
    menuExpanded: true
  },
  getters: {
    isLoggedIn: state => {
      return state.auth.refresh_token != null
    },
    isAdmin: state => {
      return state.auth.refresh_token != null && state.user != null && state.user.is_admin
    },
    isShopifyStore: state => {
      return state.retailer != null && state.retailer.shopify_store
    },
    isLimitedData: (state, getters) => {
      return state.retailer != null && getters.isFeatureAvailable('limited_data')
    },
    isFeatureAvailable: state => (featureName) => {
      return state.retailer == null || state.retailer.available_features.includes(featureName)
    },
    isMenuExpanded: state => {
      return state.menuExpanded
    },
    authHeaders: state => {
      if (state.auth != null && state.auth.access_token != null) {
        return { Authorization: 'Bearer ' + state.auth.access_token }
      } else {
        return {}
      }
    }
  },
  mutations: {
    setMaintenanceMode (state, inMaintenanceMode) {
      state.inMaintenanceMode = inMaintenanceMode
    },
    login (state, data) {
      var expiresAt = addSeconds(new Date(), data.expires_in - 60)
      Cookies.set('access_token', data.access_token)
      Cookies.set('access_token_expires', formatISO(expiresAt))
      Cookies.set('refresh_token', data.refresh_token)
      state.auth = { access_token: data.access_token, access_token_expires: expiresAt, refresh_token: data.refresh_token }
      state.user = null
      updateUserIntegrations(null)
    },
    updateUser (state, user) {
      console.log(`store user ${user}`, user)
      state.user = user
      updateUserIntegrations(user)
    },
    logout (state) {
      Cookies.expire('access_token')
      Cookies.expire('access_token_expires')
      Cookies.expire('refresh_token')
      state.auth = { access_token: null, access_token_expires: null, refresh_token: null }
      state.user = null
      updateUserIntegrations(null)
      // Removing timezone_code from local session
      nibble.timezoneManager.unset()
    },
    updateRetailer (state, retailer) {
      console.log(`store retailer ${retailer}`)
      state.retailer = retailer
      // Store retailer timezone
      if (retailer != null) {
        nibble.timezoneManager.set(retailer.timezone_code)
      } else {
        nibble.timezoneManager.unset()
      }
    },
    setMenuExpanded (state, expanded) {
      state.menuExpanded = expanded
    }
  },
  actions: {
    ensureLoggedIn ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (this.getters.isLoggedIn) {
          resolve()
        } else {
          reject(new Error('Not Logged In'))
        }
      }).then(() => {
        return this.dispatch('ensureAccessTokenIsValid')
      }).then(() => {
        return this.dispatch('ensureUserDataAvailable')
      })
    },
    logInIfPossible ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (this.getters.isLoggedIn) {
          resolve()
        } else {
          resolve()
        }
      }).then(() => {
        return this.dispatch('ensureAccessTokenIsValid')
      }).then(() => {
        return this.dispatch('ensureUserDataAvailable')
      }).catch((error) => {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve()
        })
      })
    },
    ensureAccessTokenIsValid ({ commit, state }) {
      if (state.auth.access_token_expires == null || isPast(state.auth.access_token_expires) || state.auth.access_token == null) {
        return nibble.postRaw('oauth/token', { grant_type: 'refresh_token', client_id: clientID, refresh_token: state.auth.refresh_token }).then((response) => {
          if (response.data != null) {
            commit('login', response.data)
          }
        }).catch((error) => {
          console.log(error)
          commit('logout')
        })
      } else {
        return new Promise((resolve, reject) => {
          resolve()
        })
      }
    },
    ensureUserDataAvailable ({ commit, state }) {
      if (state.user != null) {
        return new Promise((resolve, reject) => {
          resolve(state.user)
        })
      } else {
        return nibble.get('account', {}, this.getters.authHeaders).then((response) => {
          if (response.data != null) {
            commit('updateUser', response.data)
          }
        })
      }
    },
    forceUserUpdate ({ commit, state }) {
      return nibble.get('account', {}, this.getters.authHeaders).then((response) => {
        if (response.data != null) {
          commit('updateUser', response.data)
        }
        if (this.state.user.retailer != null) {
          console.log('User force update; update retailer')
          return this.dispatch('ensureRetailerDataAvailable', this.state.user.retailer.id)
        } else {
          // Removing timezone_code from local session
          nibble.timezoneManager.unset()
        }
      })
    },
    ensureRetailerDataAvailable ({ commit, state }, retailerId) {
      if (retailerId == null && state.retailer != null) {
        // No retailer set
        return new Promise((resolve, reject) => {
          commit('updateRetailer', null)
          resolve(state.retailer)
        })
      } else if (state.retailer != null && state.retailer.retailer_id === retailerId) {
        // Store retailer timezone
        nibble.timezoneManager.set(state.retailer.timezone_code)
        // Retailer is set and up to date
        return new Promise((resolve, reject) => {
          resolve(state.retailer)
        })
      } else if (retailerId != null) {
        // Wrong retailer is set; load new retailer data
        return nibble.get(`/retailers/${retailerId}`, { context: 'status' }, this.getters.authHeaders).then((response) => {
          commit('updateRetailer', response.data)
          return response.data
        })
      } else {
        // No retailer, this is an admin
        // Removing timezone_code from local session
        nibble.timezoneManager.unset()
      }
    },
    forceRetailerUpdate ({ commit, state }, retailerId) {
      return nibble.get(`/retailers/${retailerId}`, { context: 'status' }, this.getters.authHeaders).then((response) => {
        commit('updateRetailer', response.data)
      })
    }
  }
})
