<template>
  <div class="column">
    <div :class="cardClass">
      <div :class="miniBannerClass">
        <slot name="mini-banner">
          <br/>
        </slot>
      </div>
      <div class="card-content is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center">
        <header>
          <div class="details has-text-centered">
            <h2 class="title is-2 has-text-primary mb-2">
              {{ plan.name }}
            </h2>
            <p class="mb-4"><strong>{{ plan.purpose }}</strong></p>
            <p v-for="(term, index) in plan.terms" :key="index">
              <strong>
                {{ term }}
              </strong>
            </p>
          </div>
          <ul class="mt-5 mb-5">
            <li v-for="(feature, index) in plan.feature_descriptions" :key="index">
              <span v-if="index === 0" class="has-text-weight-semibold" style="color: #5E8E3E">
                <material-icon :icon="feature.icon || 'check'"/>
                {{ feature }}
              </span>
              <span v-else>
                <material-icon :icon="feature.icon || 'check'"/>
                {{ feature }}
              </span>
            </li>
          </ul>
        </header>
        <footer class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <div class="costs is-flex is-flex-direction-column is-justify-content-center is-align-items-center mb-3">
            <div class="monthly-fee-container" v-for="(pricing, index) in plan.pricing" :key="index">
              <span v-if="index === 0" class="month-text large">{{pricing}}</span>
              <span v-else class="month-text small">{{pricing}}</span>
            </div>
          </div>
          <b-button v-if="plan.is_current_plan" type="is-text is-rounded" icon-left="check" disabled expanded>
            Current Plan
          </b-button>
          <b-button v-else-if="isUpgrade" type="is-primary is-rounded" expanded @click="click">
            {{upgradeText}}
          </b-button>
          <b-button v-else type="is-primary is-rounded" expanded @click="click">
            Downgrade
          </b-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  name: 'Plan',
  components: { MaterialIcon },
  props: {
    plan: {
      required: true,
      type: Object
    },
    isUpgrade: {
      required: true,
      type: Boolean,
      default: false
    },
    isMiddlePlan: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  computed: {
    cardClass () {
      var classes = ['plan', 'card', 'is-flex', 'is-flex-direction-column']
      if (this.plan.is_current_plan) {
        classes.push('active')
      }
      return classes
    },
    miniBannerClass () {
      return ['mini-banner', 'has-text-centered', this.isMiddlePlan ? 'full' : 'blank']
    },
    upgradeText () {
      if (this.plan.self_serve) {
        return 'Upgrade'
      } else {
        return 'Contact Us'
      }
    }
  },
  methods: {
    click () {
      this.$emit('select')
    }
  }
}
</script>

<style lang="scss" scoped>
.column {
  min-height: 100%;
  max-width: 400px;
  flex-basis: inherit;
}

.plan {
  height: 100%;
  border: 1px solid #C2CCD6;
  transform: scale(1);
  transition: all 0.25s;
  &:hover {
    transform: scale(1.01);
    background-color: rgb(247, 250, 252);
    border: 1px solid #1976D2 !important;
  }
  &.active {
    border: 2px solid #1976D2 !important;
    &:hover { border-width: 2px; }
  }

  ul li {
    padding-left: 1.5rem;
    position: relative;
    .nibble-icon {
      position: absolute;
      left: 0;
      top: 1px;
    }
  }
}

.mini-banner {
  padding: 3px;
  border-radius: 14px 14px 0px 0px;
  text-transform: uppercase;
  color: white;
  font-size: 0.8em;
  font-weight: 700;
  &.blank {
    background-color: transparent;
  }
  &.full {
    background-color: #1BBB8C;
  }
}

.card-content {
  width: 100%;
  height: 100%;
}

.month-text {
  font-weight: 700;
  &.small {
    color: #668099;
  }
  &.large {
    font-size: 1.6em;
  }
}

.details {
  h2 {
    text-transform: uppercase;
  }
}

footer {
  float: bottom;
  .costs {
    .monthly-fee-container {
      .monthly-fee-small {
        font-size: 1.5em;
        font-weight: 800;
      }
      .monthly-fee-large {
        font-weight: 800;
        font-size: 2em;
      }
    }
  }
  .button, button {
    width: 180px !important;
  }
}
</style>
