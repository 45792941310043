<template>
  <page-layout :retailer-id="retailerId" :active-menu-item="menuItem">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle" content-class="main-column-sub-columns columns">
      <template #header-right v-if="items.length > 0">
        <p class="level-item buttons">
          <b-button type="is-text is-icon" :disabled="selectedItems.length === 0" @click="deleteBatch">
            <material-icon icon="delete_forever" size="large"></material-icon>
          </b-button>
          <b-button type="is-primary is-rounded" tag="router-link" :to="newRuleLink">New Rule</b-button>
        </p>
      </template>
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Rules"
            :showButton="canCreateRule"
            buttonText="New Rule"
            :link="newRuleLink">
            <template v-slot:text>
              You can create new discounting rules and edit any existing rule from this page.
              If you're seeing this message, you don't have any rules, so Nibble won't appear on your store.
            </template>
          </empty-list-indicator>
          <b-table v-else
                  :data="items"
                  :checked-rows.sync="selectedItems"
                  hoverable
                  draggable
                  checkable
                  selectable
                  @dragstart="dragstart"
                  @drop="drop"
                  @dragover="dragover"
                  @dragleave="dragleave">
            <b-table-column field="priority" centered width="48">
              <template #header>
                <material-icon size="small" icon="swap_vert"></material-icon>
              </template>
              <template #default="props">
                <span class="priority-tag">
                  {{ props.row.priority + 1 }}
                </span>
              </template>
            </b-table-column>
            <b-table-column field="name" label="Name" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  <strong>
                    {{ props.row.name }}
                  </strong>
                </router-link>
              </template>
            </b-table-column>
            <b-table-column field="icons" label="Type" width="100">
              <template #default="props">
                <span class="rule-icons">
                  <b-tooltip v-if="props.row.match_type == 'global'" label="Global">
                    <material-icon size="large" icon="public"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.match_type == 'retailer'" label="Whole Store">
                    <material-icon size="large" icon="store"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.match_type == 'product'" label="Individual Product">
                    <material-icon size="large" icon="local_offer"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.match_type == 'product_category'" label="Product Category">
                    <material-icon size="large" icon="widgets"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.cohort_ids.length > 0" label="User Cohort">
                    <material-icon size="large" icon="public"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.starts_at != null || props.row.ends_at != null" label="Time Limited">
                    <material-icon size="large" icon="schedule"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.match_type == 'cart'" label="Negotiaton On Cart">
                    <material-icon size="large" icon="shopping_cart"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.experiment_percentage" label="Experiment Percentage">
                    <span class="is-size-7">{{props.row.experiment_percentage}}%</span>
                  </b-tooltip>
                </span>
              </template>
            </b-table-column>
            <b-table-column field="algorithm_version" label="Algorithm" width="100" v-if="showAlgorithmColumn" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  {{ props.row.algorithm_version }}
                </router-link>
              </template>
            </b-table-column>
            <b-table-column field="discount" label="Discount/Objective" width="100" v-if="showDiscountColumn" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  {{ discountOrObjective(props.row) }}
                </router-link>
              </template>
            </b-table-column>
            <b-table-column field="walkaway" label="Walkaway" width="100" v-if="showWalkawayColumn" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  {{ props.row.walkaway_percentage }}%
                </router-link>
              </template>
            </b-table-column>
            <b-table-column field="active" label="Active?" width="80" v-if="canToggleActive">
              <template #default="props">
                <b-switch :value="props.row.active" @input="toggleActive(props.row, $event)"></b-switch>
              </template>
            </b-table-column>
            <b-table-column label="" width="60">
              <template #default="props">
                <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                  <template #trigger>
                    <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                  </template>
                  <b-dropdown-item aria-role="listitem" @click="editItem(props.row)">Edit</b-dropdown-item>
                  <b-dropdown-item v-if="canUnarchive" aria-role="listitem" @click="archiveItem(props.row, false)">Move to Ruleset</b-dropdown-item>
                  <b-dropdown-item v-if="canArchive" aria-role="listitem" @click="archiveItem(props.row, true)">Archive</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="deleteItem(props.row)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import nibble from '@/utils/nibble'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import MaterialIcon from '@/components/MaterialIcon'
import ItemList from '@/utils/mixins/ItemList'

export default {
  name: 'Rules',
  mixins: [ItemList],
  props: ['context'],
  components: { PageLayout, MainColumn, EmptyListIndicator, MaterialIcon },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      loading: false,
      items: [],
      selectedItems: [],
      draggingRow: null,
      draggingRowIndex: null
    }
  },
  computed: {
    newRuleLink () {
      if (this.context === 'ruleset' || this.context === 'archive') {
        return `/retailers/${this.retailerId}/rules/new`
      } else {
        return { path: '/admin/rules/new', query: { context: this.context } }
      }
    },
    listPath () {
      if (this.context === 'ruleset') {
        return `/retailers/${this.retailerId}/rules`
      } else if (this.context === 'archive') {
        return `/retailers/${this.retailerId}/archive`
      } else if (this.context === 'admin:algorithm') {
        return '/admin/algorithm'
      } else {
        return null
      }
    },
    listApiPath () {
      return '/rules'
    },
    listApiParams () {
      return { context: this.context, retailer_id: this.retailerId }
    },
    itemsKey () {
      return 'rules'
    },
    itemIdKey () {
      return 'rule_id'
    },
    itemName () {
      return 'Rule'
    },
    showEmptyList () {
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    },
    menuItem () {
      return this.context
    },
    pageTitle () {
      if (this.context === 'ruleset') {
        return 'Ruleset'
      } else if (this.context === 'archive') {
        return 'Rules Archive'
      } else if (this.context === 'admin:algorithm') {
        return 'Algorithm Rules'
      } else {
        return null
      }
    },
    canToggleActive () {
      return this.context !== 'archive' && this.context !== 'experiments-archive'
    },
    canArchive () {
      return this.context === 'ruleset'
    },
    canUnarchive () {
      return this.context === 'archive'
    },
    canCreateRule () {
      return this.context !== 'archive' && this.context !== 'experiments-archive'
    },
    showDiscountColumn () {
      return this.context === 'archive' || this.context === 'ruleset'
    },
    showWalkawayColumn () {
      return this.showDiscountColumn
    },
    showAlgorithmColumn () {
      return this.context === 'admin:algorithm'
    },
    activeColumnIndex () {
      var index = 3
      if (this.showAlgorithmColumn) {
        index = index + 1
      }
      if (this.showDiscountColumn) {
        index = index + 1
      }
      if (this.showWalkawayColumn) {
        index = index + 1
      }
      return index
    },
    menuColumnIndex () {
      var index = this.activeColumnIndex
      if (this.canToggleActive) {
        index = index + 1
      }
      return index
    }
  },
  methods: {
    itemPath (item) {
      if (this.context === 'ruleset' || this.context === 'archive') {
        return `/retailers/${this.retailerId}/rules/${item.rule_id}`
      } else {
        return { path: `/admin/rules/${item.rule_id}`, query: { context: this.context } }
      }
    },
    deleteItem (item) {
      nibble.delete(this.itemApiPath(item), { context: this.context }, this.$store.getters.authHeaders)
        .then((response) => {
          Toast.open(`${this.itemName} Deleted`, { type: 'is-primary' })
          this.fetchData()
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    archiveItem (item, archived) {
      nibble.put(`${this.listApiPath}/${item.rule_id}`, { context: this.context, archived: archived }, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchData()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        })
    },
    toggleActive (item, active) {
      nibble.put(`${this.listApiPath}/${item.rule_id}`, { context: this.context, active: active }, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchData()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        })
    },

    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index
      const droppedOnItem = this.items[droppedOnRowIndex]
      nibble.put(`${this.listApiPath}/${this.draggingRow.rule_id}`, { context: this.context, priority: droppedOnItem.priority }, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchData()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        })
    },
    clickCell (row, column, rowIndex, columnIndex) {
      if (this.canToggleActive && columnIndex === this.activeColumnIndex) {
        return
      }
      if (columnIndex === this.menuColumnIndex) {
        return
      }
      this.clickRow(row)
    },
    discountOrObjective (row) {
      if (row.objective !== undefined && row.objective !== null) {
        let objective = '- error -'
        switch (row.objective) {
          case 'increase_conversion':
            objective = 'Increase conversion'
            break
          case 'preserve_margin':
            objective = 'Preserve margin'
            break
          case 'balance_performance':
            objective = 'Balance performance'
            break
          default:
            console.log('Something wrong with objective of Rule ' + row.rule_id)
        }
        return objective
      }
      return row.target_discount_percentage + '%'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
