<template>
  <b-modal id="theme-modal" :active.sync="active" :width="3000" >
    <iframe :src="current" style="width: 100%; height:100%" />
  </b-modal>
</template>

<script>
export default {
  name: 'ThemeModal',
  data () {
    return {
      current: '',
      active: false
    }
  },
  methods: {
    show (url) {
      this.current = url
      this.active = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#theme-modal .modal-content {
  width: 90% !important;
  height: 90% !important;
  overflow: hidden;
}
</style>
