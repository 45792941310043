<template>
  <page-layout :retailer-id="retailerId" active-menu-item="product_categories">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">
        <template #header-right>
          <p class="level-item buttons">
            <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
            <b-button type="is-primary is-rounded" @click="handleSubmit(submit)">Save</b-button>
          </p>
        </template>
        <div class="card">
          <div class="card-content">
            <h2 class="title is-2">Product Category Info</h2>
            <notification-bar>
              The Category Name specified here should match the name your eCommerce platform
              uses for the product category. It can be automatically passed to Nibble via the Nibble Widget
              as a category tag. See the widget documentation for more details.
            </notification-bar>
            <div class="columns">
              <div class="column">
                <ValidationProvider rules="required" name="Category Name" v-slot="{ errors }">
                  <b-field label="Category Name" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.name"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column"></div>
            </div>
            <b-notification :active="errorMessage != null" :message="errorMessage" type="is-danger" :closable="false"></b-notification>
          </div>
        </div>
      </main-column>
    </validation-observer>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import NotificationBar from '@/components/NotificationBar'
import ItemEditor from '@/utils/mixins/ItemEditor'

export default {
  name: 'ProductCategory',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, NotificationBar },
  data () {
    return {
      item: {
        name: ''
      }
    }
  },
  computed: {
    listPath () {
      return `/retailers/${this.retailerId}/product_categories`
    },
    newPath () {
      return `/retailers/${this.retailerId}/product_categories/new`
    },
    itemPath () {
      return `/retailers/${this.retailerId}/product_categories/${this.itemId}`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/product_categories`
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}/product_categories/${this.itemId}`
    },
    itemName () {
      return 'Product Category'
    },
    itemsName () {
      return 'Product Categories'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
