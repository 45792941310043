<template>
  <!-- TOOD FIX BURGER AND RESPONSIVENESS -->
  <b-navbar spaced fix-top>
    <template #brand>
      <b-navbar-item @click="toggleMenu">
        <material-icon icon="menu" size="large"></material-icon>
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/">
        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Fill 1</title>
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stop-color="#249AF9" offset="0%"></stop>
                    <stop stop-color="#4275C4" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="UI-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-2" transform="translate(-72.000000, -20.000000)">
                    <rect id="Rectangle" stroke="#E1E7EC" fill="#FFFFFF" x="-0.5" y="-0.5" width="1441" height="81"></rect>
                    <path d="M86.9999537,20 C86.9999537,28.2844181 80.2843414,35.0004629 72,35.0004629 L72,35.0004629 L72,44.9999074 C72,53.2846959 78.7156123,60 86.9999537,60 L86.9999537,60 L97.0000463,60 C105.284388,60 112,53.2846959 112,44.9999074 L112,44.9999074 L112,35.0004629 C112,26.7156745 105.284388,20 97.0000463,20 L97.0000463,20 L86.9999537,20 Z M95.7112887,42.8397371 C95.7112887,39.8907509 98.1021563,37.5002315 101.050745,37.5002315 L101.050745,37.5002315 C103.999704,37.5002315 106.389831,39.8907509 106.389831,42.8397371 L106.389831,42.8397371 C106.389831,45.7883529 103.999704,48.1788723 101.050745,48.1788723 L101.050745,48.1788723 C98.1021563,48.1788723 95.7112887,45.7883529 95.7112887,42.8397371 L95.7112887,42.8397371 Z M78.2115896,42.8397371 C78.2115896,39.8907509 80.6017165,37.5002315 83.5506754,37.5002315 L83.5506754,37.5002315 C86.4996343,37.5002315 88.8897612,39.8907509 88.8897612,42.8397371 L88.8897612,42.8397371 C88.8897612,45.7883529 86.4996343,48.1788723 83.5506754,48.1788723 L83.5506754,48.1788723 C80.6017165,48.1788723 78.2115896,45.7883529 78.2115896,42.8397371 L78.2115896,42.8397371 Z" id="Fill-1" fill="url(#linearGradient-1)"></path>
                </g>
            </g>
        </svg>
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/">
        <nav class="level">
          <div class="level-item">
            <div>
              <p class="title-user-name">{{retailerName || 'Nibble'}}</p>
              <p class="title-account-plan"> {{ billingSchemeName }} </p>
            </div>
          </div>
        </nav>
      </b-navbar-item>
    </template>
    <template #start>
      <billing-notice :retailer-id="retailerId"></billing-notice>
    </template>
    <template #end>
      <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
        <template #trigger>
          <b-button type="is-text" icon-right="menu-down">{{userName}}</b-button>
        </template>
        <b-dropdown-item v-if="showAccount" @click="navigateToRetailerLink('account/plan')">
          <div class="is-flex is-flex-direction-row is-align-items-center">
            <material-icon class="material-icons-round" icon="person"/>
            <div class="is-flex is-flex-direction-column is-align-items-flex-start">
              <span class="menu-label-text">Account &amp; Plan</span>
              <span class="title-account-plan has-text-centered">{{ billingSchemeName }}</span>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item v-if="showAccount && planHasUsageFees" @click="navigateToRetailerLink('account/usage')">
          <material-icon class="material-icons-round" icon="receipt"></material-icon>
          <span class="menu-label-text">Usage</span>
        </b-dropdown-item>
        <hr v-if="showAccount" class="dropdown-divider">
        <b-dropdown-item @click="logOut">
          <material-icon class="material-icons-round" icon="logout"></material-icon>
          <span class="menu-label-text">Log Out</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import BillingNotice from '@/components/account/BillingNotice'

export default {
  name: 'TopNav',
  components: { MaterialIcon, BillingNotice },
  data () {
    return {
      retailerName: null,
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null
    }
  },
  mounted () {
    this.fetchRetailer()
  },
  watch: {
    retailerId () {
      this.fetchRetailer()
    }
  },
  computed: {
    userName () {
      if (this.$store.state.user != null) {
        return this.$store.state.user.name
      } else {
        return ''
      }
    },
    billingScheme () {
      if (this.$store.state.retailer != null) {
        return this.$store.state.retailer.billing_scheme
      } else {
        return null
      }
    },
    billingSchemeName () {
      if (this.billingScheme != null) {
        return this.billingScheme.name
      } else {
        return ''
      }
    },
    isRetailer () {
      return !this.$store.getters.isAdmin
    },
    planHasUsageFees () {
      if (this.billingScheme != null) {
        return this.billingScheme.usage_fee_percentage != null &&
          this.billingScheme.usage_fee_percentage > 0
      }
      return false
    },
    showAccount () {
      if (this.isRetailer && this.billingSchemeName != null) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    fetchRetailer () {
      this.$store.dispatch('ensureRetailerDataAvailable', this.retailerId)
        .then((retailer) => {
          if (retailer != null) {
            this.retailerName = retailer.name
          } else {
            this.retailerName = null
          }
        })
    },
    logOut () {
      this.$store.commit('logout')
      this.$router.push('/session/new')
    },
    toggleMenu () {
      this.$emit('toggleMenu')
    },
    retailerLink (path) {
      return `/retailers/${this.retailerId}/${path}`
    },
    navigateToRetailerLink (path) {
      this.$router.push(this.retailerLink(path))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-user-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}

.title-account-plan {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #1976D2;
}

.material-icons-round {
  line-height: 24px;
}

.material-icons-round:first-child {
  margin-right: 1.375rem;
}

.invisible-icon {
  opacity: 0;
  /* background: rgba(0, 0, 0, 0.4); */
}
</style>
