<template>
  <page-layout :retailer-id="retailerId" :active-menu-item="menuItem">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">
      <template #header-right>
        <p class="level-item buttons">
          <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
          <b-button type="is-primary is-rounded" @click="updateReason">Save</b-button>
        </p>
      </template>

      <notification-bar v-if="errorSection" type="is-danger">
        Unable to save form: {{ errorMessage }}.
      </notification-bar>

      <ValidationObserver ref="general_reason_observer" slim>
        <div class="card">
          <div class="card-content">
            <h2 class="title is-2">General Reason Settings</h2>
            <div class="columns is-multiline">
              <div class="column is-full">
                <ValidationProvider rules="required" name="Reason text" v-slot="{ errors }">
                  <b-field label="The reason text as it will be displayed in the bot" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <textarea class="textarea" v-model="item.text" rows="3" max-rows="6" placeholder="Enter something..."></textarea>
                  </b-field>
                  <p><strong>Note:</strong> HTML tags will be removed automatically</p>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </main-column>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import ItemEditor from '@/utils/mixins/ItemEditor'
import NotificationBar from '@/components/NotificationBar'

export default {
  name: 'Reason',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, NotificationBar },
  data () {
    return {
      context: this.$route.query.context || 'reasons',
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      item: {
        retailer_id: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
        reason_id: null,
        text: '',
        active: false,
        deleted: false
      },
      retailerObject: null,
      errorSection: null
    }
  },
  watch: {
  },
  computed: {
    menuItem () {
      return this.context
    },
    listPath () {
      if (this.context === 'reasons') {
        return `/retailers/${this.retailerId}/reasons`
      } else if (this.context === 'admin:global') {
        return '/admin/reasons'
      } else {
        return null
      }
    },
    newPath () {
      if (this.retailerId != null) {
        return `/retailers/${this.retailerId}/reasons/new`
      } else {
        return { path: '/admin/reasons/new', query: { context: this.context } }
      }
    },
    itemPath () {
      if (this.retailerId != null) {
        return `/retailers/${this.retailerId}/reasons/${this.itemId}`
      } else {
        return `/admin/reasons/${this.itemId}`
      }
    },
    listApiPath () {
      return '/reasons'
    },
    itemApiPath () {
      return `/reasons/${this.itemId}`
    },
    itemName () {
      return 'Reason'
    },
    selectedRetailerId () {
      if (this.retailerId != null) {
        return this.retailerId
      } else if (this.retailer != null) {
        return this.retailer.retailer_id
      } else {
        return null
      }
    },
    retailerRequired () {
      return this.item.match_type != null && this.item.match_type !== 'global'
    }
  },
  methods: {
    fetchRetailers () {
    },
    validateGeneralReasonsSettings () {
      return this.$refs.general_reason_observer.validate()
    },
    itemToParams () {
      var params = {
        reason_id: this.item.reason_id,
        context: this.context,
        retailer_id: this.item.retailer_id,
        reason_text: this.item.text,
        active: this.item.active
      }
      return params
    },
    dataToItem (data) {
      var item = {
        reason_id: data.reason_id,
        text: data.reason_text,
        active: data.reason_active,
        deleted: data.reason_deleted,
        retailer_id: data.retailer_id
      }
      return item
    },
    async updateReason () {
      const generalReasonsSettingsValid = await this.validateGeneralReasonsSettings()
      if (!generalReasonsSettingsValid) {
        this.errorMessage = 'invalid general settings'
        return null
      }
      this.submit()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.notification {
  margin-left: 0;
  margin-right: 0;
}
</style>
