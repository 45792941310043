<template>
  <div class="card-content contrast">
    <h2 class="title is-2">Objectives</h2>
    <div class="objectives">
      <div class="objectives-left">
        <div class="columns">
          <div class="column is-narrow percentage" v-if="!percentagesDisabled">
            <ValidationProvider rules="required" name="Target Discount" v-slot="{ errors }">
              <b-field label="Target Discount" :message="errors" :type="{ 'is-danger': errors[0] }">
                <p class="control">
                  <span class="button is-static">%</span>
                </p>
                <b-input v-model="objectives.target_discount_percentage" @input="input"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-narrow percentage" v-if="!percentagesDisabled">
            <ValidationProvider rules="required" name="Walkaway Threshold" v-slot="{ errors }">
              <b-field label="Walkaway Threshold" :message="errors" :type="{ 'is-danger': errors[0] }">
                <p class="control">
                  <span class="button is-static">%</span>
                </p>
                <b-input v-model="objectives.walkaway_percentage" @input="input"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="objectives-right">
        <div class="objectives-checkbox">
          <b-field>
            <template #label>
              <material-icon icon="schedule" size="small"></material-icon>
            </template>
            <b-checkbox v-model="objectives.enableTimeLimit" :disabled="disabled" @input="input"></b-checkbox>
          </b-field>
        </div>
        <div class="objectives-right-inputs">
          <div class="objectives-right-datetime">
            <div class="objective">
              <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="Begin Date" v-slot="{ errors }">
                <b-field label="Begin Date" :message="errors" :type="{ 'is-danger': errors[0] }">
                  <b-datepicker v-model="objectives.startDate"
                                :disabled="dateEditDisabled"
                                locale='en-GB'
                                placeholder="Choose Date"
                                icon="calendar-today"
                                expanded
                                :date-formatter="dateFormatter"
                                :date-parser="dateParser">
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="objective">
              <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="Begin Time" v-slot="{ errors }">
                <b-field label="Begin Time" :message="errors" :type="{ 'is-danger': errors[0] }">
                  <b-select v-model="objectives.startTime"
                            placeholder="Choose Time"
                            expanded
                            :disabled="dateEditDisabled"
                            @input="input">
                    <option v-for="(option, optionIndex) in allTimes" :key="optionIndex" :value="option">{{option}}</option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="objectives-right-datetime">
            <div class="objective">
              <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="End Date" v-slot="{ errors }">
                <b-field label="End Date" :message="errors" :type="{ 'is-danger': errors[0] }">
                  <b-datepicker v-model="objectives.endDate"
                                :disabled="dateEditDisabled"
                                locale='en-GB'
                                placeholder="Choose Date"
                                icon="calendar-today"
                                expanded
                                :date-formatter="dateFormatter"
                                :date-parser="dateParser">
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="objective">
              <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="End Time" v-slot="{ errors }">
                <b-field label="End Time" :message="errors" :type="{ 'is-danger': errors[0] }">
                  <b-select v-model="objectives.endTime"
                            placeholder="Choose Time"
                            :disabled="dateEditDisabled"
                            expanded
                            @input="input">
                    <option v-for="(option, optionIndex) in allTimes" :key="optionIndex" :value="option">{{option}}</option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import MaterialIcon from '@/components/MaterialIcon'

export default {
  name: 'ObjectivesEditorLegacy',
  components: { MaterialIcon },
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    percentagesDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      objectives: this.objectivesFromValue(this.value)
    }
  },
  watch: {
    value () {
      this.objectives = this.objectivesFromValue(this.value)
    },
    'objectives.startDate' (oldDate, newDate) {
      // The Buefy Datepicker emits input() events even when nothing changed;
      // this checks if the date has actually changed before raising
      // an input event
      if (this.objectiveToDateTimeString(oldDate, null) !== this.objectiveToDateTimeString(newDate, null)) {
        this.input()
      }
    },
    'objectives.endDate' (oldDate, newDate) {
      // The Buefy Datepicker emits input() events even when nothing changed;
      // this checks if the date has actually changed before raising
      // an input event
      if (this.objectiveToDateTimeString(oldDate, null) !== this.objectiveToDateTimeString(newDate, null)) {
        this.input()
      }
    }
  },
  computed: {
    allTimes () {
      return [
        '12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM',
        '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
        '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
        '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'
      ]
    },
    dateEditDisabled () {
      return this.disabled || !this.objectives.enableTimeLimit
    }
  },
  methods: {
    dateFormatter (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    dateParser (string) {
      return moment(string, 'DD/MM/YYYY').toDate()
    },
    objectivesFromValue (value) {
      var timeLimitEnabled = value.enableTimeLimit

      return {
        target_discount_percentage: value.target_discount_percentage,
        walkaway_percentage: value.walkaway_percentage,
        startDate: (timeLimitEnabled ? this.valueToDate(value.dateRange.startDateTime) : null),
        startTime: (timeLimitEnabled ? this.valueToTime(value.dateRange.startDateTime) : null),
        endDate: (timeLimitEnabled ? this.valueToDate(value.dateRange.endDateTime) : null),
        endTime: (timeLimitEnabled ? this.valueToTime(value.dateRange.endDateTime) : null),
        enableTimeLimit: timeLimitEnabled
      }
    },
    valueFromObjectives (objectives) {
      var value = {
        target_discount_percentage: objectives.target_discount_percentage,
        walkaway_percentage: objectives.walkaway_percentage,
        enableTimeLimit: false
      }
      value.dateRange = { startDateTime: null, endDateTime: null }
      if (objectives.enableTimeLimit) {
        value.enableTimeLimit = true
        if (objectives.startDate != null || objectives.startTime != null) {
          value.dateRange.startDateTime = this.objectiveToDateTimeString(objectives.startDate, objectives.startTime)
        }
        if (objectives.endDate != null || objectives.endTime != null) {
          value.dateRange.endDateTime = this.objectiveToDateTimeString(objectives.endDate, objectives.endTime)
        }
      }
      return value
    },
    objectiveToDateTimeString (date, time) {
      if (date == null) {
        date = moment().format('DD/MM/YYYY')
      } else {
        date = moment(date).format('DD/MM/YYYY')
      }
      if (time == null) {
        time = '12:00 AM'
      }
      return moment(`${date} ${time}`, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:SS')
    },
    valueToDate (dateTimeValue) {
      if (dateTimeValue != null) {
        return moment(dateTimeValue).toDate()
      } else {
        return null
      }
    },
    valueToTime (dateTimeValue) {
      if (dateTimeValue != null) {
        return moment(dateTimeValue).format('hh:mm A')
      } else {
        return null
      }
    },
    input (args) {
      this.$emit('input', this.valueFromObjectives(this.objectives))
    }
  }
}
</script>
<style lang="scss" scoped>
  .objectives {
    .column.percentage {
      width: 11em;
      .field {
        .input {
          border-left: none;
        }
        .button {
          padding-right: 0;
          border-right: none;
        }
      }
    }
  }
  .objectives-checkbox {
    label.b-checkbox {
      height: 2.5em;
      margin-right: 0;
    }
  }
  .objectives,
  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime {
    margin: -.75rem;
    display: flex;
  }
    .objectives-left,
    .objectives-checkbox {
      display: block;
      padding: .75rem;
      flex: none;
    }
  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime,
  .objective {
    padding: .75rem;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  @media (max-width: 1440px) {
    .objectives {
      display: block;
    }
  }
  @media (max-width: 1060px) {
    .objectives-right-datetime {
      display: block;
    }
  }
</style>
