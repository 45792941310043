<template>
  <file-pond name="image"
             ref="pond"
             :label-idle="labelIdle"
             accepted-file-types="image/jpeg, image/png"
             :server="filePondServer"></file-pond>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageSizeMetadata from 'filepond-plugin-image-size-metadata'
import nibble from '@/utils/nibble'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageSizeMetadata
)

export default {
  name: 'ImageUploadField',
  components: { FilePond },
  props: {
    value: {
      type: Object,
      required: false
    },
    retailerId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      oldImage: this.value
    }
  },
  computed: {
    labelIdle () {
      return 'Drop your file here or <span class="filepond--label-action"> Browse </span>'
    },
    filePondServer () {
      return {
        timeout: 60,
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          console.log(file)
          console.log(metadata)
          var params = {
            width: metadata.size.width,
            height: metadata.size.height,
            image_type: file.type
          }
          var image = null
          var url = '/images'
          if (this.retailerId != null) {
            url = `/retailers/${this.retailerId}/images`
          }

          nibble.post(url, params, this.$store.getters.authHeaders)
            .then((response) => {
              console.log(response)
              image = response.data.image
              const uploadSettings = response.data.image.upload
              console.log(uploadSettings)
              const formData = new FormData()
              Object.keys(uploadSettings.fields).forEach(key => {
                formData.append(key, uploadSettings.fields[key])
              })
              formData.append('file', file)
              return fetch(uploadSettings.url, { method: 'POST', body: formData, redirect: 'manual' })
            })
            .then((response) => {
              console.log('fetch complete')
              load(`${image.image_id}`)
              this.$emit('input', image)
            })
            .catch((error) => {
              console.log('fetch error')
              console.log(error)
              if (image != null) {
                load(`${image.image_id}`)
                this.$emit('input', image)
              } else {
                this.$emit('error', error)
              }
            })
        },
        revert: (fieldId, load, error) => {
          load()
          this.$emit('input', this.oldImage)
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
