<template>
  <div class="card chat-window-container">
    <div class="card-header chat-tabs" v-if="session.followup_sessions.length > 0">
      <b-tabs v-model="activeTabIndex" expanded>
        <b-tab-item v-for="(tabName, tabIndex) in tabNames" :label="tabName" :key="tabIndex"></b-tab-item>
      </b-tabs>
    </div>
    <div class="card-content">
      <div class="chat-window">
        <chat-log :session="activeSession" :admin=admin @clickUserMessage="clickUserMessage"></chat-log>
      </div>
    </div>
  </div>
</template>

<script>
import ChatLog from '@/components/ChatLog'

export default {
  name: 'ChatWindow',
  components: { ChatLog },
  props: ['session', 'admin'],
  data () {
    return {
      activeTabIndex: 0
    }
  },
  computed: {
    tabNames () {
      return ['Negotiation', 'Post-Abandonment']
    },
    tabSessions () {
      var sessions = [this.session]
      this.session.followup_sessions.forEach(s => sessions.push(s))
      return sessions
    },
    activeSession () {
      return this.tabSessions[this.activeTabIndex]
    }
  },
  methods: {
    clickUserMessage (intentDetails) {
      this.$emit('clickUserMessage', intentDetails)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.chat-window-container {
  box-shadow: 0px 8px 16px 0px rgba(31, 38, 46, 0.1);
}
.card-header.chat-tabs {
  box-shadow: none;
  .tab-content {
    display: none;
  }
  .tabs {
    a {
     text-transform: uppercase;
     padding-top: 1rem;
     padding-bottom: 16px;
     color: #668099;
     font-weight: 500;
    }
    .is-active a, a:hover {
      padding-bottom: 13px;
      border-bottom-width: 4px;
      border-bottom-color: #E0E6EB;
    }
    .is-active a {
      color: #1976D2;
      border-bottom-color: #1976D2;
    }
  }
}
</style>
