<template>
  <div class="stat-wrapper">
    <div class="stat is-flex flex-direction-row is-justify-content-center is-align-items-center">
      <div class="stat-icon is-flex is-flex-direction-column is-justify-content-center">
        <div class="icon-container" :class="status">
          <svg-icon v-if="svg" size="large" :icon="icon" :status-class="status"/>
          <material-icon v-else size="large" :icon="icon" :status-class="status"/>
        </div>
      </div>
      <div class="stat-content is-flex is-flex-direction-column is-justify-content-space-around">
        <span>{{ title }}</span>
        <div class="stat-values is-flex is-flex-direction-row is-align-items-center">
          <div class="stat-values-number title is-2" :class="boldClass">
            {{ data.value }}
          </div>
          <stat-change v-if="displayChange" :change="data.change" :reverse="reverse"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import SvgIcon from '@/components/SvgIcon'
import StatChange from '@/components/analytics/StatChange'

export default {
  name: 'Stat',
  components: { MaterialIcon, SvgIcon, StatChange },
  props: {
    showChange: {
      required: false,
      type: Boolean,
      default: true
    },
    title: {
      required: true,
      type: String
    },
    data: {
      required: true,
      type: Object
    },
    reverse: {
      required: false,
      type: Boolean,
      default: false
    },
    bold: {
      required: false,
      type: Boolean,
      default: false
    },
    icon: {
      required: true,
      type: String
    },
    svg: {
      required: false,
      type: Boolean,
      default: false
    },
    status: {
      required: false,
      type: String,
      default: 'normal'
    }
  },
  computed: {
    displayChange: function () {
      if (this.showChange) {
        if ('change' in this.data) {
          if (this.data.change !== null) {
            return this.data.change.percentage !== '0.00%'
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    boldClass: function () {
      return this.bold ? 'bold-stat' : null
    }
  }
}
</script>

<style lang="scss" scoped>
.stat-wrapper {
  padding: 10px 0px;
  .stat {
    height: 56px;
    justify-content: space-around;
    .stat-content {
      margin-left: 16px;
      height: 56px;
      .stat-values {
        .stat-values-number {
          margin-bottom: 0;
          font-weight: 400 !important;
          &.bold-stat {
            font-weight: 700 !important;
          }
        }
      }
    }
    .stat-icon img {
      height: 1.5rem;
      width: 1.5rem;
      min-height: 1.5rem;
      min-width: 1.5rem;
    }
  }
  &:first-child { padding-top: 0px; }
  &:last-child { padding-bottom: 0px; }
  &:first-child:last-child { padding: 0; }
}
</style>
