<template>
  <tr>
    <td :colspan="colspan">
      <span v-if="svg" class="nibble-icon material-icons-round"><svg-icon :icon="icon" size="small"/></span>
      <material-icon v-else :icon="icon" size="small"/>
      {{ title }}
    </td>
    <td class="has-text-right" v-if="hasValue">{{ percentage.value }}</td>
    <td class="has-text-right" v-if="showChange && hasChange">
      <stat-change v-if="getChange.percentage !== '0.00%'" :change="getChange" :reverse="reverse"/>
    </td>
    <th class="has-text-right">{{ stat.value }}</th>
  </tr>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import SvgIcon from '@/components/SvgIcon'
import StatChange from '@/components/analytics/StatChange'

export default {
  name: 'StatRow',
  components: { MaterialIcon, StatChange, SvgIcon },
  props: {
    title: {
      required: true,
      type: String
    },
    icon: {
      required: false,
      default: null
    },
    svg: {
      required: false,
      type: Boolean,
      default: false
    },
    showChange: {
      required: true,
      type: Boolean
    },
    stat: {
      required: true,
      type: Object
    },
    percentage: {
      required: false,
      default: null
    },
    reverse: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    colspan: function () {
      var colspan = 1
      if (this.showChange) {
        if (!this.hasChange) {
          colspan++
        }
      } else {
        colspan++
      }

      if (!this.hasValue) {
        colspan++
      }

      return colspan
    },
    hasValue: function () {
      if (this.percentage) {
        if ('value' in this.percentage) {
          return this.percentage.value !== null
        } else {
          return false
        }
      } else {
        return false
      }
    },
    hasChange: function () {
      if (this.percentage) {
        if ('change' in this.percentage) {
          return this.percentage.change !== null
        } else {
          return false
        }
      } else {
        if ('change' in this.stat) {
          return this.stat.change !== null
        } else {
          return false
        }
      }
    },
    getChange: function () {
      if (this.percentage) {
        if ('change' in this.percentage) {
          return this.percentage.change
        } else {
          return null
        }
      } else if ('change' in this.stat) {
        return this.stat.change
      } else {
        return null
      }
    }
  }
}
</script>
