<template>
  <page-layout active-menu-item="admin:theme">
    <main-column :breadcrumbs="breadcrumbs" page-title="Themes" content-class="main-column-sub-columns columns">
      <template #header-left>
      </template>
      <template #header-right v-if="items.length > 0">
        <p class="level-item buttons">
          <b-button type="is-text is-icon" :disabled="selectedItems.length === 0" @click="confirmDeleteBatch">
            <material-icon icon="delete_forever" size="large"></material-icon>
          </b-button>
          <b-button type="is-primary is-rounded" tag="router-link" :to="newThemeLink">New Theme</b-button>
        </p>
      </template>
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Themes"
            :showButton="true"
            buttonText="New Theme"
            :link="newThemeLink">
            <!--template v-slot:text>
              TODO: Empty theme list text
            </template-->
          </empty-list-indicator>
          <b-table v-else :data="items"
            :checked-rows.sync="selectedItems"
            hoverable
            draggable
            checkable
            selectable
            @cellclick="clickCell">

            <b-table-column field="name" label="Theme Name" v-slot="props">
              <strong>
                {{ smartName(props.row) }}
              </strong>
            </b-table-column>

            <b-table-column field="retailer" label="Assigned Retailer" v-slot="props">
              <strong v-if="props.row.retailer_name">
                {{ props.row.retailer_name }}
              </strong>
            </b-table-column>

            <b-table-column label="" width="60">
              <template #default="props">
                <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                  <template #trigger>
                    <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                  </template>
                  <b-dropdown-item aria-role="listitem" @click="editItem(props.row)">Edit</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="clone(props.row)">Clone</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="reactLogin(props.row)">Theme configurator</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="deleteItem(props.row)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
    <theme-modal ref="themeModal"></theme-modal>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ItemList from '@/utils/mixins/ItemList'
import MaterialIcon from '@/components/MaterialIcon'
import ThemeModal from '@/components/ThemeModal'
import nibble from '@/utils/nibble'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'Themes',
  mixins: [ItemList],
  components: { PageLayout, MainColumn, EmptyListIndicator, MaterialIcon, ThemeModal },
  data () {
    return {
      loading: false,
      items: [],
      selectedItems: []
    }
  },
  computed: {
    newThemeLink () {
      return '/admin/themes/new'
    },
    listPath () {
      return '/admin/themes'
    },
    listApiPath () {
      return '/admin/themes'
    },
    itemsKey () {
      return 'themes'
    },
    itemIdKey () {
      return 'theme_id'
    },
    itemName () {
      return 'Theme'
    },
    showEmptyList () {
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    }
  },
  methods: {
    // dragstart (payload) {
    //   this.draggingRow = payload.row
    //   this.draggingRowIndex = payload.index
    //   payload.event.dataTransfer.effectAllowed = 'copy'
    // },
    // dragover (payload) {
    //   payload.event.dataTransfer.dropEffect = 'copy'
    //   payload.event.target.closest('tr').classList.add('is-selected')
    //   payload.event.preventDefault()
    // },
    // dragleave (payload) {
    //   payload.event.target.closest('tr').classList.remove('is-selected')
    //   payload.event.preventDefault()
    // },
    smartName (row) {
      let name = row.name
      if (row.language_code && row.language_code !== 'en-GB') {
        name += ` (${row.language_code})`
      }
      return name
    },
    clickCell (row, column, rowIndex, columnIndex) {
      if (columnIndex === 2) {
        return
      }
      this.clickRow(row)
    },
    reactLogin (row) {
      const params = {
        destination: 'app-react',
        where: 'theme',
        id: row.theme_id
      }
      nibble.post('/auth/handoff', params, this.$store.getters.authHeaders).then((response) => {
        const url = response.data.url
        this.$refs.themeModal.show(url)
        // window.location.href = url
      })
    },
    clone (row) {
      nibble.post('/admin/themes/' + row.theme_id + '/clone', {}, this.$store.getters.authHeaders).then((response) => {
        Toast.open({ message: 'Theme cloned', type: 'is-primary' })
        window.location.reload()
      }).error((error) => {
        console.log(error)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
