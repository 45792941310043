<template>
  <page-layout :retailer-id="retailerId" :menu-available="false">
    <div class="page">
      <div class="page-layout is-flex is-flex-direction-column is-align-items-center p-5">
        <h1 class="title is-1 mb-3">Choose Your Plan</h1>
        <p class="mb-5">Take advantage of the full power of Nibble</p>
        <div class="plans columns is-flex is-flex-direction-row is-justify-content-center is-align-items-stretch">
          <plan :plan="plan"
                :is-upgrade="isPlanUpgrade(plan)"
                :is-middle="planIndex === 1"
                v-for="(plan, planIndex) in plans"
                :key="plan.identifier"
                @select="selectPlan(plan)" />
        </div>
        <a @click="goBack">No thanks, I've changed my mind</a>
      </div>
    </div>
    <update-plan-modal ref="setUsageCapModal" :retailer-id="retailerId" ></update-plan-modal>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import Plan from '@/components/account/Plan'
import UpdatePlanModal from '@/components/account/UpdatePlanModal'
import nibble from '@/utils/nibble'

export default {
  name: 'AccountPlanChoice',
  components: { PageLayout, Plan, UpdatePlanModal },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      currentBillingScheme: null,
      plans: []
    }
  },
  computed: {
    currentPlanIdentifier () {
      if (this.currentBillingScheme != null) {
        return this.currentBillingScheme.pricing_plan.identifier
      } else {
        return null
      }
    }
  },
  mounted () {
    nibble.get(`/retailers/${this.retailerId}/account/plan`, { retailer_id: this.retailerId, context: 'retailer' }, this.$store.getters.authHeaders)
      .then((response) => {
        this.currentBillingScheme = response.data
        return nibble.get(`/retailers/${this.retailerId}/account/plans`, {}, this.$store.getters.authHeaders)
      })
      .then((response) => {
        this.plans = response.data.plans
      })
      .catch((error) => {
        nibble.handleNetworkError(error, this.$store)
      })
  },
  methods: {
    goBack () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push('/')
      }
    },
    isPlanUpgrade (plan) {
      const currentPlanIndex = this.plans.findIndex((p) => p.is_current_plan)
      const planIndex = this.plans.findIndex((p) => p.identifier === plan.identifier)
      return planIndex > currentPlanIndex
    },
    selectPlan (plan) {
      if (!plan.self_serve) {
        window.open('https://www.nibbletechnology.com/contact-us?i_want_to=Upgrade+to+Premium', '_blank')
      } else if (plan.has_usage_charges) {
        var cap = 2000
        if (this.currentBillingScheme != null && this.currentBillingScheme.billing_cycle_usage_cap != null) {
          cap = this.currentBillingScheme.billing_cycle_usage_cap
        }
        this.$refs.setUsageCapModal.show(plan.identifier, 2, cap)
      } else {
        nibble.put(`/retailers/${this.retailerId}/account/plan`, { plan_identifier: plan.identifier }, this.$store.getters.authHeaders)
          .then((response) => {
            if (response.data.status === 'redirect') {
              window.location.href = response.data.redirect_uri
            } else {
              this.$store.dispatch('forceRetailerUpdate', this.retailerId)
                .then((retailer) => {
                  this.$router.push(`/retailers/${this.retailerId}/account/plan`)
                })
            }
          })
          .catch((error) => {
            nibble.handleNetworkError(error, this.$store)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.plans {
  width: 100%;
}

@media screen and (max-width: 1024px) and (min-width: 770px) {
  .plans {
    flex-direction: column !important;
    align-items: center !important;
    .column {
      width: 75% !important;
    }
  }
}

@media screen and (max-width: 769px) {
  .plans {
    flex-direction: column !important;
    align-items: center !important;
    .column {
      max-width: none;
      width: 100% !important;
    }
  }
}
</style>
