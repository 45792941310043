import { render, staticRenderFns } from "./BreadcrumbList.vue?vue&type=template&id=1be27a9e&scoped=true&"
import script from "./BreadcrumbList.vue?vue&type=script&lang=js&"
export * from "./BreadcrumbList.vue?vue&type=script&lang=js&"
import style0 from "./BreadcrumbList.vue?vue&type=style&index=0&id=1be27a9e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be27a9e",
  null
  
)

export default component.exports