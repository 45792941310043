<template>
  <div class="card-content contrast">
    <h2 class="title is-2">Value Settings</h2>
    <div class="block" v-if="disableSynthetic">
      Here you can set the value that is displayed to the customer when the Gift With Purchase is offered in a negotiation.
    </div>
    <div class="block" v-else>
      From here you can set the actual value of the Add-On (Synthetic Value) and the value it will have for Nibble during the negotiation process (Negotiation Value)
    </div>
    <div class="objectives">
      <div class="objectives-left">
        <div class="columns">
          <div class="column is-narrow decimal" v-if="!disableSynthetic">
            <ValidationProvider rules="required" name="Synthetic Value" v-slot="{ errors }">
              <b-field label="Synthetic Value" :message="errors" :type="{ 'is-danger': errors[0] }">
                <p class="control">
                  <span class="button is-static">£</span>
                </p>
                <b-input v-model="objectives.synthetic_value" :disabled="disableSynthetic" @input="input"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-narrow decimal">
            <ValidationProvider rules="required" name="Display Value" v-slot="{ errors }">
              <b-field label="Display Value" :message="errors" :type="{ 'is-danger': errors[0] }">
                <p class="control">
                  <span class="button is-static">£</span>
                </p>
                <b-input v-model="objectives.display_value" @input="input"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import nibble from '@/utils/nibble'
import moment from 'moment'

export default {
  name: 'ObjectivesEditor',
  props: {
    value: {
      type: Object,
      required: true
    },
    disableSynthetic: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      objectives: this.objectivesFromValue(this.value)
    }
  },
  watch: {
    value () {
      this.objectives = this.objectivesFromValue(this.value)
    },
    disableSynthetic () {
      if (this.disableSynthetic) {
        this.objectives.synthetic_value = '0.00'
      }
    }
  },

  methods: {
    // TODO: Remove if never used
    dateFormatter (date) {
      return nibble.timezoneManager.formatDate(date, 'DD/MM/YYYY')
    },
    // TODO: Remove if never used
    dateParser (string) {
      return moment(string, 'DD/MM/YYYY').toDate()
    },
    objectivesFromValue (value) {
      return {
        synthetic_value: value.synthetic_value,
        display_value: value.display_value
      }
    },
    valueFromObjectives (objectives) {
      var value = {
        synthetic_value: objectives.synthetic_value,
        display_value: objectives.display_value
      }
      return value
    },
    input (args) {
      this.$emit('input', this.valueFromObjectives(this.objectives))
    }
  }
}
</script>
<style lang="scss" scoped>
  .objectives {
    .column.percentage {
      width: 11em;
      .field {
        .input {
          border-left: none;
        }
        .button {
          padding-right: 0;
          border-right: none;
        }
      }
    }
  }
  .objectives-checkbox {
    label.b-checkbox {
      height: 2.5em;
      margin-right: 0;
    }
  }
  .objectives,
  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime {
    margin: -.75rem;
    display: flex;
  }
    .objectives-left,
    .objectives-checkbox {
      display: block;
      padding: .75rem;
      flex: none;
    }
  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime,
  .objective {
    padding: .75rem;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  @media (max-width: 1440px) {
    .objectives {
      display: block;
    }
  }
  @media (max-width: 1060px) {
    .objectives-right-datetime {
      display: block;
    }
  }
</style>
