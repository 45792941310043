var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{attrs:{"active-menu-item":"admin:theme"}},[_c('main-column',{attrs:{"breadcrumbs":_vm.breadcrumbs,"page-title":_vm.pageTitle},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [_c('p',{staticClass:"level-item buttons"},[_c('b-button',{attrs:{"type":"is-text is-rounded","tag":"router-link","to":_vm.listPath}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"type":"is-primary is-rounded"},on:{"click":_vm.updateTheme}},[_vm._v("Save")])],1)]},proxy:true}])},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(_vm.errorSection)?_c('notification-bar',{attrs:{"type":"is-danger"}},[_vm._v(" Unable to save theme — invalid "+_vm._s(_vm.errorSection)+" settings. ")]):_vm._e(),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('ValidationObserver',{ref:"settings_observer",attrs:{"slim":""}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1)]}}])})],1),_c('div',{staticClass:"column small-column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Language Code","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{attrs:{"placeholder":"en-GB"},model:{value:(_vm.item.language_code),callback:function ($$v) {_vm.$set(_vm.item, "language_code", $$v)},expression:"item.language_code"}})],1)]}}])})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Retailer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Retailer","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.item.retailer_id),callback:function ($$v) {_vm.$set(_vm.item, "retailer_id", $$v)},expression:"item.retailer_id"}},[_c('option',{domProps:{"value":null}},[_vm._v("All Retailers")]),_vm._l((_vm.retailers),function(retailer,retailerIndex){return _c('option',{key:retailerIndex,domProps:{"value":retailer.retailer_id}},[_vm._v(" "+_vm._s(retailer.name)+" ")])})],2)],1)]}}])})],1)])])],1),_c('br'),_c('div',{staticClass:"card-content contrast"},[_c('h3',{staticClass:"title is-5"},[_vm._v("Button and tooltip styling")]),_c('p',{staticClass:"mb-3"},[_vm._v("Click "),_c('a',{on:{"click":function($event){$event.preventDefault();_vm.openStyleGuide = true}}},[_vm._v("here")]),_vm._v(" to see the style guide.")]),_c('b-modal',{attrs:{"active":_vm.openStyleGuide},on:{"update:active":function($event){_vm.openStyleGuide=$event}}},[_c('div',{staticClass:"box has-text-centered"},[_c('img',{attrs:{"width":"500","src":"/images/theme-guide.png"}})])]),_c('b-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"Button"}},[_c('ValidationObserver',{ref:"button_style_observer",attrs:{"slim":""}},[_c('h3',{staticClass:"title is-6"},[_vm._v("Main settings")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.text),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "text", $$v)},expression:"item.theme_configuration.button.text"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font family","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.fontFamily),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "fontFamily", $$v)},expression:"item.theme_configuration.button.fontFamily"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font weight","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.fontWeight),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "fontWeight", $$v)},expression:"item.theme_configuration.button.fontWeight"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font style","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-select',{attrs:{"placeholder":"Select a style","expanded":""},model:{value:(_vm.item.theme_configuration.button.fontStyle),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "fontStyle", $$v)},expression:"item.theme_configuration.button.fontStyle"}},[_c('option',{attrs:{"value":"normal"}},[_vm._v("Normal")]),_c('option',{attrs:{"value":"italic"}},[_vm._v("Italic")]),_c('option',{attrs:{"value":"oblique"}},[_vm._v("Oblique")])])],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font size","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.fontSize),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "fontSize", $$v)},expression:"item.theme_configuration.button.fontSize"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.color),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "color", $$v)},expression:"item.theme_configuration.button.color"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font smoothing (macOS only)","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-select',{attrs:{"placeholder":"Select smoothing","expanded":""},model:{value:(_vm.item.theme_configuration.button.fontSmoothing),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "fontSmoothing", $$v)},expression:"item.theme_configuration.button.fontSmoothing"}},[_c('option',{attrs:{"value":"auto"}},[_vm._v("Auto")]),_c('option',{attrs:{"value":"none"}},[_vm._v("None")]),_c('option',{attrs:{"value":"antialiased"}},[_vm._v("Anti-aliased")]),_c('option',{attrs:{"value":"subpixel-antialiased"}},[_vm._v("Sub-pixel anti-aliased")])])],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font stretch","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.fontStretch),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "fontStretch", $$v)},expression:"item.theme_configuration.button.fontStretch"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Letter spacing","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.letterSpacing),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "letterSpacing", $$v)},expression:"item.theme_configuration.button.letterSpacing"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Text transform","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-select',{attrs:{"placeholder":"Select a transform","expanded":""},model:{value:(_vm.item.theme_configuration.button.textTransform),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "textTransform", $$v)},expression:"item.theme_configuration.button.textTransform"}},[_c('option',{attrs:{"value":"none"}},[_vm._v("None")]),_c('option',{attrs:{"value":"capitalize"}},[_vm._v("Capitalize")]),_c('option',{attrs:{"value":"uppercase"}},[_vm._v("Uppercase")]),_c('option',{attrs:{"value":"lowercase"}},[_vm._v("Lowercase")])])],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Button effect","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-select',{attrs:{"placeholder":"Select an effect","expanded":""},model:{value:(_vm.item.theme_configuration.button.effect),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "effect", $$v)},expression:"item.theme_configuration.button.effect"}},[_c('option',{attrs:{"value":"none"}},[_vm._v("None")]),_c('option',{attrs:{"value":"pulse"}},[_vm._v("Pulse")])])],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Background color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.backgroundColor),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "backgroundColor", $$v)},expression:"item.theme_configuration.button.backgroundColor"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.border),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "border", $$v)},expression:"item.theme_configuration.button.border"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border radius","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.borderRadius),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "borderRadius", $$v)},expression:"item.theme_configuration.button.borderRadius"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Box shadow","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.boxShadow),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "boxShadow", $$v)},expression:"item.theme_configuration.button.boxShadow"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Height","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.height),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "height", $$v)},expression:"item.theme_configuration.button.height"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Margin","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.margin),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "margin", $$v)},expression:"item.theme_configuration.button.margin"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Padding","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.padding),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "padding", $$v)},expression:"item.theme_configuration.button.padding"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Transition","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.transition),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button, "transition", $$v)},expression:"item.theme_configuration.button.transition"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("Logo settings")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Size","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.logo.size),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.logo, "size", $$v)},expression:"item.theme_configuration.button.logo.size"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Right margin","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.logo.marginRight),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.logo, "marginRight", $$v)},expression:"item.theme_configuration.button.logo.marginRight"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.logo.color),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.logo, "color", $$v)},expression:"item.theme_configuration.button.logo.color"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("Hover settings")]),_c('p',{staticClass:"mb-4"},[_c('em',[_vm._v("Note")]),_vm._v(": Setting any of these options to "),_c('code',[_vm._v("auto")]),_vm._v(" will copy the non-hover styling defined above.")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.hover.color),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.hover, "color", $$v)},expression:"item.theme_configuration.button.hover.color"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Logo color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.hover.logoColor),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.hover, "logoColor", $$v)},expression:"item.theme_configuration.button.hover.logoColor"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Background color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.hover.backgroundColor),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.hover, "backgroundColor", $$v)},expression:"item.theme_configuration.button.hover.backgroundColor"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Box shadow","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.hover.boxShadow),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.hover, "boxShadow", $$v)},expression:"item.theme_configuration.button.hover.boxShadow"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.hover.border),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.hover, "border", $$v)},expression:"item.theme_configuration.button.hover.border"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border radius","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.button.hover.borderRadius),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.button.hover, "borderRadius", $$v)},expression:"item.theme_configuration.button.hover.borderRadius"}})],1)]}}])})],1)])])],1),_c('b-tab-item',{attrs:{"label":"Container"}},[_c('ValidationObserver',{ref:"container_style_observer",attrs:{"slim":""}},[_c('h3',{staticClass:"title is-6"},[_vm._v("Main settings")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Max width","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.container.maxWidth),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.container, "maxWidth", $$v)},expression:"item.theme_configuration.container.maxWidth"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Min width","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.container.minWidth),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.container, "minWidth", $$v)},expression:"item.theme_configuration.container.minWidth"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Float","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.container.float),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.container, "float", $$v)},expression:"item.theme_configuration.container.float"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Margin","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.container.margin),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.container, "margin", $$v)},expression:"item.theme_configuration.container.margin"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Padding","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.container.padding),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.container, "padding", $$v)},expression:"item.theme_configuration.container.padding"}})],1)]}}])})],1)])])],1),_c('b-tab-item',{attrs:{"label":"Tooltip"}},[_c('ValidationObserver',{ref:"tooltip_style_observer",attrs:{"slim":""}},[_c('h3',{staticClass:"title is-6"},[_vm._v("Main settings")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Display tooltip?","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-checkbox',{attrs:{"true-value":"true","false-value":"false"},model:{value:(_vm.item.theme_configuration.infoText.enabled),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "enabled", $$v)},expression:"item.theme_configuration.infoText.enabled"}},[_vm._v(" "+_vm._s(_vm.item.theme_configuration.infoText.enabled === 'true' ? 'Yes' : 'No')+" ")])],1)]}}])})],1)]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.text),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "text", $$v)},expression:"item.theme_configuration.infoText.text"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font family","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.fontFamily),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "fontFamily", $$v)},expression:"item.theme_configuration.infoText.fontFamily"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font weight","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.fontWeight),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "fontWeight", $$v)},expression:"item.theme_configuration.infoText.fontWeight"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font size","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.fontSize),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "fontSize", $$v)},expression:"item.theme_configuration.infoText.fontSize"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.color),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "color", $$v)},expression:"item.theme_configuration.infoText.color"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Background color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.backgroundColor),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "backgroundColor", $$v)},expression:"item.theme_configuration.infoText.backgroundColor"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.border),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "border", $$v)},expression:"item.theme_configuration.infoText.border"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border radius","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.borderRadius),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "borderRadius", $$v)},expression:"item.theme_configuration.infoText.borderRadius"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Height","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.height),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "height", $$v)},expression:"item.theme_configuration.infoText.height"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Bottom margin","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.marginBottom),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText, "marginBottom", $$v)},expression:"item.theme_configuration.infoText.marginBottom"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("Hover settings")]),_c('p',{staticClass:"mb-4"},[_c('em',[_vm._v("Note")]),_vm._v(": Setting any of these options to "),_c('code',[_vm._v("auto")]),_vm._v(" will copy the non-hover styling defined above.")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Font color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.hover.color),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText.hover, "color", $$v)},expression:"item.theme_configuration.infoText.hover.color"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Background color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.hover.backgroundColor),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText.hover, "backgroundColor", $$v)},expression:"item.theme_configuration.infoText.hover.backgroundColor"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.hover.border),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText.hover, "border", $$v)},expression:"item.theme_configuration.infoText.hover.border"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Border radius","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.infoText.hover.borderRadius),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoText.hover, "borderRadius", $$v)},expression:"item.theme_configuration.infoText.hover.borderRadius"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("Popup settings")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{attrs:{"maxlength":"250","type":"textarea"},model:{value:(_vm.item.theme_configuration.infoPopup.text),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.infoPopup, "text", $$v)},expression:"item.theme_configuration.infoPopup.text"}})],1)]}}])})],1)])])],1),_c('b-tab-item',{attrs:{"label":"Window"}},[_c('ValidationObserver',{ref:"window_style_observer",attrs:{"slim":""}},[_c('h3',{staticClass:"title is-6"},[_vm._v("Main settings")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Display marketing footer?","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-checkbox',{attrs:{"true-value":"true","false-value":"false"},model:{value:(_vm.item.theme_configuration.window.marketing.enabled),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.window.marketing, "enabled", $$v)},expression:"item.theme_configuration.window.marketing.enabled"}},[_vm._v(" "+_vm._s(_vm.item.theme_configuration.window.marketing.enabled === 'true' ? 'Yes' : 'No')+" ")])],1)]}}])})],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"block has-text-centered"},[_vm._v(" Window Logo (replaces Nibble logo)"),_c('br'),_vm._v(" Should be 36px high, width is variable but no more than about 150px ")]),_c('b-field',[_c('image-upload-field',{model:{value:(_vm.item.logo_image),callback:function ($$v) {_vm.$set(_vm.item, "logo_image", $$v)},expression:"item.logo_image"}})],1),(_vm.item.logo_image)?_c('div',{staticClass:"block has-text-centered"},[_c('img',{attrs:{"src":_vm.item.logo_image.image_url,"width":_vm.item.logo_image.width,"height":_vm.item.logo_image.height}})]):_vm._e()],1)])])],1),_c('b-tab-item',{attrs:{"label":"Dwell Time Popup"}},[_c('ValidationObserver',{ref:"dwell_time_popup_style_observer",attrs:{"slim":""}},[_c('h3',{staticClass:"title is-6"},[_vm._v("Main settings")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Heading Text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.dwellTimePopup.headingText),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.dwellTimePopup, "headingText", $$v)},expression:"item.theme_configuration.dwellTimePopup.headingText"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Paragraph Text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.dwellTimePopup.text),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.dwellTimePopup, "text", $$v)},expression:"item.theme_configuration.dwellTimePopup.text"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("Logo Colours")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Gradient Start","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.dwellTimePopup.logoGradientStart),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.dwellTimePopup, "logoGradientStart", $$v)},expression:"item.theme_configuration.dwellTimePopup.logoGradientStart"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Gradient End","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.dwellTimePopup.logoGradientEnd),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.dwellTimePopup, "logoGradientEnd", $$v)},expression:"item.theme_configuration.dwellTimePopup.logoGradientEnd"}})],1)]}}])})],1)])])],1),_c('b-tab-item',{attrs:{"label":"Signposting"}},[_c('h3',{staticClass:"title is-6"},[_vm._v("Nibble Logo")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Logo Color","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.logoColor),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "logoColor", $$v)},expression:"item.theme_configuration.signposting.logoColor"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("'Complete Your Deal' Signposting")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Header (prefix)","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.dealHeaderPrefix),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "dealHeaderPrefix", $$v)},expression:"item.theme_configuration.signposting.dealHeaderPrefix"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Header (suffix)","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.dealHeaderSuffix),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "dealHeaderSuffix", $$v)},expression:"item.theme_configuration.signposting.dealHeaderSuffix"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Deal Ready Header","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.dealReadyHeader),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "dealReadyHeader", $$v)},expression:"item.theme_configuration.signposting.dealReadyHeader"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Show popin on recommended product?","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-checkbox',{attrs:{"true-value":true,"false-value":false},model:{value:(_vm.item.theme_configuration.signposting.showPopupOnRecommendedProduct),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "showPopupOnRecommendedProduct", $$v)},expression:"item.theme_configuration.signposting.showPopupOnRecommendedProduct"}},[_vm._v(" "+_vm._s(_vm.item.theme_configuration.signposting.showPopupOnRecommendedProduct ? 'Yes' : 'No')+" ")])],1)]}}])})],1)]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Deal description (suffix)","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.dealDescriptionSuffix),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "dealDescriptionSuffix", $$v)},expression:"item.theme_configuration.signposting.dealDescriptionSuffix"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Complete Deal button text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.completeDealButtonText),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "completeDealButtonText", $$v)},expression:"item.theme_configuration.signposting.completeDealButtonText"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("Suggestion Popup")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Header","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.suggestionHeader),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "suggestionHeader", $$v)},expression:"item.theme_configuration.signposting.suggestionHeader"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Message","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.suggestionMessage),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "suggestionMessage", $$v)},expression:"item.theme_configuration.signposting.suggestionMessage"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Add to Basket button text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.suggestionAddToBasketText),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "suggestionAddToBasketText", $$v)},expression:"item.theme_configuration.signposting.suggestionAddToBasketText"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Other Options button text","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.suggestionOtherOptionsText),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "suggestionOtherOptionsText", $$v)},expression:"item.theme_configuration.signposting.suggestionOtherOptionsText"}})],1)]}}])})],1)]),_c('h3',{staticClass:"title is-6"},[_vm._v("Recommendations")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Recommendations Message","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.recommendationsMessage),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "recommendationsMessage", $$v)},expression:"item.theme_configuration.signposting.recommendationsMessage"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Message following recommendations (prefix)","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.recommendationsFollowupMessagePrefix),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "recommendationsFollowupMessagePrefix", $$v)},expression:"item.theme_configuration.signposting.recommendationsFollowupMessagePrefix"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Message following recommendations (suffix)","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{model:{value:(_vm.item.theme_configuration.signposting.recommendationsFollowupMessageSuffix),callback:function ($$v) {_vm.$set(_vm.item.theme_configuration.signposting, "recommendationsFollowupMessageSuffix", $$v)},expression:"item.theme_configuration.signposting.recommendationsFollowupMessageSuffix"}})],1)]}}])})],1)])])],1)],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"xnib-button-preview"},[_c('theme-preview',{attrs:{"theme":_vm.item.theme_configuration}})],1)])])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }