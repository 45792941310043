<template>
  <page-layout :retailer-id="retailerId" active-menu-item="nibbles">
    <main-column :breadcrumbs="breadcrumbs" page-title="Nibble Details">
      <template #header-left>
        <div class="level-item buttons">
          <b-button type="is-text is-icon" size="is-small" class="mr-1" v-if="showPrevButton" tag="router-link"
            :to="prevSessionRoute">
            <material-icon icon="chevron_left"></material-icon>
          </b-button>
          <b-button type="is-text is-icon" size="is-small" v-if="showNextButton" tag="router-link"
            :to="nextSessionRoute">
            <material-icon icon="chevron_right"></material-icon>
          </b-button>
        </div>
      </template>
      <template #header-right v-if="isAdmin">
        <p class="level-item buttons">
          <b-button type="is-rounded" @click="saveAsImage" v-if="canScreenshotChat">Save chat as Image</b-button>
          <b-button type="is-text is-rounded" @click="isAnnotationModalActive = true">Annotate</b-button>
        </p>
      </template>
      <notification-bar v-if="errorMessage" type="is-danger">
        {{errorMessage}}
      </notification-bar>
      <div class="columns" v-if="item">
        <div class="column">
          <div class="card mb-4" v-if="sessionStatus">
            <div class="card-content">
              <div class="card-head">
                <material-icon v-if="item.negotiation_type === 'cart'" icon="shopping_cart"
                  class="mr-4"></material-icon>
                <h2 class="title is-2">
                  {{sessionStatus}}
                </h2>
                <status-icon :session-status="item.summary_status" :negotiation-type="item.negotiation_type"
                  :addon-item-id="item.addon_item_id" size="xxlarge"></status-icon>
              </div>
              <review v-if="showAbandonedNibbleOptions" :item="item" @submitRecoveredNibble="submitRecoveredNibble"
                :show-one-objective="showOneObjective"></review>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-content">
              <div class="card-head mb-4">
                <material-icon icon="assignment"></material-icon>
                <h2 class="title is-3">Details</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr>
                    <td>
                      Dates timezone:
                    </td>
                    <td class="has-text-right">{{getTimezone}}</td>
                  </tr>
                  <tr>
                    <td>Nibble ID</td>
                    <td class="has-text-right">{{item.session_id}}</td>
                  </tr>
                  <tr v-if="isAdmin">
                    <td>Retailer</td>
                    <td class="has-text-right">{{item.retailer_name}}</td>
                  </tr>
                  <tr v-if="item.retailer_order_number != null && item.retailer_order_number !== ''">
                    <td>Order Number</td>
                    <td class="has-text-right">{{item.retailer_order_number}}</td>
                  </tr>
                  <tr v-if="item.user_name != null && item.user_name !== ''">
                    <td>User</td>
                    <td class="has-text-right">{{item.user_name}}</td>
                  </tr>
                  <tr>
                    <td>Started</td>
                    <td class="has-text-right">{{formatDate(item.created_at)}}</td>
                  </tr>
                  <tr>
                    <td>Ended</td>
                    <td class="has-text-right">{{formatDate(item.updated_at)}}</td>
                  </tr>
                  <tr v-if="item.negotiation_type === 'cart'">
                    <td colspan="2">Negotiation on Cart</td>
                  </tr>
                  <tr v-if="item.product">
                    <td>Product ID</td>
                    <td class="has-text-right">{{item.product.retailer_product_id}}</td>
                  </tr>
                  <tr v-if="item.product != null && item.product.name != null">
                    <td>Product</td>
                    <td class="has-text-right">
                      <a v-if="item.product.url" :href="item.product.url" target="_blank">{{item.product.name}}</a>
                      <span v-else>{{item.product.name}}</span>
                      <span v-if="item.product.sub_product != null && item.product.sub_product.name != null">
                        <br>
                        <a v-if="item.product.sub_product.url" :href="item.product.sub_product.url" target="_blank">
                          {{item.product.sub_product.name}}
                        </a>
                        <span v-else>{{item.product.sub_product.name}}</span>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="productSKU">
                    <td>Product SKU</td>
                    <td class="has-text-right">
                      {{productSKU}}
                    </td>
                  </tr>
                  <tr v-if="item.quantity > 1">
                    <td>Quantity</td>
                    <td class="has-text-right">{{item.quantity}}</td>
                  </tr>
                  <tr v-if="item.quote != null">
                    <td>Quote</td>
                    <td class="has-text-right">
                      {{item.quote.title}}
                      <br>
                      {{item.quote.reference_id}}
                    </td>
                  </tr>
                  <tr>
                    <td>Original Price</td>
                    <td class="has-text-right">{{item.product_price}}</td>
                  </tr>
                  <tr>
                    <td :style="getFieldDealPriceCss">Deal Price</td>
                    <td class="has-text-right">
                      <span class="admin-session-stat" v-if="isAdmin && item.current_price_percentage != null">
                        {{item.current_price_percentage}}%
                      </span>
                      <span>{{item.current_price}}</span>
                    </td>
                  </tr>
                  <tr v-if="!showOneObjective || isAdmin">
                    <td :style="getFieldTargetDiscountCss">Target Discount</td>
                    <td class="has-text-right">
                      <span>{{item.target_discount_percentage}}%</span>
                      <span class="admin-session-stat" v-if="isAdmin && item.target_discount_amount != null">
                        {{item.target_discount_amount}}
                      </span>
                    </td>
                  </tr>
                  <tr v-if="isAdmin && item.last_user_bid != null">
                    <td :style="getFieldLastOfferCss">User last bid</td>
                    <td class="has-text-right">
                      <span>{{item.last_user_bid_discount_percentage}}%</span>
                      <span class="admin-session-stat">
                        {{item.last_user_bid}}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="showOneObjective">Discount Threshold</td>
                    <td v-else>Walkaway Price</td>
                    <td class="has-text-right">
                      <span class="admin-session-stat" v-if="isAdmin && item.walkaway_percentage != null">
                        {{item.walkaway_percentage}}%
                      </span>
                      <span>{{item.walkaway_price}}</span>
                    </td>
                  </tr>
                  <tr v-if="showOneObjective">
                    <td>Objective</td>
                    <td class="has-text-right">{{ nameObjective(item) }}</td>
                  </tr>
                  <tr v-if="showGraph">
                    <td colspan="2">
                      <div class="objective-graph">
                        <img src="/images/objective-increase-conversion.svg" v-if="selectedIC" />
                        <img src="/images/objective-balance-performance.svg" v-if="selectedBP" />
                        <img src="/images/objective-preserve-margin.svg" v-if="selectedPM" />
                        <div class="bars">
                          <div class="bar dealprice" v-if="showDealPrice" :style="getDealPriceCss"></div>
                          <div class="bar targetdiscount" v-if="showTargetDiscount" :style="getTargetDiscountCss"></div>
                          <div class="bar lastoffer" v-if="showLastOffer" :style="getLastOfferCss"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="item.data_quality != null">
                    <td>Data Quality</td>
                    <td class="has-text-right">{{item.data_quality}}</td>
                  </tr>
                  <tr v-if="item.has_terms_url">
                    <td>Terms &amp; Conditions Agreement</td>
                    <td class="has-text-right">{{item.retailer_terms_agreed ? 'Yes' : 'No'}} </td>
                  </tr>
                  <tr v-if="isAdmin && item.engagement_category != null">
                    <td>Engagement Score</td>
                    <td class="has-text-right">
                      <material-icon icon="fiber_manual_record" size="small"
                        :status-class="`engagement-${item.engagement_category}`" />&nbsp;
                      <b v-if="!['pending', 'unavailable'].includes(item.engagement_category)">{{item.engagement_score}}
                        <sub>/ 5.0</sub></b>
                      ({{engagementCategory}})
                    </td>
                  </tr>
                  <tr v-if="isAdmin && item.chattiness_category != null">
                    <td>Chattiness</td>
                    <td class="has-text-right">
                      <material-icon icon="fiber_manual_record" size="small"
                        :status-class="`chattiness-${item.chattiness_category}`" />
                      <b v-if="item.chattiness_category != 'pending'">{{item.chattiness}} <sub>/ 5.0</sub></b>
                      ({{chattinessCategory}})
                    </td>
                  </tr>
                  <tr v-if="item.data_tag != null">
                    <td>Tag</td>
                    <td class="has-text-right">{{item.data_tag}}</td>
                  </tr>
                  <tr v-if="item.data_note != null">
                    <td>Note</td>
                    <td class="has-text-right">{{item.data_note}}</td>
                  </tr>
                  <tr v-if="item.zone_walk != null && item.zone_walk.length > 0">
                    <td>Zone Walk</td>
                    <td class="has-text-right">
                      <zone-grid :zone-walks="[item.zone_walk]" :width="200" :height="200" :labels="true"></zone-grid>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card mb-4" v-if="item.addon_item">
            <div class="card-content">
              <div class="card-head mb-4">
                <svg-icon icon="addon" />
                <h2 class="title is-3">Add-on Details</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td class="has-text-right">{{item.addon_item.name}}</td>
                  </tr>
                  <tr>
                    <td>Short Description</td>
                    <td class="has-text-right">{{item.addon_item.short_description}}</td>
                  </tr>
                  <tr>
                    <td>Display Value</td>
                    <td class="has-text-right">{{item.addon_item.display_value}}</td>
                  </tr>
                  <tr>
                    <td>Synthetic Value</td>
                    <td class="has-text-right">{{item.addon_item.synthetic_value}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card mb-4" v-if="item.rules">
            <div class="card-content">
              <div class="card-head mb-4">
                <material-icon icon="book"></material-icon>
                <h2 class="title is-3">Matched Rules</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr v-for="(rule, ruleIndex) in item.rules" :key="ruleIndex">
                    <td>
                      <router-link :to="ruleLink(rule)" v-if="ruleLink(rule)">{{rule.name}}</router-link>
                      <span v-else>{{rule.name}}</span>
                      <span v-if="!rule.activated"> (off)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card mb-4" v-if="item.features">
            <div class="card-content">
              <div class="card-head mb-4">
                <material-icon icon="settings"></material-icon>
                <h2 class="title is-3">Active Features</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr v-for="(feature, featureIndex) in item.features" :key="featureIndex">
                    <td>{{feature}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card mb-4" v-if="item.algorithm">
            <div class="card-content">
              <div class="card-head mb-4">
                <material-icon icon="calculate"></material-icon>
                <h2 class="title is-3">Algorithm Details</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr>
                    <td>Algorithm Version</td>
                    <td class="has-text-right">{{item.algorithm.version}}</td>
                  </tr>
                  <tr v-for="(key, keyIndex) in Object.keys(item.algorithm.hyper_parameters)" :key="keyIndex">
                    <td>{{key}}</td>
                    <td class="has-text-right">{{item.algorithm.hyper_parameters[key]}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="column is-narrow">
          <chat-window id="chat-window" :session="item" :admin="isAdmin"></chat-window>
        </div>
      </div>
    </main-column>

    <b-modal :active.sync="isAnnotationModalActive">
      <div class="box">
        <form @submit="submitAnnotation">
          <h3 class="title is-3">Annotate Session</h3>
          <b-field label="Tag">
            <b-autocomplete v-model="dataTag" :data="availableDataTags" placeholder="Tag" clearable
              open-on-focus></b-autocomplete>
          </b-field>

          <div class="field">
            <label class="label">Data Quality</label>
            <div class="control">
              <input type="text" class="input" v-model.number="dataQuality" placeholder="0">
            </div>
          </div>
          <div class="field">
            <label class="label">Note</label>
            <div class="control">
              <textarea class="textarea" v-model="dataNote" placeholder="Note"></textarea>
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <p class="buttons">
              <b-button type="is-text is-rounded" @click="isAnnotationModalActive = false">Cancel</b-button>
              <b-button type="is-primary is-rounded" native-type="submit">Save</b-button>
            </p>
          </div>
        </form>
      </div>
    </b-modal>

  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import html2canvas from 'html2canvas'
import ItemEditor from '@/utils/mixins/ItemEditor'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import NotificationBar from '@/components/NotificationBar'
import ChatWindow from '@/components/ChatWindow'
import ZoneGrid from '@/components/ZoneGrid'
import StatusIcon from '@/components/StatusIcon'
import MaterialIcon from '@/components/MaterialIcon'
import SvgIcon from '@/components/SvgIcon'
import Review from '@/components/recovered-nibbles/Review'

export default {
  name: 'Session',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, NotificationBar, ChatWindow, ZoneGrid, StatusIcon, MaterialIcon, SvgIcon, Review },
  data () {
    return {
      isAnnotationModalActive: false,
      availableDataTags: [],
      dataQuality: null,
      dataTag: null,
      dataNote: null,
      session_prev_id: null,
      session_next_id: null
    }
  },
  computed: {
    getTimezone () {
      return nibble.timezoneManager.get()
    },
    showOneObjective () {
      return (this.item.objective !== null && this.item.objective !== undefined)
    },
    showPrevButton () {
      return this.session_prev_id != null
    },
    showNextButton () {
      return this.session_next_id != null
    },
    isNewItem () {
      return false
    },
    itemPath () {
      if (this.retailerId != null) {
        return `/retailers/${this.retailerId}/sessions/${this.itemId}`
      } else {
        return `/sessions/${this.itemId}`
      }
    },
    listPath () {
      if (this.retailerId != null) {
        return `/retailers/${this.retailerId}/sessions`
      } else {
        return '/sessions'
      }
    },
    listApiPath () {
      return '/sessions'
    },
    itemApiPath () {
      return `/sessions/${this.itemId}`
    },
    itemName () {
      if (this.isLimitedData) {
        return 'Recoverable Nibbles'
      } else {
        return 'Nibble'
      }
    },
    itemsName () {
      if (this.isLimitedData) {
        return 'Retargeting'
      } else {
        return 'Nibbles'
      }
    },
    isLimitedData () {
      return this.$store.getters.isLimitedData
    },
    itemApiParams () {
      var params = this.filterQuery
      if (this.retailerId != null) {
        params.retailer_id = this.retailerId
      }
      return params
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    showAbandonedNibbleOptions () {
      const abandonedNibbleStatus = ['user_offer_available', 'user_offer_accepted', 'user_offer_countered', 'user_offer_rejected']
      return abandonedNibbleStatus.includes(this.item.summary_status)
    },
    sessionStatus () {
      if (this.item.summary_status === 'sold') {
        if (this.item.negotiation_status === 'recovered_purchased') {
          return this.item.addon_item_id === null ? 'Recovered - Purchased' : 'Recovered - Purchased with Add-on'
        } else {
          return this.item.addon_item_id === null ? 'Purchased' : 'Purchased with Add-on'
        }
      } else if (this.item.summary_status === 'added_to_basket') {
        if (this.item.negotiation_type === 'cart') {
          return 'Sent to Checkout'
        } else {
          return 'Added to Basket'
        }
      } else if (this.item.summary_status === 'checkout') {
        return 'Sent to Checkout'
      } else if (this.item.summary_status === 'abandoned_deal_agreed') {
        return 'Deal Agreed'
      } else if (this.item.summary_status === 'abandoned_reached_final_offer') {
        return 'Final Offer'
      } else if (this.item.summary_status === 'user_offer_available') {
        return 'Recoverable - waiting for review'
      } else if (this.item.summary_status === 'user_offer_accepted') {
        return 'Recoverable - user offer accepted'
      } else if (this.item.summary_status === 'user_offer_rejected') {
        return 'No longer recoverable - user offer rejected'
      } else if (this.item.summary_status === 'user_offer_countered') {
        return 'Recoverable - counter offer sent'
      } else if (this.item.summary_status === 'negotiated') {
        return null
      } else {
        return null
      }
    },
    engagementCategory () {
      return this.toTitleCase(this.item.engagement_category)
    },
    chattinessCategory () {
      return this.item.chattiness_category.split('_').map(this.toTitleCase).join(' ')
    },
    filterQuery () {
      var query = {}
      var filters = this.$route.query
      if (filters.dataQuality != null) {
        query.dataQuality = filters.dataQuality
      }
      if (filters.chattinessCategory != null) {
        query.chattinessCategory = filters.chattinessCategory
      }
      if (filters.retailerId != null) {
        query.retailerId = filters.retailerId
      }
      if (filters.summaryStatus != null) {
        query.summaryStatus = filters.summaryStatus
      }
      if (filters.timeRange != null) {
        query.timeRange = filters.timeRange
      }
      if (filters.startDate != null) {
        query.startDate = filters.startDate
      }
      if (filters.endDate != null) {
        query.endDate = filters.endDate
      }
      if (filters.zeroLength) {
        query.zeroLength = 'true'
      }
      if (filters.hasGenerativeAtoms) {
        query.hasGenerativeAtoms = 'true'
      }
      if (filters.hasGenerativeComposer) {
        query.hasGenerativeComposer = 'true'
      }
      if (filters.incorrectIntentInference) {
        query.incorrectIntentInference = 'true'
      }
      if (filters.incorrectEntityInference) {
        query.incorrectEntityInference = 'true'
      }
      if (filters.incorrectGptOutput) {
        query.incorrectGptOutput = 'true'
      }
      if (filters.cohort != null) {
        query.cohort = filters.cohort
      }
      if (filters.experiment != null) {
        query.experiment = filters.experiment
      }
      if (filters.rule != null) {
        query.rule = filters.rule
      }
      return query
    },
    prevSessionRoute () {
      if (this.session_prev_id != null) {
        return { path: `${this.listPath}/${this.session_prev_id}`, query: this.filterQuery }
      } else {
        return null
      }
    },
    nextSessionRoute () {
      if (this.session_next_id != null) {
        return { path: `${this.listPath}/${this.session_next_id}`, query: this.filterQuery }
      } else {
        return null
      }
    },
    showGraph () {
      // No reason to show the graph without a user bid
      return this.showOneObjective && this.item.last_user_bid != null && this.isAdmin
    },
    graphData () {
      var range = 100 - this.item.walkaway_percentage
      var data = {
        range: range,
        dealpp: Math.round((100 - this.item.current_price_percentage * 100 / this.item.walkaway_percentage) * 100) / 100,
        tdpp: Math.round((100 - this.item.target_discount_percentage * 100 / this.item.walkaway_percentage) * 100) / 100,
        lubp: Math.round((100 - this.item.last_user_bid_discount_percentage * 100 / this.item.walkaway_percentage) * 100) / 100,
        left: 8.726
      }
      console.log('DATA:', data, this.item)
      return data
    },
    showLastOffer () {
      return this.showGraph && this.item.last_user_bid != null && (1 * this.item.walkaway_percentage > 1 * this.item.last_user_bid_discount_percentage) // Convert to numbers
    },
    showDealPrice () {
      return this.showGraph
    },
    showTargetDiscount () {
      return this.showGraph
    },
    getLastOfferCss () {
      if (!this.showLastOffer) {
        return ''
      }
      var data = this.graphData
      return 'left:' + (Math.max(Math.min(data.lubp, 99.9), 0.2)) + '%'
    },
    getDealPriceCss () {
      if (!this.showDealPrice) {
        return ''
      }
      var data = this.graphData
      return 'left:' + (Math.max(Math.min(data.dealpp, 99.9), 0.2)) + '%'
    },
    getTargetDiscountCss () {
      if (!this.showTargetDiscount) {
        return ''
      }
      var data = this.graphData
      return 'left:' + (data.tdpp) + '%'
    },
    selectedIC () {
      return (this.item.objective === 'increase_conversion')
    },
    selectedBP () {
      return (this.item.objective === 'balance_performance')
    },
    selectedPM () {
      return (this.item.objective === 'preserve_margin')
    },
    getFieldDealPriceCss () {
      if (this.showDealPrice) {
        return 'color:orange;'
      }
      return ''
    },
    getFieldTargetDiscountCss () {
      if (this.showTargetDiscount) {
        return 'color:green;'
      }
      return ''
    },
    getFieldLastOfferCss () {
      if (this.showLastOffer) {
        return 'color:blue;'
      }
      return ''
    },
    canScreenshotChat () {
      return this.$store.getters.isFeatureAvailable('chat_export')
    },
    productSKU () {
      var sku = null
      if (this.item.product != null && this.item.product.sub_product != null) {
        sku = this.item.product.sub_product.product_sku
      }
      if ((sku == null || sku === '') && this.item.product != null) {
        sku = this.item.product.product_sku
      }
      return sku
    }
  },
  methods: {
    dataToItem (data) {
      this.dataQuality = data.data_quality
      this.dataTag = data.data_tag
      this.dataNote = data.data_note
      this.availableDataTags = data.available_tags
      this.session_next_id = data.session_next_id
      this.session_prev_id = data.session_prev_id
      if (data.retailer_order_number == null) {
        data.retailer_order_number = this.getOrderNumber(data)
      }
      return data
    },
    getOrderNumber (data) {
      if (data.retailer_order_number !== undefined && data.retailer_order_number !== null) {
        return data.retailer_order_number
      }
      var on = null
      if (data.followup_sessions !== undefined && data.followup_sessions !== null && data.followup_sessions.length > 0) {
        let i = 0
        const l = data.followup_sessions.length
        while (on == null && i < l) {
          on = this.getOrderNumber(data.followup_sessions[i])
          i++
        }
      }
      return on
    },
    hasFlag (session, flagName) {
      return session.status_flags.indexOf(flagName) !== -1
    },
    formatDate (date) {
      return nibble.timezoneManager.formatDate(date)
    },
    toTitleCase (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    },
    discountPercentage (amount) {
      const percentage = Math.round(amount * 100 / this.item.price)
      return `${percentage}%`
    },
    ruleLink (rule) {
      if (rule.visibility === 'retailer') {
        return `/retailers/${rule.retailer_id}/rules/${rule.rule_id}`
      } else {
        const path = `/admin/rules/${rule.rule_id}`
        if (rule.algorithm_version != null) {
          return { path: path, query: { context: 'admin:algorithm' } }
        } else {
          return null
        }
      }
    },
    submitAnnotation (e) {
      e.preventDefault()
      this.isAnnotationModalActive = false

      const params = {
        data_quality: this.dataQuality || 0,
        data_tag: this.dataTag != null && this.dataTag !== '' ? this.dataTag : null,
        data_note: this.dataNote != null && this.dataNote !== '' ? this.dataNote : null
      }

      nibble.put(`/sessions/${this.itemId}`, params, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchItemData()
        }).catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    submitRecoveredNibble (params) {
      nibble.put(`/sessions/${this.itemId}`, params, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchItemData()
        }).catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    nameObjective (item) {
      if (item.objective !== undefined && item.objective !== null) {
        let objective = '- error -'
        switch (item.objective) {
          case 'increase_conversion':
            objective = 'Increase conversion'
            break
          case 'preserve_margin':
            objective = 'Preserve margin'
            break
          case 'balance_performance':
            objective = 'Balance performance'
            break
          default:
            console.log('Something wrong with objective of Session ' + item.session_id)
        }
        return objective
      }
      return ''
    },
    async saveAsImage () {
      const element = document.getElementById('chat-window')
      let chat = false
      if (element) {
        chat = element.querySelector('.chat-scroll')
      }
      if (chat) {
        chat.classList.add('capture-style')

        // Adding a fix to all SVG elements
        var svgElements = chat.querySelectorAll('svg')
        svgElements.forEach(function (item) {
          item.setAttribute('width', item.getBoundingClientRect().width)
          item.setAttribute('height', item.getBoundingClientRect().height)
          item.style.width = null
          item.style.height = null
        })

        // 1 second delay to load the SVG logo
        const date = Date.now()
        let currentDate = null
        do {
          currentDate = Date.now()
        } while (currentDate - date < 1000)

        const canvas = await html2canvas(chat)

        chat.classList.remove('capture-style')

        const imgDataUrl = canvas.toDataURL('image/png')

        const d = new Date()
        const link = document.createElement('a')
        link.href = imgDataUrl
        link.download = 'session-' + this.item.session_id + '-download-' + d.getTime() + '.png'
        link.click()
        link.remove()
      } else {
        alert('Something wrong, can\'t find the chat log')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.accept-offer-button {
  background: #F5A623;
  color: #FFFFFF;
  &:hover,
  &.is-hovered
  &:focus,
  &.is-focused
  &:active,
  &.is-active {
    border-color: #f8c065;
    background: #f8c065;
    color: #ffffff;
  }
}
.pending-price {
  color: #F5A623;
}
.chat-window-container {
  margin-top: 3.5rem;
  margin-left: 4rem;
}
.admin-session-stat {
  padding-right: 1em;
  // color: #EDF5FD;
  line-height: inherit;
  &:last-child {
    padding-right: 0;
    padding-left: 1em;
  }
}
.objective-graph {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: fit-content;
  img {
    z-index: 1;
  }
  .bars {
    display: block;
    position: absolute;
    overflow: hidden;
    left: 8.726%;
    width: 81.601%;
    height: 72%;
    z-index: 2;
    top: 4%;
    .bar {
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        left: -10px;
        z-index: 3;
        border: none;
        border-right: dashed 2px transparent;
      &.dealprice {
        border-right-color: orange;
        height: 100%;
        top: 3%;
      }
      &.targetdiscount {
        border-right-color: green;
      }
      &.lastoffer {
        border-right-color: blue;
      }
    }
  }
}
</style>
