<template>
  <div :class="containerClass">
    <div class="main-column-header" v-if="!noHeader">
      <breadcrumb-list v-if="breadcrumbs" :breadcrumbs="breadcrumbs"></breadcrumb-list>
      <nav class="level">
        <div class="level-left">
          <h1 class="level-item title is-1" v-if="pageTitle">
            {{pageTitle}}
          </h1>
          <slot name="header-left"></slot>
        </div>
        <div class="level-right">
          <slot name="header-right"></slot>
        </div>
      </nav>
    </div>

    <div class="banners" v-if="!noHeader && !noBanner">
      <banner banner-type="is-info" class="m-5" v-if="($route.name === 'Rules') && (callBannerType === 'welcome')">
        <template v-slot:title>Welcome to Nibble!</template>
        <template v-slot:icon><img src="/images/nibble-logo.svg"/></template>
        <template v-slot:text>
          This is your <b>Ruleset</b> page, where you can see all the rules Nibble will follow during negotiations.<br/>
          At the moment, there is only a <b>Default Rule</b> that we created for you.
          <p class="mt-1">
            Don't worry, the rule is <b>not active yet</b> since the switch is off.
            You can turn it on now by clicking on the switch in the 'Active' column.
          </p>
        </template>
        <template v-slot:button>
          <b-button type="is-primary is-rounded" @click="updateBanner('initial_book_call', true)">Continue</b-button>
        </template>
      </banner>
      <banner banner-type="is-info" class="m-5" v-if="callBannerType === 'initial_book_call_1'">
        <template v-slot:title>Make the most out of Nibble</template>
        <template v-slot:icon><material-icon size="xlarge" icon="devices"/></template>
        <template v-slot:text>
          We strongly suggest you to book free training with us, so we can help with your initial setup.<br/>
          <b>We can schedule a call now for the next 48 hours.</b>
        </template>
        <template v-slot:button>
          <b-button class="mr-1" type="is-primary is-rounded" @click="bookCallCallback(true)">Book Free Training</b-button>
          <b-button class="ml-1" type="is-primary is-light is-rounded" @click="subBanner = 2">Not Now</b-button>
        </template>
      </banner>
      <banner banner-type="is-info" class="m-5" v-if="($route.name === 'Rules') && (callBannerType === 'initial_book_call_2')">
        <template v-slot:title>It looks like you know what you are doing!</template>
        <template v-slot:icon><material-icon size="xlarge" icon="tune"/></template>
        <template v-slot:text>
          If you are comfortable with how <b>Target Discount</b> and <b>Walkaway Threshold</b> are set up for your <b>Whole Store</b>,
          you can simply switch the rule on and Nibble will appear on all the products. Click on the Rule name for <b>additional settings</b>.
        </template>
        <template v-slot:button>
          <b-button type="is-primary is-rounded" @click="bookCallCallback(true)">Book Free Training</b-button>
        </template>
      </banner>
      <banner banner-type="is-info" class="m-5" v-if="callBannerType === 'persistent_book_call'">
        <template v-slot:icon><material-icon size="xlarge" icon="devices"/></template>
        <template v-slot:text>
          <b>Need Help?</b><br>
          Click the button to the right to book free training with us for your setup so you can make the most out of Nibble.
        </template>
        <template v-slot:button>
          <b-button type="is-link is-light is-rounded" @click="bookCallCallback(true)" outlined>Book Free Training</b-button>
        </template>
      </banner>
      <banner banner-type="is-info" class="m-5" v-if="callBannerType === 'persistent_book_call'">
        <template v-slot:icon><material-icon size="xlarge" icon="brush"/></template>
        <template v-slot:text>
          <b>Need to change the look of your Nibble button?</b><br>
          Let us know using the chat button on the bottom right, and we'll help you out at no cost.
        </template>
      </banner>
    </div>

    <div class="main-column-content-spacer"/>

    <div :class="contentClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import nibble from '@/utils/nibble'
import BreadcrumbList from '@/components/BreadcrumbList'
import MaterialIcon from '@/components/MaterialIcon'
import Banner from '@/components/Banner'

export default {
  name: 'MainColumn',
  components: { BreadcrumbList, MaterialIcon, Banner },
  props: {
    breadcrumbs: {
      required: false,
      type: Array
    },
    pageTitle: {
      required: false,
      type: String
    },
    noHeader: {
      required: false,
      type: Boolean,
      default: false
    },
    noBanner: {
      required: false,
      type: Boolean,
      default: false
    },
    contentClass: {
      required: false,
      type: String,
      default: 'main-column-content'
    }
  },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      bannerType: null,
      subBanner: 1
    }
  },
  created () {
    this.fetchBannerType()
  },
  watch: {
    bannerType: function () {
      if ((this.bannerType === 'initial_book_call')) {
        this.updateBanner('persistent_book_call', false)
      }
    },
    retailerId () {
      this.fetchBannerType()
    }
  },
  computed: {
    containerClass () {
      var classes = ['main-column-container']
      if (this.noHeader) {
        classes.push('no-header')
      }
      return classes
    },
    callBannerType () {
      if (this.bannerType === 'initial_book_call') {
        return `${this.bannerType}_${this.subBanner}`
      } else {
        return this.bannerType
      }
    }
  },
  methods: {
    fetchBannerType () {
      this.$store.dispatch('ensureRetailerDataAvailable', this.retailerId)
        .then((retailer) => {
          if (retailer != null) {
            this.bannerType = retailer.admin_site_banner_type
          } else {
            this.bannerType = null
          }
        }).catch((error) => {
          console.log(error)
        })
    },
    updateBanner (type, fetch = false) {
      if (this.retailerId) {
        nibble.put(`/retailers/${this.retailerId}`, { admin_site_banner_type: type }, this.$store.getters.authHeaders)
          .then((response) => {
            this.$store.state.retailer.admin_site_banner_type = type
            this.$store.dispatch('forceRetailerUpdate', this.retailerId)
            if (fetch) {
              this.fetchBannerType()
            }
          }).catch((error) => {
            console.log(error)
          })
      }
    },
    bookCallCallback (success) {
      // For now, keep the banner active even if the user books a call
      window.open('https://meetings.hubspot.com/jamieettedgui/book-a-training-call', '_blank')
    }
  }
}
</script>

<style scoped>
</style>
