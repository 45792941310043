<template>
  <span>
    <label class="label">{{label}}</label>
    <p class="tag-list">
      <b-tag v-for="(option, optionIndex) in selectedOptions"
             :key="optionIndex"
             @close="deselectOption(option)"
             closable
             attached
             rounded
             close-icon="close"
             size="is-medium">
        {{option.name}}
      </b-tag>
    </p>
    <b-field :message="message" :type="inputType">
      <b-select v-model="selectedOption"
                :placeholder="placeholder"
                expanded
                v-if="availableOptions.length > 0">
        <option v-for="(option, optionIndex) in availableOptions"
                :key="optionIndex"
                :value="option">
          {{option.name}}
        </option>
      </b-select>
    </b-field>
  </span>
</template>
<script>
export default {
  name: 'TagPicker',
  props: ['value', 'options', 'validationRules', 'label', 'placeholder', 'idKey', 'message', 'inputType'],
  data () {
    return {
      selectedOptions: this.selectedOptionsFromValue(this.value),
      selectedOption: null
    }
  },
  watch: {
    value () {
      this.selectedOptions = this.selectedOptionsFromValue(this.value)
    },
    selectedOption () {
      if (this.selectedOption != null) {
        this.selectedOptions.push(this.selectedOption)
        this.$nextTick(() => {
          this.selectedOption = null
          this.input()
        })
      }
    }
  },
  computed: {
    availableOptions () {
      return this.options.filter((o) => this.findIndex(o, this.selectedOptions) === -1)
    }
  },
  methods: {
    findIndex (option, options) {
      return options.findIndex((o) => o[this.idKey] === option[this.idKey])
    },
    deselectOption (option) {
      this.selectedOptions.splice(this.findIndex(option, this.selectedOptions), 1)
      this.input()
    },
    selectedOptionsFromValue (value) {
      return this.options.filter((o) => this.findIndex(o, value) !== -1)
    },
    valueFromSelectedOptions (selectedOptions) {
      return this.options.filter((o) => this.findIndex(o, selectedOptions) !== -1)
    },
    input () {
      var options = this.valueFromSelectedOptions(this.selectedOptions)
      this.$emit('input', options)
    }
  }
}
</script>
