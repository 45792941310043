<template>
  <page-layout active-menu-item="retailers">
    <main-column :breadcrumbs="breadcrumbs" page-title="Retailers" content-class="main-column-sub-columns columns">
      <template #header-left/>
      <template #header-right/>

      <retailer-filters
                       :filters="filters"
                       @change="debouncedUpdateFilters"/>

      <div class="column">
        <div :class="mainColumnContentClasses">
          <nav class="level" v-if="items != null && items.length > 0">
            <p class="level-left list-summary">
              {{offset + 1}}-{{Math.min(offset + 20, totalItemCount)}} of {{totalItemCount}} Retailers
            </p>
          </nav>
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Retailers"
            :showButton="false">
            <template v-slot:text>
              No retailers matching those filters were found.
            </template>
          </empty-list-indicator>
          <b-table v-else :data="items" hoverable>
            <b-table-column field="name" label="Retailer" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ props.row.name }}
                </strong>
              </router-link>
            </b-table-column>
            <b-table-column field="plan" label="Pricing Plan" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <span v-html="pricingPlanName(props.row)"></span>
              </router-link>
            </b-table-column>
            <b-table-column field="signup" :label="`Signed Up (${currentTimezone})`" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <span v-html="formatDate(props.row.created_at)"></span>
              </router-link>
            </b-table-column>
            <b-table-column field="latest_nibble" :label="`Last Nibble (${currentTimezone})`" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <span v-html="formatDate(props.row.last_nibble_at)"></span>
              </router-link>
            </b-table-column>
            <b-table-column label="" v-slot="props">
              <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                <template #trigger>
                  <b-button type="is-text is-icon" icon-left="dots-vertical"/>
                </template>
                <b-dropdown-item aria-role="listitem" @click="editItem(props.row)">Manage</b-dropdown-item>
              </b-dropdown>
            </b-table-column>
          </b-table>
          <list-paginator :offset="offset" :page-size="20" :total-item-count="totalItemCount" @navigate="setOffset"/>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import debounce from 'lodash/debounce'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import ListPaginator from '@/components/ListPaginator'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ItemList from '@/utils/mixins/ItemList'
import RetailerFilters from '@/components/RetailerFilters'

export default {
  name: 'Products',
  mixins: [ItemList],
  components: { PageLayout, MainColumn, EmptyListIndicator, RetailerFilters, ListPaginator },
  data () {
    return {
      loading: false,
      items: [],
      filters: { sortOrder: 'recent', pricingPlanIdentifier: null, storeTypeIdentifier: null, searchText: '' },
      totalItemCount: null,
      offset: this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0
    }
  },
  created () {
    this.debouncedUpdateFilters = debounce(this.updateFilters, 500)
  },
  computed: {
    currentTimezone () {
      return nibble.timezoneManager.get()
    },
    newProductLink () {
      return '/retailers/new'
    },
    listPath () {
      return '/retailers'
    },
    listApiPath () {
      return '/retailers'
    },
    listApiParams () {
      var params = this.filterQuery(this.filters)
      params.offset = this.offset
      params.context = 'list'
      return params
    },
    itemsKey () {
      return 'retailers'
    },
    itemIdKey () {
      return 'retailer_id'
    },
    itemName () {
      return 'Retailer'
    },
    showEmptyList () {
      return this.items != null & this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    }
  },
  methods: {
    itemPath (item) {
      return `/retailers/${item.retailer_id}`
    },
    editItem (item) {
      this.$router.push(this.itemPath(item))
    },
    routeUpdated () {
      this.updateFiltersFromRoute()
    },
    updateFiltersFromRoute () {
      this.offset = this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0
      this.filters = {
        sortOrder: this.$route.query.sortOrder != null ? this.$route.query.sortOrder : 'recent',
        pricingPlanIdentifier: (this.$route.query.pricingPlanIdentifier != null && this.$route.query.pricingPlanIdentifier !== '') ? this.$route.query.pricingPlanIdentifier : null,
        storeTypeIdentifier: (this.$route.query.storeTypeIdentifier != null && this.$route.query.storeTypeIdentifier !== '') ? this.$route.query.storeTypeIdentifier : null,
        hasUnbilledCharges: this.$route.query.hasUnbilledCharges === 'true',
        searchText: this.$route.query.searchText != null ? this.$route.query.searchText : ''
      }
      this.fetchData()
    },
    updateFilters (newFilters) {
      console.log('update route from filters')
      this.$router.replace({ path: this.listPath, query: this.filterQuery(newFilters) })
    },
    setOffset (offset) {
      var query = this.filterQuery(this.filters)
      query.offset = offset
      this.$router.push({ path: this.listPath, query: query })
    },
    filterQuery (filters) {
      var query = { }
      if (filters.searchText != null || filters.sortOrder != null) {
        query.sortOrder = filters.sortOrder
        query.pricingPlanIdentifier = filters.pricingPlanIdentifier
        query.storeTypeIdentifier = filters.storeTypeIdentifier
        query.searchText = filters.searchText
      }
      if (filters.hasUnbilledCharges) {
        query.hasUnbilledCharges = true
      }
      return query
    },
    formatDate (date) {
      return nibble.timezoneManager.formatDate(date, 'DD/MM/YYYY hh:mm[&nbsp;]A')
    },
    pricingPlanName (retailer) {
      if (retailer.billing_scheme != null) {
        return retailer.billing_scheme.name
      } else {
        return 'None'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.retailer-status {
  display: flex;
  flex-direction: row;
}

.retailer-status-label {
  display: block;
  margin-left: 8px;
  flex: 1;
}
.retailer-status-tooltip {
  display: block;
  text-transform: capitalize;
}
</style>
