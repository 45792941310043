<template>
    <div class="objectives">
        <div class="objectives-right">
            <div class="objectives-right-inputs">
                <div class="objectives-right-datetime">
                    <div class="objective">
                        <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="Begin Date" v-slot="{ errors }">
                        <b-field label="Begin Date" :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-datepicker v-model="objectives.startDate"
                                        locale='en-GB'
                                        placeholder="Choose Date"
                                        icon="calendar-today"
                                        expanded
                                        :date-formatter="dateFormatter"
                                        :date-parser="dateParser">
                            </b-datepicker>
                        </b-field>
                        </ValidationProvider>
                    </div>
                    <div class="objective">
                        <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="Begin Time" v-slot="{ errors }">
                        <b-field label="Begin Time" :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select v-model="objectives.startTime"
                                    placeholder="Choose Time"
                                    expanded
                                    @input="input">
                            <option v-for="(option, optionIndex) in allTimes" :key="optionIndex" :value="option">{{option}}</option>
                            </b-select>
                        </b-field>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="objectives-right-datetime">
                    <div class="objective">
                        <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="End Date" v-slot="{ errors }">
                        <b-field label="End Date" :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-datepicker v-model="objectives.endDate"
                                        locale='en-GB'
                                        placeholder="Choose Date"
                                        icon="calendar-today"
                                        expanded
                                        :date-formatter="dateFormatter"
                                        :date-parser="dateParser">
                            </b-datepicker>
                        </b-field>
                        </ValidationProvider>
                    </div>
                    <div class="objective">
                        <ValidationProvider :rules="{required: objectives.enableTimeLimit}" name="End Time" v-slot="{ errors }">
                        <b-field label="End Time" :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select v-model="objectives.endTime"
                                    placeholder="Choose Time"
                                    expanded
                                    @input="input">
                            <option v-for="(option, optionIndex) in allTimes" :key="optionIndex" :value="option">{{option}}</option>
                            </b-select>
                        </b-field>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'TimeLimitEditor',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      objectives: this.objectivesFromValue(this.value)
    }
  },
  watch: {
    value () {
      this.objectives = this.objectivesFromValue(this.value)
    },
    'objectives.startDate' (oldDate, newDate) {
      // The Buefy Datepicker emits input() events even when nothing changed;
      // this checks if the date has actually changed before raising
      // an input event
      if (this.objectiveToDateTimeString(oldDate, null) !== this.objectiveToDateTimeString(newDate, null)) {
        this.input()
      }
    },
    'objectives.endDate' (oldDate, newDate) {
      // The Buefy Datepicker emits input() events even when nothing changed;
      // this checks if the date has actually changed before raising
      // an input event
      if (this.objectiveToDateTimeString(oldDate, null) !== this.objectiveToDateTimeString(newDate, null)) {
        this.input()
      }
    }
  },
  computed: {
    allTimes () {
      return [
        '12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM',
        '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
        '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
        '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'
      ]
    }
  },
  methods: {
    dateFormatter (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    dateParser (string) {
      return moment(string, 'DD/MM/YYYY').toDate()
    },
    objectivesFromValue (value) {
      var timeLimitEnabled = value.enableTimeLimit

      return {
        startDate: (timeLimitEnabled ? this.valueToDate(value.dateRange.startDateTime) : null),
        startTime: (timeLimitEnabled ? this.valueToTime(value.dateRange.startDateTime) : null),
        endDate: (timeLimitEnabled ? this.valueToDate(value.dateRange.endDateTime) : null),
        endTime: (timeLimitEnabled ? this.valueToTime(value.dateRange.endDateTime) : null),
        enableTimeLimit: timeLimitEnabled
      }
    },
    valueFromObjectives (objectives) {
      var value = {
        enableTimeLimit: false,
        dateRange: {
          startDateTime: null,
          endDateTime: null
        }
      }
      if (objectives.enableTimeLimit) {
        value.enableTimeLimit = true
        if (objectives.startDate != null || objectives.startTime != null) {
          value.dateRange.startDateTime = this.objectiveToDateTimeString(objectives.startDate, objectives.startTime)
        }
        if (objectives.endDate != null || objectives.endTime != null) {
          value.dateRange.endDateTime = this.objectiveToDateTimeString(objectives.endDate, objectives.endTime)
        }
      }
      return value
    },
    objectiveToDateTimeString (date, time) {
      if (date == null) {
        date = moment().format('DD/MM/YYYY')
      } else {
        date = moment(date).format('DD/MM/YYYY')
      }
      if (time == null) {
        time = '12:00 AM'
      }
      return moment(`${date} ${time}`, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:SS')
    },
    valueToDate (dateTimeValue) {
      if (dateTimeValue != null) {
        return moment(dateTimeValue).toDate()
      } else {
        return null
      }
    },
    valueToTime (dateTimeValue) {
      if (dateTimeValue != null) {
        return moment(dateTimeValue).format('hh:mm A')
      } else {
        return null
      }
    },
    input () {
      this.$emit('input', this.valueFromObjectives(this.objectives))
    }
  }
}
</script>
<style lang="scss" scoped>
  .objectives,
  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime {
    margin: -.75rem;
    display: flex;
  }

  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime,
  .objective {
    padding: .75rem;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  @media (max-width: 1440px) {
    .objectives {
      display: block;
    }
  }

  @media (max-width: 1060px) {
    .objectives-right-datetime {
      display: block;
    }
  }
</style>
