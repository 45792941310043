<template>
  <page-layout :menu-available="false">
    <main-column :no-header="true">
      <div class="narrow-column">
        <form class="card mb-4" v-on:submit.prevent.stop="submit">
          <div class="card-content">
            <div class="terms">
              <h2 class="title is-2 has-text-centered">Nibble's Simple Terms &amp; Conditions</h2>
              <p class="mb-4">
                Here at Nibble we like to keep things straight forward and friendly, so we have created a
                simple set of terms and conditions with no complex legal jargon. We think you'll find them
                clear and concise.
              </p>
              <h4 class="title is-4 mb-1">Service Provider</h4>
              <p class="mb-4">
                Nibble Technology Limited (referred to as &quot;Nibble&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;)
                <br>
                Registered in the UK Company Registration Number: 2171827163
                <br>
                Registered Address:  5, Elstree Gate, Elstree Way, Borehamwood WD6 1JD
              </p>
              <h4 class="title is-4 mb-1">Description</h4>
              <p class="mb-4">
                Nibble is a negotiation chatbot that is installed on your website. It will negotiate a
                personalised price with your customer based on the target discount you provide. More
                details can be found at <a href="www.nibbletechnology.com">www.nibbletechnology.com</a>
              </p>
              <h4 class="title is-4 mb-1">Term</h4>
              <p class="mb-4">
                This agreement shall start on the date you install this app.
              </p>
              <h4 class="title is-4 mb-1">Cancellation</h4>
              <p class="mb-4">
                You can cancel at any time by uninstalling the app or turning off all rules.
              </p>
              <h4 class="title is-4 mb-1">Support</h4>
              <p class="mb-4">
                All our services are hosted-on Amazon AWS and subject to their availability.
                <br>
                Our support team is available Monday-Friday 9am-6pm GMT and can be contacted at <a href="mailto:support@nibble.team">support@nibble.team</a>
                <br>
                We endeavour to respond to all support requests within 8 hours.
              </p>
              <h4 class="title is-4 mb-1">Liability</h4>
              <p class="mb-4">
                Nibble Technology Limited is not liable for any loss.
              </p>
              <h4 class="title is-4 mb-1">Confidentiality</h4>
              <p class="mb-4">
                Nibble is a proprietary technology, and you will not disclose any confidential information you may acquire during your use.
              </p>
              <h4 class="title is-4 mb-1">Protection</h4>
              <p class="mb-4">
                You will not attempt to reverse-engineer, modify or copy Nibble's technology.
              </p>
              <h4 class="title is-4 mb-1">Non-solicit</h4>
              <p class="mb-4">
                You will not, during the contract period, nor for the 12 months thereafter seek to employ or offer to employ any member of the Nibble team.
              </p>
              <h4 class="title is-4 mb-1">Brand</h4>
              <p class="mb-4">
                Nibble is a registered trademark, and you will not use the logo or trademark in any marketing / other manner without our prior approval.
              </p>
            </div>
            <div class="field is-grouped mt-5">
              <b-checkbox v-model="termsAgreed" class="control is-expanded">I have read and accept the Terms & Conditions</b-checkbox>
              <p class="control">
                <button type="submit" class="button is-primary is-rounded" :disabled="!termsAgreed">Continue</button>
              </p>
            </div>
            <b-notification :active="errorMessage != null" :message="errorMessage" type="is-danger" :closable="false"></b-notification>
          </div>
        </form>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import { ToastProgrammatic as Toast } from 'buefy'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'

export default {
  name: 'RetailerTermsAndConditions',
  components: { PageLayout, MainColumn },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      termsAgreed: false,
      errorMessage: null
    }
  },
  computed: {
  },
  methods: {
    submit () {
      if (!this.termsAgreed) {
        this.errorMessage = 'You must agree to the terms & conditions to continue.'
      } else {
        nibble.put(`/retailers/${this.retailerId}`, { terms_agreed: true }, this.$store.getters.authHeaders)
          .then((response) => {
            Toast.open({ message: 'Saved', type: 'is-primary' })
            return this.$store.dispatch('forceUserUpdate')
          })
          .then(() => {
            this.$router.push(`/retailers/${this.retailerId}/rules`)
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errorMessage = error.response.data.errorMessage
            } else {
              nibble.handleNetworkError(error, this.$store)
            }
          })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card-content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 14rem);
  .terms {
    flex: 1;
    overflow-y: scroll;
  }
}
</style>
