<template>
  <page-layout :retailer-id="retailerId" active-menu-item="products">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">

        <template #header-right>
          <p class="level-item buttons">
            <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
            <b-button type="is-primary is-rounded" @click="handleSubmit(submit)">Save</b-button>
          </p>
        </template>

        <div class="card">
          <div class="card-content">
            <h2 class="title is-2">Product Info</h2>
            <notification-bar>
              The Product ID specified here should match the identifier your eCommerce platform
              uses for the product. It is automatically passed to Nibble via the Nibble Widget.
              See the widget documentation for more details.
            </notification-bar>
            <div class="columns">
              <div class="column">
                <ValidationProvider rules="required" name="Product ID" v-slot="{ errors }">
                  <b-field label="Product ID" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.retailer_product_id"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider rules="required" name="Product Name" v-slot="{ errors }">
                  <b-field label="Product Name" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.name"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <notification-bar v-if="errorMessage != null" type="is-danger">
              {{errorMessage}}
            </notification-bar>
          </div>
        </div>

      </main-column>
    </validation-observer>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import NotificationBar from '@/components/NotificationBar'
import ItemEditor from '@/utils/mixins/ItemEditor'

export default {
  name: 'Products',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, NotificationBar },
  data () {
    return {
      item: {
        name: '',
        retailer_product_id: ''
      }
    }
  },
  computed: {
    listPath () {
      return `/retailers/${this.retailerId}/products`
    },
    newPath () {
      return `/retailers/${this.retailerId}/products/new`
    },
    itemPath () {
      return `/retailers/${this.retailerId}/products/${this.itemId}`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/products`
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}/products/${this.itemId}`
    },
    itemName () {
      return 'Product'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
