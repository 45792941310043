<template>
  <page-layout active-menu-item="admin:theme">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">
      <template #header-right>
        <p class="level-item buttons">
          <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
          <b-button type="is-primary is-rounded" @click="updateTheme">Save</b-button>
        </p>
      </template>

      <div class="columns">
        <div class="column">
          <notification-bar v-if="errorSection" type="is-danger">
            Unable to save theme &#8212; invalid {{ errorSection }} settings.
          </notification-bar>
          <div class="card">
            <div class="card-content">
              <ValidationObserver ref="settings_observer" slim>
              <div class="columns">
                <div class="column">
                  <ValidationProvider rules="required" name="Name" v-slot="{ errors }">
                    <b-field label="Name" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-input v-model="item.name"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <!-- FIXME: theme_type is currently unused - this should be deleted if we remove theme_type
                <div class="column">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-field label="Theme Type" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-input v-model="item.theme_type"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                -->
                <div class="column small-column">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-field label="Language Code" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-input v-model="item.language_code" placeholder="en-GB"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column">
                  <ValidationProvider name="Retailer" v-slot="{ errors }">
                    <b-field label="Retailer" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-select v-model="item.retailer_id" expanded>
                        <option :value="null">All Retailers</option>
                        <option v-for="(retailer, retailerIndex) in retailers"
                                :key="retailerIndex"
                                :value="retailer.retailer_id">
                          {{retailer.name}}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              </ValidationObserver>
            </div>
            <br>
            <div class="card-content contrast">
              <h3 class="title is-5">Button and tooltip styling</h3>
              <p class="mb-3">Click <a @click.prevent="openStyleGuide = true">here</a> to see the style guide.</p>
              <b-modal :active.sync="openStyleGuide" >
                <div class="box has-text-centered">
                  <img width="500" src="/images/theme-guide.png"/>
                </div>
              </b-modal>
              <b-tabs v-model="activeTab">
                <b-tab-item label="Button">
                  <ValidationObserver ref="button_style_observer" slim>
                  <h3 class="title is-6">Main settings</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.text"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font family" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.fontFamily"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font weight" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.fontWeight"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font style" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-select placeholder="Select a style" v-model="item.theme_configuration.button.fontStyle" expanded>
                            <option value="normal">Normal</option>
                            <option value="italic">Italic</option>
                            <option value="oblique">Oblique</option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font size" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.fontSize"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.color"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font smoothing (macOS only)" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-select placeholder="Select smoothing" v-model="item.theme_configuration.button.fontSmoothing" expanded>
                            <option value="auto">Auto</option>
                            <option value="none">None</option>
                            <option value="antialiased">Anti-aliased</option>
                            <option value="subpixel-antialiased">Sub-pixel anti-aliased</option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font stretch" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.fontStretch"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Letter spacing" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.letterSpacing"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Text transform" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-select placeholder="Select a transform" v-model="item.theme_configuration.button.textTransform" expanded>
                            <option value="none">None</option>
                            <option value="capitalize">Capitalize</option>
                            <option value="uppercase">Uppercase</option>
                            <option value="lowercase">Lowercase</option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Button effect" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-select placeholder="Select an effect" v-model="item.theme_configuration.button.effect" expanded>
                            <option value="none">None</option>
                            <option value="pulse">Pulse</option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Background color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.backgroundColor"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.border"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border radius" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.borderRadius"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Box shadow" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.boxShadow"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Height" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.height"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Margin" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.margin"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Padding" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.padding"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Transition" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.transition"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">Logo settings</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Size" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.logo.size"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Right margin" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.logo.marginRight"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.logo.color"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">Hover settings</h3>
                  <p class="mb-4"><em>Note</em>: Setting any of these options to <code>auto</code> will copy the non-hover styling defined above.</p>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.hover.color"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Logo color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.hover.logoColor"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Background color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.hover.backgroundColor"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Box shadow" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.hover.boxShadow"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.hover.border"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border radius" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.button.hover.borderRadius"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  </ValidationObserver>
                </b-tab-item>
                <b-tab-item label="Container">
                  <ValidationObserver ref="container_style_observer" slim>
                  <h3 class="title is-6">Main settings</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Max width" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.container.maxWidth"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Min width" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.container.minWidth"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Float" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.container.float"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Margin" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.container.margin"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Padding" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.container.padding"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  </ValidationObserver>
                </b-tab-item>
                <b-tab-item label="Tooltip">
                  <ValidationObserver ref="tooltip_style_observer" slim>
                  <h3 class="title is-6">Main settings</h3>
                  <div class="columns">
                    <div class="column">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Display tooltip?" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-checkbox
                            v-model="item.theme_configuration.infoText.enabled"
                            true-value="true" false-value="false">
                            {{ item.theme_configuration.infoText.enabled === 'true' ? 'Yes' : 'No' }}
                          </b-checkbox>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.text"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font family" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.fontFamily"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font weight" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.fontWeight"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font size" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.fontSize"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.color"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Background color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.backgroundColor"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.border"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border radius" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.borderRadius"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Height" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.height"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Bottom margin" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.marginBottom"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">Hover settings</h3>
                  <p class="mb-4"><em>Note</em>: Setting any of these options to <code>auto</code> will copy the non-hover styling defined above.</p>
                  <div class="columns">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Font color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.hover.color"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Background color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.hover.backgroundColor"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.hover.border"></b-input>
                        </b-field>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Border radius" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.infoText.hover.borderRadius"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">Popup settings</h3>
                  <div class="columns">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input maxlength="250" type="textarea" v-model="item.theme_configuration.infoPopup.text"/>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  </ValidationObserver>
                </b-tab-item>
                <b-tab-item label="Window">
                  <ValidationObserver ref="window_style_observer" slim>
                  <h3 class="title is-6">Main settings</h3>
                  <div class="columns">
                    <div class="column">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Display marketing footer?" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-checkbox
                            v-model="item.theme_configuration.window.marketing.enabled"
                            true-value="true" false-value="false">
                            {{ item.theme_configuration.window.marketing.enabled === 'true' ? 'Yes' : 'No' }}
                          </b-checkbox>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column">
                      <div class="block has-text-centered">
                        Window Logo (replaces Nibble logo)<br>
                        Should be 36px high, width is variable but no more than about 150px
                      </div>
                      <b-field>
                        <image-upload-field v-model="item.logo_image"></image-upload-field>
                      </b-field>
                      <div v-if="item.logo_image" class="block has-text-centered">
                        <img :src="item.logo_image.image_url" :width="item.logo_image.width" :height="item.logo_image.height">
                      </div>
                    </div>
                  </div>
                  </ValidationObserver>
                </b-tab-item>
                <b-tab-item label="Dwell Time Popup">
                  <ValidationObserver ref="dwell_time_popup_style_observer" slim>
                  <h3 class="title is-6">Main settings</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Heading Text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.dwellTimePopup.headingText"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Paragraph Text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.dwellTimePopup.text"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">Logo Colours</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Gradient Start" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.dwellTimePopup.logoGradientStart"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Gradient End" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.dwellTimePopup.logoGradientEnd"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  </ValidationObserver>
                </b-tab-item>
                <b-tab-item label="Signposting">
                  <h3 class="title is-6">Nibble Logo</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Logo Color" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.logoColor"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">'Complete Your Deal' Signposting</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Header (prefix)" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.dealHeaderPrefix"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Header (suffix)" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.dealHeaderSuffix"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Deal Ready Header" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.dealReadyHeader"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Show popin on recommended product?" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-checkbox
                            v-model="item.theme_configuration.signposting.showPopupOnRecommendedProduct"
                            :true-value="true" :false-value="false">
                            {{ item.theme_configuration.signposting.showPopupOnRecommendedProduct ? 'Yes' : 'No' }}
                          </b-checkbox>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Deal description (suffix)" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.dealDescriptionSuffix"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Complete Deal button text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.completeDealButtonText"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">Suggestion Popup</h3>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Header" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.suggestionHeader"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Message" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.suggestionMessage"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Add to Basket button text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.suggestionAddToBasketText"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Other Options button text" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.suggestionOtherOptionsText"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <h3 class="title is-6">Recommendations</h3>
                  <div class="columns is-mobile">
                    <div class="column">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Recommendations Message" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.recommendationsMessage"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Message following recommendations (prefix)" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.recommendationsFollowupMessagePrefix"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-half">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field label="Message following recommendations (suffix)" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-input v-model="item.theme_configuration.signposting.recommendationsFollowupMessageSuffix"></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="xnib-button-preview">
                  <theme-preview :theme="item.theme_configuration"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import NotificationBar from '@/components/NotificationBar'
import ThemePreview from '@/components/ThemePreview'
import ImageUploadField from '@/components/ImageUploadField'

import ItemEditor from '@/utils/mixins/ItemEditor'
import nibble from '@/utils/nibble'
import { defaultTheme, mergeThemes } from '@/utils/theme'

export default {
  name: 'Theme',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, ThemePreview, NotificationBar, ImageUploadField },
  data () {
    return {
      retailers: null,
      activeTab: 0,
      errorSection: null,
      openStyleGuide: false,
      item: {
        theme_type: 'button',
        language_code: 'en-GB',
        name: '',
        retailer_id: null,
        logo_image: null,
        theme_configuration: defaultTheme
      }
    }
  },
  computed: {
    listPath () {
      return '/admin/themes'
    },
    newPath () {
      return '/admin/themes/new'
    },
    itemPath () {
      return `/admin/themes/${this.itemId}`
    },
    listApiPath () {
      return '/admin/themes'
    },
    itemApiPath () {
      return `/admin/themes/${this.itemId}`
    },
    itemName () {
      return 'Theme'
    }
  },
  methods: {
    fetchOptions () {
      this.fetchRetailers()
      // Use styles from loaded theme, but use defaults for any unspecified settings
      this.item.theme_configuration = mergeThemes(defaultTheme, this.item.theme_configuration)
    },
    fetchRetailers () {
      return nibble.get('/retailers', { context: 'search', sortOrder: 'name' }, this.$store.getters.authHeaders)
        .then((response) => {
          this.retailers = response.data.retailers
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    validateMainSettings () {
      return this.$refs.settings_observer.validate()
    },
    validateButtonStyles () {
      return this.$refs.button_style_observer.validate()
    },
    validateContainerStyles () {
      return this.$refs.container_style_observer.validate()
    },
    validateTooltipStyles () {
      return this.$refs.tooltip_style_observer.validate()
    },
    validateWindowStyles () {
      return this.$refs.window_style_observer.validate()
    },
    async updateTheme () {
      const mainSettingsValid = await this.validateMainSettings()
      const buttonStylesValid = await this.validateButtonStyles()
      const containerStylesValid = await this.validateContainerStyles()
      const tooltipStylesValid = await this.validateTooltipStyles()
      const windowStylesValid = await this.validateWindowStyles()

      if (!buttonStylesValid) {
        this.activeTab = 0
        this.errorSection = 'button'
        return null
      }

      if (!containerStylesValid) {
        this.activeTab = 1
        this.errorSection = 'container'
        return null
      }

      if (!tooltipStylesValid) {
        this.activeTab = 2
        this.errorSection = 'tooltip'
        return null
      }

      if (!windowStylesValid) {
        this.activeTab = 3
        this.errorSection = 'window'
        return null
      }

      if (!mainSettingsValid) {
        this.errorSection = 'main'
        return null
      }

      this.submit()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.xnib-button-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.small-column {
  max-width: 200px;
}
</style>
