<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-6-tablet is-5-desktop is-5-widescreen">
        <form class="card" v-on:submit.prevent.stop="submit">
          <div class="card-content">
            <div class="block has-text-centered">
              <img src="/images/nibble-logo.png" alt="Nibble. Converse. Convert." width="168" height="50">
            </div>

            <p>
              Please log in below to access your Nibble retailer account.
            </p>
            <hr>
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input type="email" class="input" v-model.trim="email" placeholder="hello@example.com" autocomplete="email">
              </div>
            </div>

            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input type="password" class="input" v-model.trim="password" placeholder="******" autocomplete="current-password">
              </div>
            </div>

            <div class="notification is-danger is-light" v-if="error">
              {{error}}
            </div>
            <div class="field is-grouped is-grouped-right">
              <div class="control buttons">
                <router-link to="/account/forgot" class="button is-text is-rounded">Forgot Password?</router-link>
                <button type="submit" class="button is-primary is-rounded">Log In</button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  </section>
</template>

<script>
import nibble from '@/utils/nibble'

const WEB_CLIENT_ID = 'XO5QD20YN4T9GJTLWR38C2WFTF87WGG1H6IZKDL9'

export default {
  name: 'Login',
  components: { },
  data () {
    return {
      email: '',
      phone: '',
      password: '',
      error: null,
      loading: false
    }
  },
  computed: {
  },
  methods: {
    validate () {
      this.error = null
      if (this.email === '') {
        this.error = 'Please enter your email address'
      }
      if (this.password === '') {
        this.error = 'Please enter your password'
      }
    },
    submit (e) {
      this.validate()
      if (this.error != null) {
        return
      }
      this.submitLogin()
    },
    submitLogin () {
      var params = {
        email: this.email,
        password: this.password,
        grant_type: 'password',
        client_id: WEB_CLIENT_ID
      }
      nibble.postRaw('oauth/token', params)
        .then((response) => {
          console.log('got auth response')
          if (response.data != null) {
            console.log('got response data')
            this.$store.commit('login', response.data)
            return this.$store.dispatch('ensureUserDataAvailable')
          }
        })
        .then(() => {
          if (this.$store.state.user != null) {
            if (this.$store.state.user.retailer != null) {
              console.log('Login update retailer')
              return this.$store.dispatch('ensureRetailerDataAvailable', this.$store.state.user.retailer.id)
            } else {
              return this.$store.dispatch('ensureRetailerDataAvailable', null)
            }
          }
        })
        .then(() => {
          if (this.$route.query.return_path != null) {
            this.$router.push({ path: this.$route.query.return_path, query: this.$route.query.return_query })
          } else {
            this.$router.push('/')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            if (error.response.data != null && error.response.data.error_message != null) {
              this.error = error.response.data.error_message
            } else {
              this.error = 'Incorrect login credentials, please try again.'
            }
          } else {
            this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            nibble.handleNetworkError(error, this.$store)
          }
          console.log(error)
        })
      console.log('submit')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
