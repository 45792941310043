<template>
  <div :class="columnClass">
    <div class="filters-container">

      <div class="filters">
        <nav class="level">
          <div class="level-left">
            <filter-button @toggle="toggleVisibility"></filter-button>
            <h3 class="level-item title is-3">Filters</h3>
          </div>
          <div class="level-right">
            <b-button type="is-text is-rounded" class="level-item" @click="resetFilters">Reset</b-button>
          </div>
        </nav>
        <form class="filters" @submit="submit">
          <fieldset>
            <legend>Sort Order</legend>
            <b-field>
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="sortOrder" @change="submit" expanded>
                    <option value="recent">Signed Up</option>
                    <option value="name">Name</option>
                  </select>
                </span>
              </div>
            </b-field>
          </fieldset>
          <fieldset>
            <legend>Filter Retailers</legend>
            <b-field label="Pricing Plan">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="pricingPlanIdentifier" @change="submit" expanded>
                    <option :value="null">All Plans</option>
                    <option v-for="(pricingPlan, pricingPlanIndex) in this.pricingPlans" :key="pricingPlanIndex" :value="pricingPlan.identifier">{{ pricingPlan.name }}</option>
                  </select>
                </span>
              </div>
            </b-field>
              <b-field label="Store Type">
                <div class="control expanded">
                  <span class="select is-fullwidth">
                    <select v-model="storeTypeIdentifier" @change="submit" expanded>
                      <option :value="null">All Stores</option>
                      <option v-for="(storeType, storeTypeIndex) in this.storeTypes" :key="storeTypeIndex" :value="storeType.identifier">{{ storeType.name }}</option>
                    </select>
                  </span>
                </div>
              </b-field>
            <div class="field">
              <label class="b-checkbox checkbox">
                <input type="checkbox" name="hasUnbilledCharges" v-model="hasUnbilledCharges" @change="submit">
                <span class="check"></span>
                <span class="control-label">Has Unbilled Charges?</span>
              </label>
            </div>
          </fieldset>
          <fieldset>
            <legend>Search Retailers</legend>
            <b-field label="Retailer Name">
              <b-input v-model="searchText" @input="search"></b-input>
            </b-field>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FilterButton from '@/components/FilterButton'

export default {
  name: 'RetailerFilters',
  components: { FilterButton },
  props: ['options', 'filters', 'searchEnabled'],
  data () {
    return {
      visible: true,
      searchText: this.filters != null ? this.filters.searchText : '',
      pricingPlanIdentifier: this.filters != null ? this.filters.pricingPlanIdentifier : null,
      storeTypeIdentifier: this.filters != null ? this.filters.storeTypeIdentifier : null,
      hasUnbilledCharges: this.filters.hasUnbilledCharges,
      sortOrder: this.filters != null ? this.filters.sortOrder : 'recent'
    }
  },
  computed: {
    columnClass () {
      if (this.visible) {
        return 'column filters-column is-narrow filters-visible'
      } else {
        return 'column filters-column is-narrow filters-hidden'
      }
    },
    pricingPlans () {
      return [
        { identifier: 'starter', name: 'Starter' },
        { identifier: 'professional', name: 'Professional' },
        { identifier: 'enterprise', name: 'Enterprise' },
        { identifier: 'basic', name: 'Basic (Legacy)' },
        { identifier: 'legacy_professional', name: 'Professional (Legacy)' },
        { identifier: 'premium', name: 'Premium (Legacy)' }
      ]
    },
    storeTypes () {
      return [
        { identifier: 'shopify', name: 'Shopify' },
        { identifier: 'magento', name: 'Magento' },
        { identifier: 'woocommerce', name: 'WooCommerce' },
        { identifier: 'api', name: 'API / Other' }
      ]
    }
  },
  watch: {
    filters: 'updateFromProps'
  },
  methods: {
    toggleVisibility () {
      this.visible = !this.visible
    },
    hasFilter (filterName) {
      return filterName in this.options
    },
    updateFromProps () {
      this.searchText = this.filters.searchText
      this.pricingPlanIdentifier = this.filters.pricingPlanIdentifier
      this.storeTypeIdentifier = this.filters.storeTypeIdentifier
      this.hasUnbilledCharges = this.filters.hasUnbilledCharges
      this.sortOrder = this.filters.sortOrder
    },
    resetFilters () {
      this.sortOrder = 'recent'
      this.pricingPlanIdentifier = null
      this.storeTypeIdentifier = null
      this.hasUnbilledCharges = false
      this.searchText = ''
      this.emitChange()
    },
    search (e) {
      this.emitChange()
    },
    submit (e) {
      e.preventDefault()
      this.emitChange()
    },
    emitChange () {
      var filters = {
        searchText: this.searchText,
        pricingPlanIdentifier: this.pricingPlanIdentifier,
        storeTypeIdentifier: this.storeTypeIdentifier,
        hasUnbilledCharges: this.hasUnbilledCharges,
        sortOrder: this.sortOrder
      }
      this.$emit('change', filters)
    }
  }
}
</script>
