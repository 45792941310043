<template>
  <b-modal :active="active" :canCancel="false" :id="option">
    <div class="box dialog">
      <div class="media">
        <div class="media-left has-text-primary">
          <material-icon :icon="option.icon" size="xlarge"></material-icon>
        </div>
        <div class="content">
          <h2 class="title is-2 mb-0">{{ option.modalTitle }}</h2>
        </div>
      </div>
      <p v-html="option.explanation" />
      <h4 class="title is-4 mt-4 mb-0" v-if="option.offerField !=null">{{ option.offerField }}</h4>
      <h2 class="title is-2 has-text-primary mt-2 mb-0" v-if="option.offerField !=null">{{ offerPrice }}</h2>
      <h4 class="title is-4 mt-4 mb-2">Add a custom message</h4>
      <b-field label="Your message">
        <b-input maxlength="250" type="textarea" v-model="retailerMessage"></b-input>
      </b-field>
      <div v-if="showReasonOptions">
        <h4 class="title is-4 mb-2">Tell Nibble why you are accepting this offer</h4>
        <p class="mb-3">Your answer will help optimizing your conversion and margins:</p>
        <section>
          <b-field>
            <b-radio v-model="reason" native-value="exception" type="is-info">
              This is a one-time exception
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="reason" native-value="should_have_accepted" type="is-info">
              I think Nibble should have accepted this offer
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="reason" native-value="clearing_stock" type="is-info">
              I’m trying to clear stock for this item
            </b-radio>
          </b-field>
        </section>
      </div>

      <div class="field is-grouped is-grouped-right mt-4">
        <p class="buttons">
          <b-button type="is-rounded is-outlined" @click="cancel">Cancel</b-button>
          <b-button type="is-primary is-rounded" @click="submit">Confirm</b-button>
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  props: {
    active: {
      required: true,
      type: Boolean,
      default: false
    },
    offerPrice: {
      required: false,
      type: String
    },
    option: {
      required: true,
      type: Object
    }
  },
  components: { MaterialIcon },
  data () {
    return {
      retailerMessage: '',
      reason: ''
    }
  },
  methods: {
    cancel () {
      this.retailerMessage = ''
      this.reason = ''
      this.$emit('cancel')
    },
    submit () {
      this.$emit('submit', this.retailerMessage, this.reason)
    }
  },
  computed: {
    showReasonOptions () {
      if (this.option.type === 'user_offer_rejected') {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .icon {
//   color: #1976D2;
//   background-color: #1976D2;
// }
</style>
