<template>
  <div :class="columnClass">
    <div class="filters-container">

      <div class="filters">
        <nav class="level">
          <div class="level-left">
            <filter-button @toggle="toggleVisibility"></filter-button>
            <h3 class="level-item title is-3">Filters</h3>
          </div>
          <div class="level-right">
            <b-button type="is-text is-rounded" class="level-item" @click="resetFilters">Reset</b-button>
          </div>
        </nav>
        <form @submit="submit">
          <fieldset>
            <legend>Session Filters</legend>
            <b-field label="Status" v-if="hasFilter('summaryStatus')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="summaryStatus" @change="submit" expanded>
                    <option :value="null">Any Session Status</option>
                    <option v-for="(status, statusIndex) in this.options.summaryStatus" :key="statusIndex"
                      :value="status.status">
                      {{status.name}}
                    </option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field label="Rule" v-if="hasFilter('rules')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="rule" @change="submit" expanded>
                    <option :value="null">Any</option>
                    <option v-for="(rule, ruleIndex) in this.options.rules" :key="ruleIndex" :value="rule.rule_id">{{
                      rule.name }}</option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field label="Time Range" v-if="hasFilter('timeRange')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="timeRange" @change="changeTimeRange" expanded>
                    <option v-for="(range, rangeIndex) in this.options.timeRange" :key="rangeIndex"
                      :value="range.value">
                      {{range.name}}
                    </option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field v-if="timeRange === 'custom'" class="datepicker-field" label="Start Date">
              <b-datepicker placeholder="Start Date" icon="calendar-today" editable v-model="startDate"
                @input="changeStartDate" :max-date="endDate" expanded />
              <p class="control">
                <b-button icon-right="backspace" @click="clearStartDate" />
              </p>
            </b-field>
            <b-field v-if="timeRange === 'custom'" class="datepicker-field" label="End Date">
              <b-datepicker placeholder="End Date" icon="calendar-today" editable v-model="endDate"
                @input="changeEndDate" :min-date="startDate" :max-date="currentDate" expanded />
              <p class="control">
                <b-button icon-right="backspace" @click="clearEndDate" />
              </p>
            </b-field>
          </fieldset>
          <fieldset v-if="viewAsAdmin">
            <legend>Advanced Filters</legend>
            <b-field label="Retailer" v-if="hasFilter('retailer')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="retailerId" @change="submit" expanded>
                    <option :value="null">All Retailers</option>
                    <option v-for="(retailer, retailerIndex) in this.options.retailer" :key="retailerIndex"
                      :value="retailer.retailerId">{{retailer.name}}</option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field label="Data Quality" v-if="hasFilter('dataQuality')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="dataQuality" @change="submit" expanded>
                    <option :value="null">Any Data Quality</option>
                    <option v-for="(quality, qualityIndex) in this.options.dataQuality" :key="qualityIndex"
                      :value="quality">{{quality}} and above</option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field label="Chattiness Category" v-if="hasFilter('chattinessCategory')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="chattinessCategory" @change="submit" expanded>
                    <option :value="null">Any Category</option>
                    <option v-for="(category, categoryIndex) in this.options.chattinessCategory" :key="categoryIndex"
                      :value="category.category">{{category.name}}</option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field label="Negotiation Objective">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="objective" @change="submit" expanded>
                    <option :value="null">Any</option>
                    <option value="none">None (Old Rule Configuration)</option>
                    <option value="increase_conversion">Increase conversion</option>
                    <option value="balance_performance">Balance Performance</option>
                    <option value="preserve_margin">Preserve Margin</option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field label="Cohort Experiments" v-if="hasFilter('experimentalRules')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="cohort" @change="submit" expanded>
                    <option :value="null">Any</option>
                    <option v-for="(experimentCohort, experimentCohortIndex) in this.experimentCohortOptions"
                      :key="experimentCohortIndex" :value="experimentCohort.key">{{experimentCohort.name}}</option>
                  </select>
                </span>
              </div>
            </b-field>
            <b-field label="Old Experiments" v-if="hasFilter('experimentalRules')">
              <div class="control expanded">
                <span class="select is-fullwidth">
                  <select v-model="experiment" @change="submit" expanded>
                    <option :value="null">Any</option>
                    <option v-for="(experiment, experimentIndex) in this.experimentOptions" :key="experimentIndex"
                      :value="experiment.key">{{experiment.name}}</option>
                  </select>
                </span>
              </div>
            </b-field>
            <div class="field">
              <label class="b-checkbox checkbox">
                <input type="checkbox" name="hasGenerativeAtoms" v-model="hasGenerativeAtoms" @change="submit">
                <span class="check"></span>
                <span class="control-label">Has Generative Atoms?</span>
              </label>
            </div>
            <div class="field">
              <label class="b-checkbox checkbox">
                <input type="checkbox" name="hasGenerativeComposer" v-model="hasGenerativeComposer" @change="submit">
                <span class="check"></span>
                <span class="control-label">Uses Generative Composer?</span>
              </label>
            </div>
            <div class="field">
              <label class="b-checkbox checkbox">
                <input type="checkbox" name="incorrectIntentInference" v-model="incorrectIntentInference"
                  @change="submit">
                <span class="check"></span>
                <span class="control-label">Incorrect Intent Inference?</span>
              </label>
            </div>
            <div class="field">
              <label class="b-checkbox checkbox">
                <input type="checkbox" name="incorrectEntityInference" v-model="incorrectEntityInference"
                  @change="submit">
                <span class="check"></span>
                <span class="control-label">Incorrect Entity Inference?</span>
              </label>
            </div>
            <div class="field">
              <label class="b-checkbox checkbox">
                <input type="checkbox" name="incorrectGptOutput" v-model="incorrectGptOutput" @change="submit">
                <span class="check"></span>
                <span class="control-label">Incorrect GPT Output?</span>
              </label>
            </div>
          </fieldset>
        </form>
        <form class="filters" @submit="search">
          <fieldset v-if="searchEnabled && canSeeLimitedData()">
            <legend>Search Nibbles</legend>
            <b-field label="Nibble ID">
              <b-input v-model="searchText"></b-input>
            </b-field>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import FilterButton from '@/components/FilterButton'
import nibble from '../utils/nibble'

export default {
  name: 'SessionFilters',
  components: { FilterButton },
  props: ['options', 'filters', 'searchEnabled'],
  data () {
    return {
      visible: true,
      searchText: '',
      dataQuality: this.filters.dataQuality || null,
      chattinessCategory: this.filters.chattinessCategory || null,
      retailerId: this.filters.retailerId || null,
      summaryStatus: !this.canSeeLimitedData() ? 'user_offer_available' : (this.filters.summaryStatus || null),
      timeRange: this.filters.timeRange || null,
      startDate: this.filters.startDate != null ? moment(nibble.timezoneManager.fromUTCtoLocal(this.filters.startDate, 'YYYY-MM-DDT00:00:00Z')).toDate() : null,
      endDate: this.filters.endDate != null ? moment(nibble.timezoneManager.fromUTCtoLocal(this.filters.endDate, 'YYYY-MM-DDT00:00:00Z')).toDate() : null,
      zeroLength: this.filters.zeroLength,
      hasGenerativeAtoms: this.filters.hasGenerativeAtoms,
      hasGenerativeComposer: this.filters.hasGenerativeComposer,
      incorrectIntentInference: this.filters.incorrectIntentInference,
      incorrectEntityInference: this.filters.incorrectEntityInference,
      incorrectGptOutput: this.filters.incorrectGptOutput,
      currentDate: moment().toDate(),
      objective: this.filters.objective || null,
      cohort: this.filters.cohort || null,
      experiment: this.filters.experiment || null,
      rule: this.filters.rule || null
    }
  },
  computed: {
    columnClass () {
      if (this.visible) {
        return 'column filters-column is-narrow filters-visible'
      } else {
        return 'column filters-column is-narrow filters-hidden'
      }
    },
    navRetailerId () {
      // Fetches :retailerId if the route matches /retailers/:retailerId/dashboard
      return this.$route.params.retailerId ? parseInt(this.$route.params.retailerId) : null
    },
    queryRetailerId () {
      // Returns the retailer ID if it is specified as a query parameter
      return this.$route.query.retailerId ? parseInt(this.$route.query.retailerId) : null
    },
    viewAsAdmin () {
      if (this.isAdmin) {
        return this.navRetailerId == null
      } else {
        return false
      }
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    experimentOptions () {
      var options = []
      this.options.experimentalRules.forEach(function (experiment) {
        options.push({
          key: `${experiment.rule_id}:false`,
          name: `${experiment.name} - Control`
        })
        options.push({
          key: `${experiment.rule_id}:true`,
          name: `${experiment.name} - Experiment`
        })
      })
      return options
    },
    experimentCohortOptions () {
      var options = []
      this.options.cohortExperimentRules.forEach(function (experiment) {
        experiment.cohorts.forEach(function (cohort) {
          options.push({
            key: `${cohort.cohort_id}`,
            name: `${experiment.name} - ${cohort.name}`
          })
        })
      })
      return options
    }
  },
  watch: {
    filters: 'update'
  },
  methods: {
    canSeeLimitedData () {
      if (this.$route.name !== 'Sessions') {
        // Remove filter only in sessions page
        return true
      }
      return !this.$store.getters.isLimitedData
    },
    toggleVisibility () {
      this.visible = !this.visible
    },
    hasFilter (filterName) {
      if (!this.canSeeLimitedData() && filterName === 'summaryStatus') {
        return false
      }
      return filterName in this.options
    },
    update () {
      this.dataQuality = this.filters.dataQuality
      // this.engagementCategory = this.filters.engagementCategory
      this.chattinessCategory = this.filters.chattinessCategory
      this.retailerId = this.filters.retailerId
      this.summaryStatus = !this.canSeeLimitedData() ? 'user_offer_available' : this.filters.summaryStatus
      this.timeRange = this.filters.timeRange
      this.startDate = this.filters.startDate != null ? moment(nibble.timezoneManager.fromUTCtoLocal(this.filters.startDate, 'YYYY-MM-DDT00:00:00Z')).toDate() : null
      this.endDate = this.filters.endDate != null ? moment(nibble.timezoneManager.fromUTCtoLocal(this.filters.endDate, 'YYYY-MM-DDT00:00:00Z')).toDate() : null
      this.zeroLength = this.filters.zeroLength
      this.hasGenerativeAtoms = this.filters.hasGenerativeAtoms
      this.hasGenerativeComposer = this.filters.hasGenerativeComposer
      this.incorrectIntentInference = this.filters.incorrectIntentInference
      this.incorrectEntityInference = this.filters.incorrectEntityInference
      this.incorrectGptOutput = this.filters.incorrectGptOutput
      this.objective = this.filters.objective || null
      this.cohort = this.filters.cohort || null
      this.experiment = this.filters.experiment || null
      this.rule = this.filters.rule || null
    },
    resetFilters () {
      this.dataQuality = null
      // this.engagementCategory = null
      this.chattinessCategory = null
      this.retailerId = null
      this.summaryStatus = !this.canSeeLimitedData() ? 'user_offer_available' : null
      this.timeRange = 'all'
      this.startDate = null
      this.endDate = null
      this.zeroLength = null
      this.hasGenerativeAtoms = null
      this.hasGenerativeComposer = null
      this.incorrectIntentInference = null
      this.incorrectEntityInference = null
      this.incorrectGptOutput = null
      this.objective = null
      this.cohort = null
      this.experiment = null
      this.rule = null
      this.emitChange()
    },
    search (e) {
      e.preventDefault()
      this.$emit('search', this.searchText)
    },
    submit (e) {
      e.preventDefault()
      this.emitChange()
    },
    changeTimeRange (e) {
      e.preventDefault()
      if (this.timeRange === 'all') {
        this.startDate = null
        this.endDate = null
        this.emitChange()
      } else if (this.timeRange !== 'custom') {
        var days = parseInt(this.timeRange.split('d')[0])
        var startMoment = moment().subtract(days - 1, 'days')
        this.startDate = startMoment.toDate()
        this.endDate = this.currentDate
        this.emitChange()
      }
    },
    changeStartDate (d) {
      this.timeRange = 'custom'
      this.startDate = d
      this.emitChange()
    },
    changeEndDate (d) {
      this.timeRange = 'custom'
      this.endDate = d
      this.emitChange()
    },
    clearStartDate () {
      this.timeRange = 'custom'
      this.startDate = null
      this.emitChange()
    },
    clearEndDate () {
      this.timeRange = 'custom'
      this.endDate = null
      this.emitChange()
    },
    emitChange () {
      var startDay = null

      if (this.startDate != null) {
        // console.log(['StartDate', this.startDate])
        startDay = moment(this.startDate).format('YYYY-MM-DDT00:00:00Z') // Midnight
        // console.log(['Midnight', startDay])
        startDay = nibble.timezoneManager.replaceTimezone(startDay, localStorage.getItem('timezone'))
        // console.log(['Midnight RETAILER', startDay])
        startDay = nibble.timezoneManager.formatDateToUTC(startDay, 'YYYY-MM-DDTHH:mm:ssZ')
        // console.log(['Midnight UTC', startDay])
      }

      var endDay = null

      if (this.endDate != null) {
        // console.log(['EndDate', this.endDate])
        endDay = moment(this.endDate).format('YYYY-MM-DDT23:59:00Z') // 1 minute to next midnight
        // console.log(['EndDay', endDay])
        endDay = nibble.timezoneManager.replaceTimezone(endDay, localStorage.getItem('timezone'))
        // console.log(['EndDay RETAILER', endDay])
        endDay = nibble.timezoneManager.formatDateToUTC(endDay, 'YYYY-MM-DDTHH:mm:ssZ')
        // console.log(['EndDay UTC', endDay])
      }

      var filters = {
        dataQuality: this.dataQuality,
        // engagementCategory: this.engagementCategory,
        chattinessCategory: this.chattinessCategory,
        retailerId: this.retailerId,
        summaryStatus: !this.canSeeLimitedData() ? 'user_offer_available' : this.summaryStatus,
        timeRange: this.timeRange,
        startDate: startDay,
        endDate: endDay,
        zeroLength: this.zeroLength,
        hasGenerativeAtoms: this.hasGenerativeAtoms,
        hasGenerativeComposer: this.hasGenerativeComposer,
        incorrectIntentInference: this.incorrectIntentInference,
        incorrectEntityInference: this.incorrectEntityInference,
        incorrectGptOutput: this.incorrectGptOutput,
        objective: this.objective,
        cohort: this.cohort,
        experiment: this.experiment,
        rule: this.rule
      }

      // console.log('FILTERS', filters)

      this.$emit('change', filters)
    }
  }
}
</script>

<style lang="scss">
.datepicker-field .field {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.filters select {
  max-width: 300px;
}
</style>
