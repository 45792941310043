import axios from 'axios'
import NProgress from 'nprogress'
import QueryString from 'qs'
import moment from 'moment-timezone'
import maintenace from './maintenance'

const BASE_URL = process.env.VUE_APP_API_PATH || '/api'

const agent = axios.create({
  baseURL: BASE_URL
})

agent.interceptors.request.use((request) => {
  NProgress.start()
  request.meta = request.meta || {}
  request.meta.requestStartedAt = new Date().getTime()
  return request
})

agent.interceptors.response.use((response) => {
  NProgress.done()
  response.responseTime = new Date().getTime() - response.config.meta.requestStartedAt
  return response
}, error => {
  NProgress.done()
  return Promise.reject(error)
})

export default {
  get: (path, parameters, headers) => {
    if (parameters == null) {
      parameters = {}
    }
    parameters._ = new Date().getTime()
    console.log('get', path, parameters)
    return agent.get(path, { params: parameters, headers: headers })
  },
  delete: (path, params, headers) => {
    return agent.delete(path, { data: params, headers: headers })
  },
  put: (path, params, headers) => {
    return agent.put(path, params, { headers: headers })
  },
  post: (path, params, headers, options) => {
    if (options == null) {
      options = {}
    }
    options.headers = headers
    console.log(path, params, options)
    return agent.post(path, params, options)
  },
  postRaw: (path, params, headers) => {
    if (headers == null) {
      headers = {}
    }
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
    return agent.post(path, QueryString.stringify(params), { headers: headers })
  },
  handleNetworkError: (error, store) => {
    console.log(error)
    if (error.response == null) {
      // Network error
      maintenace.checkMaintenanceStatus(store)
    }
  },
  timezoneManager: {
    get: () => {
      const timezone = localStorage.getItem('timezone')
      return timezone != null ? timezone : 'UTC'
    },
    set: (value) => {
      if (value === null) {
        localStorage.removeItem('timezone')
        return
      }
      console.log('SET TIMEZONE', value)
      localStorage.setItem('timezone', value)
    },
    unset: () => {
      console.log('UNSET TIMEZONE')
      localStorage.removeItem('timezone')
    },
    convertDate: (date = null) => {
      if (date == null) {
        return null
      }
      const utc = moment.tz(date, 'UTC')
      return utc.clone().tz(localStorage.getItem('timezone') != null ? localStorage.getItem('timezone') : 'UTC').toDate()
    },
    formatDate: (date = null, format = 'DD/MM/YYYY hh:mm A') => {
      if (date == null) {
        return null
      }
      let utc
      if (date instanceof Date) {
        // I suppose it's a date in local time!!! Damn JS
        utc = moment(date).tz('UTC')
      } else {
        // I suppose it's a string in UTC timezone
        utc = moment.tz(date, 'UTC')
      }
      return utc.clone().tz(localStorage.getItem('timezone') != null ? localStorage.getItem('timezone') : 'UTC').format(format)
    },
    formatDateToUTC: (dateString = null, format = 'DD/MM/YYYY hh:mm A') => {
      if (dateString == null) {
        return null
      }
      const tz = moment.tz(dateString, localStorage.getItem('timezone') != null ? localStorage.getItem('timezone') : 'UTC')
      return tz.clone().tz('UTC').format(format)
    },
    replaceTimezone: (s = null, t = null) => {
      if (s == null) {
        return null
      }
      if (t == null) {
        t = 'UTC'
      }
      if (typeof s !== 'string') {
        // Something wrong, lets return NULL just in case
        return null
      }
      const to = moment(s).tz(t).format('Z')
      return s.replace(moment(s).format('Z'), to)
    },
    fromUTCtoLocal: (s = null, format = 'YYYY-MM-DDTHH:mm:ssZ') => {
      if (s == null) {
        return null
      }
      // Converting to retailer's timezone
      // console.log(new Date(s), moment(new Date(s), currentTimezone).tz('UTC'))
      const retailerTimezone = localStorage.getItem('timezone') != null ? localStorage.getItem('timezone') : 'UTC'
      const utcDate = moment.utc(s)
      const retailerDate = utcDate.clone().tz(retailerTimezone)
      const retailerOffset = moment(s).tz(retailerTimezone).format('Z')
      const localOffset = moment(s).format('Z')
      // I'm using T 12 to not have weird behaviours with local offsets
      // I'm building the date in UTC timezone
      /*
      console.log('DEBUG')
      console.log([s, new Date(s)])
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      console.log({
        f: format,
        __0s: s,
        _0currentTimezone: currentTimezone,
        _1retailerTimezone: retailerTimezone,
        _2utcDate: utcDate.format('YYYY-MM-DDTHH:mm:ssZ'),
        _3retailerDate: retailerDate.format('YYYY-MM-DDTHH:mm:ssZ'),
        _3retailerDate1: retailerDate,
        _4retailerOffset: retailerOffset,
        _5localOffset: localOffset,
        _6prereverted: retailerDate.clone().format('YYYY-MM-DDTHH:mm:ssZ'),
        _7reverted: retailerDate.clone().format(format).replace(retailerOffset, localOffset),
        _8local: moment().format('YYYY-MM-DDTHH:mm:SS.000000Z')
      })
      */

      return retailerDate.clone().format(format).replace(retailerOffset, localOffset)
    }
  }
}
