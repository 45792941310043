<template>
  <img :src="src" class="nibble-icon" :class="iconClass">
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      required: true,
      type: String
    },
    size: {
      required: false,
      type: String,
      default: 'medium'
    },
    statusClass: {
      required: false,
      type: String,
      default: null
    }
  },
  computed: {
    iconClass () {
      if (this.statusClass !== null) {
        return `icon-${this.size} icon-status-${this.statusClass}`
      } else {
        return `icon-${this.size}`
      }
    },
    src () {
      return `/images/${this.icon}.svg`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
