<template>
  <page-layout active-menu-item="admin:search">
    <main-column :breadcrumbs="breadcrumbs" page-title="Message Search" content-class="main-column-sub-columns columns">
      <template>
        <div :class="columnClass">
          <div class="filters-container">
            <div class="filters">
              <nav class="level">
                <div class="level-left">
                  <filter-button @toggle="toggleVisibility"></filter-button>
                  <h3 class="level-item title is-3">Filters</h3>
                </div>
                <div class="level-right">
                  <b-button type="is-text is-rounded" class="level-item" @click="resetFilters">Reset</b-button>
                </div>
              </nav>

              <form @submit="debouncedSearch">
                <fieldset>
                  <legend>Sort Order</legend>
                  <b-field>
                    <div class="control expanded">
                      <span class="select is-fullwidth">
                        <select v-model="filters.sortOrder" @change="debouncedSearch" expanded>
                          <option value="recent">Most Recent</option>
                          <option value="match">Best Match</option>
                        </select>
                      </span>
                    </div>
                  </b-field>
                </fieldset>
                <fieldset>
                  <legend>Message Filters</legend>
                  <b-field label="Retailer" v-if="retailers != null && retailers.length > 0">
                    <div class="control expanded">
                      <span class="select is-fullwidth">
                        <select v-model="filters.retailerId" @change="debouncedSearch" expanded>
                          <option :value="null">All Retailers</option>
                          <option v-for="(retailer, retailerIndex) in this.retailers" :key="retailerIndex" :value="retailer.retailer_id">{{ retailer.name }}</option>
                        </select>
                      </span>
                    </div>
                  </b-field>
                  <div class="field">
                    <label class="label">User Message</label>
                    <div class="control">
                      <input class="input" type="text" v-model="filters.searchUserText" placeholder="Search..." @input="debouncedSearch">
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Bot Message</label>
                    <div class="control">
                      <input class="input" type="text" v-model="filters.searchBotText" placeholder="Keyword" @input="debouncedSearch">
                    </div>
                  </div>
                  <div class="field">
                    <label class="b-checkbox checkbox">
                      <input type="checkbox" name="isFeedback" v-model="filters.isFeedback" @change="debouncedSearch">
                      <span class="check"></span>
                      <span class="control-label">Limit to Feedback Messages</span>
                    </label>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </template>

      <div class="column">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Messages"
            :showButton="false">
            <template v-slot:text>
              No messages matching those filters were found.
            </template>
          </empty-list-indicator>
          <nav class="level" v-if="items != null && items.length > 0">
            <p class="level-left list-summary">
              {{offset + 1}}-{{Math.min(offset + 21, totalItemCount)}} of {{totalItemCountString}} records
            </p>
          </nav>
          <b-table v-if="items != null && items.length > 0" :data="items" hoverable @click="clickRow">
            <b-table-column field="nibble_id" label="Nibble ID" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ props.row.nibble_id }}
                </strong>
              </router-link>
            </b-table-column>

            <b-table-column field="user_message" label="User Message" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ truncate(props.row.user_message) }}
                </strong>
              </router-link>
            </b-table-column>

            <b-table-column field="user_message" label="Bot Message" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ truncate(props.row.bot_message) }}
                </strong>
              </router-link>
            </b-table-column>

          </b-table>
          <list-paginator :offset="offset" :page-size="20" :total-item-count="totalItemCount" @navigate="setOffset"/>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import debounce from 'lodash/debounce'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import ListPaginator from '@/components/ListPaginator'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ItemList from '@/utils/mixins/ItemList'
import FilterButton from '@/components/FilterButton'

export default {
  name: 'Search',
  mixins: [ItemList],
  components: { PageLayout, MainColumn, ListPaginator, EmptyListIndicator, FilterButton },
  data () {
    console.log(`offset ${this.$route.query.offset}`)
    return {
      visible: true,
      items: [],
      selectedItems: [],
      totalItemCount: 0,
      offset: this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0,
      filters: {
        sortOrder: 'recent',
        retailerId: null,
        searchUserText: null,
        searchBotText: null,
        isFeedback: this.$route.query.isFeedback
      },
      retailers: []
    }
  },
  created () {
    this.debouncedSearch = debounce(this.search, 500)
    this.fetchRetailers()
  },
  watch: {
    $route: 'routeUpdated'
  },
  computed: {
    breadcrumbs () {
      return [
        {
          link: '/admin/search',
          title: 'Message Search'
        }
      ]
    },
    columnClass () {
      if (this.visible) {
        return 'column filters-column is-narrow filters-visible'
      } else {
        return 'column filters-column is-narrow filters-hidden'
      }
    },
    emptyListText () {
      return 'There are no Nibbles matching the selected filters'
    },
    listApiParams () {
      var params = this.filterQuery(this.filters)
      params.offset = this.offset
      return params
    },
    itemsKey () {
      return 'messages'
    },
    listPath () {
      return '/admin/search'
    },
    listApiPath () {
      return '/admin/search'
    },
    showEmptyList () {
      return this.items != null && this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    },
    totalItemCountString () {
      if (this.totalItemCount === 10000) {
        return '10000+'
      } else {
        return this.totalItemCount
      }
    }
  },
  methods: {
    itemPath (item) {
      return '/sessions/' + item.nibble_id
    },
    fetchRetailers () {
      return nibble.get('/retailers', { context: 'search', sortOrder: 'name' }, this.$store.getters.authHeaders)
        .then((response) => {
          this.retailers = response.data.retailers
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    routeUpdated () {
      this.updateFiltersFromRoute()
    },
    updateFiltersFromRoute () {
      this.offset = this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0
      this.filters.sortOrder = this.$route.query.sortOrder != null ? this.$route.query.sortOrder : 'recent'

      this.filters.retailerId = this.$route.query.retailerId != null ? parseInt(this.$route.query.retailerId) : null
      this.filters.searchUserText = this.$route.query.searchUserText != null ? this.$route.query.searchUserText : null
      this.filters.searchBotText = this.$route.query.searchBotText != null ? this.$route.query.searchBotText : null
      this.filters.isFeedback = this.$route.query.isFeedback === 'true'

      this.filters.offset = this.offset

      this.fetchData()
    },
    search () {
      console.log('update route from filters')
      this.filters.offset = 0
      this.$router.replace({ path: this.listPath, query: this.filterQuery(this.filters) })
    },
    filterQuery (filters) {
      var query = {}
      if (filters.sortOrder != null) {
        query.sortOrder = filters.sortOrder
      }
      if (filters.retailerId != null) {
        query.retailerId = filters.retailerId
      }
      if (filters.searchUserText != null && filters.searchUserText !== '') {
        query.searchUserText = filters.searchUserText
      }
      if (filters.searchBotText != null && filters.searchBotText !== '') {
        query.searchBotText = filters.searchBotText
      }
      if (filters.isFeedback) {
        query.isFeedback = 'true'
      }
      return query
    },
    truncate (text) {
      if (text.length > 50) {
        return text.substring(0, 50) + '...'
      }
      return text
    },
    setOffset (offset) {
      var query = this.filterQuery(this.filters)
      query.offset = offset
      this.$router.push({ path: this.listPath, query: query })
    },
    clickRow (item) {
      return this.$router.push('/sessions/' + item.nibble_id)
    },
    toggleVisibility () {
      this.visible = !this.visible
    },
    resetFilters () {
      this.offset = 0
      this.filters.sortOrder = 'recent'
      this.filters.retailerId = null
      this.filters.searchUserText = null
      this.filters.searchBotText = null
      this.filters.offset = this.offset
      this.filters.isFeedback = null

      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
