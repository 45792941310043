var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{attrs:{"retailer-id":_vm.retailerId,"active-menu-item":"campaigns"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('main-column',{attrs:{"breadcrumbs":_vm.breadcrumbs,"page-title":_vm.pageTitle},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [_c('p',{staticClass:"level-item buttons"},[_c('b-button',{attrs:{"type":"is-text is-rounded","tag":"router-link","to":_vm.listPath}},[(_vm.isNewItem)?_c('span',[_vm._v("Cancel")]):_c('span',[_vm._v("Back")])]),(_vm.isNewItem)?_c('b-button',{attrs:{"type":"is-primary is-rounded"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v("Save & Generate URL")]):_vm._e()],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('h2',{staticClass:"title is-2"},[_vm._v("General Magic Link Settings")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Magic Link Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Magic Link Name","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{attrs:{"disabled":!_vm.isNewItem},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Magic Link Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Magic Link Type","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-select',{attrs:{"placeholder":"Select a Magic Link Type","expanded":"","disabled":!_vm.isNewItem},model:{value:(_vm.item.campaign_type),callback:function ($$v) {_vm.$set(_vm.item, "campaign_type", $$v)},expression:"item.campaign_type"}},[_c('option',{attrs:{"value":"link_to_rule"}},[_vm._v("Link to Rule")])])],1)]}}],null,true)})],1)]),(_vm.errorMessage != null)?_c('notification-bar',{attrs:{"type":"is-danger"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)]),_c('br'),(_vm.item.campaign_type)?_c('div',{staticClass:"card"},[(_vm.item.campaign_type === 'negotiation_on_cart')?_c('div',{staticClass:"card-content"},[_c('h2',{staticClass:"title is-2"},[_vm._v("Settings")]),_c('notification-bar',[_vm._v(" Paste in the URL fo your cart here, and Nibble will generate a new link that you can use in your marketing emails etc. ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Cart URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Cart URL","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{attrs:{"placeholder":"Paste your Cart URL here...","disabled":!_vm.isNewItem},model:{value:(_vm.item.product_url),callback:function ($$v) {_vm.$set(_vm.item, "product_url", $$v)},expression:"item.product_url"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.item.campaign_type === 'single_product')?_c('div',{staticClass:"card-content"},[_c('h2',{staticClass:"title is-2"},[_vm._v("Settings")]),_c('notification-bar',[_vm._v(" Paste in the URL of your product page here, and Nibble will generate a new link that you can use in your marketing emails etc. The Nibble Magic Link will enable Nibble on the product page, with the settings you choose below. ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Product URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Product URL","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{attrs:{"placeholder":"Paste your Product Detail Page URL here...","disabled":!_vm.isNewItem},model:{value:(_vm.item.product_url),callback:function ($$v) {_vm.$set(_vm.item, "product_url", $$v)},expression:"item.product_url"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.item.campaign_type === 'link_to_rule')?_c('div',{staticClass:"card-content"},[_c('h2',{staticClass:"title is-2"},[_vm._v("Settings")]),_c('notification-bar',[_vm._v(" Select a rule that will be automatically activated when the user follows the link. The rule will remain active until the user closes their browser, or after 3 hours (whichever is sooner). It is recommended that you choose a rule that is not currently active. ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":{required: _vm.ruleRequired},"name":"Rule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Rule","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-select',{attrs:{"placeholder":"Select a Rule","expanded":""},model:{value:(_vm.item.rule_id),callback:function ($$v) {_vm.$set(_vm.item, "rule_id", $$v)},expression:"item.rule_id"}},_vm._l((_vm.rules),function(rule,ruleIndex){return _c('option',{key:ruleIndex,domProps:{"value":rule.rule_id}},[_vm._v(" "+_vm._s(rule.name)+" ")])}),0)],1)]}}],null,true)})],1)]),_c('notification-bar',[_vm._v(" Paste in the URL of any product page here, and Nibble will generate a new link that you can use in your marketing emails etc. The Nibble Magic Link will enable the above rule. ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Product URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Product URL","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-input',{attrs:{"placeholder":"Paste your Product Detail Page URL here...","disabled":!_vm.isNewItem},model:{value:(_vm.item.product_url),callback:function ($$v) {_vm.$set(_vm.item, "product_url", $$v)},expression:"item.product_url"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.showLegacyEditor && !_vm.percentagesDisabled)?_c('objectives-editor-legacy',{attrs:{"disabled":!_vm.isNewItem},model:{value:(_vm.item.objectives),callback:function ($$v) {_vm.$set(_vm.item, "objectives", $$v)},expression:"item.objectives"}}):_vm._e(),(!_vm.showLegacyEditor && !_vm.percentagesDisabled)?_c('objectives-editor',{attrs:{"disabled":!_vm.isNewItem},model:{value:(_vm.item.objectives),callback:function ($$v) {_vm.$set(_vm.item, "objectives", $$v)},expression:"item.objectives"}}):_vm._e(),(_vm.percentagesDisabled)?_c('div',{staticClass:"card-content pt-0"},[_c('div',{staticClass:"column is-full"},[_c('h3',{staticClass:"title is-3"},[_vm._v("Magic Link Duration")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Magic Link Duration Select"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"id":"campaign-duration","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-radio',{attrs:{"native-value":false,"type":"is-info"},model:{value:(_vm.item.objectives.enableTimeLimit),callback:function ($$v) {_vm.$set(_vm.item.objectives, "enableTimeLimit", $$v)},expression:"item.objectives.enableTimeLimit"}},[_vm._v(" No time limit ")])],1),_c('b-field',{attrs:{"id":"campaign-duration","message":errors,"type":{ 'is-danger': errors[0] }}},[_c('b-radio',{attrs:{"native-value":true,"type":"is-info"},model:{value:(_vm.item.objectives.enableTimeLimit),callback:function ($$v) {_vm.$set(_vm.item.objectives, "enableTimeLimit", $$v)},expression:"item.objectives.enableTimeLimit"}},[_vm._v(" For a limited time only ")])],1)]}}],null,true)}),(_vm.item.objectives.enableTimeLimit)?_c('time-limit-editor',{attrs:{"value":_vm.timeLimitObjectives},on:{"input":_vm.timeLimitInput}}):_vm._e(),(_vm.item.objectives.enableTimeLimit)?_c('div',[_vm._v("Timezone: "+_vm._s(_vm.currentTimezone))]):_vm._e()],1)]):_vm._e(),(!_vm.percentagesDisabled)?_c('p',{staticStyle:{"padding":"10px"}},[_vm._v("Timezone: "+_vm._s(_vm.currentTimezone))]):_vm._e(),(_vm.hasCampaignUrl && !_vm.isNewItem)?_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Magic Link"}},[_c('b-input',{attrs:{"disabled":"","expanded":""},model:{value:(_vm.item.campaign_url),callback:function ($$v) {_vm.$set(_vm.item, "campaign_url", $$v)},expression:"item.campaign_url"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-outline","label":"Copy URL"},on:{"click":_vm.copyItemUrl}})],1)],1)],1)])]):_vm._e()],1):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }