<template>
  <section class="section">
    <div class="columns">
      <div class="column is-3">
        <account-menu activePage="security"></account-menu>
      </div>
      <div class="column is-6-tablet is-5-desktop is-5-widescreen">
        <form v-on:submit.prevent.stop="submit">
          <h3 class="title is-3">Change Password</h3>
          <div class="field">
            <label class="label">Old Password</label>
            <div class="control">
              <input type="password" class="input" v-model.trim="user.oldPassword" placeholder="">
            </div>
          </div>
          <div class="field">
            <label class="label">New Password</label>
            <div class="control">
              <input type="password" class="input" v-model.trim="user.newPassword" placeholder="">
            </div>
          </div>
          <div class="field">
            <label class="label">Confirm New Password</label>
            <div class="control">
              <input type="password" class="input" v-model.trim="user.confirmNewPassword" placeholder="">
            </div>
          </div>

          <div class="notification is-danger is-light" v-if="error">
            {{error}}
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control buttons">
              <button type="submit" class="button is-primary">Save Changes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import nibble from '@/utils/nibble'
import AccountMenu from '@/components/account/AccountMenu'

export default {
  name: 'AccountSecurity',
  components: { AccountMenu },
  data () {
    return {
      user: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      error: null
    }
  },
  computed: {
  },
  methods: {
    validate () {
      this.error = null
      if (this.user.oldPassword === '') {
        this.error = 'Please enter your password'
      }
      if (this.user.newPassword === '') {
        this.error = 'Please enter your new password'
      }
      if (this.user.confirmNewPassword === '') {
        this.error = 'Please confirm your new password'
      }
      if (this.user.confirmNewPassword !== this.user.newPassword) {
        this.error = 'Please confirm your new password'
      }
    },
    submit (e) {
      this.validate()
      if (this.error != null) {
        return
      }
      this.submitPassword()
    },
    submitPassword () {
      this.loading = true
      var params = {
        oldPassword: this.user.oldPassword,
        newPassword: this.user.newPassword,
        confirmNewPassword: this.user.confirmNewPassword
      }
      nibble.put('/account/password', params, this.$store.getters.authHeaders)
        .then(response => this.updated())
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            if (error.response.data != null && error.response.data.error_message != null) {
              this.error = error.response.data.error_message
            } else {
              this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            }
          } else {
            this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            nibble.handleNetworkError(error, this.$store)
          }
          console.log(error)
        })
    },
    updated () {
      this.loading = false
      this.user = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }
      this.$buefy.snackbar.open({
        message: 'Your password has been updated',
        type: 'is-success',
        position: 'is-top',
        actionText: null
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
