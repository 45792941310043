<template>
  <b-message :type="bannerType" :class="bannerClass">
    <div class="is-hidden-mobile is-flex is-flex-direction-row is-justify-content-space-between">
      <div class="is-flex is-align-items-center is-flex-grow-1 mr-4">
        <slot name="icon"/>
      </div>
      <div class="is-flex is-flex-direction-column" style="flex-grow:100;">
        <h2 class="banner-title mb-1">
          <slot name="title">{{ title }}</slot>
        </h2>
        <p>
          <slot name="text"/>
        </p>
      </div>
      <div class="is-flex is-align-items-center is-flex-grow-1 ml-4">
        <slot name="button"/>
      </div>
    </div>
    <div class="is-hidden-tablet is-flex is-flex-direction-column is-justify-content-center">
      <div class="is-flex is-justify-content-center mb-4">
        <slot name="icon"/>
      </div>
      <div class="is-flex is-flex-direction-column has-text-centered">
        <h2 class="banner-title mb-1">
          <slot name="title">{{ title }}</slot>
        </h2>
        <p>
          <slot name="text"/>
        </p>
      </div>
      <div class="is-flex is-justify-content-center mt-4">
        <slot name="button"/>
      </div>
    </div>
  </b-message>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    title: {
      required: false,
      type: String,
      default: ''
    },
    bannerType: {
      required: false,
      default: null
    },
    bannerClass: {
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>
.banner-title {
  font-size: 1.2em !important;
  font-weight: bold;
}
</style>
