<template>
  <page-layout active-menu-item="bot-test">
    <main-column :breadcrumbs="breadcrumbs" :page-title="breadcrumbs[0].title"
      content-class="main-column-sub-columns columns">
      <div class="column">
        <div class="main-column-content">
          <div class="columns">
            <div class="column">
              <div class="card">
                <div class="card-content">
                  <div class="columns">
                    <div class="column">
                      <h2 class="title is-4">API Version</h2>
                      <b-field>
                        <b-radio v-model="apiVersion" native-value="v1" type="is-info">v1 - eCommerce</b-radio>
                      </b-field>
                      <b-field>
                        <b-radio v-model="apiVersion" native-value="v2" type="is-info">v2 - Negotiation as a
                          Service</b-radio>
                      </b-field>
                      <p ref="nibble-container" class="nibble" v-if="showNibble">
                        <span :class="nibbleContainerClass('v1')">
                          <nibble-button id="nibble-v1" show="true" :window-script-url=nibbleWindowUrl :api-key="apiKey"
                            :api-version="apiVersion" header-height="100" :product-id="productId"
                            :feature-names="featureNames" :language="language"></nibble-button>
                        </span>
                        <span :class="nibbleContainerClass('v2')">
                          <nibble-launch-button id="nibble-v2" show="true" :api-key="apiKey" :api-version="apiVersion"
                            header-height="100" :product-id="productId" :feature-names="featureNames"
                            :language="language" :wide="wide" :contract="isContract()"></nibble-launch-button>
                        </span>
                      </p>
                      <div class="basket" v-if="showBasket">
                        <notification-bar icon="check">Added to Basket</notification-bar>
                        <p class="has-text-centered">
                          <b-button type="is-primary is-rounded" @click="recordPurchased">Mark as Purchased</b-button>
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <table class="table is-narrow" v-if="apiKey">
                        <tbody>
                          <tr>
                            <th class="has-text-right">API Version</th>
                            <td>{{ apiVersion }}</td>
                          </tr>
                          <tr>
                            <th class="has-text-right">Retailer Session ID</th>
                            <td>
                              {{ retailerSessionId }}<br>
                              Refresh page to reset
                            </td>
                          </tr>
                          <tr>
                            <th class="has-text-right">Nibble ID</th>
                            <td v-if="nibbleId">{{ nibbleId }}</td>
                            <td v-else>
                              None<br>
                              Click Nibble button to start
                            </td>
                          </tr>
                          <tr>
                            <th class="has-text-right">Order Number</th>
                            <td v-if="showPurchased">{{ orderNumber }}</td>
                            <td v-else>
                              None<br>
                              Agree Deal and Purchase to generate
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="card mb-4">
                <div class="card-content">
                  <h2 class="title is-4">Negotiation Type</h2>
                  <b-field>
                    <b-radio v-model="negotiationType" native-value="product" type="is-info">Product</b-radio>
                  </b-field>
                  <b-field>
                    <b-radio v-model="negotiationType" native-value="contract" type="is-info">Contract</b-radio>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="card" v-if="negotiationType == 'product'">
                <div class="card-content">
                  <h2 class="title is-4">Product Negotiation Settings</h2>
                  <table class="table is-narrow is-fullwidth">
                    <tbody v-if="apiVersion">
                      <tr>
                        <th class="has-text-right">Negotiation Direction</th>
                        <td>
                          <ValidationProvider :rules="{ required: true }" name="Negotiation Direction"
                            v-slot="{ errors }">
                            <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-select v-model="productSettings.negotiationDirection" placeholder="Select a direction"
                                expanded>
                                <option value="down">Downwards (Selling)</option>
                                <option value="up">Upwards (Buyback)</option>
                              </b-select>
                            </b-field>
                          </ValidationProvider>
                        </td>
                      </tr>
                      <tr>
                        <th class="has-text-right">Product Price £</th>
                        <td>
                          <ValidationProvider rules="required" name="product price" v-slot="{ errors }">
                            <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-input v-model="productSettings.productPrice"></b-input>
                            </b-field>
                          </ValidationProvider>
                        </td>
                      </tr>
                      <tr>
                        <th class="has-text-right">Objective</th>
                        <td>
                          <ValidationProvider :rules="{ required: true }" name="Objective" v-slot="{ errors }">
                            <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-select v-model="productSettings.objective" placeholder="Select an Objective" expanded>
                                <option value="increase_conversion">Increase Conversion</option>
                                <option value="balance_performance">Balance Performance</option>
                                <option value="preserve_margin">Preserve Margin</option>
                              </b-select>
                            </b-field>
                          </ValidationProvider>
                        </td>
                      </tr>
                      <tr>
                        <th class="has-text-right">Walkaway Price £</th>
                        <td>
                          <ValidationProvider rules="required" name="walkaway price" v-slot="{ errors }">
                            <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-input v-model="productSettings.walkawayPrice"></b-input>
                            </b-field>
                          </ValidationProvider>
                        </td>
                      </tr>
                      <tr>
                        <th class="has-text-right">Language</th>
                        <td>
                          <ValidationProvider :rules="{ required: true }" name="Language" v-slot="{ errors }">
                            <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-select v-model="language" placeholder="Select a Language" expanded
                                :disabled="!isMultiLanguageEnabled">
                                <option v-for="lang in availableLanguages" :key="lang.code" :value="lang.code">
                                  {{ lang.name }}</option>
                              </b-select>
                            </b-field>
                          </ValidationProvider>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card" v-if="negotiationType == 'contract'">
                <div class="card-content">
                  <h2 class="title is-4">Contract Negotiation Settings</h2>
                  <table class="table is-narrow is-fullwidth">
                    <tbody v-if="apiVersion">
                      <tr>
                        <th class="has-text-right">Contract Profile</th>
                        <td>
                          <ValidationProvider :rules="{ required: true }" name="Contract Profile" v-slot="{ errors }">
                            <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-select v-model="contractSettings.selectedProfile" placeholder="Select a prifle"
                                expanded>
                                <option v-for="(profile, profileIndex) in availableContractProfiles" :key="profileIndex"
                                  :value="profile">{{ profile.offering.contract_name }}</option>
                              </b-select>
                            </b-field>
                          </ValidationProvider>
                        </td>
                      </tr>
                      <tr>
                        <th class="has-text-right">Wide view</th>
                        <td>
                          <b-field>
                            <b-switch v-model="wide" type="is-info"></b-switch>
                          </b-field>
                        </td>
                      </tr>
                      <tr>
                        <th class="has-text-right">Language</th>
                        <td>
                          <ValidationProvider :rules="{ required: true }" name="Language" v-slot="{ errors }">
                            <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-select v-model="language" placeholder="Select a Language" expanded
                                :disabled="!isMultiLanguageEnabled">
                                <option v-for="lang in availableLanguages" :key="lang.code" :value="lang.code">
                                  {{ lang.name }}</option>
                              </b-select>
                            </b-field>
                          </ValidationProvider>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="card">
                <div class="card-content">
                  <h2 class="title is-4">Enabled Features</h2>
                  <feature-list v-model="featureSettings"></feature-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import NotificationBar from '@/components/NotificationBar'
import FeatureList from '@/components/admin/FeatureList'

export default {
  name: 'Demo',
  components: { PageLayout, MainColumn, NotificationBar, FeatureList },
  data () {
    return {
      activeTab: 'product',
      apiVersion: 'v1',
      apiKey: null,
      nibbleId: null,
      negotiationType: 'product',
      productSettings: {
        negotiationDirection: 'down',
        productId: null,
        productPrice: null,
        walkawayPrice: null,
        objective: 'increase_conversion'
      },
      contractSettings: {
        selectedProfile: null
      },
      nibbleButtonUrls: [],
      nibbleWindowUrl: null,
      wide: false,
      retailerSessionId: Math.random().toString(36).substring(2, 15),
      addedToBasket: false,
      purchased: false,
      orderNumber: null,
      language: 'en-GB',
      availableContractProfiles: [],
      availableLanguages: [],
      availableFeatures: [],
      featureSettings: [],
      editorOptions: {
        tabSize: 2
      }
    }
  },
  created () {
    this.fetchData()
  },
  beforeDestroy () {
    if (this.nibbleButtonUrls.length > 0) {
      for (const url of this.nibbleButtonUrls) {
        this.$unloadScript(url)
      }
    }
  },
  watch: {
    showNibble () {
      this.$nextTick(() => {
        if (this.showNibble) {
          document.getElementById('nibble-v1').addEventListener('nibble-session-start', this.startSession)
          document.getElementById('nibble-v1').addEventListener('nibble-session-end', this.endSession)
          document.getElementById('nibble-v2').addEventListener('nibble-session-start', this.startSession)
          document.getElementById('nibble-v2').addEventListener('nibble-session-end', this.endSession)
        }
      })
    },
    selectedFeatures () {
      if (this.isMultiLanguageEnabled) {
        this.language = 'en-GB'
      }
    },
    apiVersion () {
      this.updateFeaturesFromContractSettings()
    },
    negotiationType () {
      this.updateFeaturesFromContractSettings()
    },
    'contractSettings.selectedProfile' () {
      this.updateFeaturesFromContractSettings()
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {
          link: '/admin/bot-test',
          title: 'Bot Tester'
        }
      ]
    },
    showNibble () {
      return this.apiKey != null && !this.addedToBasket && !this.purchased
    },
    showBasket () {
      return this.addedToBasket && !this.purchased
    },
    showPurchased () {
      return this.purchased
    },
    featureNames () {
      return this.featureSettings.filter(feature => feature.enabled).map(feature => feature.code)
    },
    productId () {
      if (this.productSettings.productId != null) {
        return this.productSettings.productId
      } else {
        return null
      }
    },
    isMultiLanguageEnabled () {
      const feature = this.featureSettings.find(feature => feature.code === 'multi_language')
      return feature != null && feature.enabled
    }
  },
  methods: {
    nibbleContainerClass (apiVersion) {
      var classes = ['nibble-container']
      if (apiVersion !== this.apiVersion) {
        classes.push('hidden')
      }
      return classes
    },
    isContract () {
      return this.negotiationType === 'contract'
    },
    updateFeaturesFromContractSettings () {
      if (this.apiVersion !== 'v2' || this.negotiationType !== 'contract') {
        return
      }
      if ('feature_settings' in this.contractSettings.selectedProfile) {
        // Set feature settings from contract profile
        for (const featureSetting of this.featureSettings) {
          var matchingFeature = this.contractSettings.selectedProfile.feature_settings.find((setting) => {
            return setting.feature === featureSetting.code
          })
          if (matchingFeature != null) {
            featureSetting.enabled = true
            featureSetting.hyperParameters = JSON.stringify(matchingFeature.hyper_parameters)
          } else {
            featureSetting.enabled = false
          }
        }
      } else {
        // Reset feature settings
        this.featureSettings = this.availableFeatures.map((feature) => {
          return {
            code: feature.code,
            enabled: false,
            hyperParameters: JSON.stringify(feature.default_hyper_parameters)
          }
        })
      }
    },
    fetchData () {
      nibble.get('/bot_test', {}, this.$store.getters.authHeaders)
        .then((response) => {
          console.log(response)
          this.wigetUrls = response.data.widget_urls
          for (const url of this.wigetUrls) {
            this.$loadScript(url)
          }

          this.apiKey = response.data.api_key
          this.negotiationType = response.data.negotiation_type
          this.productSettings = {
            negotiationDirection: 'down',
            productId: response.data.product_id,
            productPrice: response.data.product_price,
            objective: response.data.objective,
            walkawayPrice: response.data.walkaway_price
          }
          this.availableContractProfiles = response.data.available_contract_profiles
          this.contractSettings.selectedProfile = this.availableContractProfiles[0]
          this.availableFeatures = response.data.available_features
          this.availableLanguages = response.data.available_languages
          this.featureSettings = this.availableFeatures.map((feature) => {
            return {
              code: feature.code,
              enabled: false,
              hyperParameters: JSON.stringify(feature.default_hyper_parameters)
            }
          })
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    startSession (event) {
      const successCallback = event.detail[0]
      const errorCallback = event.detail[1]

      const params = {
        apiVersion: this.apiVersion,
        retailerSessionId: this.retailerSessionId,
        negotiationType: this.negotiationType,
        featureSettings: this.featureSettings.map((feature) => {
          return {
            name: feature.code,
            enabled: feature.enabled,
            hyperParameters: JSON.parse(feature.hyperParameters || '{}')
          }
        })
      }
      if (this.negotiationType === 'product') {
        params.negotiationDirection = this.productSettings.negotiationDirection
        params.productPrice = this.productSettings.productPrice
        params.objective = this.productSettings.objective
        params.walkawayPrice = this.productSettings.walkawayPrice
      } else if (this.negotiationType === 'contract') {
        params.offering = this.contractSettings.selectedProfile.offering
        params.initialOffer = this.contractSettings.selectedProfile.initial_bot_offer
      }

      nibble.post('/bot_test', params, this.$store.getters.authHeaders)
        .then(response => {
          this.nibbleId = response.data.nibbleId
          successCallback(response.data)
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
          errorCallback(error)
        })
    },
    endSession (event) {
      const sessionStatus = event.detail[1]
      const endCallback = event.detail[2]
      if (sessionStatus === 'successful') {
        this.addedToBasket = true
      }
      endCallback({ close: true })
    },
    recordPurchased (event) {
      this.orderNumber = Math.random().toString(10).substring(2, 7)
      const params = {
        apiVersion: this.apiVersion,
        productId: this.productId,
        nibbleId: this.nibbleId,
        orderNumber: this.orderNumber
      }

      nibble.post('/bot_test/purchase', params, this.$store.getters.authHeaders)
        .then(response => {
          this.purchased = true
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.nibble-container {
  display: block;
  max-width: 400px;
  margin: 0 auto 0;
  padding-bottom: 2em;
}

.nibble-container.hidden {
  display: none;
}
</style>
