<template>
    <div class="card-content contrast">
        <h2 class="title is-2">Objectives</h2>
        <div class="objectives">
            <div class="objectives-left">
                <div class="columns is-multiline">
                    <div class="column is-half is-narrow percentage">
                        <ValidationProvider rules="required" name="Target Discount" v-slot="{ errors }">
                            <b-field label="Target Discount" :message="showMessage(errors, targetDescription)" :type="messageType(errors)">
                                <p class="control">
                                    <span class="button is-static" disabled>%</span>
                                </p>
                                <b-input v-model="objectives.target_discount_percentage" @input="input"/>
                            </b-field>
                        </ValidationProvider>
                    </div>
                    <div class="column is-half is-narrow percentage">
                        <ValidationProvider rules="required" name="Walkaway Threshold" v-slot="{ errors }">
                            <b-field label="Walkaway Threshold" :message="showMessage(errors, walkawayDescription)" :type="{ 'is-danger': errors[0] }">
                                <p class="control">
                                    <span class="button is-static" disabled>%</span>
                                </p>
                                <b-input v-model="objectives.walkaway_percentage" @input="input"/>
                            </b-field>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-full">
                        Bear in mind that Nibble works better when there is at least a 5% margin between your Target Discount and your Walkaway Threshold.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ObjectiveEditorLegacy',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      objectives: this.objectivesFromValue(this.value)
    }
  },
  watch: {
    value () {
      this.objectives = this.objectivesFromValue(this.value)
    }
  },
  computed: {
    targetDescription: function () {
      return 'The average discount you want Nibble to achieve during negotiation'
    },
    walkawayDescription: function () {
      return 'The threshold below which Nibble will NEVER agree to a deal'
    }
  },
  methods: {
    showMessage (errors, message) {
      if (errors === null) {
        return message
      } else {
        return [message].concat(errors)
      }
    },
    messageType (errors) {
      if (errors === null) {
        return {}
      } else {
        if (errors.length === 0) {
          return {}
        } else {
          return { 'is-danger': errors[0] }
        }
      }
    },
    objectivesFromValue (value) {
      return {
        target_discount_percentage: value.target_discount_percentage,
        walkaway_percentage: value.walkaway_percentage
      }
    },
    valueFromObjectives (objectives) {
      return {
        target_discount_percentage: objectives.target_discount_percentage,
        walkaway_percentage: objectives.walkaway_percentage
      }
    },
    input () {
      this.$emit('input', this.valueFromObjectives(this.objectives))
    }
  }
}
</script>

<style lang="scss" scoped>
.card-content {
    border-radius: 15px;
}
.objectives {
    .column.percentage {
        .field {
            .input {
                border-left: none;
            }
            .button {
                // padding-right: 0;
                border-right: none;
            }
        }
    }
}
.objectives {
    margin: -.75rem;
    display: flex;
}
.objectives-left {
    display: block;
    padding: .75rem;
    flex: none;
}
.objective {
    padding: .75rem;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}
@media (max-width: 1440px) {
    .objectives {
        display: block;
    }
}
</style>
