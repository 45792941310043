<template>
  <page-layout :retailer-id="retailerId" active-menu-item="sessions">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle" content-class="main-column-sub-columns columns">
      <template #header-right v-if=canDownload>

        <p class="level-item buttons">
          <b-button type="is-rounded" @click="showExportModal" v-if="retailerId == null">Export Selected Data</b-button>
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left" v-else>
            <template #trigger>
              <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
            </template>
            <b-dropdown-item aria-role="listitem" @click="downloadRetailerSessions">Download Session List</b-dropdown-item>
          </b-dropdown>
        </p>
      </template>
      <session-filters :options="filterOptions"
                       :filters="filters"
                       @change="updateFilters"
                       v-if="filterOptions"
                       search-enabled="true"
                       @search="search"/>
      <div class="column">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Nibbles"
            :showButton="false">
            <template v-slot:text>
              <span v-if="canSeeLimitedData">
                Once customers start negotiating with Nibble on your store, this is where you can read their chat logs and see what kinds of deals they're making.
                Don't forget about these chat logs — they can give you great pricing insights and honest customer feedback!
              </span>
              <span v-else>
                You don't have any pending Recoverable Nibbles. If you have enabled the feature, any new Recovered Nibble
                requests will appear here.
              </span>
            </template>
          </empty-list-indicator>
          <nav class="level" v-if="items != null && items.length > 0">
            <p class="level-left list-summary">
              {{offset + 1}}-{{Math.min(offset + 21, totalItemCount)}} of {{totalItemCount}} Nibbles
            </p>
          </nav>
          <b-table v-if="items != null && items.length > 0" :data="items" hoverable>
            <b-table-column field="nibbleId" label="Nibble ID" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                {{props.row.session_id}}
                <span class="session-icons">
                  <b-tooltip v-if="props.row.negotiation_type === 'cart'" label="Negotiation on Cart">
                    <material-icon size="small" icon="shopping_cart" class="icon-status-cart"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.is_returning_user" label="Repeat Negotiation">
                    <material-icon size="small" icon="repeat"></material-icon>
                  </b-tooltip>
                </span>
              </router-link>
            </b-table-column>
            <b-table-column field="time" :label="`Time (${currentTimezone})`" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <span v-html="formatDate(props.row.created_at)"></span>
              </router-link>
            </b-table-column>
            <b-table-column field="retailer" label="Retailer" v-slot="props" v-if="retailerId == null" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                {{props.row.retailer_name}}
              </router-link>
            </b-table-column>
            <b-table-column field="name" label="Product" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <strong>
                  {{productLabel(props.row)}}
                </strong>
              </router-link>
            </b-table-column>
            <b-table-column field="name" label="Price" v-slot="props" cell-class="price link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <small>
                  <s>{{props.row.product_price}}</s>
                  <br>
                  {{props.row.current_price}}
                </small>
              </router-link>
            </b-table-column>
            <b-table-column field="icons" label="Status" v-slot="props" cell-class="has-text-centered link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <span class="rule-icons">
                  <status-icon :session-status="props.row.summary_status"
                               :negotiation-type="props.row.negotiation_type"
                               :addon-item-id="props.row.addon_item_id">
                  </status-icon>
                </span>
              </router-link>
            </b-table-column>
            <b-table-column label="" v-if="showZoneWalk" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="{ path: itemPath(props.row), query: filterQuery(filters) } ">
                <zone-grid v-if="props.row.zone_walk" :zone-walks="[props.row.zone_walk]" :width="30" :height="30" :labels="true"></zone-grid>
              </router-link>
            </b-table-column>
            <!-- <b-table-column label=""> -->
              <!-- <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                <template #trigger>
                  <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                </template>
                <b-dropdown-item aria-role="listitem" @click="viewItem(props.row)">View Details</b-dropdown-item>
              </b-dropdown> -->
            <!-- </b-table-column> -->
          </b-table>
          <list-paginator :offset="offset" :page-size="20" :total-item-count="totalItemCount" @navigate="setOffset"/>
        </div>
      </div>
      <b-modal :active.sync="isExportModalActive">
        <div class="box">
          <form @submit="submitExport">
            <h3 class="title is-3">Choose Export Format</h3>
            <b-field>
              <b-radio v-model="exportFormat" :native-value="'simple_list'">
                Simple list, one line per negotiation
              </b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="exportFormat" :native-value="'simple_message_list'">
                Simple list, one line per interaction (bot message + user message)
              </b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="exportFormat" :native-value="'finetune_data'">
                Finetune training data (only exports sessions with corrected output)
              </b-radio>
            </b-field>
            <notification-bar v-if="totalItemCount != null && totalItemCount > 3000" type="is-danger">
              Warning: a very large number of sessions have been selected for export. This export may take too long to complete.
            </notification-bar>
            <div class="field is-grouped is-grouped-right">
              <p class="buttons">
                <b-button type="is-text is-rounded" @click="isExportModalActive = false">Cancel</b-button>
                <b-button type="is-primary is-rounded" native-type="submit">Export</b-button>
              </p>
            </div>
          </form>
        </div>
      </b-modal>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import ItemList from '@/utils/mixins/ItemList'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import ListPaginator from '@/components/ListPaginator'
import SessionFilters from '@/components/SessionFilters'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ZoneGrid from '@/components/ZoneGrid'
import StatusIcon from '@/components/StatusIcon'
import MaterialIcon from '@/components/MaterialIcon'
import NotificationBar from '../components/NotificationBar.vue'

export default {
  name: 'Sessions',
  mixins: [ItemList],
  components: { PageLayout, MainColumn, ListPaginator, SessionFilters, EmptyListIndicator, ZoneGrid, StatusIcon, MaterialIcon, NotificationBar },
  data () {
    return {
      filterOptions: null,
      filters: {},
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      items: null,
      totalItemCount: null,
      offset: this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0,
      isExportModalActive: false,
      exportFormat: 'simple_list',
      downloadSettings: {
        simple_list: { filename: 'Nibble_Data.csv', mime_type: 'text/csv' },
        simple_message_list: { filename: 'Nibble_Data.csv', mime_type: 'text/csv' },
        finetune_data: { filename: 'finetune_data.jsonl', mime_type: 'application/x-ndjson' }
      }
    }
  },
  computed: {
    currentTimezone () {
      return nibble.timezoneManager.get()
    },
    listPath () {
      if (this.retailerId != null) {
        return `/retailers/${this.retailerId}/sessions`
      } else {
        return '/sessions'
      }
    },
    listApiPath () {
      return '/sessions'
    },
    listApiParams () {
      var params = this.filterQuery(this.filters)
      if (this.retailerId != null) {
        params.retailer_id = this.retailerId
      }
      params.offset = this.offset
      return params
    },
    itemsKey () {
      return 'sessions'
    },
    itemIdKey () {
      return 'session_id'
    },
    itemName () {
      if (this.canSeeLimitedData) {
        return 'Nibble'
      } else {
        return 'Recoverable Nibble'
      }
    },
    itemsName () {
      if (this.canSeeLimitedData) {
        return 'Nibbles'
      } else {
        return 'Retargeting'
      }
    },
    pageTitle () {
      if (this.canSeeLimitedData) {
        return 'Nibbles'
      } else {
        return 'Recoverable Nibbles'
      }
    },
    canSeeLimitedData () {
      return !this.$store.getters.isLimitedData
    },
    showZoneWalk () {
      return this.items != null && this.items.length > 0 && this.items[0].zone_walk != null
    },
    showEmptyList () {
      return this.items !== null && this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    },
    canDownload () {
      return this.retailerId === null || this.$store.getters.isFeatureAvailable('session_export')
    }
  },
  methods: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    fetchOptions () {
      var params = { action: 'options' }
      if (this.retailerId != null) {
        params.retailerId = parseInt(this.retailerId)
      }

      nibble.post('/stats', params, this.$store.getters.authHeaders)
        .then((response) => {
          this.filterOptions = response.data
          this.updateFiltersFromRoute()
        }).catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    routeUpdated () {
      if (this.filterOptions == null) {
        this.fetchOptions()
      } else {
        this.updateFiltersFromRoute()
      }
    },
    updateFiltersFromRoute () {
      this.offset = this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0
      this.filters = {
        dataQuality: this.$route.query.dataQuality != null ? this.$route.query.dataQuality : null,
        chattinessCategory: this.$route.query.chattinessCategory != null ? this.$route.query.chattinessCategory : null,
        retailerId: this.$route.query.retailerId != null ? parseInt(this.$route.query.retailerId) : null,
        summaryStatus: this.$route.query.summaryStatus != null ? this.$route.query.summaryStatus : null,
        timeRange: this.$route.query.timeRange != null ? this.$route.query.timeRange : 'all',
        startDate: this.$route.query.startDate != null ? this.$route.query.startDate : null,
        endDate: this.$route.query.endDate != null ? this.$route.query.endDate : null,
        zeroLength: this.$route.query.zeroLength === 'true',
        hasGenerativeAtoms: this.$route.query.hasGenerativeAtoms === 'true',
        hasGenerativeComposer: this.$route.query.hasGenerativeComposer === 'true',
        incorrectIntentInference: this.$route.query.incorrectIntentInference === 'true',
        incorrectEntityInference: this.$route.query.incorrectEntityInference === 'true',
        incorrectGptOutput: this.$route.query.incorrectGptOutput === 'true',
        objective: this.$route.query.objective,
        cohort: this.$route.query.cohort,
        experiment: this.$route.query.experiment,
        rule: this.$route.query.rule
      }

      this.fetchData()
    },
    updateFilters (newFilters) {
      this.$router.push({ path: this.listPath, query: this.filterQuery(newFilters) })
    },
    filterQuery (filters) {
      var query = { }
      if (filters.dataQuality != null) {
        query.dataQuality = filters.dataQuality
      }
      if (filters.chattinessCategory != null) {
        query.chattinessCategory = filters.chattinessCategory
      }
      if (filters.retailerId != null) {
        query.retailerId = filters.retailerId
      }
      if (!this.canSeeLimitedData) {
        query.summaryStatus = 'user_offer_available'
      } else if (filters.summaryStatus != null) {
        query.summaryStatus = filters.summaryStatus
      }
      if (filters.timeRange != null) {
        query.timeRange = filters.timeRange
      }
      if (filters.startDate != null) {
        query.startDate = filters.startDate
      }
      if (filters.endDate != null) {
        query.endDate = filters.endDate
      }
      if (filters.zeroLength) {
        query.zeroLength = 'true'
      }
      if (filters.hasGenerativeAtoms) {
        query.hasGenerativeAtoms = 'true'
      }
      if (filters.hasGenerativeComposer) {
        query.hasGenerativeComposer = 'true'
      }
      if (filters.incorrectIntentInference) {
        query.incorrectIntentInference = 'true'
      }
      if (filters.incorrectEntityInference) {
        query.incorrectEntityInference = 'true'
      }
      if (filters.incorrectGptOutput) {
        query.incorrectGptOutput = 'true'
      }
      if (filters.cohort != null) {
        query.cohort = filters.cohort
      }
      if (filters.experiment != null) {
        query.experiment = filters.experiment
      }
      if (filters.rule != null) {
        query.rule = filters.rule
      }
      return query
    },
    productLabel (row) {
      if (row.product == null) {
        if (row.negotiation_type === 'cart') {
          return 'Whole Cart'
        } else if (row.negotiation_type === 'quote') {
          return `Quote ${row.quote.reference_id}`
        } else {
          return '-'
        }
      }
      if (row.product.name != null && row.product.name !== '') {
        return row.product.name
      } else {
        return row.product.retailer_product_id
      }
    },
    formatDate (date) {
      return nibble.timezoneManager.formatDate(date, 'DD/MM/YYYY hh:mm[&nbsp;]A')
    },
    setOffset (offset) {
      var query = this.filterQuery(this.filters)
      query.offset = offset
      this.$router.push({ path: this.listPath, query: query })
    },
    search (text) {
      this.$router.push({ path: this.listPath + '/' + text })
    },
    showExportModal () {
      this.isExportModalActive = true
    },
    submitExport (event) {
      event.preventDefault()
      this.isExportModalActive = false
      this.download(this.exportFormat)
    },
    downloadRetailerSessions () {
      this.download('simple_list')
    },
    download (formatName) {
      var query = this.filterQuery(this.filters)
      query.action = 'download'
      query.format = formatName
      if (this.retailerId != null) {
        query.retailerId = this.retailerId
      }
      const settings = this.downloadSettings[formatName]
      this.loading = true
      nibble.post('/sessions/export', query, this.$store.getters.authHeaders)
        .then((response) => {
          var blob = null
          if (settings.mime_type === 'application/x-ndjson') {
            const jsonlString = typeof response.data === 'string' ? response.data : JSON.stringify(response.data)
            blob = new Blob([jsonlString], { type: settings.mime_type })
          } else {
            blob = new Blob([response.data], { type: settings.mime_type })
          }
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = settings.filename
          link.click()
          URL.revokeObjectURL(link.href)
          this.loading = false
        }).catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
