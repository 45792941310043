<template>
  <div class="zone-grid">
    <v-stage :config="canvasConfig">
      <v-layer>
        <v-group>
          <v-line :config="zoneLine(zone)" v-for="(zone, zoneIndex) in zones" :key="zoneIndex"></v-line>
        </v-group>
        <v-group>
          <v-line :config="roundLine(round)" v-for="(round, roundIndex) in rounds" :key="roundIndex"></v-line>
        </v-group>
      </v-layer>
      <v-layer>
        <v-group>
          <v-text :config="zoneText(zone)" v-for="(zone, zoneIndex) in zones" :key="zoneIndex"></v-text>
        </v-group>
        <v-group>
          <v-text :config="roundText(round)" v-for="(round, roundIndex) in rounds" :key="roundIndex"></v-text>
        </v-group>
      </v-layer>
      <v-layer v-if="showHeat">
        <v-group v-for="(round, roundIndex) in heatMap.values" :key="roundIndex">
          <v-rect :config="heatMapRect(roundIndex, zoneIndex, value)" v-for="(value, zoneIndex) in round" :key="zoneIndex"></v-rect>
        </v-group>
      </v-layer>
      <v-layer v-if="zoneWalks.length == 1">
        <v-line :config="walkLine(zoneWalks[0])"></v-line>
      </v-layer>
      <v-layer v-if="zoneWalks.length >= 3">
        <v-line :config="walkSegmentLine(segment)" v-for="(segment, segmentIndex) in Object.keys(walkSegments)" :key="segmentIndex"></v-line>
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ZoneGrid',
  props: ['zoneWalks', 'showHeat', 'width', 'height', 'labels'],
  data () {
    return {
      canvasConfig: {
        width: this.width,
        height: this.height
      }
    }
  },
  computed: {
    showLines () {
      return this.width >= 40
    },
    rounds () {
      const maxRound = _.maxBy(this.zoneWalks, (walk) => { return walk.length }).length
      var rounds = []
      for (var i = 0; i < maxRound; i++) {
        rounds.push(i)
      }
      return rounds
    },
    zones () {
      const minZone = Math.min(_.min(_.minBy(this.zoneWalks, (walk) => { return _.min(walk) })), 1)
      const maxZone = Math.max(_.max(_.maxBy(this.zoneWalks, (walk) => { return _.max(walk) })), 7)
      var zones = []
      for (var i = minZone; i <= maxZone; i++) {
        zones.push(i)
      }
      return zones
    },
    heatMap () {
      // Array[round][zone] where round and zone are normalized indeces
      var maxHeat = 0
      var values = Array(this.rounds.length).fill(0).map((x) => Array(this.zones.length).fill(0))
      _.forEach(this.zoneWalks, (walk) => {
        _.forEach(walk, (zone, round) => {
          var zoneIndex = this.zones.indexOf(zone)
          values[round][zoneIndex]++
          maxHeat = Math.max(maxHeat, values[round][zoneIndex])
        })
      })
      return {
        maxHeat: maxHeat,
        values: values
      }
    },
    walkSegments () {
      var segments = {}
      _.forEach(this.zoneWalks, (walk) => {
        var previousNode = null
        _.forEach(walk, (zone, round) => {
          if (previousNode == null) {
            previousNode = [zone, round]
          } else {
            var segment = [previousNode, [zone, round]]
            if (segments[segment] == null) {
              segments[segment] = [segment, 1]
            } else {
              segments[segment] = [segment, segments[segment][1] + 1]
            }
            previousNode = [zone, round]
          }
        })
      })
      return segments
    },
    edgeConfig () {
      return {
        x: 0,
        y: 0,
        width: this.canvasConfig.width,
        height: this.canvasConfig.height,
        stroke: this.gridStroke,
        strokeWidth: 1
      }
    },
    maxWalkSegmentIntensity () {
      return _.max(_.map(Object.keys(this.walkSegments), (key) => { return this.walkSegments[key][1] }))
    },
    gridRows () {
      if (this.labels) {
        return this.rounds.length + 1
      } else {
        return this.rounds.length
      }
    },
    gridColumns () {
      if (this.labels) {
        return this.zones.length + 1
      } else {
        return this.zones.length
      }
    },
    gridOffsetX () {
      if (this.labels) {
        return this.cellWidth
      } else {
        return 0
      }
    },
    gridOffsetY () {
      if (this.labels) {
        return this.cellHeight
      } else {
        return 0
      }
    },
    cellWidth () {
      return this.canvasConfig.width / this.gridColumns
    },
    cellHeight () {
      return this.canvasConfig.height / this.gridRows
    },
    gridStroke () {
      return 'rgba(10, 10, 10, 0.1)'
    },
    walkStroke () {
      return 'rgba(25, 118, 210, 1)'
    }
  },
  methods: {
    zoneLine (zone) {
      return {
        points: [
          (this.cellWidth * (zone - 1)) + this.gridOffsetX, 0,
          (this.cellWidth * (zone - 1)) + this.gridOffsetX, this.canvasConfig.height
        ],
        stroke: this.gridStroke,
        strokeWidth: 1
      }
    },
    roundLine (round) {
      return {
        points: [
          0, this.cellHeight * round + this.gridOffsetY,
          this.canvasConfig.width, this.cellHeight * round + this.gridOffsetY
        ],
        stroke: this.gridStroke,
        strokeWidth: 1
      }
    },
    zoneText (zone) {
      return {
        x: (this.cellWidth * (zone - 1)) + this.gridOffsetX,
        y: 0,
        width: this.cellWidth,
        height: this.cellHeight,
        align: 'center',
        verticalAlign: 'middle',
        text: `${zone}`
      }
    },
    roundText (round) {
      return {
        x: 0,
        y: this.cellHeight * (this.rounds.length - round - 1) + this.gridOffsetY,
        width: this.cellWidth,
        height: this.cellHeight,
        align: 'center',
        verticalAlign: 'middle',
        text: `${round + 1}`
      }
    },
    walkLine (walk) {
      var points = []
      _.forEach(walk, (zone, index) => {
        const x = (this.cellWidth * (zone - 1)) + this.cellWidth / 2 + this.gridOffsetX
        const y = this.cellHeight * (this.rounds.length - index - 1) + this.cellHeight / 2 + this.gridOffsetY
        points.push(x)
        points.push(y)
      })
      return {
        points: points,
        stroke: this.walkStroke,
        strokeWidth: 2,
        lineJoin: 'round',
        lineCap: 'round'
      }
    },
    walkSegmentColor (intensity) {
      const opacity = 0.1 + (intensity / this.maxWalkSegmentIntensity) * 0.9
      return `rgba(25, 118, 210, ${opacity})`
    },
    walkSegmentLine (segmentKey) {
      var points = []
      const segment = this.walkSegments[segmentKey][0]
      const segmentIntensity = this.walkSegments[segmentKey][1]
      _.forEach(segment, (zr, index) => {
        const zone = zr[0]
        const round = zr[1]
        const x = (this.cellWidth * (zone - 1)) + this.cellWidth / 2 + this.gridOffsetX
        const y = this.cellHeight * (this.rounds.length - round - 1) + this.cellHeight / 2 + this.gridOffsetY
        points.push(x)
        points.push(y)
      })
      return {
        points: points,
        stroke: this.walkSegmentColor(segmentIntensity),
        strokeWidth: 4
      }
    },
    heatMapRect (roundIndex, zoneIndex, value) {
      const opacity = value / this.heatMap.maxHeat
      return {
        x: this.cellWidth * zoneIndex + this.gridOffsetX,
        y: this.cellHeight * (this.rounds.length - roundIndex - 1) + this.gridOffsetY,
        width: this.cellWidth,
        height: this.cellHeight,
        fill: `rgba(255, 0, 0, ${opacity})`
      }
    }
  }
}
</script>

<style>
</style>
