<template>
  <page-layout :retailer-id="retailerId" :active-menu-item="menuItem">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">
      <template #header-right>
        <p class="level-item buttons">
          <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
          <b-button type="is-primary is-rounded" @click="updateRule">Save</b-button>
        </p>
      </template>

      <notification-bar v-if="errorSection" type="is-danger">
        Unable to save theme &#8212; invalid {{ errorSection }} settings.
      </notification-bar>

      <b-tabs v-model="activeTab">
        <b-tab-item key="basic-settings" value="basic-settings" label="Basic Settings">
          <ValidationObserver ref="basic_settings_observer" slim>
            <div class="card">
              <div class="card-content">
                <h2 class="title is-2">General Rule Settings</h2>
                <div class="columns is-multiline">
                  <div class="column is-full">
                    <ValidationProvider rules="required" name="Rule Name" v-slot="{ errors }">
                      <b-field label="The Rule name as it will appear in your Ruleset" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-input v-model="item.name"></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half">
                    <ValidationProvider rules="required" name="Rule Type" v-slot="{ errors }">
                      <b-field label="What will Nibble negotiate on?" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-select v-model="item.match_type" placeholder="Select a Rule Type" expanded>
                          <option value="global" v-if="context !== 'ruleset'">Global</option>
                          <option value="retailer">Whole Store</option>
                          <option value="product">Individual Products</option>
                          <option value="product_category">Product Categories</option>
                          <option value="cart" :disabled="!cartEnabled">Cart</option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half" v-if="retailerRequired && retailers != null">
                    <ValidationProvider :rules="{required: retailerRequired}" name="Retailer" v-slot="{ errors }">
                      <b-field label="Retailer" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-select v-model="retailerObject" placeholder="Select a Retailer" expanded>
                          <option v-for="(retailer, retailerIndex) in retailers"
                                  :key="retailerIndex"
                                  :value="retailer">
                            {{retailer.name}}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-full" v-if="!isAlgorithmRule && timeLimitsEnabled">
                    <h3 class="title is-3">Rule Duration</h3>

                    <!-- Commented for now -->
                    <!--
                    <ValidationProvider rules="required" name="Rule Duration Select" v-slot="{ errors }">
                      <b-field id="rule-duration" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.objectives.enableTimeLimit" :native-value="false" type="is-info">
                          No time limit
                        </b-radio>
                      </b-field>
                      <b-field id="rule-duration" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.objectives.enableTimeLimit" :native-value="true" type="is-info">
                          Date range
                        </b-radio>
                      </b-field>
                      <b-field id="rule-duration" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.objectives.enableTimeLimit" :native-value="true" type="is-info">
                          Recurring days/time
                        </b-radio>
                      </b-field>
                    </ValidationProvider>
                    -->

                    <div class="columns">
                      <div class="column is-half">
                        <ValidationProvider rules="required" name="Rule Duration" v-slot="{ errors }">
                          <b-field label="What kind of time limitation does this rule have?" :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select v-model="item.objectives.advancedTimeLimit" placeholder="Select a duration" expanded>
                              <option value="no-limit">No limits</option>
                              <option value="date-range">Date range</option>
                              <option value="recurring">Recurring date/time</option>
                            </b-select>
                          </b-field>
                        </ValidationProvider>
                      </div>
                    </div>
                    <time-limit-editor :value="timeLimitObjectives" v-on:input="timeLimitInput" v-if="item.objectives.enableTimeLimit"/>
                    <recurring-time-limit-editor :value="recurringTimeLimitObjectives" v-on:input_recurring="recurringTimeLimitInput" v-if="item.objectives.advancedTimeLimit === 'recurring'"/>
                    <div v-if="item.objectives.advancedTimeLimit !== 'no-limit'">Timezone: {{currentTimezone}}</div>
                  </div>
                  <div class="column is-full" v-if="canAddExperiment">
                    <h3 class="title is-3">A/B Testing</h3>
                    <b-field>
                      <b-checkbox v-model="enableExperiment">Enable this rule at random (A/B testing)</b-checkbox>
                    </b-field>
                    <div class="columns" v-if="enableExperiment">
                      <div class="column is-narrow">
                        <ValidationProvider :rules="{required: enableExperiment}" name="Activation Ratio" v-slot="{ errors }">
                          <b-field class="is-half" label="Activation Ratio" :message="errors" :type="{ 'is-danger': errors[0] }">
                            <p class="control">
                              <span class="button is-static">%</span>
                            </p>
                            <b-input v-model.number="item.experiment_percentage"></b-input>
                          </b-field>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br v-if="shouldShowMatchTypes"/>
            <div class="card" v-if="shouldShowMatchTypes">
              <div class="card-content" v-if="item.match_type === 'product' && selectedRetailerId != null">
                <h2 class="title is-2">Products</h2>
                <div class="columns">
                  <div class="column is-two-thirds">
                    <!-- <div class="column is-half"> -->
                    <ValidationProvider rules="required" name="Product" v-slot="{ errors }">
                      <b-field label="Which products will be available for a negotiation through Nibble?" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <product-list v-model="selectedProducts" :retailer-id="retailerId"></product-list>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="card-content" v-if="item.match_type === 'product_category' && selectedRetailerId != null">
                <h2 class="title is-2">Product Categories</h2>
                <div class="columns">
                  <div class="column is-two-thirds">
                    <ValidationProvider rules="required" name="Product Category" v-slot="{ errors }">
                      <b-field label="Which product categories will be available for a negotiation through Nibble?" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <category-list v-model="selectedProductCategories" :retailer-id="retailerId"></category-list>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- <b-message type="is-info is-small" has-icon>
                  Can't find the product category you are searching for?<br/>
                  The list of your product categories can be updated from the <router-link :to="{ name: 'ProductCategories' }"><b>Product Categories</b></router-link> section on the main menu.
                </b-message> -->
              </div>
              <div class="card-content" v-if="item.match_type === 'cart' && selectedRetailerId != null">
                <h2 class="title is-2">Negotiaton On Cart Settings</h2>
                <div class="columns">
                  <div class="column is-half">
                    <ValidationProvider rules="required" name="Match Basis" v-slot="{ errors }">
                      <p class="mb-4">Show Cart Negotiation Based on:</p>
                      <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.cart_match_basis" :native-value="'minimum_value'" type="is-info">
                          Minimum Cart Value
                        </b-radio>
                      </b-field>
                      <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.cart_match_basis" :native-value="'minimum_item_count'" :disabled="!cartSizeEnabled" type="is-info">
                          Minimum Number of Items in Cart
                        </b-radio>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half">
                    <ValidationProvider v-if="item.cart_match_basis==='minimum_value'" :rules="{required: item.cart_match_basis==='minimum_value'}" name="Minimum Cart Value" v-slot="{ errors }">
                      <b-field label="Minimum Cart Value" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-input v-model="item.minimum_value"></b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider v-if="item.cart_match_basis==='minimum_item_count'" :rules="{required: item.cart_match_basis==='minimum_item_count'}" name="Minimum Item Count" v-slot="{ errors }">
                      <b-field label="Minimum Item Count" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-input v-model="item.minimum_item_count"></b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider v-if="item.cart_match_basis==='minimum_item_count'" :rules="{required: item.cart_match_basis==='minimum_item_count'}" name="Minimum Value of Counted Items" v-slot="{ errors }">
                      <b-field label="Minimum Value of Counted Items" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-input v-model="item.minimum_item_value"></b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider v-if="signpostingEnabled" name="Signposting Enabled">
                      <b-field>
                        <b-checkbox v-model="item.signposting_enabled">Show Signposting in Cart</b-checkbox>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="card" v-if="!isAlgorithmRule && !showLegacyObjectiveEditor">
              <objective-editor :value="discountObjectives" v-on:input="discountInput"/>
            </div>
            <div class="card" v-if="!isAlgorithmRule && showLegacyObjectiveEditor">
              <objective-editor-legacy :value="discountObjectives" v-on:input="discountInput"/>
            </div>
            <div class="card" v-if="isAlgorithmRule">
              <objective-editor-admin :value="discountObjectives" v-on:input="discountInput"/>
            </div>
            <br v-if="!isAlgorithmRule"/>
            <div class="card" v-if="!isAlgorithmRule">
              <div class="card-content" v-if="selectedRetailerId != null">
                <h2 class="title is-2">Chat Settings</h2>
                <div class="columns">
                  <div class="column is-half">
                    <h3 class="title is-3">Free Shipping</h3>
                    <ValidationProvider rules="required" name="Free Shipping" v-slot="{ errors }">
                      <p class="mb-4">If a user asks, is free shipping available?</p>
                      <b-field id="free-shipping" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.has_free_shipping_included" :native-value="false" type="is-info">
                          No
                        </b-radio>
                      </b-field>
                      <b-field id="free-shipping" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.has_free_shipping_included" :native-value="true" type="is-info">
                          Yes
                        </b-radio>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half" v-if="item.match_type !== 'cart'">
                    <h3 class="title is-3">Ask for Quantity</h3>
                    <ValidationProvider rules="required" name="Ask Quantity First" v-slot="{ errors }">
                      <p class="mb-4">At the start, should Nibble ask the user how many they want to buy?</p>
                      <b-field id="ask_quantity_first" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.ask_quantity_first" :native-value="false" type="is-info">
                          No
                        </b-radio>
                      </b-field>
                      <b-field id="ask_quantity_first" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.ask_quantity_first" :native-value="true" type="is-info">
                          Yes
                        </b-radio>
                      </b-field>
                    </ValidationProvider>
                  <b-message type="is-warning is-small" has-icon>
                    Enabling this option will make Nibble ask the user how many of the product in question they want to buy.
                    Only choose 'Yes' if you usually sell multiple units of the same product at once.
                  </b-message>
                  </div>
                </div>
              </div>
            </div>
            <br v-if="isAlgorithmRule"/>
            <div class="card" v-if="isAlgorithmRule && loadedRule">
              <algorithm-editor v-model="item.algorithm_settings"/>
            </div>
          </ValidationObserver>
        </b-tab-item>
        <b-tab-item key="behavioural-tools" value="behavioural-tools" label="Behavioural Tools" v-if="!isAlgorithmRule">
          <ValidationObserver ref="behavioural_settings_observer" slim>
            <div class="card">
              <div class="card-content" v-if="selectedRetailerId != null">
                <h2 class="title is-2">Behavioural Tools</h2>
                <div class="columns is-multiline">
                  <div class="column is-full">
                    <p class="mb-4">Take a more granular approach, triggering Nibble via certain user behaviours or for specific cohorts.</p>
                    <div v-if="!behaviouralEnabled">
                      <b-button v-if="billingLink" :to="billingLink" type="is-rounded" icon-right="lock" tag="router-link">
                        Add Behavioural Rules
                      </b-button>
                    </div>
                  </div>
                  <div class="column is-full" v-if="behaviouralEnabled">
                    <ValidationProvider rules="required" name="Behavioural Select" v-slot="{ errors }">
                      <b-field id="behavioural-select" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="selectedAvailability" :native-value="'always'" type="is-info">
                          Always available for every visitor
                        </b-radio>
                      </b-field>
                      <b-field id="behavioural-select" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="selectedAvailability" :native-value="'limited'" type="is-info">
                          Available on specific user behaviours / conditions
                        </b-radio>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half" v-if="behaviouralEnabled && selectedAvailability === 'limited'">
                    <ValidationProvider rules="required" name="Show Button" v-slot="{ errors }">
                      <b-field label="When will Nibble be available?" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-select v-model="item.display_setting" placeholder="Select a Display Setting" expanded>
                          <option value="always">Always</option>
                          <option value="delay">After a set amount of time during a page visit</option>
                          <option value="exit_intent">Before the user leaves the page (exit intent)</option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="numeric|required" name="Delay seconds" v-slot="{ errors }" v-if="delayRequired">
                      <b-field label="How long should Nibble wait? (seconds)" class="ml-5" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-numberinput
                          v-model="item.delay_seconds"
                          type="is-info"
                          step="5"
                          min="5"
                          aria-minus-label="Decrement by 5"
                          aria-plus-label="Increment by 5"
                          controls-rounded/>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider name="Minimum Page Visits" v-slot="{ errors }" v-if="canLimitViewNumber">
                      <b-field label="How many page visits are required for Nibble to appear?" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-numberinput
                          v-model="item.minimum_visit_number"
                          type="is-info"
                          min="1"
                          placeholder="1"
                          controls-rounded/>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="required" name="Visibility Audience" v-slot="{ errors }">
                      <b-field label="Who will Nibble be available for?" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-select v-model="selectedCohortRestriction" placeholder="Select a restriction" expanded>
                          <option value="all_users">All users</option>
                          <option value="cohorts">Specific user cohort(s)</option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="required" name="User Cohorts" v-slot="{ errors }" v-if="selectedCohortRestriction === 'cohorts'">
                      <div class="ml-5">
                        <tag-picker v-model="selectedCohorts"
                          :options="cohorts"
                          label="Which user cohorts will be able to negotiate?"
                          placeholder="Select User Cohorts"
                          id-key="cohort_id"
                          :message="errors"
                          :input-type="{ 'is-danger': errors[0] }"/>
                        <b-message type="is-info is-small" has-icon>
                          Can't find the user cohort you are searching for?<br/>
                          The list of your user cohorts can be updated from the <router-link :to="{ name: 'Cohorts' }"><b>User Cohorts</b></router-link> section on the main menu.
                        </b-message>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="card mb-3">
              <div class="card-content">
                <h2 class="title is-2">Appearance</h2>
                <div class="columns">
                  <div class="column is-6-tablet" v-if="theme">
                    <label class="label">Theme Preview</label>
                    <theme-preview  :theme="theme.theme_configuration"></theme-preview>
                    <button @click="reactLogin()" class="button is-link is-light is-rounded is-outlined theme-button" v-if="canEditTheme">
                      Edit this theme in configurator
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </b-tab-item>
        <b-tab-item key="add-ons" value="add-ons" label="Gift With Purchase" v-if="!isAlgorithmRule">
          <ValidationObserver ref="addon_settings_observer" slim>
          <div class="card">
            <div class="card-content" v-if="selectedRetailerId != null">
              <h2 class="title is-2">Gift With Purchase</h2>
              <div class="columns is-multiline">
                <div class="column is-full">
                  <p class="mb-4">Select which items Nibble can add to the deal during negotiations under this rule.</p>
                  <div v-if="addonsEnabled">
                    <div class="mb-5" v-if="addonItems.length > 0">
                      <addon-rule-list ref="addon_rule_list" :rule_addon_items="item.rule_addon_items" :addon_items="addonItems"/>
                    </div>
                    <b-message type="is-info is-small" has-icon>
                      Can't find the items you are searching for?<br/>
                      The list of your Gifts With Purchase can be updated from the <router-link :to="{ name: 'Addons' }"><b>Gift With Purchase</b></router-link> section on the main menu.
                    </b-message>
                  </div>
                  <div v-else>
                    <b-button v-if="billingLink" :to="billingLink" type="is-rounded" icon-right="lock" tag="router-link">
                      Add Gift With Purchase
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </ValidationObserver>
        </b-tab-item>
        <b-tab-item key="retargeting" value="retargeting" label="Retargeting" v-if="!isAlgorithmRule">
          <ValidationObserver ref="retargeting_settings_observer" slim>
          <div class="card">
            <div class="card-content" v-if="selectedRetailerId != null">
              <h2 class="title is-2">Recovered Nibbles</h2>
              <div class="columns is-multiline">
                <div class="column is-full">
                  <p class="mb-4">Collect customer emails to recover unsuccessful negotiations.</p>
                  <div v-if="retargetingEnabled">
                    <b-message type="is-info" v-if="disableRecoveredNibbles">
                      Recovered Nibbles are not available on Cart negotiations
                    </b-message>
                    <ValidationProvider rules="required" name="Behavioural Select" v-slot="{ errors }">
                      <b-field id="recovered-nibbles-select" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.recovered_nibbles_active"
                          :native-value="false"
                          type="is-info"
                          :disabled="disableRecoveredNibbles">
                          Inactive
                        </b-radio>
                      </b-field>
                      <b-field id="recovered-nibbles-select" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-radio v-model="item.recovered_nibbles_active"
                          :native-value="true"
                          type="is-info"
                          :disabled="disableRecoveredNibbles">
                          Active
                        </b-radio>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div v-else>
                    <b-button v-if="billingLink" :to="billingLink" type="is-rounded" icon-right="lock" tag="router-link">
                      Add Recovered Nibbles
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </ValidationObserver>
        </b-tab-item>
      </b-tabs>
    </main-column>
    <theme-modal ref="themeModal"></theme-modal>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import TagPicker from '@/components/TagPicker'
import NotificationBar from '@/components/NotificationBar'
import TimeLimitEditor from '@/components/TimeLimitEditor'
import ObjectiveEditor from '@/components/ObjectiveEditor'
import ObjectiveEditorAdmin from '@/components/ObjectiveEditorAdmin'
import ObjectiveEditorLegacy from '@/components/ObjectiveEditorLegacy'
import AlgorithmEditor from '@/components/AlgorithmEditor'
import AddonRuleList from '@/components/AddonRuleList'
import ThemePreview from '@/components/ThemePreview'
import ItemEditor from '@/utils/mixins/ItemEditor'
import nibble from '@/utils/nibble'
import ProductList from '@/components/product-search/ProductList'
import CategoryList from '@/components/product-search/CategoryList'
import RecurringTimeLimitEditor from '@/components/RecurringTimeLimitEditor'
import ThemeModal from '@/components/ThemeModal'

export default {
  name: 'Rule',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, TimeLimitEditor, ObjectiveEditor, ObjectiveEditorAdmin, ObjectiveEditorLegacy, TagPicker, NotificationBar, AlgorithmEditor, AddonRuleList, ThemePreview, ProductList, CategoryList, RecurringTimeLimitEditor, ThemeModal },
  data () {
    return {
      activeTab: 0,
      errorSection: null,
      context: this.$route.query.context || 'ruleset',
      retailers: null,
      retailer: null,
      enableTimeLimit: false,
      advancedTimeLimit: 'no-limit', // 0 - no time limit | 1 - range | 2 - recurring
      enableExperiment: false,
      cohorts: [],
      productCategories: [],
      addonItems: [],
      planFeatures: [],
      selectedProducts: [],
      selectedProductCategories: [],
      selectedCohorts: [],
      selectedAvailability: 'always',
      selectedCohortRestriction: 'all_users',
      retailerObject: null,
      loadedRule: false,
      productsData: null,
      isProductModalActive: false,
      userProductQuery: '',
      theme: null,
      item: {
        name: '',
        match_type: null,
        cohort_ids: [],
        product_category_ids: [],
        rule_addon_items: [],
        minimum_visit_number: null,
        has_free_shipping_included: null,
        ask_quantity_first: false,
        display_setting: 'always',
        delay_seconds: null,
        cart_match_basis: 'minimum_value',
        minimum_value: null,
        minimum_item_count: null,
        minimum_item_value: null,
        signposting_enabled: false,
        objectives: {
          target_discount_percentage: null,
          walkaway_percentage: 16,
          objective: 'balance_performance',
          enableTimeLimit: false,
          advancedTimeLimit: 'no-limit', // 0 - no time limit | 1 - range | 2 - recurring
          dateRange: {
            startDateTime: null,
            endDateTime: null
          },
          recurringRange: {
            startTime: null,
            endTime: null,
            day: null
          }
        },
        algorithm_settings: null,
        recovered_nibbles_active: false
      }
    }
  },
  watch: {
    'item.objectives.advancedTimeLimit' () {
      if (this.item.objectives.advancedTimeLimit !== 'date-range') {
        this.item.objectives.enableTimeLimit = false
      }
      if (this.item.objectives.advancedTimeLimit === 'date-range') {
        this.item.objectives.enableTimeLimit = true
      }
      if (this.item.objectives.advancedTimeLimit !== 'recurring') {
        this.item.objectives.recurringRange = {
          startTime: null,
          endTime: null,
          day: null
        }
      }
      console.log(this.item.objectives)
    },
    'item.objectives.enableTimeLimit' () {
      if (!this.item.objectives.enableTimeLimit) {
        this.item.objectives.dateRange = {
          startDateTime: null,
          endDateTime: null
        }
      }
    },
    'item.display_setting' () {
      this.selectedAvailability = this.showBehaviouralOptions ? 'limited' : 'always'
    },
    'item.delay_seconds' () {
      this.selectedAvailability = this.showBehaviouralOptions ? 'limited' : 'always'
    },
    'item.minimum_visit_number' () {
      this.selectedAvailability = this.showBehaviouralOptions ? 'limited' : 'always'
    },
    'item.cart_match_basis' () {
      if (this.item.cart_match_basis === 'minimum_value') {
        this.item.minimum_item_count = null
        this.item.minimum_item_value = null
      } else {
        this.item.minimum_value = null
      }
    },
    selectedAvailability () {
      if (this.selectedAvailability === 'always') {
        this.item.display_setting = 'always'
        this.item.delay_seconds = null
        this.item.minimum_visit_number = null
        this.selectedCohortRestriction = 'all_users'
      }
    },
    selectedCohortRestriction () {
      if (this.selectedCohortRestriction === 'all_users') {
        this.selectedCohorts = []
      }
      this.selectedAvailability = this.showBehaviouralOptions ? 'limited' : 'always'
    },
    selectedCohorts () {
      if (this.selectedCohorts.length > 0) {
        this.selectedCohortRestriction = 'cohorts'
      }
    },
    retailer () {
      this.fetchOptions()
    },
    retailers () {
      this.selectedRetailerId = this.retailer.retailer_id
      this.retailerObject = this.retailers.find((c) => c.retailer_id === this.retailer.retailer_id)
    },
    'item.match_type' () {
      if (this.item.match_type === 'cart') {
        this.item.recovered_nibbles_active = false
      }
      if (this.item.match_type === 'global') {
        this.retailerObject = null
      }
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    currentTimezone () {
      return nibble.timezoneManager.get()
    },
    menuItem () {
      return this.context
    },
    delayRequired () {
      return this.item.display_setting === 'delay'
    },
    canLimitViewNumber () {
      return true
    },
    canAddExperiment () {
      return this.context.startsWith('admin:')
    },
    isAlgorithmRule () {
      return this.context === 'admin:algorithm'
    },
    shouldShowMatchTypes () {
      return (this.context !== 'admin:algorithm') &&
        (this.item.match_type) &&
        (!['global', 'retailer'].includes(this.item.match_type))
    },
    showLegacyObjectiveEditor () {
      return (this.item.objectives.target_discount_percentage > 0)
    },
    listPath () {
      if (this.context === 'ruleset') {
        return `/retailers/${this.retailerId}/rules`
      } else if (this.context === 'archive') {
        return `/retailers/${this.retailerId}/archive`
      } else if (this.context === 'admin:algorithm') {
        return '/admin/algorithm'
      } else {
        return null
      }
    },
    newPath () {
      if (this.retailerId != null) {
        return `/retailers/${this.retailerId}/rules/new`
      } else {
        return { path: '/admin/rules/new', query: { context: this.context } }
      }
    },
    itemPath () {
      if (this.retailerId != null) {
        return `/retailers/${this.retailerId}/rules/${this.itemId}`
      } else {
        return `/admin/rules/${this.itemId}`
      }
    },
    listApiPath () {
      return '/rules'
    },
    itemApiPath () {
      return `/rules/${this.itemId}`
    },
    itemName () {
      return 'Rule'
    },
    selectedRetailerId () {
      if (this.retailerId != null) {
        return this.retailerId
      } else if (this.retailer != null) {
        return this.retailer.retailer_id
      } else {
        return null
      }
    },
    retailerRequired () {
      return this.item.match_type != null && this.item.match_type !== 'global'
    },
    addonChanges () {
      if (this.$refs.addon_rule_list != null) {
        return this.$refs.addon_rule_list.changes
      } else {
        return null
      }
    },
    showBehaviouralOptions () {
      return (this.item.display_setting !== 'always') ||
        (this.item.delay_seconds !== null) ||
        (this.item.minimum_visit_number !== null) ||
        (this.selectedCohortRestriction === 'cohorts')
    },
    addonsEnabled () {
      return this.planFeatures.includes('addons')
    },
    retargetingEnabled () {
      return this.planFeatures.includes('abandoned_nibbles')
    },
    behaviouralEnabled () {
      return this.planFeatures.includes('behavioural_rules')
    },
    cartEnabled () {
      return this.planFeatures.includes('negotiation_on_cart')
    },
    cartSizeEnabled () {
      return this.planFeatures.includes('cart_size_rules')
    },
    signpostingEnabled () {
      return this.planFeatures.includes('signposting')
    },
    timeLimitsEnabled () {
      return this.planFeatures.includes('timed_rules') ||
        this.item.objectives.dateRange.startDateTime != null ||
        this.item.objectives.dateRange.endDateTime != null ||
        this.item.objectives.recurringRange.startTime != null
    },
    billingLink () {
      return this.selectedRetailerId !== null ? `/retailers/${this.selectedRetailerId}/account/plan/choice` : null
    },
    timeLimitObjectives () {
      return {
        enableTimeLimit: this.item.objectives.enableTimeLimit,
        dateRange: this.item.objectives.dateRange
      }
    },
    recurringTimeLimitObjectives () {
      return {
        enableTimeLimit: this.item.objectives.advancedTimeLimit === 'recurring',
        recurringRange: this.item.objectives.recurringRange
      }
    },
    discountObjectives () {
      return {
        target_discount_percentage: this.item.objectives.target_discount_percentage,
        walkaway_percentage: this.item.objectives.walkaway_percentage,
        objective: this.item.objectives.objective
      }
    },
    disableRecoveredNibbles () {
      return this.item.match_type === 'cart'
    },
    canEditTheme () {
      return this.$store.getters.isAdmin && this.theme && this.theme.theme_id
    }
  },
  methods: {
    fetchOptions () {
      this.loadedRule = true

      if (this.retailerId != null || this.retailer != null) {
        this.fetchOptionsForRetailer()
      } else {
        this.fetchRetailers()
      }

      this.fetchTheme()
    },
    fetchTheme () {
      return nibble.get('/admin/themes/0', { retailer_id: this.retailerId }, this.$store.getters.authHeaders)
        .then((response) => {
          this.theme = response.data
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchRetailers () {
      return nibble.get('/retailers', {}, this.$store.getters.authHeaders)
        .then((response) => {
          this.retailers = response.data.retailers
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchOptionsForRetailer () {
      this.fetchCohorts()
        .then(this.fetchProductCategories)
        .then(this.fetchProducts)
        .then(this.fetchAddonItems)
        .then(this.fetchBillingDetails)
        .then(() => {
          this.selectedCohorts = this.item.cohort_ids.map((id) => this.cohorts.find((c) => c.cohort_id === id))
          if (this.item.match_type === 'product') {
            this.selectedProducts = [...this.item.products]
          }
          if (this.item.match_type === 'product_category') {
            this.selectedProductCategories = [...this.item.product_categories]
          }
        })
    },
    fetchCohorts () {
      return nibble.get(`/retailers/${this.selectedRetailerId}/cohorts`, {}, this.$store.getters.authHeaders)
        .then((response) => {
          this.cohorts = response.data.cohorts
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchAddonItems () {
      return nibble.get(`/retailers/${this.selectedRetailerId}/addon_items`, { retailer_id: this.selectedRetailerId, context: 'retailer' }, this.$store.getters.authHeaders)
        .then((response) => {
          this.addonItems = response.data.addon_items
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchBillingDetails () {
      return nibble.get(`/retailers/${this.selectedRetailerId}/account/plan`, { retailer_id: this.selectedRetailerId, context: 'retailer' }, this.$store.getters.authHeaders)
        .then((response) => {
          this.planFeatures = response.data.feature_names
          console.log(response.data)
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    itemToParams () {
      var params = {
        context: this.context,
        retailer_id: (this.retailerObject != null ? this.retailerObject.retailer_id : this.retailerId),
        name: this.item.name,
        match_type: this.item.match_type,
        target_discount_percentage: this.item.objectives.target_discount_percentage,
        walkaway_percentage: this.item.objectives.walkaway_percentage,
        objective: this.item.objectives.objective,
        has_free_shipping_included: this.item.has_free_shipping_included,
        addons: this.addonChanges,
        display_setting: this.item.display_setting,
        delay_seconds: this.item.delay_seconds,
        minimum_visit_number: this.item.minimum_visit_number === 1 ? null : this.item.minimum_visit_number,
        recovered_nibbles_action: this.item.recovered_nibbles_active ? 'activate' : 'deactivate',
        ask_quantity_first_action: this.item.ask_quantity_first ? 'activate' : 'deactivate',
        cohort_ids: this.selectedCohorts.map((c) => c.cohort_id)
      }
      // Advanced time limit
      if (this.item.objectives.advancedTimeLimit === 'recurring' && this.item.objectives.recurringRange.day !== null) {
        params.day = this.item.objectives.recurringRange.day
      }
      if (this.item.objectives.advancedTimeLimit === 'recurring' && this.item.objectives.recurringRange.startTime != null) {
        params.local_starts_at_time = this.item.objectives.recurringRange.startTime
      }
      if (this.item.objectives.advancedTimeLimit === 'recurring' && this.item.objectives.recurringRange.endTime != null &&
        this.item.objectives.recurringRange.endTime !== 'End of day') {
        params.local_ends_at_time = this.item.objectives.recurringRange.endTime
      }
      // Note: If for any reasons both limits are set I'm using only the advanced one
      if (this.item.objectives.advancedTimeLimit === 'date-range') {
        // Base time limit
        if (this.item.objectives.enableTimeLimit && this.item.objectives.dateRange.startDateTime != null) {
          // converting to UTC from retailers timezone
          var tempStart = nibble.timezoneManager.replaceTimezone(this.item.objectives.dateRange.startDateTime, localStorage.getItem('timezone'))
          tempStart = nibble.timezoneManager.formatDateToUTC(tempStart, 'YYYY-MM-DDTHH:mm:ssZ')
          params.starts_at = tempStart
        }
        if (this.item.objectives.enableTimeLimit && this.item.objectives.dateRange.endDateTime != null) {
          // converting to UTC from retailers timezone
          var tempEnd = nibble.timezoneManager.replaceTimezone(this.item.objectives.dateRange.endDateTime, localStorage.getItem('timezone'))
          tempEnd = nibble.timezoneManager.formatDateToUTC(tempEnd, 'YYYY-MM-DDTHH:mm:ssZ')
          params.ends_at = tempEnd
        }
      }
      // Other parameters
      if (this.item.match_type === 'product') {
        params.products = this.selectedProducts
      }
      if (this.item.match_type === 'product_category') {
        params.product_categories = this.selectedProductCategories
      }
      if (this.item.match_type === 'cart') {
        params.minimum_value = this.item.minimum_value
        params.minimum_item_count = this.item.minimum_item_count
        params.minimum_item_value = this.item.minimum_item_value
        params.signposting_enabled = this.item.signposting_enabled
      }
      if (this.context === 'admin:algorithm') {
        params.algorithm_version = this.item.algorithm_settings.algorithm_version
        params.hyper_parameters = Object.assign({}, this.item.algorithm_settings.hyper_parameters)
      }
      if (this.enableExperiment && this.item.experiment_percentage != null && this.item.experiment_percentage !== '') {
        params.experiment_percentage = this.item.experiment_percentage
      }
      return params
    },
    dataToItem (data) {
      // CHECK IF THIS IS A LEGACY OBJECTIVE CASE
      if (data.target_discount_percentage > 0 && this.context !== 'admin:algorithm') {
        data.objective = null
      }
      var item = {
        name: data.name,
        match_type: data.match_type,
        minimum_visit_number: data.minimum_visit_number,
        minimum_value: data.minimum_value != null ? data.minimum_value : null,
        minimum_item_count: data.minimum_item_count != null ? data.minimum_item_count : null,
        minimum_item_value: data.minimum_item_value != null ? data.minimum_item_value : null,
        cart_match_basis: (data.minimum_item_count != null ? 'minimum_item_count' : 'minimum_value'),
        signposting_enabled: data.signposting_enabled,
        experiment_percentage: data.experiment_percentage,
        objectives: {
          target_discount_percentage: data.target_discount_percentage,
          walkaway_percentage: data.walkaway_percentage,
          objective: data.objective,
          enableTimeLimit: false,
          advancedTimeLimit: false,
          dateRange: {
            startDateTime: null,
            endDateTime: null
          },
          recurringRange: {
            startTime: null,
            endTime: null,
            day: null
          }
        },
        algorithm_settings: {
          algorithm_version: data.algorithm_version,
          hyper_parameters: data.hyper_parameters
        },
        cohort_ids: data.cohort_ids,
        products: data.products,
        product_categories: data.product_categories,
        has_free_shipping_included: data.has_free_shipping_included,
        rule_addon_items: data.rule_addon_items,
        display_setting: data.display_setting,
        delay_seconds: null
      }
      if (data.delay_seconds != null) {
        item.delay_seconds = data.delay_seconds
      }
      if (data.retailer != null && this.retailerId == null) {
        this.retailer = data.retailer
      }
      // Advanced time limit
      if (data.local_starts_at_time !== undefined && data.local_starts_at_time !== null) {
        item.objectives.advancedTimeLimit = 'recurring'
        item.objectives.recurringRange.startTime = data.local_starts_at_time
      }
      if (data.local_ends_at_time !== undefined && data.local_ends_at_time !== null) {
        item.objectives.advancedTimeLimit = 'recurring'
        item.objectives.recurringRange.endTime = data.local_ends_at_time
      }
      if (data.day !== undefined && data.day !== null) {
        item.objectives.advancedTimeLimit = 'recurring'
        item.objectives.recurringRange.day = data.day
      }
      // Note: If for any reasons both limits are set I'm using only the advanced one
      if (item.objectives.advancedTimeLimit === 'date-range' || item.objectives.advancedTimeLimit === false) {
        // Base time limit
        if (data.starts_at !== undefined && data.starts_at !== null) {
          item.objectives.advancedTimeLimit = 'date-range'
          item.objectives.enableTimeLimit = true
          // converting to user's timezone from UTC
          item.objectives.dateRange.startDateTime = nibble.timezoneManager.fromUTCtoLocal(data.starts_at, 'YYYY-MM-DDTHH:mm:ssZ')
        }
        if (data.ends_at !== undefined && data.ends_at !== null) {
          item.objectives.advancedTimeLimit = 'date-range'
          item.objectives.enableTimeLimit = true
          // converting to user's timezone from UTC
          item.objectives.dateRange.endDateTime = nibble.timezoneManager.fromUTCtoLocal(data.ends_at, 'YYYY-MM-DDTHH:mm:ssZ')
        }
      }
      if (item.objectives.advancedTimeLimit === false) {
        item.objectives.advancedTimeLimit = 'no-limit'
      }

      this.enableExperiment = item.experiment_percentage != null

      if (data.recovered_nibbles_active != null) {
        item.recovered_nibbles_active = data.recovered_nibbles_active
      }
      if (data.ask_quantity_first != null) {
        item.ask_quantity_first = data.ask_quantity_first
      }
      return item
    },
    validateBasicSettings () {
      return this.$refs.basic_settings_observer.validate()
    },
    validateBehaviouralSettings () {
      return this.$refs.behavioural_settings_observer.validate()
    },
    validateAddonSettings () {
      return this.$refs.addon_settings_observer.validate()
    },
    validateRetargetingSettings () {
      return this.$refs.retargeting_settings_observer.validate()
    },
    async updateRule () {
      // Validate this tab for all rule types
      const basicSettingsValid = await this.validateBasicSettings()
      if (!basicSettingsValid) {
        this.activeTab = 0
        this.errorSection = 'basic'
        return null
      }

      // Don't validate these tabs if viewing an algorithm rule
      if (!this.isAlgorithmRule) {
        const behaviouralSettingsValid = await this.validateBehaviouralSettings()
        if (!behaviouralSettingsValid) {
          this.activeTab = 1
          this.errorSection = 'behavioural'
          return null
        }

        const addonSettingsValid = await this.validateAddonSettings()
        if (!addonSettingsValid) {
          this.activeTab = 2
          this.errorSection = 'add-on'
          return null
        }

        const retargetingSettingsValid = await this.validateRetargetingSettings()
        if (!retargetingSettingsValid) {
          this.activeTab = 3
          this.errorSection = 'retargeting'
          return null
        }
      }

      this.submit()
    },
    timeLimitInput (timeLimitObjectives) {
      this.item.objectives.enableTimeLimit = timeLimitObjectives.enableTimeLimit
      this.item.objectives.dateRange = timeLimitObjectives.dateRange
    },
    recurringTimeLimitInput (timeLimitObjectives) {
      this.item.objectives.recurringRange = timeLimitObjectives.recurringRange
    },
    discountInput (discountObjectives) {
      this.item.objectives.target_discount_percentage = discountObjectives.target_discount_percentage
      this.item.objectives.walkaway_percentage = discountObjectives.walkaway_percentage
      this.item.objectives.objective = discountObjectives.objective
    },
    reactLogin () {
      var theme = this.theme
      const params = {
        destination: 'app-react',
        id: theme.theme_id,
        where: 'theme'
      }
      nibble.post('/auth/handoff', params, this.$store.getters.authHeaders).then((response) => {
        const url = response.data.url
        this.$refs.themeModal.show(url)
        // window.location.href = url
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card {
  overflow: visible;
}
.notification {
  margin-left: 0;
  margin-right: 0;
}
.theme-button {
  margin-top: 1em;
}
</style>
