<template>
  <div>
    <table class="table is-fullwidth stats-table is-narrow mt-4 mb-0">
      <tbody>
        <tr v-if="productSKU">
          <td class="pl-0">SKU</td>
          <td class="has-text-right pr-0">{{productSKU}}</td>
        </tr>
        <tr v-if="item.product != null && item.product.quantity">
          <td class="pl-0">Stock Level</td>
          <td class="has-text-right pr-0">{{item.product.quantity}}</td>
        </tr>
        <tr v-if="item.product != null && item.product.name != null">
          <td class="pl-0">Product Name</td>
          <td class="has-text-right pr-0">
            {{item.product.name}}
            <span v-if="item.product.sub_product != null && item.product.sub_product.name != null">
              <br>
              {{item.product.sub_product.name}}
            </span>
          </td>
        </tr>
        <tr v-if="item.product != null && item.product.quantity">
          <td class="pl-0">Cost</td>
          <td class="has-text-right pr-0">{{item.product.cost}}</td>
        </tr>
        <tr v-if="item.product_price">
          <td class="pl-0">Display Price</td>
          <td class="has-text-right pr-0">{{item.product_price}}</td>
        <tr>
          <td class="pl-0">Final offer from Nibble</td>
          <td class="has-text-right pr-0">{{item.current_price}}</td>
        </tr>
        <tr class="no-border mt-4">
          <td rowspan="2" class="has-text-weight-bold pl-0">Last offer from User</td>
        </tr>
      </tbody>
    </table>
    <div class="is-flex is-flex-horizontal is-align-items-flex-end mb-4">
      <div class="is-flex is-flex-grow-1 is-flex-horizontal is-align-items-baseline">
        <h2 class="title is-2 mb-0 mr-4 pending-price">{{item.last_user_bid}}</h2>
        <p class="is-flex-grow-1 has-text-lighter">
          {{item.last_user_bid_discount_percentage}}%
          against
          <span v-if="showOneObjective">
            {{item.walkaway_percentage}}% Discount Threshold
          </span>
          <span v-else>
            {{item.target_discount_percentage}}% target
          </span>
        </p>
      </div>
    </div>

    <div v-if="item.summary_status === 'user_offer_available'">
      <h2 class="title is-2 mb-4 mt-5">
        What do you want to do?
      </h2>
      <div class="media media-card has-text-lighter hoverable"
           v-for="option in options"
           :key="option.type"
           :class="{ active: option === selectedOption }" @click="clickCard(option)">
        <div class="media-left" :class="{ activeIcon: option === selectedOption }">
          <material-icon :icon="option.icon" size="xlarge"></material-icon>
        </div>
        <div class="media-content">
          <h4 class="title is-4 mb-2"> {{ option.title}} </h4>
          <p v-html="option.explanation"/>
          <div class="is-narrow" v-if="selectedOption && selectedOption.type === 'user_offer_countered' && option.type === 'user_offer_countered'">
            <ValidationProvider rules="required" name="Counter Offer" v-slot="{ errors }">
              <b-field label="Your Counter-offer:" :message="errors" :type="{ 'is-danger': errors[0] }">
                <p class="control">
                  <span class="button is-static">{{ item.currency_symbol }}</span>
                </p>
                <b-input v-model="counterOffer"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <b-radio class="mr-0 my-auto" v-model="selectedOption" :native-value="option" type="is-info" size="is-large"/>
      </div>
      <div v-if="selectedOption != null" class="field is-grouped is-grouped-right mt-4 confirm-input">
        <p>Confirm your choice and add a custom message</p>
        <b-button type="is-rounded is-primary" @click="confirm">Confirm</b-button>
      </div>
    </div>

    <notification-bar v-if="item.summary_status === 'user_offer_accepted'">
      We’ve sent an email to the user letting them know you’ve accepted their offer of <strong>{{ offerPrice }}</strong>. They’ll be able to checkout directly from this email.
    </notification-bar>
    <notification-bar v-if="item.summary_status === 'user_offer_rejected'">
      We’ve sent an email to the user letting them know their offer of {{ offerPrice }} was rejected. They’ve still got the option to negotiate again and make better offers next time. 🤞
    </notification-bar>
    <notification-bar v-if="item.summary_status === 'user_offer_countered'">
      We’ve sent an email to the user with your final counter-offer of <strong>{{ offerPrice }}</strong>. Once they come back, they’ll be able to decide whether to accept or reject your offer. Fingers crossed! 🤞
    </notification-bar>

    <confirmation-modal v-if="isRecoveredNibbleComfirmationModalActive"
                        :active="isRecoveredNibbleComfirmationModalActive"
                        :option="selectedOption"
                        :offer-price="offerPrice"
                        @cancel="setRecoveredNibbleComfirmationModalInactive"
                        @submit="submitRecoveredNibble">
    </confirmation-modal>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import NotificationBar from '@/components/NotificationBar'
import ConfirmationModal from '@/components/recovered-nibbles/ConfirmationModal'

export default {
  components: { MaterialIcon, NotificationBar, ConfirmationModal },
  props: {
    item: {
      type: Object,
      required: true
    },
    showOneObjective: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isRecoveredNibbleComfirmationModalActive: false,
      counterOffer: null,
      selectedOption: null,
      options: [
        {
          type: 'user_offer_accepted',
          icon: 'thumb_up',
          title: 'ACCEPT THE OFFER',
          modalTitle: 'Accept the offer',
          explanation: 'The user will receive an email with a link to a one-off, non-negotiable offer from Nibble, at the exact amount equal to their last offer, as shown above. <b>Link will expire in 24h.</b>',
          offerField: 'Offer'
        },
        {
          type: 'user_offer_countered',
          icon: 'swap_horizontal_circle',
          title: 'MAKE FINAL COUNTER-OFFER',
          modalTitle: 'Final Counter-Offer',
          explanation: 'The user will receive an email with a link to a one-off, non-negotiable offer from Nibble, at the exact amount specified. <b>Link will expire in 24h.</b>',
          offerField: 'Counter-Offer'
        },
        {
          type: 'user_offer_rejected',
          icon: 'thumb_down',
          title: 'REJECT THE OFFER',
          modalTitle: 'Reject the offer',
          explanation: 'The user will be notified of your rejection, and <b>their offer will be archived permanently.</b> You can access all archived offers by filtering your Nibbles list as “Archived”.',
          offerField: null
        }
      ]
    }
  },
  computed: {
    offerPrice () {
      if (this.item.summary_status === 'user_offer_accepted' || this.item.summary_status === 'user_offer_countered') {
        return this.item.recovered_nibble.retailer_offer
      } else if (this.counterOffer != null && this.selectedOption.type === 'user_offer_countered') {
        return this.item.currency_symbol + this.counterOffer
      } else if (this.item.last_user_bid == null) {
        return this.item.product_price
      } else {
        return this.item.last_user_bid
      }
    },
    productSKU () {
      var sku = null
      if (this.item.product != null && this.item.product.sub_product != null) {
        sku = this.item.product.sub_product.product_sku
      }
      if ((sku == null || sku === '') && this.item.product != null) {
        sku = this.item.product.product_sku
      }
      return sku
    }
  },
  methods: {
    confirm () {
      this.isRecoveredNibbleComfirmationModalActive = true
    },
    setRecoveredNibbleComfirmationModalInactive () {
      this.isRecoveredNibbleComfirmationModalActive = false
    },
    submitRecoveredNibble (retailerMessage, reason) {
      this.isRecoveredNibbleComfirmationModalActive = false
      const params = {
        recovered_nibble: {
          offer_type: this.selectedOption.type,
          retailer_message: retailerMessage,
          reason: reason,
          retailer_offer: parseFloat(this.offerPrice.replaceAll(',', '').match(/[\d.]+/)[0]),
          user_offer: parseFloat(this.item.last_user_bid.replaceAll(',', '').match(/[\d.]+/)[0])
        }
      }
      this.$emit('submitRecoveredNibble', params)
    },
    clickCard (option) {
      this.selectedOption = option
    }
  }
}
</script>
<style lang="scss" scoped>
.media-card {
  border: 1px solid #E0E6EB;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px 16px 16px 24px;
}

.media-card.hoverable:hover {
  border-color: #3e8ed0;
  cursor: pointer;
}

.active {
  border: 2px solid #1976D2 !important;
  &:hover { border-width: 2px; }
}

.activeIcon {
  color: #1976D2;
}

.confirm-input {
  display: flex;
  align-items: center;
}

.confirm-input p {
  color: #668099;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
</style>
<style lang="scss">
.media-card.hoverable:hover {
  .b-radio.radio input[type=radio] + .check{
    border-color: #3e8ed0 !important;
  }
}
</style>
