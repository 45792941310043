<template>
  <div :class="messageClass()">
    <div class="columns" v-if="message.user_message" @click="clickMessage">
      <div class="column is-10 is-offset-2 line customer">
        <div class="line">
          <span class="chat-message">
            {{message.user_message}}
            <reactions-bot :message="message"></reactions-bot>
          </span>
        </div>
      </div>
    </div>
    <deal-celebration :expression="message.expression" v-if="message.expression && message.expression.expressionId === 'deal_celebration'"></deal-celebration>
    <expression :expression="message.expression" v-if="message.expression && message.expression.expressionId != 'deal_celebration'"></expression>
    <div class="columns" v-if="message.messages != null && message.messages.length > 0" @click="clickMessage">
      <div class="column is-10 line bot">
        <div class="line" v-for="(line, lineIndex) in message.messages" :key="lineIndex">
          <span class="chat-message">
            {{linkifyText(line)}}
            <reactions :message="message"></reactions>
          </span>
        </div>
      </div>
    </div>
    <chat-message-modal v-if="hasModal" :session="session" :chat-message-id="message.message_id"  ref="modal"></chat-message-modal>
  </div>
</template>

<script>
import Expression from './Expression'
import DealCelebration from './DealCelebration'
import Reactions from './Reactions'
import ReactionsBot from './ReactionsBot'
import ChatMessageModal from './ChatMessageModal'

export default {
  name: 'ChatMessage',
  components: { Expression, DealCelebration, ReactionsBot, Reactions, ChatMessageModal },
  props: {
    session: {
      required: true,
      type: Object
    },
    message: {
      required: true,
      type: Object
    },
    hasModal: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  computed: {
    welcomeExpression () {
      return {
        expressionId: 'welcome',
        sources: [
          { type: 'image/svg+xml', url: 'https://cdn.nibble.website/expressions/neutral_start.svg' }
        ]
      }
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  methods: {
    messageClass () {
      var cls = 'message-container'
      if (this.message.meta != null && this.message.meta.understanding_issues) {
        cls = cls + ' understanding-error'
      }
      if (this.message.meta != null && this.message.meta.user_reaction) {
        cls = cls + ' with-user-reactions'
      }
      if (this.message.meta != null && this.message.meta.user_reaction) {
        cls = cls + ' with-bot-reactions'
      }
      return cls
    },
    linkifyText (text) {
      // Links look like this:
      // <link text|link_url>
      const re = /<(.+)\|(.+)>/g
      const link = '<a href="$2" target="_blank">$1</a>'

      // This function call is the equivalent of text.replaceAll(re, link)
      // but certain third party js libraries override the replaceAll method.
      return re[Symbol.replace](text, link)
    },
    clickMessage () {
      if (this.isAdmin && this.hasModal) {
        this.$refs.modal.show()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.line .chat-message{
    background-color: #505465;
}
.line.bot .chat-message{
    background-color: #f5f5f6;
}
.message-container {
  max-width: 428px;
  margin-bottom: 0.5em;
  border: 2px solid transparent;
}
.message-container.active {
  border: 2px dashed rgba(0, 209, 178, 0.25);
}
.message-container:hover {
  background-color: rgba(0, 209, 178, 0.05);
}
.line {
  margin-bottom: 1em;
}
.line:hover {
  cursor: pointer;
}
.line:last-child {
  margin-bottom: 0;
}
.line a.linkified {
  color: inherit;
  text-decoration: underline;
}
.line.customer {
  text-align: right;
}
.line .chat-message {
  display: inline-block;
  border-radius: 16px;
  padding: 0.5em 1em;
}
.line.customer .chat-message {
  background-color: #fff;
  border: 1px solid #e0e6eb;
  border-radius: 16px 16px 0 16px;
  color: #000;
}
.understanding-error .line .chat-message,
.understanding-error .line.bot .chat-message {
  background-color: #FCE9ED;
}
.line.bot .chat-message {
  background-color: #f6f7f9;
  border-radius: 16px 16px 16px 0;
  color: black;
}
.line.bot .chat-message a {
  color: black;
  text-decoration: underline;
}
.line .buttons {
  text-align: center;
}
.timestamp {
  color: black;
  margin: 0;
  font-size: 0.7em;
}
.bot-btns {
  text-align: center;
}
.message-container {
  &.with-bot-reactions .customer .chat-message:last-child {
    margin-bottom: 24px;
  }
  &.with-user-reactions .bot .chat-message:last-child {
    margin-bottom: 24px;
  }
}
</style>
