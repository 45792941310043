<template>
  <page-layout :retailer-id="retailerId" active-menu-item="addon_items">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">

        <template #header-right>
          <p class="level-item buttons">
            <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
            <b-button type="is-primary is-rounded" @click="handleSubmit(submit)">Save</b-button>
          </p>
        </template>
        <div class="card mb-4">
          <div class="card-content">
            <h2 class="title is-2">Gift With Purchase Info</h2>
            <div class="columns is-multiline">
              <div class="column is-half">
                <ValidationProvider rules="required" name="Product ID" v-slot="{ errors }">
                  <b-field label="Product ID" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.retailer_product_id"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-4" v-for="(language, languageIndex) in availableLanguages" :key="languageIndex">
          <div class="card-content">
            <h2 class="title is-2">
              <span v-if="availableLanguages.length > 1">{{language.name}}</span>
              <span v-else>Gift With Purchase</span> Text</h2>
            <div class="columns is-multiline">
              <div class="column is-half">
                <ValidationProvider rules="required" name="Name" v-slot="{ errors }">
                  <b-field label="Name" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.localized_name[language.code]"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half">
                <ValidationProvider rules="required" name="Short Description" v-slot="{ errors }">
                  <b-field label="Short Description" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.localized_short_description[language.code]" maxlength="32"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <notification-bar v-if="errorMessage != null" type="is-danger">
              {{errorMessage}}
            </notification-bar>
          </div>
        </div>
        <div class="card mb-4">
          <addon-objectives-editor v-model="item.objectives" :disableSynthetic="disableSynthetic" v-if="shouldShowObjectivesEditor"/>
        </div>
        <div class="card mb-4">
          <div class="card-content">
            <h2 class="title is-2">Image</h2>
            <div class="block">
              In-chat Card Preview
            </div>
            <div class="columns is-mobile">
              <div class="column">
                <div class="addon-box">
                  <div class="box">
                    <div class="columns is-variable is-8 is-mobile">
                      <div class="column is-one-quarter">
                        <div class="addon-image" :style="bannerStyle">
                          <img v-if="item.addon_banner_image"
                               :src="item.addon_banner_image.image_url"
                               :width="item.addon_banner_image.width"
                               :height="item.addon_banner_image.height">
                        </div>
                      </div>

                      <div class="column">
                        <div class="content">
                          <p class="title is-6" :style="{'margin-bottom':'24px'}">{{name}}</p>

                          <p class="subtitle is-7">{{shortDescription}}</p>

                          <!-- <p class="has-text-right"><span class="has-text-lighter">Value of £</span> {{item.objectives.display_value}}</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="upload-image-file">
                  <div class="block has-text-centered">
                      Upload an Image for this Gift With Purchase
                  </div>
                  <b-field>
                    <image-upload-field v-model="item.addon_banner_image" :retailer-id="retailerId"></image-upload-field>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main-column>
    </validation-observer>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import NotificationBar from '@/components/NotificationBar'
import AddonObjectivesEditor from '@/components/AddonObjectivesEditor'
import ItemEditor from '@/utils/mixins/ItemEditor'
import ImageUploadField from '@/components/ImageUploadField'
// import nibble from '@/utils/nibble'

export default {
  name: 'Addon',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, NotificationBar, AddonObjectivesEditor, ImageUploadField },
  data () {
    return {
      item: {
        localized_name: {},
        product_id: '',
        localized_short_description: {},
        addon_type: 'sweetener',
        objectives: {
          synthetic_value: '0.00',
          display_value: '5.00'
        },
        addon_banner_background_color: '#F0F2F5',
        addon_banner_image: null
      },
      disableSynthetic: true
    }
  },
  computed: {
    name () {
      var name = null
      if (this.item.localized_name != null && this.availableLanguages.length > 0) {
        name = this.item.localized_name[this.availableLanguages[0].code]
      }
      if (name == null) {
        name = 'Gift with Purchase'
      }
      return name
    },
    shortDescription () {
      var shortDescription = null
      if (this.item.localized_short_description != null && this.availableLanguages.length > 0) {
        shortDescription = this.item.localized_short_description[this.availableLanguages[0].code]
      }
      if (shortDescription == null) {
        shortDescription = ''
      }
      return shortDescription
    },
    listPath () {
      return `/retailers/${this.retailerId}/addon_items`
    },
    newPath () {
      return `/retailers/${this.retailerId}/addon_items/new`
    },
    itemPath () {
      return `/retailers/${this.retailerId}/addon_items/${this.itemId}`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/addon_items`
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}/addon_items/${this.itemId}`
    },
    itemName () {
      return 'Gift With Purchase'
    },
    itemsName () {
      return 'Gifts With Purchase'
    },
    shouldShowObjectivesEditor () {
      return this.context !== 'admin:algorithm'
    },
    bannerStyle () {
      var borderColor = this.item.addon_banner_background_color
      if (borderColor == null || borderColor.toLowerCase() === 'white' || borderColor.match(/^#f+$/)) {
        borderColor = '#E7EBEE'
      }
      return {
        backgroundColor: this.item.addon_banner_background_color,
        borderColor: borderColor
      }
    },
    addonType () {
      return this.item.addon_type
    },
    availableLanguages () {
      if (this.$store.state.retailer != null) {
        return this.$store.state.retailer.languages
      } else {
        return [{ code: 'en-GB', name: 'English' }]
      }
    }
  },
  watch: {
    addonType () {
      if (['sweetener', 'free_shipping'].includes(this.addonType)) {
        this.disableSynthetic = true
        this.item.objectives.synthetic_value = '0.00'
      } else {
        this.disableSynthetic = false
      }
    }
  },
  methods: {
    itemToParams () {
      var params = {
        retailer_id: (this.retailer != null ? this.retailer.retailer_id : this.retailerId),
        localized_name: this.item.localized_name,
        retailer_product_id: this.item.retailer_product_id,
        localized_short_description: this.item.localized_short_description,
        addon_type: this.item.addon_type,
        display_value: this.item.objectives.display_value,
        synthetic_value: this.item.objectives.synthetic_value,
        addon_banner_image: this.item.addon_banner_image
      }

      return params
    },
    dataToItem (data) {
      var item = {
        localized_name: data.localized_name,
        retailer_product_id: data.retailer_product_id,
        localized_short_description: data.localized_short_description,
        addon_type: data.addon_type,
        objectives: {
          display_value: data.display_value,
          synthetic_value: data.synthetic_value
        },
        addon_banner_image: data.addon_banner_image
      }
      if (data.retailer != null && this.retailerId == null) {
        this.retailer = data.retailer
      }
      return item
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.addon-image {
  border-radius: 8px;
  height: 80px;
  width: 80px;
  border: 1px solid;
}
.upload-image-file {
  margin-bottom: 55px;
  margin-left: 193px;
  margin-right: 162px;
  margin-top: 12px;
  width: 357px;
  height: 64px
}
.addon-box {
  margin-top: 12px;
  margin-bottom: 24px;
  margin-right: auto;
  height: 168px;
  width: 368px;
}
</style>
