<template>
    <div class="card-content contrast">
      <h2 class="title is-2">Objectives</h2>
      <div class="objectives">
        <div class="columns">
          <div class="column is-half">
              <div class="objectives-objective-select-fields">
                  <ValidationProvider name="Objective objective Select" v-slot="{ errors }">
                    <b-field label="Match retailer rule objective:" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-select v-model="objectives.objective" placeholder="Any" expanded @input="input">
                        <option :value="null">Any</option>
                        <option value="increase_conversion">Increase conversion</option>
                        <option value="balance_performance">Balance performance</option>
                        <option value="preserve_margin">Preserve Margin</option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
              </div>
          </div>
          <div class="column is-half is-narrow percentage">
              <ValidationProvider rules="double" name="Target Discount" v-slot="{ errors }">
                  <b-field label="Target Discount (as a percentage of discount threshold)" :message="showMessage(errors, targetDescription)" :type="messageType(errors)">
                      <p class="control">
                          <span class="button is-static" disabled>%</span>
                      </p>
                      <b-input v-model="objectives.target_discount_percentage" @input="input"/>
                  </b-field>
              </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ObjectiveEditorAdmin',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      objectives: this.objectivesFromValue(this.value)
    }
  },
  watch: {
    value () {
      this.objectives = this.objectivesFromValue(this.value)
    }
  },
  computed: {
  },
  methods: {
    showMessage (errors, message) {
      if (errors === null) {
        return message
      } else {
        return [message].concat(errors)
      }
    },
    messageType (errors) {
      if (errors === null) {
        return {}
      } else {
        if (errors.length === 0) {
          return {}
        } else {
          return { 'is-danger': errors[0] }
        }
      }
    },
    objectivesFromValue (value) {
      return {
        target_discount_percentage: value.target_discount_percentage,
        objective: value.objective
      }
    },
    valueFromObjectives (objectives) {
      return {
        target_discount_percentage: objectives.target_discount_percentage,
        objective: objectives.objective
      }
    },
    input () {
      this.$emit('input', this.valueFromObjectives(this.objectives))
    }
  }
}
</script>

<style lang="scss" scoped>
.card-content {
    border-radius: 15px;
}
.objectives {
    .column.percentage {
        .field {
            .input {
                border-left: none;
            }
            .button {
                // padding-right: 0;
                border-right: none;
            }
        }
    }
}

</style>
