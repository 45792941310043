<template>
  <div :class="notificationClass">
    <div class="media">
      <div class="media-left" v-if="icon">
        <material-icon :icon="iconName" size="large"></material-icon>
      </div>
      <div class="media-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  name: 'TagPicker',
  components: { MaterialIcon },
  props: ['type', 'icon'],
  computed: {
    notificationClass () {
      if (this.type != null) {
        return `notification ${this.type}`
      } else {
        return 'notification'
      }
    },
    iconName () {
      if (this.icon != null) {
        return this.icon
      } else if (this.type === 'is-danger') {
        return 'error_outline'
      } else {
        return 'help_outline'
      }
    }
  }
}
</script>
