<template>
  <page-layout :retailer-id="retailerId" active-menu-item="addon_items">
    <main-column :breadcrumbs="breadcrumbs" page-title="Gift With Purchase" content-class="main-column-sub-columns columns">
      <template #header-right v-if="items.length > 0">
        <p class="level-item buttons">
          <b-button type="is-text is-icon" :disabled="selectedItems.length === 0" @click="deleteBatch">
            <material-icon icon="delete_forever" size="large"></material-icon>
          </b-button>
          <b-button type="is-primary is-rounded" tag="router-link" :to="newAddOnLink">New Gift With Purchase</b-button>
        </p>
      </template>
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no gifts"
            :showButton="true"
            buttonText="New Gift With Purchase"
            :link="newAddOnLink">
            <template v-slot:text>
              Use this section to design Gifts With Purchase that Nibble can offer during negotiations. Want to offer a bonus gift to customers to help difficult negotiations succeed? You can set that up here.
            </template>
          </empty-list-indicator>
          <b-table v-else
            :data="items"
            :checked-rows.sync="selectedItems"
            draggable
            checkable
            hoverable
            selectable
            @cellclick="clickCell"
            @dragstart="dragstart"
            @dragover="dragover"
            @dragleave="dragleave">
            <b-table-column field="name" label="Name" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ props.row.name }}
                </strong>
              </router-link>
            </b-table-column>
            <b-table-column field="retailer_product_id" label="Product ID" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ props.row.retailer_product_id }}
                </strong>
              </router-link>
            </b-table-column>
            <!-- <b-table-column field="synthetic_value" label="Synthetic Value" width="140" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  £{{ props.row.synthetic_value }}
                </router-link>
              </template>
            </b-table-column> -->
            <b-table-column field="display_value" label="Display Value" width="120" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  £{{ props.row.display_value }}
                </router-link>
              </template>
            </b-table-column>
            <!-- <b-table-column field="addon_type" label="Addon Type" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  {{ addonTypes[props.row.addon_type] }}
                </router-link>
              </template>
            </b-table-column> -->
            <b-table-column label="" v-slot="props">
              <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                <template #trigger>
                  <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                </template>
                <b-dropdown-item aria-role="listitem" @click="editItem(props.row)">Edit</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="deleteItem(props.row)">Delete</b-dropdown-item>
              </b-dropdown>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ItemList from '@/utils/mixins/ItemList'
import nibble from '@/utils/nibble'
import MaterialIcon from '@/components/MaterialIcon'

export default {
  name: 'Addon',
  mixins: [ItemList],
  components: { PageLayout, MainColumn, EmptyListIndicator, MaterialIcon },
  data () {
    return {
      retailerId: this.$route.params.retailerId,
      loading: false,
      items: [],
      selectedItems: [],
      draggingRow: null,
      draggingRowIndex: null,
      addonTypes: {
        sweetener: 'Gift With Purchase',
        bundle_item: 'Bundle Item',
        free_shipping: 'Free Shipping'
      }
    }
  },
  computed: {
    newAddOnLink () {
      return `/retailers/${this.retailerId}/addon_items/new`
    },
    listPath () {
      return `/retailers/${this.retailerId}/addon_items`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/addon_items`
    },
    itemsKey () {
      return 'addon_items'
    },
    itemIdKey () {
      return 'addon_item_id'
    },
    itemName () {
      return 'Gift With Purchase'
    },
    itemsName () {
      return 'Gift With Purchase'
    },
    showEmptyList () {
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    },
    activeColumnIndex () {
      var index = 3
      if (this.showAlgorithmColumn) {
        index = index + 1
      }
      if (this.showDiscountColumn) {
        index = index + 1
      }
      if (this.showWalkawayColumn) {
        index = index + 1
      }
      return index
    },
    menuColumnIndex () {
      var index = this.activeColumnIndex
      if (this.canToggleActive) {
        index = index + 1
      }
      return index
    }
  },
  methods: {
    itemPath (item) {
      return `/retailers/${this.retailerId}/addon_items/${item.addon_item_id}`
    },
    deleteItem (item) {
      nibble.delete(this.itemApiPath(item), { context: this.context }, this.$store.getters.authHeaders)
        .then((response) => {
          Toast.open(`${this.itemName} Deleted`, { type: 'is-primary' })
          this.fetchData()
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    clickCell (row, column, rowIndex, columnIndex) {
      if (this.canToggleActive && columnIndex === this.activeColumnIndex) {
        return
      }
      if (columnIndex === this.menuColumnIndex) {
        return
      }
      this.clickRow(row)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
