<template>
  <!--DONE: Product Modal -->
  <b-modal :active="active" :can-cancel="false">
    <div class="box">
      <header>
        <h2 class="modal-card-title title-is-2">Search Products</h2>
        <b-button
          type="is-primary is-rounded"
          @click="saveProducts"
          v-if="selectedProducts.length > 0">{{addButtonText}}</b-button>
        <b-button class="close-btn" @click="closeModal">
          <b-icon class="modal-close-icon" icon="close"></b-icon>
        </b-button>
      </header>
      <!--DONE: Product Search Input Field -->
      <form @submit="search">
        <b-input
          id="product-search"
          v-model="searchText"
          field="name"
          rounded
          clearable
          placeholder="Product, variants, etc..."
          icon-right="magnify"
          icon-right-clickable
          @icon-right-click="search"
          @keyup.enter="search">
        </b-input>
      </form>

      <!--DONE: Product Table -->
      <div class="product-results-table" v-if="products && products.length > 0">
        <b-table
          :data="products"
          ref="table"
          detailed
          checkable
          :header-checkable="false"
          sticky-checkbox
          :checked-rows.sync="selectedProducts"
          :is-row-checkable="(row) => row.sub_products.length <= 1"
          checkbox-position="right"
          checkbox-type="is-primary"
          @check="toggleProduct"
          :show-detail-icon="false"
          custom-detail-row
          @details-open="expandedRow"
          @details-close="collapsedRow">

          <b-table-column
            field="name"
            v-slot="props"
            visible
            label="Name">
            <div class="columns is-variable is-8">
              <div class="column is-one-quarter">
                <div class="product-image">
                  <img
                    v-if="props.row.thumbnail_url"
                    :src="props.row.thumbnail_url"
                    alt="Product Thumbnail Image" />
                </div>
              </div>
              <div class="column" id="product-col-info">
                <div class="content" id="product-col-content">
                  <p
                    class="title is-6">{{props.row.name != '' ? props.row.name : 'Product Name'}}</p>
                  <p class="subtitle is-7 has-text-lighter"></p>
                </div>
              </div>
            </div>
          </b-table-column>
          <b-table-column
            field="SKU"
            v-slot="props"
            visible
            label="SKU">
            <div v-if="props.row.sub_products">
              <span v-if="props.row.sub_products.length > 1" class="subproducts-variants">
                <b-button class="variants-button" type="is-text" @click="props.toggleDetails(props.row)" :icon-left="isProductExpanded(props.row) ? 'chevron-up' : 'chevron-down'">
                  Variants
                </b-button>
              </span>
              <span v-else class="sub-sku">
                {{props.row.sub_products[0].sku}}
              </span>
            </div>
            <div v-if="props.row.sku">
              <span class="sub-sku">
                {{props.row.sku}}
              </span>
            </div>
          </b-table-column>

          <template slot="detail" slot-scope="props" class="sub-products-row">
            <tr
              v-for="subRow in props.row.sub_products"
              :key="subRow.retailer_product_id"
              class="detailed-row" >
              <td>
                <div class="columns is-variable is-8">
                  <div class="column is-one-quarter">
                    <div class="product-image">
                      <img
                        v-if="props.row.thumbnail_url"
                        :src="props.row.thumbnail_url"
                        alt="Product Thumbnail Image" />
                    </div>
                  </div>
                  <div class="column" id="product-col-info">
                    <div class="content" id="product-col-content">
                      <p class="title is-6">{{subRow.name != '' ? subRow.name : 'Product Name'}}</p>
                      <p class="subtitle is-7 has-text-lighter"></p>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{subRow.sku}}</td>
              <td class="checkbox-cell is-sticky">
                <label class="b-checkbox checkbox">
                  <input type="checkbox" :checked="selectedProducts.indexOf(subRow) !== -1 || selectedProducts.indexOf(props.row) !== -1" @change="toggleVariant(props.row, subRow, $event)" />
                  <span class="check"></span>
                  <span class="control-label"></span>
                </label>
              </td>
            </tr>
          </template>
        </b-table>
        <section id="pagination" v-if="hasNextPage || hasPreviousPage">
          <nav class="level">
            <div class="level-left">
              <p class="level-item buttons" v-if="hasPreviousPage">
                <b-button type="is-rounded" @click="previousPage">Previous</b-button>
              </p>
            </div>
            <div class="level-item">
              Page {{page}} of {{totalPage}}
            </div>
            <div class="level-right">
              <p class="level-item buttons" v-if="hasNextPage">
                <b-button type="is-rounded" @click="nextPage">Next</b-button>
              </p>
            </div>
          </nav>
        </section>
      </div>
    </div>
  </b-modal>
</template>

<script>
import nibble from '@/utils/nibble'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  props: {
    retailerId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      searchText: '',
      endCursor: null,
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      active: false,
      products: [],
      selectedProducts: [],
      expandedProducts: new Set(),
      totalPage: 1,
      page: 1,
      itemsPerPage: 4
    }
  },
  computed: {
    addButtonText () {
      return 'ADD ' + Object.keys(this.selectedProducts).length + (Object.keys(this.selectedProducts).length === 1 ? ' PRODUCT' : ' PRODUCTS')
    },
    resultsSummary () {
      return 'X of Y Results'
    }
  },
  methods: {
    search (e) {
      e.preventDefault()
      this.fetch(this.searchText, null, null)
    },
    previousPage () {
      this.fetch(this.searchText, this.startCursor, null)
      this.page -= 1
    },
    nextPage () {
      this.fetch(this.searchText, null, this.endCursor)
      this.page += 1
    },
    fetch (searchText, startCursor, endCursor) {
      console.log(`Fetching ${searchText}`)
      /*
        Except for the products data, the response also has 'endCursor', 'startCursor', 'hasNextPage', 'hasPreviousPage'.
        To show next/previous buttons, can simply check the value of 'hasNextPage' and 'hasPreviousPage' in response.
        If user clicks the next button, should include 'endCursor' in the parameters.
        If user clicks the previous button, should include 'startCursor' in the parameters.
        If user enters search query, please return 'product_query' in the parameters
      */
      var params = { product_query: searchText }
      if (endCursor != null) {
        params.endCursor = endCursor
      }
      if (startCursor != null) {
        params.startCursor = startCursor
      }
      nibble.get(`/retailers/${this.retailerId}/store/products`, params, this.$store.getters.authHeaders)
        .then((response) => {
          console.log(response.data)
          this.products = response.data.results.products
          this.startCursor = response.data.results.startCursor
          this.endCursor = response.data.results.endCursor
          this.hasNextPage = response.data.results.hasNextPage
          this.hasPreviousPage = response.data.results.hasPreviousPage
          this.totalPage = Math.ceil(response.data.results.totalItemCount / this.itemsPerPage)
          if (!this.products) {
            Toast.open('No product found!', { type: 'is-warning' })
          }
        })
        .catch((error) => {
          console.log(error)
          Toast.open('No product found!', { type: 'is-warning' })
        })
    },
    saveProducts () {
      console.log(this.selectedProducts)
      this.$emit('select', this.selectedProducts)
      this.closeModal()
    },
    isProductExpanded (product) {
      return this.expandedProducts.has(product)
    },
    expandedRow (product) {
      this.expandedProducts.add(product)
    },
    collapsedRow (product) {
      this.expandedProducts.delete(product)
    },
    toggleProduct (list, product, $event) {
      var newSelectedProducts = [...list]
      if (list.indexOf(product) !== -1) {
        if (product.sub_products) {
          // product has been added to the list; remove all variants
          product.sub_products.forEach((sub) => {
            newSelectedProducts.splice(newSelectedProducts.indexOf(sub), 1)
          })
          this.selectedProducts = newSelectedProducts
        } else {
          this.selectedProducts = product
        }
      }
    },
    toggleVariant (product, subProduct, $event) {
      var newSelectedProducts = []
      if ($event.target.checked) {
        // add variant
        newSelectedProducts = [...this.selectedProducts]
        newSelectedProducts.push(subProduct)
        // check if all variants are now selected
        if (product.sub_products.every((sub) => newSelectedProducts.indexOf(sub) !== -1)) {
          // all variants are selected; select product and remove individual variants
          newSelectedProducts.push(product)
          product.sub_products.forEach((sub) => {
            newSelectedProducts.splice(newSelectedProducts.indexOf(sub), 1)
          })
        }
      } else {
        // remove variant
        if (this.selectedProducts.indexOf(product) !== -1) {
          // whole product was selected; deselect it and select specific variants
          newSelectedProducts = this.selectedProducts.filter((p) => p !== product)
          product.sub_products.forEach((sub) => {
            if (sub !== subProduct) {
              newSelectedProducts.push(sub)
            }
          })
        } else {
          // Individual variant was selected; deselect it
          newSelectedProducts = this.selectedProducts.filter((p) => p !== subProduct)
        }
      }
      this.selectedProducts = newSelectedProducts
    },
    show () {
      this.products = []
      this.selectedProducts = []
      this.expandedProducts = new Set()
      this.startCursor = null
      this.endCursor = null
      this.hasNextPage = false
      this.hasPreviousPage = false
      this.active = true
    },
    closeModal () {
      this.active = false
      this.searchText = ''
      this.page = 1
    },
    toggle (row) {
      this.$refs.table.toggleDetails(row)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#my-table .table {
  background: red;
}
.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 800px;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  padding: 0px;
  gap: 9px;
  height: 32px;
  background-color: #fff;
}

header h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.modal-content {
  max-width: 800px;
  height: 75%;
}

.modal-close-icon {
  color: #668099;
  background-color: #fff;
  border: none;
}

.close-btn {
  border: none;
}

.close-btn:focus {
  outline: none;
  box-shadow: none;
}

.search-field {
  box-sizing: border-box;
  width: 736px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #c2ccd6;
  border-radius: 24px;
}

.number-of-results {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1F262E;
  padding: 0px 0px 16px;
}

.product-image {
  background-color: rgb(240, 242, 245);
  border-radius: 8px;
  height: 56px;
  width: 56px;
}

.product-image img {
  object-fit: cover;
}

#product-col-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // padding-left: 2.2rem;
}

#product-col-content {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  row-gap: 0.35rem;
}

#product-col-content .title {
  font-weight: 500;
  font-size: 1rem;
}

#product-col-content .subtitle {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}

.columns.is-mobile > .column.is-one-quarter {
  width: 14%;
}

.table-chevron-icon {
  color: #0B66C1;
  background-color: transparent;
  border: none;
}

.dropdown-icon {
  background-color: transparent;
  border: none;
  padding-left: 0px;
}

.dropdown-icon:focus {
  outline: none;
  box-shadow: none;
}

.variants-button {
  text-transform: none;
  text-decoration: none;
  color: #0B66C1;
  &:hover {
    text-decoration: none;
    color: #0B66C1;
  }
}

.subproducts-variants {
  display: flex;
  align-items: center;
  color: #0B66C1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.sub-products-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 46px;
  width: 712px;
  height: 72px;
  background: #FFFFFF;
  border: 1px solid #E0E6EB;
  border-radius: 8px;
}

.product-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
  width: 736px;
  height: 80px;
  background: #F9FAFB;
  border-radius: 16px;
  margin-bottom: 32px;
}
.lightbulb-icon {
  color: #1976D2;
}

.b-table::v-deep table.table tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 70%;
}

.b-table::v-deep table.table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.b-table::v-deep table.table tbody {
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.b-table::v-deep .table .checkbox-cell .checkbox .check {
  border: 2px solid #668099;
}

.sub-sku {
  color: #668099;
}

.columns.is-variable.is-8::v-deep {
   --columnGap: 0rem;
}

.column.is-one-quarter::v-deep {
  width: 15%;
}

.b-table::v-deep table.table tbody tr td {
    border-bottom: 1px solid #D5DDE5;
    width: 100%;
    padding: 8px;
}

.b-table::v-deep table.table tr {
    height: 100%;
    width: 100%;
}

.b-table::v-deep table.table tbody tr.is-checked td {
    background-color: #ffffff;
    border-top: 1px solid #D5DDE5;
    border-bottom: 1px solid #D5DDE5;
}

.b-table::v-deep table.table tbody tr.is-checked td:last-child {
    border-right: 1px solid #D5DDE5;
}

.b-table::v-deep table.table tbody tr td:last-child {
    padding-right: 20px;
}

.b-table::v-deep table.table tbody tr.is-checked td:first-child {
    border-left: 1px solid #D5DDE5;
    padding-left: 8px;
}

.b-table::v-deep  table.table tr.detailed-row {
  width: 96%;
  margin-left: 30px;
}

.b-table::v-deep table.table thead {
  display: flex;
  width: 100%;
  padding-right: 10px;
}

.b-table::v-deep table.table thead tr {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}

.b-table::v-deep table.table tbody tr td {
  width: 100%;
}

.b-table::v-deep table.table thead tr th:first-child {
  width: 65%;
}

.b-table::v-deep table.table thead tr th:nth-child(2) {
  width: 200px;
}
</style>
