<template>
  <div class="xnib-container" :style="containerStyle">
    <button :class="buttonClass" :style="buttonStyles">
      <div class="xnib-button-logo-container">
        <svg class="xnib-button-logo" viewBox="0 0 24 24" :width="buttonStyles['--xnib-button-logo-size']" :height="buttonStyles['--xnib-button-logo-size']" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Nibble Logo</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-667.000000, -484.000000)">
              <g id="Nibble-Logo" transform="translate(667.000000, 484.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <path d="M9.49997685,2 C9.49997685,6.14220905 6.1421707,9.50023146 2,9.50023146 L2,9.50023146 L2,14.4999537 C2,18.6423479 5.35780615,22 9.49997685,22 L9.49997685,22 L14.5000231,22 C18.6421939,22 22,18.6423479 22,14.4999537 L22,14.4999537 L22,9.50023146 C22,5.35783724 18.6421939,2 14.5000231,2 L14.5000231,2 L9.49997685,2 Z M13.8556443,13.4198685 C13.8556443,11.9453754 15.0510781,10.7501157 16.5253724,10.7501157 L16.5253724,10.7501157 C17.9998519,10.7501157 19.1949153,11.9453754 19.1949153,13.4198685 L19.1949153,13.4198685 C19.1949153,14.8941765 17.9998519,16.0894362 16.5253724,16.0894362 L16.5253724,16.0894362 C15.0510781,16.0894362 13.8556443,14.8941765 13.8556443,13.4198685 L13.8556443,13.4198685 Z M5.10579478,13.4198685 C5.10579478,11.9453754 6.30085825,10.7501157 7.7753377,10.7501157 L7.7753377,10.7501157 C9.24981715,10.7501157 10.4448806,11.9453754 10.4448806,13.4198685 L10.4448806,13.4198685 C10.4448806,14.8941765 9.24981715,16.0894362 7.7753377,16.0894362 L7.7753377,16.0894362 C6.30085825,16.0894362 5.10579478,14.8941765 5.10579478,13.4198685 L5.10579478,13.4198685 Z" id="Fill-1"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <span>{{ buttonText }}</span>
    </button>
    <div v-if="showTooltip" class="xnib-tooltip" :style="tooltipStyles">
      <div class="xnib-tooltip-inner">{{ tooltipText }}</div>
      <span class="xnib-tooltip-container">
        <span class="xnib-tooltip-popover">
          {{ tooltipPopupText }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThemePreview',
  props: {
    theme: {
      type: Object,
      required: true
    }
  },
  computed: {
    containerStyle () {
      var namespace = this.theme.container
      var prefix = '--xnib-container-'
      return {
        [`${prefix}max-width`]: namespace.maxWidth,
        [`${prefix}min-width`]: namespace.minWidth,
        [`${prefix}margin`]: namespace.margin,
        [`${prefix}padding`]: namespace.padding,
        [`${prefix}float`]: namespace.float
      }
    },
    buttonClass () {
      var classes = ['xnib-button']
      var effect = this.theme.button.effect
      if (effect !== 'none') {
        classes.push(`xnib-button-effect-${effect}`)
      }
      return classes
    },
    buttonText () {
      return this.theme.button.text
    },
    buttonStyle () {
      var namespace = this.theme.button
      var prefix = '--xnib-button-'
      return {
        [`${prefix}background-color`]: namespace.backgroundColor,
        [`${prefix}border`]: namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius,
        [`${prefix}box-shadow`]: namespace.boxShadow,
        [`${prefix}height`]: namespace.height,
        [`${prefix}margin`]: namespace.margin,
        [`${prefix}padding`]: namespace.padding,
        [`${prefix}transition`]: namespace.transition
      }
    },
    buttonHoverStyle () {
      var namespace = this.theme.button.hover
      var prefix = '--xnib-button-hover-'
      return {
        [`${prefix}color`]: namespace.color === 'auto' ? this.theme.button.color : namespace.color,
        [`${prefix}logo-color`]: namespace.logoColor === 'auto' ? this.theme.button.logo.color : namespace.logoColor,
        [`${prefix}background-color`]: namespace.backgroundColor === 'auto' ? this.theme.button.backgroundColor : namespace.backgroundColor,
        [`${prefix}border`]: namespace.border === 'auto' ? this.theme.button.border : namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius === 'auto' ? this.theme.button.borderRadius : namespace.borderRadius,
        [`${prefix}box-shadow`]: namespace.boxShadow === 'auto' ? this.theme.button.boxShadow : namespace.boxShadow
      }
    },
    buttonLogoStyle () {
      var namespace = this.theme.button.logo
      var prefix = '--xnib-button-logo-'
      return {
        [`${prefix}color`]: namespace.color,
        [`${prefix}size`]: parseInt(namespace.size, 10) || 0,
        [`${prefix}margin-right`]: namespace.marginRight,
        [`${prefix}display`]: namespace.show === false || namespace.show === 'false' ? 'none' : 'flex'
      }
    },
    buttonTextStyle () {
      var namespace = this.theme.button
      var prefix = '--xnib-button-text-'
      return {
        [`${prefix}font-family`]: namespace.fontFamily,
        [`${prefix}font-weight`]: namespace.fontWeight,
        [`${prefix}font-style`]: namespace.fontStyle,
        [`${prefix}font-size`]: namespace.fontSize,
        [`${prefix}font-smoothing`]: namespace.fontSmoothing,
        [`${prefix}font-stretch`]: namespace.fontStretch,
        [`${prefix}color`]: namespace.color,
        [`${prefix}letter-spacing`]: namespace.letterSpacing,
        [`${prefix}text-transform`]: namespace.textTransform
      }
    },
    buttonStyles () {
      return {
        ...this.buttonStyle,
        ...this.buttonHoverStyle,
        ...this.buttonLogoStyle,
        ...this.buttonTextStyle
      }
    },
    tooltipStyle () {
      var namespace = this.theme.infoText
      var prefix = '--xnib-tooltip-'
      return {
        [`${prefix}font-family`]: namespace.fontFamily,
        [`${prefix}font-weight`]: namespace.fontWeight,
        [`${prefix}font-size`]: namespace.fontSize,
        [`${prefix}color`]: namespace.color,
        [`${prefix}background-color`]: namespace.backgroundColor,
        [`${prefix}border`]: namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius,
        [`${prefix}height`]: namespace.height,
        [`${prefix}margin-bottom`]: namespace.marginBottom
      }
    },
    tooltipHoverStyle () {
      var namespace = this.theme.infoText.hover
      var prefix = '--xnib-tooltip-hover-'
      return {
        [`${prefix}color`]: namespace.color === 'auto' ? this.theme.infoText.color : namespace.color,
        [`${prefix}background-color`]: namespace.backgroundColor === 'auto' ? this.theme.infoText.backgroundColor : namespace.backgroundColor,
        [`${prefix}border`]: namespace.border === 'auto' ? this.theme.infoText.border : namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius === 'auto' ? this.theme.infoText.borderRadius : namespace.borderRadius
      }
    },
    tooltipText () {
      return this.theme.infoText.text
    },
    showTooltip () {
      return this.theme.infoText.enabled === 'true'
    },
    tooltipStyles () {
      return {
        ...this.tooltipStyle,
        ...this.tooltipHoverStyle
      }
    },
    tooltipPopupText () {
      return this.theme.infoPopup.text
    }
  }
}
</script>

<style scoped>
.xnib-container {
  display: block;
  width: 100%; /* Only for preview */
  /* Customizable styling */
  max-width: var(--xnib-container-max-width);
  min-width: var(--xnib-container-min-width);
  margin: var(--xnib-container-margin);
  padding: var(--xnib-container-padding);
  float: var(--xnib-container-float);
}

.xnib-container,
  .xnib-container ::before,
  .xnib-container ::after {
    box-sizing: border-box; }
  .xnib-container ::after {
    clear: both;
}

.xnib-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  /* Customizable styling */
  background-color: var(--xnib-button-background-color);
  border: var(--xnib-button-border);
  border-radius: var(--xnib-button-border-radius);
  box-shadow: var(--xnib-button-box-shadow);
  height: var(--xnib-button-height);
  max-width: var(--xnib-button-max-width);
  min-width: var(--xnib-button-min-width);
  margin: var(--xnib-button-margin);
  padding: var(--xnib-button-padding);
  transition: var(--xnib-button-transition); }

  .xnib-button .xnib-button-logo-container {
    display: var(--xnib-button-logo-display);
    /* Customizable styling */
    width: var(--xnib-button-logo-size);
    height: var(--xnib-button-logo-size);
    margin-right: var(--xnib-button-logo-margin-right); }

    .xnib-button .xnib-button-logo path {
      /* Customizable styling */
      fill: var(--xnib-button-logo-color); }

  .xnib-button span {
    /* Customizable styling */
    font-family: var(--xnib-button-text-font-family);
    font-weight: var(--xnib-button-text-font-weight);
    font-style: var(--xnib-button-text-font-style);
    font-size: var(--xnib-button-text-font-size);
    color: var(--xnib-button-text-color);
    -webkit-font-smoothing: var(--xnib-button-text-font-smoothing); /* macOS only */
    font-stretch: var(--xnib-button-text-font-stretch);
    letter-spacing: var(--xnib-button-text-letter-spacing);
    text-transform: var(--xnib-button-text-text-transform); }

  .xnib-button:hover {
    /* Customizable styling */
    background-color: var(--xnib-button-hover-background-color);
    border : var(--xnib-button-hover-border);
    border-radius: var(--xnib-button-hover-border-radius);
    box-shadow: var(--xnib-button-hover-box-shadow); }

  .xnib-button:hover span {
    /* Customizable styling */
    color: var(--xnib-button-hover-color); }

  .xnib-button:hover .xnib-button-logo path {
    /* Customizable styling */
    fill: var(--xnib-button-hover-logo-color);
}

/* Button effects */

@keyframes pulse {
  0%, 66% { transform: scale(1); }
  33% { transform: scale(1.05); }
}

.xnib-button-effect-pulse {
  animation: 1s ease-in pulse infinite;
  transform-origin: center; }

  .xnib-button-effect-pulse:hover {
    animation: none;
}

.xnib-tooltip {
  position: relative;
  text-align: center;
  letter-spacing: 0.2px;
  cursor: pointer;
  /* Customizable styling */
  font-family: var(--xnib-tooltip-font-family);
  font-weight: var(--xnib-tooltip-font-weight);
  font-size: var(--xnib-tooltip-font-size);
  color: var(--xnib-tooltip-color);
  background-color: var(--xnib-tooltip-background-color);
  border: var(--xnib-tooltip-border);
  border-radius: var(--xnib-tooltip-border-radius);
  height: var(--xnib-tooltip-height);
  margin-bottom: var(--xnib-tooltip-margin-bottom); }

  .xnib-tooltip:hover {
    /* Customizable styling */
    color: var(--xnib-tooltip-hover-color);
    background-color: var(--xnib-tooltip-hover-background-color);
    border: var(--xnib-tooltip-hover-border);
    border-radius: var(--xnib-tooltip-hover-border-radius); }

  .xnib-tooltip .xnib-tooltip-inner {
    display: inline-block;
}

.xnib-tooltip-container {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px; }

  .xnib-tooltip-popover {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 18px;
    transform: translateY(16px);
    box-shadow: 0 2px 32px rgba(0,0,0,.2);
    border-radius: 16px;
    text-align: center;
    box-sizing: border-box;
    display: none;
    font-size: 14px;
    line-height: 1.45;
    color: rgba(0,0,0,.6);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    z-index: 2147483647; }

  .xnib-tooltip:hover .xnib-tooltip-popover {
    display: block; }

  .xnib-tooltip-popover:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 16px 8px;
    border-color: transparent transparent #ffffff transparent;
}
</style>
