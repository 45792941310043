<template>
  <div class="expression">
    <video muted playsinline autoplay @ended="videoEnded" v-if="showVideo">
      <source v-for="(source, sourceIndex) in videoSources" :key="sourceIndex" :src="source.url" :type="source.type">
    </video>
    <img :src="imageSourceUrl" width="400" height="224" :style="imageStyle">
  </div>
</template>

<script>

export default {
  name: 'Expression',
  props: ['expression'],
  data () {
    return {
      videoPlaying: true
    }
  },
  mounted () {
    if (this.isVideo) {
      this.videoPlaying = true
    }
  },
  computed: {
    showVideo () {
      return this.videoPlaying && this.isVideo
    },
    showImage () {
      return !this.videoPlaying || !this.isVideo
    },
    isVideo () {
      return this.videoSources.length > 0
    },
    imageSourceUrl () {
      const source = this.expression.sources.find((e) => e.type === 'image/svg+xml')
      return source.url
    },
    videoSources () {
      return this.expression.sources.filter((e) => e.type === 'video/mp4' || e.type === 'video/webm')
    },
    imageStyle () {
      if (this.showImage) {
        return ''
      } else {
        return 'width: 0; height: 0'
      }
    }
  },
  methods: {
    videoEnded () {
      this.videoPlaying = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

video {
  max-width: 100%;
  height: auto;
}
</style>
