<template>
    <div class="card-content contrast">
        <h2 class="title is-2">Objectives</h2>
        <div class="objectives">
            <div class="column is-full">
                <div class="objective-discount-treshold percentage">
                    <h4>What is your Discount Threshold?</h4>
                    <ValidationProvider rules="required" name="Discount Treshold" v-slot="{ errors }">
                        <b-field :message="showMessage(errors)" :type="messageType(errors)">
                            <p class="control">
                                <span class="button is-static" disabled>%</span>
                            </p>
                            <b-input v-model="objectives.walkaway_percentage" @input="input"/>
                            <div class="discount-description" v-html="discountTresholdDescription"></div>
                        </b-field>
                    </ValidationProvider>
                </div>
                <div class="objectives-objective-select">
                    <h4>What is your primary objective?</h4>
                    <div class="objectives-objective-select-fields">
                        <ValidationProvider rules="required" name="Objective objective Select" v-slot="{ errors }">
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-radio v-model="objectives.objective" :native-value="'increase_conversion'" @input="input">
                              Increase conversion
                            </b-radio>
                          </b-field>
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-radio v-model="objectives.objective" :native-value="'balance_performance'" @input="input">
                              Balance Performance
                            </b-radio>
                          </b-field>
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-radio v-model="objectives.objective" :native-value="'preserve_margin'" @input="input">
                              Preserve Margin
                            </b-radio>
                          </b-field>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-one-third">
                        <div class="objective-data">
                            <div class="objective-title" v-html="objectivesTitle"></div>
                            <div class="objective-description" v-html="objectivesDescription"></div>
                        </div>
                    </div>
                    <div class="column is-two-thirds">
                        <div class="objective-graph">
                            <img src="/images/objective-increase-conversion.svg" v-if="selectedIC" />
                            <img src="/images/objective-balance-performance.svg" v-if="selectedBP" />
                            <img src="/images/objective-preserve-margin.svg" v-if="selectedPM" />
                            <div class="average" v-if="showAverageValue">{{ averageValue }}%</div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-full" v-if="tresholdTooLow">
                        <div class="treshold-alert">
                           The Discount Threshold you set will not deliver a great negotiation experience with this Objective. For better results, try to increase the Discount Threshold or change your Objective.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ObjectivesEditor',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      objectives: this.objectivesFromValue(this.value)
    }
  },
  watch: {
    value () {
      this.objectives = this.objectivesFromValue(this.value)
    }
  },
  computed: {
    discountTresholdDescription () {
      return 'Nibble will never agree to a larger discount than this value.'
    },
    targetDescription: function () {
      return 'The average discount you want Nibble to achieve during negotiation'
    },
    walkawayDescription: function () {
      return 'The threshold below which Nibble will NEVER agree to a deal'
    },
    objectivesDescription () {
      const defaultText = {
        increase_conversion: '<strong>Nibble will be very accomodating, trying to convert as much as possible.</strong><p>The average discount for your customers will sit closer to your Discount Threshold, and several negotiations may end up hitting your Discount Threshold.</p>',
        balance_performance: '<strong>Nibble will  balance discounting and conversion rate depending on users’ negotiation style.</strong><p>The average discount for your customers will sit somewhere in the middle between 0% and your Discount Threshold.</p>',
        preserve_margin: '<strong>Nibble will try to minimise discounting, while still trying hard to convert.</strong><p>The average discount for your customers will sit far away from your Discount Threshold: this may reduce slightly the conversion rate, while preserving margin.</p>'
      }
      return ((this.objectives.objective in defaultText) ? defaultText[this.objectives.objective] : '')
    },
    objectivesTitle () {
      const defaultText = {
        increase_conversion: 'Increase conversion',
        balance_performance: 'Balance performance',
        preserve_margin: 'Preserve margin'
      }
      return ((this.objectives.objective in defaultText) ? defaultText[this.objectives.objective] : '')
    },
    selectedIC () {
      return (this.objectives.objective === 'increase_conversion')
    },
    selectedBP () {
      return (this.objectives.objective === 'balance_performance')
    },
    selectedPM () {
      return (this.objectives.objective === 'preserve_margin')
    },
    averageValue () {
      let average = this.objectives.walkaway_percentage
      if (undefined === average) {
        average = 0
      } else {
        average = parseInt(this.objectives.walkaway_percentage)
      }
      return ((this.objectives.walkaway_percentage) ? Math.round(average) : 0)
    },
    showAverageValue () {
      return (this.averageValue > 0 && (this.selectedIC || this.selectedBP || this.selectedPM))
    },
    tresholdTooLow () {
      return this.objectives.walkaway_percentage < 5
    }
  },
  methods: {
    showMessage (errors, message) {
      if (errors === null) {
        return message
      } else {
        return [message].concat(errors)
      }
    },
    messageType (errors) {
      if (errors === null) {
        return {}
      } else {
        if (errors.length === 0) {
          return {}
        } else {
          return { 'is-danger': errors[0] }
        }
      }
    },
    objectivesFromValue (value) {
      // console.log('OFV', value.walkaway_percentage, value.objective)
      return {
        walkaway_percentage: value.walkaway_percentage,
        objective: value.objective
      }
    },
    valueFromObjectives (objectives) {
      // console.log('VFO', objectives.walkaway_percentage, objectives.objective)
      return {
        walkaway_percentage: objectives.walkaway_percentage,
        objective: objectives.objective
      }
    },
    input () {
      this.$emit('input', this.valueFromObjectives(this.objectives))
    }
  }
}
</script>

<style lang="scss" scoped>
.card-content {
    border-radius: 15px;
}
.discount-description {
    display: inline-block;
    padding: 0.5em;
    margin-left: 1em;
}
.objective-discount-treshold {
    margin-bottom: 2em;
}
.objective-discount-treshold .control {
    max-width: 4.5em;
}
.objectives {
    .column.percentage {
        .field {
            .input {
                border-left: none;
            }
            .button {
                // padding-right: 0;
                border-right: none;
            }
        }
    }
}
.objectives {
    margin: -.75rem;
    display: flex;
}
.objectives-left {
    display: block;
    padding: .75rem;
    flex: none;
}
.objective {
    padding: .75rem;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}
.objective-discount-treshold,
.objectives-objective-select {
    h4 {
        /* H4 */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;

        /* identical to box height, or 133% */
        display: flex;
        align-items: center;

        color: #000000;
        margin-bottom: 0.75em;
    }
}
.objectives-objective-select {
    .objectives-objective-select-fields > span {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;

        width: 100%;

        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        label.b-radio.radio::v-deep {
            /* Auto layout */
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px;
            gap: 12px;

            width: 100%;

            /* White */
            background: #FFFFFF;
            border-radius: 16px;

            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 1;
            span.check {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0px;
                gap: 9px;

                width: 32px;
                height: 32px;

                /* Inside auto layout */
                flex: none;
                order: 0;
                flex-grow: 0;
            }
            span.control-label {
                width: calc(100% - 32px);
                height: 24px;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                /* identical to box height, or 150% */
                text-align: center;

                /* Grey L15 */
                color: #1F262E;

                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
    }
}
.objective-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 264px;
    height: 240px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    /* or 171% */

    /* Grey L30 */
    color: #3D4D5C;

    .objective-title::v-deep{
        font-size: 18px;
        line-height: 32px;

        /* identical to box height, or 178% */
        display: flex;
        align-items: center;

        /* Primary Default */
        color: #1976D2;
    }
}
.objective-graph {
    position: relative;
    width: 424px;
    .average {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        padding: 2px 8px;
        background: #E54B6A;
        border-radius: 16px;
        position: absolute;
        width: auto;
        display: block;
        left: 0px;
        bottom: 22px;
        &:after{
            content: ' OFF'
        }
    }
}
.treshold-alert {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    gap: 32px;
    width: 100%;
    background: #FBF4D5;
    border: 1px solid #F5A623;
    border-radius: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
@media (max-width: 1440px) {
    .objectives {
        display: block;
    }
}
</style>
