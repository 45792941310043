<template>
  <page-layout :retailer-id="retailerId" :active-menu-item="menuItem">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle" content-class="main-column-sub-columns columns">
      <template #header-right v-if="items.length > 0">
        <p class="level-item buttons">
          <b-button type="is-text is-icon" :disabled="selectedItems.length === 0" @click="deleteBatch">
            <material-icon icon="delete_forever" size="large"></material-icon>
          </b-button>
          <b-button type="is-primary is-rounded" tag="router-link" :to="newReasonLink">New Reason</b-button>
        </p>
      </template>
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Reasons"
            :showButton="canCreateReason"
            buttonText="New Reason"
            :link="newReasonLink">
            <template v-slot:text>
              You can create new reasons and edit any existing reason from this page.
            </template>
          </empty-list-indicator>
          <b-table v-else
                  :data="items"
                  :checked-rows.sync="selectedItems"
                  hoverable
                  checkable
                  selectable>
            <b-table-column field="reason_text" label="Text" cell-class="link-cell">
              <template #default="props">
                <router-link class="link" :to="itemPath(props.row)">
                  <strong>
                    {{ props.row.reason_text }}
                  </strong>
                </router-link>
              </template>
            </b-table-column>
            <b-table-column field="icons" label="Type" width="100" v-if="this.retailerId == null">
              <template #default="props">
                <span class="reason-icons">
                  <b-tooltip v-if="props.row.retailer_id == null" label="Global">
                    <material-icon size="large" icon="public"></material-icon>
                  </b-tooltip>
                  <b-tooltip v-if="props.row.retailer_id != null" label="Retailer">
                    <material-icon size="large" icon="store"></material-icon>
                  </b-tooltip>
                </span>
              </template>
            </b-table-column>
            <b-table-column field="active" label="Active?" width="80" v-if="canToggleActive">
              <template #default="props">
                <b-switch :value="props.row.active" @input="toggleActive(props.row, $event)"></b-switch>
              </template>
            </b-table-column>
            <b-table-column label="" width="60">
              <template #default="props">
                <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                  <template #trigger>
                    <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                  </template>
                  <b-dropdown-item aria-role="listitem" @click="editItem(props.row)">Edit</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="deleteItem(props.row)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import nibble from '@/utils/nibble'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import MaterialIcon from '@/components/MaterialIcon'
import ItemList from '@/utils/mixins/ItemList'

export default {
  name: 'Reasons',
  mixins: [ItemList],
  props: ['context'],
  components: { PageLayout, MainColumn, EmptyListIndicator, MaterialIcon },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      loading: false,
      items: [],
      selectedItems: [],
      draggingRow: null,
      draggingRowIndex: null
    }
  },
  computed: {
    newReasonLink () {
      if (this.context === 'reasons' || this.context === 'archive') {
        return `/retailers/${this.retailerId}/reasons/new`
      } else {
        return { path: '/admin/reasons/new', query: { context: this.context } }
      }
    },
    listPath () {
      if (this.context === 'reasons') {
        return `/retailers/${this.retailerId}/reasons`
      } else if (this.context === 'admin:global') {
        return '/admin/reasons'
      } else {
        return null
      }
    },
    listApiPath () {
      return '/reasons'
    },
    listApiParams () {
      return { context: this.context, retailer_id: this.retailerId }
    },
    itemsKey () {
      return 'reasons'
    },
    itemIdKey () {
      return 'reason_id'
    },
    itemName () {
      return 'Reason'
    },
    showEmptyList () {
      console.log(this.items)
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    },
    menuItem () {
      return this.context
    },
    pageTitle () {
      if (this.context === 'reasons') {
        return 'Reasons'
      } else if (this.context === 'admin:global') {
        return 'Reasons global'
      } else {
        return null
      }
    },
    canToggleActive () {
      return true
    },
    canCreateReason () {
      return true
    },
    menuColumnIndex () {
      var index = this.activeColumnIndex
      if (this.canToggleActive) {
        index = index + 1
      }
      return index
    }
  },
  methods: {
    itemPath (item) {
      if (this.context === 'reasons') {
        return `/retailers/${this.retailerId}/reasons/${item.reason_id}`
      } else {
        return { path: `/admin/reasons/${item.reason_id}`, query: { context: this.context } }
      }
    },
    deleteItem (item) {
      nibble.delete(this.itemApiPath(item), { context: this.context }, this.$store.getters.authHeaders)
        .then((response) => {
          Toast.open(`${this.itemName} Deleted`, { type: 'is-primary' })
          this.fetchData()
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    toggleActive (item, active) {
      nibble.put(`${this.listApiPath}/${item.reason_id}`, { context: this.context, active: active }, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchData()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        })
    },
    clickCell (row, column, rowIndex, columnIndex) {
      if (this.canToggleActive && columnIndex === this.activeColumnIndex) {
        return
      }
      if (columnIndex === this.menuColumnIndex) {
        return
      }
      this.clickRow(row)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
