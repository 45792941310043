import axios from 'axios'

const MAINTENANCE_STATUS_FILE = process.env.VUE_APP_MAINTENANCE_STATUS_FILE
const MAINTENANCE_STATUS_REDIRECT = process.env.VUE_APP_MAINTENANCE_STATUS_REDIRECT

const agent = axios.create()

const MAINTENANCE_DEBOUNCE = 60 * 1000
var lastMaintenanceCheck = null

function redirectToStatusPage () {
  window.location.href = MAINTENANCE_STATUS_REDIRECT
}

export default {
  checkMaintenanceStatus (store) {
    if (MAINTENANCE_STATUS_FILE == null) {
      return
    }
    if (lastMaintenanceCheck != null && (new Date() - lastMaintenanceCheck) < MAINTENANCE_DEBOUNCE) {
      return
    }
    lastMaintenanceCheck = new Date()
    return agent.get(MAINTENANCE_STATUS_FILE)
      .then((response) => {
        var inMaintenanceMode = !response.data.status['admin-site']
        store.commit('setMaintenanceMode', inMaintenanceMode)
        console.log(`Maintenance mode: ${inMaintenanceMode}`)
        if (inMaintenanceMode) {
          redirectToStatusPage()
        }
      })
  }
}
