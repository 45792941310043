<template>
  <b-tooltip :label="tooltip" v-if="icon !== null || svgIcon !== null">
    <svg-icon v-if="svgIcon" :size="size" :icon="svgIcon" :status-class="iconStatus"></svg-icon>
    <material-icon v-else :size="size" :icon="icon" :status-class="iconStatus"></material-icon>
  </b-tooltip>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import SvgIcon from '@/components/SvgIcon'

export default {
  name: 'StatusIcon',
  components: { MaterialIcon, SvgIcon },
  props: {
    addonItemId: {
      type: Number,
      required: false,
      default: null
    },
    sessionStatus: {
      type: String,
      required: true
    },
    negotiationType: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'large'
    }
  },
  computed: {
    tooltip () {
      if (this.sessionStatus === 'sold') {
        return this.addonItemId === null ? 'Purchased' : 'Purchased with Add-on'
      } else if (this.sessionStatus === 'added_to_basket') {
        if (this.negotiationType === 'cart') {
          return 'Sent to Checkout'
        } else {
          return 'Added to Basket'
        }
      } else if (this.sessionStatus === 'checkout') {
        return 'Sent to Checkout'
      } else if (this.sessionStatus === 'abandoned_negotiated') {
        return 'Abandoned - negotiated'
      } else if (this.sessionStatus === 'abandoned_reached_final_offer') {
        return 'Abandoned - After Final Offer'
      } else if (this.sessionStatus === 'abandoned_deal_agreed') {
        return 'Abandoned - Deal Agreed'
      } else if (this.sessionStatus === 'user_offer_available') {
        return 'Recoverable - waiting for review'
      } else if (this.sessionStatus === 'user_offer_accepted') {
        return 'Recoverable - user offer accepted'
      } else if (this.sessionStatus === 'user_offer_rejected') {
        return 'No longer recoverable - user offer rejected'
      } else if (this.sessionStatus === 'user_offer_countered') {
        return 'Recoverable - counter offer sent'
      } else if (this.sessionStatus === 'recovered_purchased') {
        return this.addonItemId === null ? 'Recovered - Purchased' : 'Recovered - Purchased with Add-on'
      } else {
        return ''
      }
    },
    icon () {
      if (this.sessionStatus === 'sold') {
        return 'monetization_on'
      } else if (this.sessionStatus === 'added_to_basket' || this.sessionStatus === 'checkout') {
        return 'shopping_basket'
      } else if (this.sessionStatus === 'abandoned_deal_agreed') {
        return 'thumb_up'
      } else if (this.sessionStatus === 'abandoned_negotiated') {
        return 'chat'
      } else {
        return null
      }
    },
    svgIcon () {
      if (this.sessionStatus === 'abandoned_reached_final_offer') {
        return 'status_user_offer_unavailable'
      } else if (this.sessionStatus === 'user_offer_available') {
        return 'status_user_offer_available'
      } else if (this.sessionStatus === 'user_offer_accepted') {
        return 'status_user_offer_accepted'
      } else if (this.sessionStatus === 'user_offer_rejected') {
        return 'status_user_offer_rejected'
      } else if (this.sessionStatus === 'user_offer_countered') {
        return 'status_user_offer_countered'
      } else if (this.sessionStatus === 'recovered_purchased') {
        return 'status_recovered_purchased'
      } else if ((this.sessionStatus === 'sold') && (this.addonItemId !== null)) {
        return 'status_purchased_with_addon'
      } else {
        return null
      }
    },
    iconStatus () {
      if (this.sessionStatus === 'sold') {
        return 'sold'
      } else {
        return 'normal'
      }
    }
  }
}

</script>
