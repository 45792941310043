import { ToastProgrammatic as Toast } from 'buefy'
import nibble from '@/utils/nibble'

export default {
  created () {
    this.fetchItemData()
  },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      itemId: this.$route.params.itemId,
      item: null,
      errorMessage: null
    }
  },
  computed: {
    pageTitle () {
      if (this.isNewItem) {
        return `New ${this.itemName}`
      } else {
        return `Edit ${this.itemName}`
      }
    },
    itemsName () {
      return `${this.itemName}s`
    },
    breadcrumbs () {
      return [
        {
          link: this.listPath,
          title: this.itemsName
        },
        {
          link: this.currentPath,
          title: this.itemName
        }
      ]
    },
    currentPath () {
      if (this.isNewItem) {
        return this.newPath
      } else {
        return this.itemPath
      }
    },
    itemCreatedToastMessage () {
      return `${this.itemName} Created`
    },
    itemUpdatedToastMessage () {
      return `${this.itemName} Saved`
    },
    isNewItem () {
      return this.itemId === 'new'
    },
    itemApiParams () {
      return {}
    }
  },
  watch: {
    $route: 'routeUpdated'
  },
  methods: {
    routeUpdated () {
      this.itemId = this.$route.params.itemId
      this.retailerId = this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null
      this.updateDataFromRoute()
      this.fetchItemData()
    },
    updateDataFromRoute () {
    },
    fetchItemData () {
      if (!this.isNewItem) {
        console.log('not new item')
        nibble.get(this.itemApiPath, this.itemApiParams, this.$store.getters.authHeaders)
          .then((response) => {
            console.log(response)
            this.item = this.dataToItem(response.data)
            this.fetchOptions()
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errorMessage = error.response.data.errorMessage
            } else {
              this.errorMessage = 'Sorry, something went wrong'
              nibble.handleNetworkError(error, this.$store)
            }
          })
      } else {
        this.fetchOptions()
      }
    },
    fetchOptions () {},
    dataToItem (data) {
      return data
    },
    itemToParams () {
      return this.item
    },
    submit () {
      if (this.isNewItem) {
        nibble.post(this.listApiPath, this.itemToParams(), this.$store.getters.authHeaders)
          .then((response) => {
            Toast.open({ message: this.itemCreatedToastMessage, type: 'is-primary' })
            this.$router.push(this.listPath)
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errorMessage = error.response.data.errorMessage
            } else {
              nibble.handleNetworkError(error, this.$store)
            }
          })
      } else {
        nibble.put(this.itemApiPath, this.itemToParams(), this.$store.getters.authHeaders)
          .then((response) => {
            Toast.open({ message: this.itemUpdatedToastMessage, type: 'is-primary' })
            this.$router.push(this.listPath)
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errorMessage = error.response.data.errorMessage
            } else {
              nibble.handleNetworkError(error, this.$store)
            }
          })
      }
    }
  }
}
