<template>
  <page-layout :retailer-id="retailerId" active-menu-item="campaigns">
    <main-column :breadcrumbs="breadcrumbs" page-title="Magic Links" content-class="main-column-sub-columns columns">
      <template #header-right>
        <p class="level-item buttons">
          <b-button type="is-text is-icon" icon-right="delete" :disabled="selectedItems.length === 0" @click="confirmDeleteBatch"></b-button>
          <b-button type="is-primary is-rounded" tag="router-link" :to="newCampaignLink">New Magic Link</b-button>
        </p>
      </template>
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Magic Links"
            :showButton="true"
            buttonText="New Magic Links"
            :link="newCampaignLink">
            <template v-slot:text>
              Here, you can create Magic Links to generate a magic link that triggers Nibble on your store once clicked. This is handy when you'd like to offer your customers a deal via marketing emails, or when creating social media posts that tempt people to check out your store.
            </template>
          </empty-list-indicator>
          <b-table v-else :data="items" :checked-rows.sync="selectedItems" checkable hoverable>
            <b-table-column field="name" label="Magic Link" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ props.row.name }}
                </strong>
              </router-link>
            </b-table-column>
            <!-- <b-table-column field="discount" label="Discount/Objective" v-slot="props" width="100" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                {{ discountOrObjective(props.row) }}
              </router-link>
            </b-table-column> -->
            <!-- <b-table-column field="walkaway" label="Walkaway" v-slot="props" width="100" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                {{ props.row.walkaway_percentage }}%
              </router-link>
            </b-table-column> -->
            <!-- <b-table-column field="icons" label="Expires" v-slot="props" width="160" cell-class="link-cell">
              <span class="rule-icons">
                <material-icon size="small" icon="schedule" v-if="props.row.end_date != null"></material-icon>
                {{expiryText(props.row)}}
              </span>
            </b-table-column> -->
            <b-table-column label="" v-slot="props" width="240">
              <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                <template #trigger>
                  <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
                </template>
                <b-dropdown-item aria-role="listitem" @click="copyUrl(props.row)">Copy Magic Link</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="deleteItem(props.row)">Delete</b-dropdown-item>
              </b-dropdown>
              <button class="button is-rounded is-pulled-right" @click="copyUrl(props.row)">Copy URL</button>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import { ToastProgrammatic as Toast } from 'buefy'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ItemList from '@/utils/mixins/ItemList'

export default {
  name: 'Campaigns',
  mixins: [ItemList],
  components: { PageLayout, MainColumn, EmptyListIndicator },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      loading: false,
      items: [],
      selectedItems: []
    }
  },
  computed: {
    newCampaignLink () {
      return `/retailers/${this.retailerId}/campaigns/new`
    },
    listPath () {
      return `/retailers/${this.retailerId}/campaigns`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/campaigns`
    },
    itemsKey () {
      return 'campaigns'
    },
    itemIdKey () {
      return 'campaign_id'
    },
    itemName () {
      return 'Campaign'
    },
    showEmptyList () {
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    }
  },
  methods: {
    copyUrl (item) {
      this.$clipboard(item.campaign_url)
      Toast.open({ message: 'Magic Link copied to clipboard', type: 'is-primary' })
    },
    expiryText (item) {
      if (item.end_date) {
        return nibble.timezoneManager.formatDate(item.end_date, 'DD/MM/YYYY')
      } else {
        return ''
      }
    },
    discountOrObjective (item) {
      if (item.objective !== undefined && item.objective !== null) {
        let objective = '- error -'
        switch (item.objective) {
          case 'increase_conversion':
            objective = 'Increase conversion'
            break
          case 'preserve_margin':
            objective = 'Preserve margin'
            break
          case 'balance_performance':
            objective = 'Balance performance'
            break
          default:
            console.log('Something wrong with objective of Rule ' + item.campaign_id)
        }
        return objective
      }
      return item.target_discount_percentage + '%'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
