<template>
  <b-menu>
    <b-menu-list label='My Account'>
      <b-menu-item icon="account"
                   :active="isMenuItemActive('personal')"
                   label="Personal Details"
                   tag="router-link"
                   to="/account"></b-menu-item>
      <b-menu-item icon="lock"
                   :active="isMenuItemActive('security')"
                   label="Account Security"
                   tag="router-link"
                   to="/account/security"></b-menu-item>
    </b-menu-list>
  </b-menu>
</template>

<script>

export default {
  name: 'AccountMenu',
  props: ['activePage'],
  components: { },
  methods: {
    isMenuItemActive (page) {
      return page === this.activePage
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
