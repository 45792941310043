<template>
    <div class="objectives">
        <div class="objectives-right">
            <div class="objectives-right-inputs">
                <div class="objectives-right-datetime">
                    <div class="objective">
                      <ValidationProvider :rules="{required: objectives.advancedTimeLimit === 'recurring'}" name="Begin Time" v-slot="{ errors }">
                        <b-field label="Every" :message="errors" :type="{ 'is-danger': errors[0] }">
                          <b-select v-model="objectives.day" placeholder="Select a day" expanded @input="input">
                            <option value="mon">Monday</option>
                            <option value="tue">Tuesday</option>
                            <option value="wed">Wednesday</option>
                            <option value="thu">Thursday</option>
                            <option value="fri">Friday</option>
                            <option value="sat">Saturday</option>
                            <option value="sun">Sunday</option>
                            <option value="working">Monday to Friday</option>
                            <option value="weekend">Saturday to Sunday</option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>
                </div>
                <div class="objectives-right-datetime">
                    <div class="objective">
                        <ValidationProvider :rules="{required: objectives.advancedTimeLimit === 'recurring'}" name="Begin Time" v-slot="{ errors }">
                          <b-field label="Begin Time" :message="errors" :type="{ 'is-danger': errors[0] }">
                              <b-select v-model="objectives.startTime"
                                      placeholder="Choose Time"
                                      expanded
                                      @input="input">
                              <option v-for="(option, optionIndex) in allTimes" :key="optionIndex" :value="option">{{option}}</option>
                              </b-select>
                          </b-field>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="objectives-right-datetime">
                    <div class="objective">
                        <ValidationProvider :rules="{required: objectives.advancedTimeLimit === 'recurring'}" name="End Time" v-slot="{ errors }">
                        <b-field label="End Time" :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select v-model="objectives.endTime"
                                    placeholder="Choose Time"
                                    expanded
                                    @input="input">
                            <option v-for="(option, optionIndex) in allTimesEnd" :key="optionIndex" :value="option">{{option}}</option>
                            </b-select>
                        </b-field>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: 'RecurringTimeLimitEditor',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      objectives: this.objectivesFromValue(this.value)
    }
  },
  watch: {
    value () {
      this.objectives = this.objectivesFromValue(this.value)
    }
  },
  computed: {
    allTimes () {
      return [
        '12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM',
        '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
        '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
        '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'
      ]
    },
    allTimesEnd () {
      return [
        '12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM',
        '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
        '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
        '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM',
        'End of day'
      ]
    }
  },
  methods: {
    objectivesFromValue (value) {
      var timeLimitEnabled = value.enableTimeLimit

      var endTime = null

      console.log('X', value)

      if (timeLimitEnabled) {
        if (value.recurringRange.endTime != null && value.recurringRange.endTime !== 'End of day') {
          endTime = value.recurringRange.endTime
        } else if (value.recurringRange.startTime != null) {
          endTime = 'End of day'
        }
      }

      console.log((timeLimitEnabled ? value.recurringRange.startTime : null), endTime)

      return {
        startTime: (timeLimitEnabled ? value.recurringRange.startTime : null),
        endTime: endTime,
        enableTimeLimit: timeLimitEnabled,
        day: (timeLimitEnabled ? value.recurringRange.day : null)
      }
    },
    valueFromObjectives (objectives) {
      var value = {
        enableTimeLimit: false,
        recurringRange: {
          startTime: null,
          endTime: null,
          day: null
        }
      }
      if (objectives.enableTimeLimit) {
        value.enableTimeLimit = true
        if (objectives.startTime != null) {
          value.recurringRange.startTime = objectives.startTime
        }
        if (objectives.startTime != null && objectives.endTime == null) {
          value.recurringRange.endTime = 'End of day'
        } else if (objectives.endTime != null) {
          value.recurringRange.endTime = objectives.endTime
        }
        if (objectives.day != null) {
          value.recurringRange.day = objectives.day
        }
      }
      return value
    },
    input () {
      this.$emit('input_recurring', this.valueFromObjectives(this.objectives))
    }
  }
}
</script>
<style lang="scss" scoped>
  .objectives,
  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime {
    margin: -.75rem;
    display: flex;
  }

  .objectives-right,
  .objectives-right-inputs,
  .objectives-right-datetime,
  .objective {
    padding: .75rem;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  @media (max-width: 1440px) {
    .objectives {
      display: block;
    }
  }

  @media (max-width: 1060px) {
    .objectives-right-datetime {
      display: block;
    }
  }
</style>
