<template>
  <page-layout :retailer-id="retailerId" active-menu-item="dashboard">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <main-column :breadcrumbs="breadcrumbs" page-title="Shopify Store Integration" no-banner>

          <template #header-right>
            <p class="level-item buttons">
              <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
              <b-button type="is-primary is-rounded" @click="handleSubmit(submit)">Save</b-button>
            </p>
          </template>

        <div class="card">
          <div class="card-content">
            <h2 class="title is-2">Theme JavaScript</h2>
            <div class="content">
              <p>
                JavaScript here will be injected into every page of the Shopify store, when the Nibble Shopify app is installed. Try to keep execution as fast as possible.
              </p>
              <h4 class="is-4">Guidelines</h4>
              <p>
                This code block must be valid JavaScript. It should assign a value to the config variable, which is declared earlier in surrounding code.<br><code>config = {};</code>
                Adding elements to the config object will allow you to specify button positions and custom behaviour.
              </p>
              <h4 class="is-4">Example</h4>
              <pre>
                config = {
                  buttons: [
                    {
                      // type must be one of: 'product', 'cart', 'cart-drawer', 'cart-notification'
                      type: 'product',

                      // formSelector must be present and identify the product/cart form element
                      formSelector: 'form[action="/cart/add"]',

                      // quantityInputSelector only applies to product buttons and is optional
                      quantityInputSelector: 'form[action="/cart/add"] input.quantity',

                      // positionElement must be present and is a function that inserts the Nibble button at the
                      // desired location on the page.
                      positionElement: function(el) {
                        document.querySelecctor('form[action="/cart/add"]').insertAdjacentElement('beforeend', el);
                      },

                      // provide this function if you want to, for example, hide certain page elements when the
                      // Nibble button is shown (and show them again when the button is hidden)
                      onDisplayUpdate: function(nibbleShown, inCheckoutMode) {
                        if (inCheckoutMode) {
                          document.querySelector('.checkout-button').style.display = nibbleShown ? 'none' : 'block';
                        }
                      }
                    }
                  ]
                }
              </pre>
            </div>
            <div class="editor">
              <vue-monaco-editor v-model="item.custom_code" language="javascript" :options="editorOptions" />
            </div>
          </div>
        </div>
        <br/>
      </main-column>
    </validation-observer>
  </page-layout>
</template>

<script>
import MainColumn from '@/components/MainColumn'
import PageLayout from '@/components/PageLayout'
import ItemEditor from '@/utils/mixins/ItemEditor'

export default {
  name: 'ShopifyStore',
  mixins: [ItemEditor],
  components: { MainColumn, PageLayout },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      loaded: false,
      item: {},
      defaultCode: 'config = {\n// Custom code here!\n};',
      editorOptions: {
        tabSize: 2
      }
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {
          link: '/admin/retailers',
          title: 'Retailers'
        },
        {
          link: `/admin/retailers/${this.retailerId}/`,
          title: 'Retailer'
        },
        {
          link: this.currentPath,
          title: this.itemName
        }
      ]
    },
    listPath () {
      return `/retailers/${this.retailerId}/`
    },
    newPath () {
      return null
    },
    itemPath () {
      return `/retailers/${this.retailerId}/shopify_store`
    },
    listApiPath () {
      return null
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}/shopify_store`
    },
    itemName () {
      return 'Shopify Store Integration'
    }
  },
  methods: {
    dataToItem (data) {
      return { custom_code: data.custom_code || this.defaultCode }
    }
  }
}
</script>

<style lang="scss" scoped>
.editor {
  height: 500px;
}
</style>
