<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-6-tablet is-5-desktop is-5-widescreen">
        <div class="card" v-if="complete">
          <div class="card-content">
            <p>
              Thank you. We have sent you an email with instructions on resetting your password. If you do not receive your email within 2 hours, please contact us for assistance.
            </p>
          </div>
        </div>
        <form class="card" v-on:submit.prevent.stop="submit" v-else>
          <div class="card-content">
            <div class="block has-text-centered">
              <img src="/images/nibble-logo.png" alt="Nibble. Converse. Convert." width="168" height="50">
            </div>

            <p>
              Can't remember your password? Please submit your email address below and we'll send you instructions to reset it.
            </p>
            <hr>
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input type="email" class="input" v-model.trim="email" placeholder="hello@example.com">
              </div>
            </div>

            <div class="notification is-danger is-light" v-if="error">
              {{error}}
            </div>
            <div class="field is-grouped is-grouped-right">
              <div class="control buttons">
                <router-link to="/" class="button is-text is-rounded">Cancel</router-link>
                <button type="submit" class="button is-primary is-rounded">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  </section>
</template>

<script>
import nibble from '@/utils/nibble'

export default {
  name: 'ForgotPassword',
  components: { },
  data () {
    return {
      email: '',
      error: null,
      loading: false,
      complete: false
    }
  },
  computed: {
  },
  methods: {
    validate () {
      this.error = null
      if (this.email === '') {
        this.error = 'Please enter your email address'
      }
    },
    submit (e) {
      this.validate()
      if (this.error != null) {
        return
      }
      this.submitPasswordReset()
    },
    submitPasswordReset () {
      nibble.post('account/password', { email: this.email })
        .then((response) => {
          this.complete = true
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.error = error.response.data.errorMessage
          } else {
            this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            nibble.handleNetworkError(error, this.$store)
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
