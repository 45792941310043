<template>
  <page-layout :retailer-id="retailerId" active-menu-item='account:usage'>
    <main-column :breadcrumbs="breadcrumbs" :page-title="item.description">
      <div class="card mb-6">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <div class="card-head mb-4">
                <material-icon icon="assignment"></material-icon>
                <h2 class="title is-3">Details</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr>
                    <td>Usage Charge ID</td>
                    <td class="has-text-right">{{ item.billing_event_id }}</td>
                  </tr>
                  <tr>
                    <td>Date Billed</td>
                    <td class="has-text-right">{{ formatDate(item.billed_at) }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td class="has-text-right">{{ item.description }}</td>
                  </tr>
                  <tr>
                    <td>Time Range</td>
                    <td class="has-text-right">{{ timeRangeCalculator }}</td>
                  </tr>
                  <tr v-if="isUsageChargeFee">
                    <td>Sales Number</td>
                    <td class="has-text-right">{{ totalAssociatedSessionsCount }}</td>
                  </tr>
                  <tr v-if="isUsageChargeFee">
                    <td>Sales Total Amount</td>
                    <td class="has-text-right">{{ item.total_sales_amount }}</td>
                  </tr>
                  <tr v-if="isUsageChargeFee">
                    <td><strong>Nibble {{ getUsageFeePercentage }} Commission on sales</strong></td>
                    <td class="has-text-right"><strong>{{ item.original_amount }}</strong></td>
                  </tr>
                  <tr v-if="isUsageChargeFee">
                    <td><strong>Converted Charge</strong></td>
                    <td class="has-text-right"><strong>{{ item.amount }}</strong></td>
                  </tr>
                  <tr v-if="isUsageChargeSubscriptionFee">
                    <td><strong>Monthly Subscription Fee</strong></td>
                    <td class="has-text-right"><strong>{{ item.amount }}</strong></td>
                  </tr>
                </tbody>
              </table>
              <b-button v-if="canBill" @click="submitBilling" type="is-primary is-rounded" class="pull-right">Bill Now</b-button>
            </div>
          </div>
        </div>
      </div>
      <b-notification :active="errorMessage != null" :message="errorMessage" type="is-danger" :closable="false"></b-notification>
      <div class="card mb-4" v-if="item.billing_scheme">
        <div class="card-content">
          <h2 class="title is-2">Billing Info</h2>
          <table class="table is-fullwidth stats-table">
            <tbody>
              <tr>
                <td>
                  Pricing Plan
                </td>
                <td class="has-text-right">{{ item.billing_scheme.name }}</td>
              </tr>
              <tr>
                <td>
                  Current Billing Period Started
                </td>
                <td class="has-text-right">{{ billingCycleStart }}</td>
              </tr>
              <tr>
                <td>
                  Current Billing Period Ends
                </td>
                <td class="has-text-right">{{ billingCycleEnd }}</td>
              </tr>
              <tr>
                <td>Billing Cycle Usage Fee Cap</td>
                <td class="has-text-right">{{ item.billing_scheme.billing_cycle_usage_cap_with_currency }}</td>
              </tr>
              <tr>
                <td>Billed This Cycle</td>
                <td class="has-text-right">{{ item.billing_scheme.current_cycle_billed_fees }}</td>
              </tr>
              <tr>
                <td>Unbilled Usage Fees</td>
                <td class="has-text-right"><strong>{{ item.billing_scheme.unbilled_usage_fees }}</strong></td>
              </tr>
            </tbody>
          </table>
          <b-button :to="planPath" type="is-secondary is-rounded" tag="router-link">Manage Plan</b-button>&nbsp;
          <b-button :to="usagePath" type="is-secondary is-rounded" tag="router-link">Manage Charges</b-button>
        </div>
      </div>
      <div v-if="isUsageChargeFee && totalAssociatedSessionsCount > 0">
        <h3 class="has-text-centered">Related Transactions</h3>
        <nav class="level mb-0">
          <p class="level-left list-summary">
            {{offset + 1}}-{{Math.min(offset + 21, totalAssociatedSessionsCount)}} of {{totalAssociatedSessionsCount}} records
          </p>
        </nav>
        <b-table :data="item.associated_sessions" hoverable>
          <b-table-column field="nibbleId" v-slot="props" width="100" cell-class="link-cell">
            <router-link class="link" :to="{ path: sessionPath(props.row) } ">
              {{props.row.session_id}}
              <span class="session-icons">
                <b-tooltip v-if="props.row.negotiation_type === 'cart'" label="Negotiation on Cart">
                  <material-icon size="small" icon="shopping_cart" class="icon-status-cart"></material-icon>
                </b-tooltip>
                <b-tooltip v-if="props.row.is_returning_user" label="Repeat Negotiation">
                  <material-icon size="small" icon="repeat"></material-icon>
                </b-tooltip>
              </span>
            </router-link>
          </b-table-column>
          <b-table-column field="time" v-slot="props" width="100" cell-class="link-cell">
            <router-link class="link" :to="{ path: sessionPath(props.row) }">
              <span v-html="formatDateShort(props.row.created_at)"></span>
            </router-link>
          </b-table-column>
          <b-table-column field="retailer" v-slot="props" width="120" cell-class="link-cell">
            <router-link class="link" :to="{ path: sessionPath(props.row) }">
              {{props.row.user_name}}
            </router-link>
          </b-table-column>
          <b-table-column field="productName" v-slot="props" cell-class="link-cell">
            <router-link class="link" :to="{ path: sessionPath(props.row) }">
              <strong>
                {{productLabel(props.row)}}
              </strong>
            </router-link>
          </b-table-column>
          <b-table-column field="productPrice" v-slot="props" width="100" cell-class="price link-cell">
            <router-link class="link" :to="{ path: sessionPath(props.row) }">
              <small>
                <s>{{props.row.product_price}}</s>
              </small>
            </router-link>
          </b-table-column>
          <b-table-column field="productCurrentPrice" v-slot="props" width="100" cell-class="price link-cell">
            <router-link class="link" :to="{ path: sessionPath(props.row) }">
              <small>
                {{props.row.current_price}}
              </small>
            </router-link>
          </b-table-column>
          <b-table-column field="icons" v-slot="props" width="100" cell-class="has-text-centered link-cell">
            <router-link class="link" :to="{ path: sessionPath(props.row) }">
              <span class="rule-icons">
                <status-icon :session-status="props.row.summary_status"
                             :negotiation-type="props.row.negotiation_type"
                             :addon-item-id="props.row.addon_item_id">
                </status-icon>
              </span>
            </router-link>
          </b-table-column>
          <b-table-column label="" width="100" v-slot="props">
            <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
              <template #trigger>
                <b-button type="is-text is-icon" icon-left="dots-vertical"></b-button>
              </template>
              <b-dropdown-item aria-role="listitem" @click="viewItem(props.row)">View Details</b-dropdown-item>
            </b-dropdown>
          </b-table-column>
        </b-table>
        <list-paginator :offset="offset" :page-size="20" :total-item-count="totalAssociatedSessionsCount" @navigate="setOffset"></list-paginator>
        <br/>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import moment from 'moment'
import nibble from '@/utils/nibble'
import MainColumn from '@/components/MainColumn'
import MaterialIcon from '@/components/MaterialIcon'
import ItemEditor from '@/utils/mixins/ItemEditor'
import StatusIcon from '@/components/StatusIcon'
import PageLayout from '@/components/PageLayout'
import ListPaginator from '@/components/ListPaginator'

export default {
  name: 'AccountUsageDetails',
  components: { MainColumn, MaterialIcon, StatusIcon, PageLayout, ListPaginator },
  mixins: [ItemEditor],
  data () {
    return {
      errorMessage: null,
      item: {
        billing_scheme: null,
        billing_event_id: null,
        date: '',
        description: '',
        amount: null,
        event_type: '',
        associated_sessions: [],
        original_amount: null,
        total_sales_amount: null
      },
      offset: this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0
    }
  },
  computed: {
    billingCycleStart () {
      const start = this.item.billing_scheme.last_billing_cycle_ended_at || this.item.billing_scheme.billing_cycle_started_at
      if (start != null) {
        return moment(start).format('DD/MM/YYYY')
      } else {
        return 'N/A'
      }
    },
    billingCycleEnd () {
      const end = this.item.billing_scheme.current_billing_cycle_ends_at
      if (end != null) {
        return moment(end).format('DD/MM/YYYY')
      } else {
        return 'N/A'
      }
    },
    canBill () {
      return this.item.billing_scheme != null &&
        this.item.billing_scheme.payment_type === 'shopify' &&
        this.item.billed_at == null &&
        this.item.amount !== '$0.00'
    },
    listPath () {
      return `/retailers/${this.retailerId}/account/usage`
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}/account/usage/${this.itemId}`
    },
    itemPath () {
      return `/retailers/${this.retailerId}/account/usage/${this.itemId}`
    },
    itemName () {
      return 'Usage Details'
    },
    itemsName () {
      return 'Usage'
    },
    itemApiParams () {
      var params = {}
      if (this.retailerId != null) {
        params.retailer_id = this.retailerId
      }
      params.offset = this.offset
      return params
    },
    pageTitle () {
      return this.item.billing_event_id
    },
    isUsageChargeFee () {
      return this.item.event_type === 'usage_fee'
    },
    isUsageChargeSubscriptionFee () {
      return this.item.event_type === 'subscription_fee'
    },
    timeRangeCalculator () {
      if (this.isUsageChargeFee) {
        return `From ${this.formatDate(this.item.date)} to ${this.formatDate(this.minusDays(-1))}`
      } else if (this.startDate != null) {
        return `From ${this.formatDateShort(this.startDate)} to ${this.formatDateShort(this.item.date)}`
      } else {
        return this.formatDateShort(this.item.date)
      }
    },
    totalAssociatedSessionsCount () {
      return this.item.total_number_of_sales
    },
    startDate () {
      if (this.isUsageChargeFee) {
        return this.minusDays(1)
      } else if (this.isUsageChargeSubscriptionFee) {
        return this.minusDays(30)
      } else {
        return ''
      }
    },
    getUsageFeePercentage () {
      if (this.$store.state.user.retailer != null) {
        if (this.$store.state.user.retailer.usage_fee_percentage != null) {
          return `${this.$store.state.user.retailer.usage_fee_percentage}%`
        }
      }
      return ''
    }
  },
  methods: {
    routeUpdated () {
      this.offset = this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0
      this.retailerId = this.$route.params.retailerId
      this.fetchItemData()
    },
    sessionPath (session) {
      return `/retailers/${this.retailerId}/sessions/` + session.session_id
    },
    productLabel (session) {
      if (session.product.name != null && session.product.name !== '') {
        return session.product.name
      } else {
        return session.product.retailer_product_id
      }
    },
    formatDate (date) {
      return nibble.timezoneManager.formatDate(date)
    },
    formatDateShort (date) {
      return nibble.timezoneManager.formatDate(date, 'DD/MM/YYYY')
    },
    minusDays (days) {
      var result = new Date(this.item.date)
      result.setDate(result.getDate() - days)
      return result
    },
    itemToParams () {
      var params = {
        retailer_id: (this.retailer != null ? this.retailer.retailer_id : this.retailerId),
        billing_event_id: this.item.billing_event_id
      }
      return params
    },
    dataToItem (data) {
      var item = {
        billing_event_id: data.billing_event_id,
        billing_scheme: data.billing_scheme,
        billed_at: data.billed_at,
        date: data.reference_date,
        description: data.description,
        associated_sessions: data.associated_sessions,
        amount: data.amount,
        event_type: data.event_type,
        original_amount: data.original_amount,
        total_sales_amount: data.total_sales_amount,
        total_number_of_sales: data.total_number_of_sales
      }
      return item
    },
    viewItem (session) {
      this.$router.push(this.sessionPath(session))
    },
    setOffset (offset) {
      var query = {}
      query.offset = offset
      this.$router.push({ path: this.itemPath, query: query })
    },
    submitBilling () {
      nibble.post(this.itemApiPath, {}, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchItemData()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  color: #668099;
  font-weight: bold;
  font-size: 20px;
}

.card {
  width: 80%;
  margin: 0 auto;
}
</style>
