<template>
  <button class="filter-button" @click="toggle">
    <material-icon size="large" :icon="icon"></material-icon>
  </button>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  components: { MaterialIcon },
  data () {
    return {
      active: true
    }
  },
  computed: {
    icon () {
      if (this.active) {
        return 'close'
      } else {
        return 'filter_list'
      }
    }
  },
  methods: {
    toggle () {
      this.active = !this.active
      this.$emit('toggle')
    }
  }
}
</script>

<style>
</style>
