<template>
  <section class="section">
    <div class="columns">
      <div class="column is-3">
        <account-menu activePage="personal"></account-menu>
      </div>
      <div class="column is-6-tablet is-5-desktop is-5-widescreen">
        <form v-on:submit.prevent.stop="submit">
          <h3 class="title is-3">My Details</h3>
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input type="text" class="input" v-model.trim="user.name" placeholder="">
            </div>
          </div>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input type="email" class="input" v-model.trim="user.email" placeholder="hello@example.com">
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control buttons">
              <button type="submit" class="button is-primary">Save Changes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import nibble from '@/utils/nibble'
import AccountMenu from '@/components/account/AccountMenu'

export default {
  name: 'AccountHome',
  components: { AccountMenu },
  data () {
    return {
      user: {
        name: this.$store.state.user.name,
        email: this.$store.state.user.email
      },
      error: null
    }
  },
  computed: {
  },
  methods: {
    validate () {
      this.error = null
      if (this.email === '') {
        this.error = 'Please enter your email address'
      }
      if (this.password === '') {
        this.error = 'Please enter your password'
      }
    },
    submit (e) {
      this.validate()
      if (this.error != null) {
        return
      }
      this.submitAccount()
    },
    submitAccount () {
      this.loading = true
      var params = {
        name: this.user.name,
        email: this.user.email
      }
      nibble.put('/account', params, this.$store.getters.authHeaders)
        .then(response => this.updateAccount())
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            if (error.response.data != null && error.response.data.error_message != null) {
              this.error = error.response.data.error_message
            } else {
              this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            }
          } else {
            this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            nibble.handleNetworkError(error, this.$store)
          }
          console.log(error)
        })
    },
    updateAccount () {
      this.loading = true
      nibble.get('/account', {}, this.$store.getters.authHeaders)
        .then(response => {
          this.user.name = response.data.name
          this.user.email = response.data.email
          this.$store.commit('updateUser', response.data)
          this.loading = false
          this.$buefy.snackbar.open({
            message: 'Your details have been updated',
            type: 'is-success',
            position: 'is-top',
            actionText: null
          })
        })
        .catch((error) => {
          this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
          nibble.handleNetworkError(error, this.$store)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
