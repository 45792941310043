<template>
  <div :class="containerClass">
    <svg class="xnib-deal-anim" width="400" height="80" viewBox="0 0 400 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="xnib-deal-anim-stroke-l" d="M16 40H152" stroke="#E0E6EB"/>
      <path class="xnib-deal-anim-sparkle" opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M200 11.3333C201.105 11.3333 202 12.2288 202 13.3333V16C202 17.1046 201.105 18 200 18C198.895 18 198 17.1046 198 16V13.3333C198 12.2288 198.895 11.3333 200 11.3333Z" fill="#146DEB"/>
      <path class="xnib-deal-anim-sparkle" opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M222.937 17.0627C223.718 17.8437 223.718 19.11 222.937 19.8911L221.051 21.7767C220.27 22.5577 219.004 22.5577 218.223 21.7767C217.442 20.9956 217.442 19.7293 218.223 18.9483L220.109 17.0627C220.89 16.2816 222.156 16.2816 222.937 17.0627Z" fill="#146DEB"/>
      <path class="xnib-deal-anim-sparkle" opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M228.667 40C228.667 41.1046 227.771 42 226.667 42L224 42C222.895 42 222 41.1046 222 40C222 38.8954 222.895 38 224 38L226.667 38C227.771 38 228.667 38.8954 228.667 40Z" fill="#146DEB"/>
      <path class="xnib-deal-anim-tag" fill-rule="evenodd" clip-rule="evenodd" d="M196.509 26.6876C194.024 26.5132 191.573 27.4342 189.798 29.209L175.876 43.1315C172.518 46.4891 172.482 51.8964 175.794 55.209L184.791 64.2059C188.104 67.5185 193.511 67.482 196.869 64.1244L210.791 50.2019C212.566 48.4271 213.487 45.9758 213.312 43.4914L212.722 35.0847C212.427 30.887 209.113 27.5725 204.915 27.2778L196.509 26.6876ZM198.483 41.5169C200.118 43.1516 202.768 43.1516 204.402 41.5169C206.037 39.8823 206.037 37.2321 204.402 35.5974C202.768 33.9628 200.118 33.9628 198.483 35.5974C196.848 37.2321 196.848 39.8823 198.483 41.5169Z" fill="#146DEB"/>
      <path class="xnib-deal-anim-sparkle" opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M222.937 62.937C222.156 63.7181 220.889 63.7181 220.108 62.937L218.223 61.0514C217.442 60.2703 217.442 59.004 218.223 58.223C219.004 57.4419 220.27 57.4419 221.051 58.223L222.937 60.1086C223.718 60.8896 223.718 62.156 222.937 62.937Z" fill="#146DEB"/>
      <path class="xnib-deal-anim-sparkle" opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M181.777 21.7769C180.996 22.5579 179.729 22.5579 178.948 21.7769L177.063 19.8912C176.281 19.1102 176.281 17.8439 177.063 17.0628C177.844 16.2818 179.11 16.2818 179.891 17.0628L181.777 18.9484C182.558 19.7295 182.558 20.9958 181.777 21.7769Z" fill="#146DEB"/>
      <path class="xnib-deal-anim-stroke-l" d="M248 40H384" stroke="#E0E6EB"/>
    </svg>
    <div class="xnib-deal-message">
      <span v-if="expression.percentage != null">
        You got a
        <span class="xnib-deal-highlight">{{ expression.percentage }}</span>
        discount!
      </span>
      <span v-if="expression.dealPrice != null">
        You got a deal at
        <span class="xnib-deal-highlight">{{ expression.dealPrice }}</span>
        !
      </span>
    </div>

  </div>
</template>

<script>

export default {
  name: 'DealCelebration',
  props: {
    expression: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      started: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.started = true
    }, 1)
  },
  computed: {
    containerClass () {
      var classes = ['xnib-deal-celebration']
      if (!this.started) {
        classes.push('anim-start')
      }
      return classes
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.xnib-deal-message {
  color: #146DEB;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 18px;
  text-align: center;
  transition: opacity 0.3s, transform 0.3s;
}
.xnib-deal-message .xnib-deal-highlight {
  background-color: #146DEB;
  color: white;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
  border-radius: 16px;
}
  .anim-start .xnib-deal-message {
    opacity: 0;
    transform: translateY(10px);
  }

.xnib-deal-anim-tag {
  /* bounce */
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  transform-origin: 50% 50%;
}
.xnib-deal-anim-stroke-l, .xnib-deal-anim-stroke-r {
  transition: transform .2s ease-in;
}
.xnib-deal-anim-stroke-l {
  transform-origin: 50% 50%;
}
.xnib-deal-anim-stroke-r {
  transform-origin: 50% 50%;
}
.anim-start .xnib-deal-anim .xnib-deal-anim-tag {
  /* scale to 0 around its center */
  transform: scale(0);
}
.anim-start .xnib-deal-anim .xnib-deal-anim-stroke-l,
.anim-start .xnib-deal-anim .xnib-deal-anim-stroke-r {
  transform: scaleX(0);
}
.xnib-deal-anim-sparkle {
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;
  transform-origin: 50% 50%;
}
.anim-start .xnib-deal-anim .xnib-deal-anim-sparkle {
  transform: scale(0);
}
.xnib-white-label .xnib-deal-message {
  color: #425165;
}
.xnib-white-label .xnib-deal-message .xnib-deal-highlight {
  background-color: #425165;
}
.xnib-white-label .xnib-deal-anim .xnib-deal-anim-tag,
.xnib-white-label .xnib-deal-anim .xnib-deal-anim-sparkle {
  fill: #425165;
}
</style>
