<template>
  <div v-if="activeRules !== null">
    <b-message v-if="activeRules.length === 0" type="is-warning is-rounded" has-icon>
      There are currently no Active Rules, and Nibble will not appear anywhere on your site.
    </b-message>
    <table v-else class="table is-fullwidth stats-table">
      <tbody>
        <tr v-for="rule in displayedActiveRules" :key="rule.rule_id">
          <td>
            <a :href="ruleLink(rule.rule_id)">{{ rule.name }}</a>
          </td>
        <tr>
        <tr v-if="moreActiveRules">
          <td>
            <a :href="rulesLink">{{ moreActiveRules }} more...</a>
          </td>
        </tr>
      </tbody>
    </table>
    <b-button type="is-primary is-rounded" tag="router-link" :to="rulesLink">Edit Rules</b-button>
  </div>
  <div v-else>
    <div v-if="retailerId === null">
      <b-message type="is-rounded">
        Select a Retailer in the filters to see the relevant active Rules.
      </b-message>
      <b-button disabled type="is-primary is-rounded" tag="router-link">Edit Rules</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActiveRuleList',
  props: {
    activeRules: {
      required: true
    },
    maxRules: {
      required: true,
      type: Number
    },
    retailerId: {
      required: true,
      default: null
    }
  },
  computed: {
    rulesLink: function () {
      return `/retailers/${this.retailerId}/rules`
    },
    displayedActiveRules: function () {
      if (this.activeRules.length <= this.maxRules) {
        return this.activeRules
      } else {
        return this.activeRules.slice(0, this.maxRules)
      }
    },
    moreActiveRules: function () {
      return Math.max(this.activeRules.length - this.maxRules, 0)
    }
  },
  methods: {
    ruleLink (id) {
      return `/retailers/${this.retailerId}/rules/${id}`
    }
  }
}
</script>
