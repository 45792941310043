<template>
  <div class="empty-list-indicator">
    <p class="has-text-centered pb-3">
      <img src="/images/empty_state.svg"/>
    </p>
    <h2 class="title is-3 has-text-centered">
      {{ header }}
    </h2>
    <p class="has-text-centered is-size-7" v-if="hasText">
      <slot name="text"/>
    </p>
    <br v-if="hasText"/>
    <p class="has-text-centered" v-if="showButton">
      <router-link :to="link" class="button dashed-button">
        <material-icon icon="add" size="small"></material-icon>
        {{ buttonText }}
      </router-link>
    </p>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  name: 'EmptyListIndicator',
  components: { MaterialIcon },
  props: ['header', 'buttonText', 'link', 'showButton'],
  computed: {
    hasText () {
      return !!this.$slots.text
    }
  }
}
</script>

<style scoped>
.empty-list-indicator {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin-top: 0 !important;
}
</style>
