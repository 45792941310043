import { render, staticRenderFns } from "./AccountPlanChoice.vue?vue&type=template&id=a4071932&scoped=true&"
import script from "./AccountPlanChoice.vue?vue&type=script&lang=js&"
export * from "./AccountPlanChoice.vue?vue&type=script&lang=js&"
import style0 from "./AccountPlanChoice.vue?vue&type=style&index=0&id=a4071932&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4071932",
  null
  
)

export default component.exports