<template>
  <page-layout :retailer-id="retailerId" active-menu-item="campaigns">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">
        <template #header-right>
          <p class="level-item buttons">
            <b-button type="is-text is-rounded" tag="router-link" :to="listPath">
              <span v-if="isNewItem">Cancel</span>
              <span v-else>Back</span>
            </b-button>
            <b-button v-if="isNewItem" type="is-primary is-rounded" @click="handleSubmit(submit)">Save &amp; Generate URL</b-button>
          </p>
        </template>
        <div class="card">
          <div class="card-content">
            <h2 class="title is-2">General Magic Link Settings</h2>
            <div class="columns">
              <div class="column">
                <ValidationProvider rules="required" name="Magic Link Name" v-slot="{ errors }">
                  <b-field label="Magic Link Name" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.name" :disabled="!isNewItem"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider rules="required" name="Magic Link Type" v-slot="{ errors }">
                  <b-field label="Magic Link Type" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-select v-model="item.campaign_type" placeholder="Select a Magic Link Type" expanded :disabled="!isNewItem">
                      <!-- <option value="single_product">One Product, One Email</option> -->
                      <option value="link_to_rule">Link to Rule</option>
                      <!-- <option value="negotiation_on_cart">Negotiation on cart</option> -->
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <notification-bar v-if="errorMessage != null" type="is-danger">
              {{errorMessage}}
            </notification-bar>
          </div>
        </div>
        <br>
        <div class="card" v-if="item.campaign_type">
          <div class="card-content" v-if="item.campaign_type === 'negotiation_on_cart'">
            <h2 class="title is-2">Settings</h2>
            <notification-bar>
              Paste in the URL fo your cart here, and Nibble will generate a new link
              that you can use in your marketing emails etc.
            </notification-bar>
            <ValidationProvider rules="required" name="Cart URL" v-slot="{ errors }">
              <b-field label="Cart URL" :message="errors" :type="{ 'is-danger': errors[0] }">
                <b-input v-model="item.product_url"
                         placeholder="Paste your Cart URL here..."
                         :disabled="!isNewItem"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="card-content" v-if="item.campaign_type === 'single_product'">
            <h2 class="title is-2">Settings</h2>
            <notification-bar>
              Paste in the URL of your product page here, and Nibble will generate a new link
              that you can use in your marketing emails etc. The Nibble Magic Link
              will enable Nibble on the product page, with the settings you choose below.
            </notification-bar>
            <ValidationProvider rules="required" name="Product URL" v-slot="{ errors }">
              <b-field label="Product URL" :message="errors" :type="{ 'is-danger': errors[0] }">
                <b-input v-model="item.product_url"
                         placeholder="Paste your Product Detail Page URL here..."
                         :disabled="!isNewItem"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="card-content" v-if="item.campaign_type === 'link_to_rule'">
            <h2 class="title is-2">Settings</h2>
            <notification-bar>
              Select a rule that will be automatically activated when the user follows the
              link. The rule will remain active until the user closes their browser, or
              after 3 hours (whichever is sooner). It is recommended that you choose a
              rule that is not currently active.
            </notification-bar>
            <div class="columns">
              <div class="column is-half">
                <ValidationProvider :rules="{required: ruleRequired}" name="Rule" v-slot="{ errors }">
                  <b-field label="Rule" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-select v-model="item.rule_id"
                              placeholder="Select a Rule"
                              expanded>
                      <option v-for="(rule, ruleIndex) in rules"
                              :key="ruleIndex"
                              :value="rule.rule_id">
                        {{rule.name}}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <notification-bar>
              Paste in the URL of any product page here, and Nibble will generate a new link
              that you can use in your marketing emails etc. The Nibble Magic Link
              will enable the above rule.
            </notification-bar>
            <ValidationProvider rules="required" name="Product URL" v-slot="{ errors }">
              <b-field label="Product URL" :message="errors" :type="{ 'is-danger': errors[0] }">
                <b-input v-model="item.product_url"
                         placeholder="Paste your Product Detail Page URL here..."
                         :disabled="!isNewItem"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <objectives-editor-legacy v-model="item.objectives" :disabled="!isNewItem" v-if="showLegacyEditor && !percentagesDisabled"></objectives-editor-legacy>
          <objectives-editor v-model="item.objectives" :disabled="!isNewItem" v-if="!showLegacyEditor && !percentagesDisabled"></objectives-editor>
          <div class="card-content pt-0" v-if="percentagesDisabled">
            <div class="column is-full">
              <h3 class="title is-3">Magic Link Duration</h3>
              <ValidationProvider rules="required" name="Magic Link Duration Select" v-slot="{ errors }">
                <b-field id="campaign-duration" :message="errors" :type="{ 'is-danger': errors[0] }">
                  <b-radio v-model="item.objectives.enableTimeLimit" :native-value="false" type="is-info">
                    No time limit
                  </b-radio>
                </b-field>
                <b-field id="campaign-duration" :message="errors" :type="{ 'is-danger': errors[0] }">
                  <b-radio v-model="item.objectives.enableTimeLimit" :native-value="true" type="is-info">
                    For a limited time only
                  </b-radio>
                </b-field>
              </ValidationProvider>
              <time-limit-editor :value="timeLimitObjectives" v-on:input="timeLimitInput" v-if="item.objectives.enableTimeLimit"/>
              <div v-if="item.objectives.enableTimeLimit">Timezone: {{currentTimezone}}</div>
            </div>
          </div>
          <p style="padding: 10px" v-if="!percentagesDisabled">Timezone: {{currentTimezone}}</p>
          <div class="card-content" v-if="hasCampaignUrl && !isNewItem">
            <div class="columns">
              <div class="column">
                <b-field label="Magic Link" >
                  <b-input v-model="item.campaign_url" disabled expanded></b-input>
                  <p class="control">
                    <b-button @click="copyItemUrl" type="is-outline" label="Copy URL"></b-button>
                  </p>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </main-column>
    </validation-observer>
  </page-layout>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import NotificationBar from '@/components/NotificationBar'
import TimeLimitEditor from '@/components/TimeLimitEditor'
import ObjectivesEditor from '@/components/ObjectivesEditor'
import ObjectivesEditorLegacy from '@/components/ObjectivesEditorLegacy'
import ItemEditor from '@/utils/mixins/ItemEditor'
import nibble from '@/utils/nibble'

export default {
  name: 'Campaign',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, TimeLimitEditor, ObjectivesEditor, ObjectivesEditorLegacy, NotificationBar },
  data () {
    return {
      rules: null,
      item: {
        name: '',
        campaign_type: 'link_to_rule',
        product_url: null,
        campaign_url: null,
        rule_id: null,
        objectives: {
          target_discount_percentage: null,
          walkaway_percentage: 16,
          discount_treshold: null,
          objective: 'balance_performance',
          enableTimeLimit: false,
          dateRange: {
            startDateTime: null,
            endDateTime: null
          }
        }
      }
    }
  },
  computed: {
    currentTimezone () {
      return nibble.timezoneManager.get()
    },
    listPath () {
      return `/retailers/${this.retailerId}/campaigns`
    },
    newPath () {
      return `/retailers/${this.retailerId}/campaigns/new`
    },
    itemPath () {
      return `/retailers/${this.retailerId}/campaigns/${this.itemId}`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/campaigns`
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}/campaigns/${this.itemId}`
    },
    itemName () {
      return 'Magic Link'
    },
    percentagesDisabled () {
      return this.item.campaign_type === 'link_to_rule'
    },
    ruleRequired () {
      return this.item.campaign_type === 'link_to_rule'
    },
    hasCampaignUrl () {
      return ['link_to_rule', 'single_product', 'negotiation_on_cart'].indexOf(this.item.campaign_type) !== -1
    },
    showLegacyEditor () {
      return this.item.objectives.objective === null || this.item.objectives.objective === undefined
    },
    timeLimitObjectives () {
      return {
        enableTimeLimit: this.item.objectives.enableTimeLimit,
        dateRange: this.item.objectives.dateRange
      }
    }
  },
  methods: {
    fetchOptions () {
      return nibble.get('/rules', { retailer_id: this.retailerId, context: 'retailer' }, this.$store.getters.authHeaders)
        .then((response) => {
          this.rules = response.data.rules
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    copyItemUrl () {
      this.$clipboard(this.item.campaign_url)
      Toast.open({ message: 'Campaign URL copied to clipboard', type: 'is-primary' })
    },
    itemToParams () {
      var params = {
        name: this.item.name,
        campaign_type: this.item.campaign_type,
        product_url: this.item.product_url,
        rule_id: this.item.rule_id,
        target_discount_percentage: this.item.objectives.target_discount_percentage,
        walkaway_percentage: this.item.objectives.walkaway_percentage,
        discount_treshold: this.item.objectives.discount_treshold,
        objective: this.item.objectives.objective
      }
      if (this.item.objectives.enableTimeLimit && this.item.objectives.dateRange.startDateTime != null) {
        params.starts_at = nibble.timezoneManager.formatDateToUTC(this.item.objectives.dateRange.startDateTime, 'YYYY-MM-DDTHH:mm:SS')
      }
      if (this.item.objectives.enableTimeLimit && this.item.objectives.dateRange.endDateTime != null) {
        params.ends_at = nibble.timezoneManager.formatDateToUTC(this.item.objectives.dateRange.endDateTime, 'YYYY-MM-DDTHH:mm:SS')
      }
      return params
    },
    dataToItem (data) {
      var item = {
        name: data.name,
        campaign_type: data.campaign_type,
        product_url: data.product_url,
        campaign_url: data.campaign_url,
        rule_id: data.rule_id,
        objectives: {
          target_discount_percentage: data.target_discount_percentage,
          walkaway_percentage: data.walkaway_percentage,
          discount_treshold: data.discount_treshold,
          objective: data.objective,
          enableTimeLimit: false,
          dateRange: {
            startDateTime: null,
            endDateTime: null
          }
        }
      }
      if (data.start_date != null) {
        item.objectives.dateRange.startDateTime = nibble.timezoneManager.formatDate(data.start_date, 'YYYY-MM-DDTHH:mm:SS')
        item.objectives.enableTimeLimit = true
      }
      if (data.end_date != null) {
        item.objectives.dateRange.endDateTime = nibble.timezoneManager.formatDate(data.end_date, 'YYYY-MM-DDTHH:mm:SS')
        item.objectives.enableTimeLimit = true
      }
      return item
    },
    timeLimitInput (timeLimitObjectives) {
      this.item.objectives.enableTimeLimit = timeLimitObjectives.enableTimeLimit
      this.item.objectives.dateRange = timeLimitObjectives.dateRange
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.card {
  overflow: visible;
}
</style>
