<template>
  <div class="menu-container" :class="containerClass">
    <b-menu class="top-menu" v-if="isAccountMenu">
      <b-menu-list>
        <b-menu-item tag="router-link" to="/admin/dashboard">
          <template #label>
            <material-icon icon="chevron_left"></material-icon>
            <span class="menu-label-text">Back to Dashboard</span>
          </template>
        </b-menu-item>
      </b-menu-list>

      <b-menu-list v-if="isAccountMenu">
        <b-menu-item :active="isActive('account:plan')" tag="router-link" :to="retailerLink('account/plan')">
          <template #label>
            <material-icon icon="person"></material-icon>
            <span class="menu-label-text">Account &amp; Plan</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="planHasUsageFees" tag="router-link" :active="isActive('account:usage')" :to="retailerLink('account/usage')">
          <template #label>
            <material-icon icon="receipt"></material-icon>
            <span class="menu-label-text">Usage</span>
          </template>
        </b-menu-item>
      </b-menu-list>
    </b-menu>

    <b-menu class="top-menu" v-else-if="retailerId">
      <b-menu-list>
        <b-menu-item :active="isActive('dashboard')" tag="router-link" :to="retailerLink('dashboard')">
          <template #label>
            <material-icon icon="dashboard"></material-icon>
            <span class="menu-label-text">Dashboard</span>
          </template>
        </b-menu-item>
        <b-menu-item
                     :active="isActive('sessions')"
                     tag="router-link"
                     :to="retailerLink('sessions')">
           <template #label>
             <material-icon icon="forum"></material-icon>
             <span v-if="canSeeLimitedData()" class="menu-label-text">Nibbles</span>
             <span v-if="!canSeeLimitedData()" class="menu-label-text">Retargeting</span>
           </template>
         </b-menu-item>
        <b-menu-item class="submenu-closed"
                     :active="isActive('ruleset') || isActive('archive')"
                     tag="router-link"
                     :to="retailerLink('rules')">
          <template #label>
            <material-icon icon="book"></material-icon>
            <span class="menu-label-text">Rules</span>
          </template>
        </b-menu-item>
        <b-menu-item class="submenu-open"
                     :expanded="isRulesExpanded"
                     tag="router-link"
                     :to="retailerLink('rules')">
          <template #label>
            <material-icon icon="book"></material-icon>
            <span class="menu-label-text">Rules</span>
            <material-icon icon="arrow_drop_down"></material-icon>
          </template>
          <b-menu-item
                       :active="isActive('ruleset')"
                       tag="router-link"
                       :to="retailerLink('rules')">
             <template #label>
               <material-icon icon="book"></material-icon>
               <span class="menu-label-text">Ruleset</span>
             </template>
          </b-menu-item>
          <b-menu-item
                       :active="isActive('archive')"
                       tag="router-link"
                       :to="retailerLink('archive')">
             <template #label>
               <material-icon icon="inventory_2"></material-icon>
               <span class="menu-label-text">Archive</span>
             </template>
          </b-menu-item>
        </b-menu-item>
        <b-menu-item :active="isActive('campaigns')"
                     tag="router-link"
                     :to="retailerLink('campaigns')">
           <template #label>
             <material-icon icon="campaign"></material-icon>
             <span class="menu-label-text">Magic Links</span>
           </template>
        </b-menu-item>
      </b-menu-list>
      <b-menu-list>
        <b-menu-item :active="isActive('retailer')"
                     tag="router-link"
                     :to="retailerLink('settings')">
           <template #label>
             <material-icon icon="store"></material-icon>
             <span class="menu-label-text">Store Settings</span>
           </template>
        </b-menu-item>
        <b-menu-item v-if="!isShopifyStore"
                     :active="isActive('products')"
                     tag="router-link"
                     :to="retailerLink('products')">
           <template #label>
             <material-icon icon="sell"></material-icon>
             <span class="menu-label-text">Individual Products</span>
           </template>
        </b-menu-item>
        <b-menu-item v-if="!isShopifyStore"
                     :active="isActive('product_categories')"
                     tag="router-link"
                     :to="retailerLink('product_categories')">
           <template #label>
             <material-icon icon="widgets"></material-icon>
             <span class="menu-label-text">Product Categories</span>
           </template>
        </b-menu-item>
        <b-menu-item v-if="isFeatureAvailable('user_cohorts')"
                     :active="isActive('cohorts')"
                     tag="router-link"
                     :to="retailerLink('cohorts')">
           <template #label>
             <material-icon icon="people"></material-icon>
             <span class="menu-label-text">User Cohorts</span>
           </template>
        </b-menu-item>
        <b-menu-item :active="isActive('addon_items')"
                     tag="router-link"
                     :to="retailerLink('addon_items')">
           <template #label>
             <material-icon icon="control_point_duplicate"></material-icon>
             <span class="menu-label-text">Gift With Purchase</span>
          </template>
        </b-menu-item>
        <b-menu-item :active="isActive('retailer-newdashboard')" tag="router-link" :to="retailerLink('newdashboard')" v-if="isAdmin">
          <template #label>
            <material-icon icon="dashboard"></material-icon>
            <span class="menu-label-text">New dashboard</span>
          </template>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
    <b-menu class="bottom-menu">
      <b-menu-list>
        <b-menu-item v-if="isAdmin && retailerId != null" tag="router-link" :to="retailerLink('account/plan')">
          <template #label>
            <div class="is-flex is-flex-direction-row is-align-items-center">
              <material-icon class="material-icons-round" icon="person"/>
              <div class="is-flex is-flex-direction-column is-align-items-flex-start">
                <span class="menu-label-text">{{retailerName}}</span>
                <span class="title-account-plan has-text-centered">Account &amp; Plan</span>
              </div>
            </div>
          </template>
        </b-menu-item>
        <b-menu-item v-if="isAdmin" tag="router-link" to="/admin/dashboard">
          <template #label>
            <material-icon icon="settings"></material-icon>
            <span class="menu-label-text">Admin Home</span>
          </template>
        </b-menu-item>
        <b-menu-item href="https://support.nibbletechnology.com/" target="_blank">
          <template #label>
            <material-icon icon="assignment"></material-icon>
            <span class="menu-label-text">Documentation &amp; FAQs</span>
          </template>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  name: 'MainMenu',
  components: { MaterialIcon },
  props: {
    retailerId: {
      required: false,
      type: Number
    },
    activeItem: {
      required: false,
      type: String
    },
    expanded: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    isRulesExpanded () {
      return this.isActive('ruleset') || this.isActive('archive')
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    isShopifyStore () {
      return this.$store.getters.isShopifyStore
    },
    containerClass () {
      if (this.expanded) {
        return 'expanded'
      } else {
        return 'collapsed'
      }
    },
    isAccountMenu () {
      if (this.$route.name.match(/Account.*/)) {
        return true
      } else {
        return false
      }
    },
    planHasUsageFees () {
      if (this.$store.state.retailer != null && this.$store.state.retailer.billing_scheme != null) {
        return this.$store.state.retailer.billing_scheme.usage_fee_percentage != null &&
          this.$store.state.retailer.billing_scheme.usage_fee_percentage > 0
      }
      return false
    },
    retailerName () {
      if (this.$store.state.retailer != null) {
        return this.$store.state.retailer.name
      } else {
        return null
      }
    }
  },
  methods: {
    isFeatureAvailable (featureName) {
      return this.$store.state.retailer != null && this.$store.state.retailer.available_features.includes(featureName)
    },
    isActive (item) {
      return item === this.activeItem
    },
    retailerLink (path) {
      return `/retailers/${this.retailerId}/${path}`
    },
    canSeeLimitedData () {
      return !this.$store.getters.isLimitedData
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
