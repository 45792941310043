<template>
  <page-layout :retailer-id="retailerId" active-menu-item='account:usage'>
    <main-column :breadcrumbs="breadcrumbs" page-title="Usage" content-class="main-column-sub-columns columns">
      <div class="column full-height">
        <div :class="mainColumnContentClasses">
          <empty-list-indicator v-if="showEmptyList"
            header="Currently there are no Usage Charges"
            :showButton="false">
            <template v-slot:text>
              In addition to monthly fees, usage charges from users negotiating with Nibble on your products will appear here.
            </template>
          </empty-list-indicator>
          <b-table v-else :data="items" hoverable>
            <b-table-column field="id" label="ID" v-slot="props" width="100" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>
                  {{ props.row.billing_event_id }}
                </strong>
              </router-link>
            </b-table-column>
            <b-table-column field="description" label="Description" v-slot="props" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                {{ props.row.description }}
              </router-link>
            </b-table-column>
            <b-table-column field="billed_at" label="Billed At" v-slot="props" width="160" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                {{ formatDateShort(props.row.billed_at) }}
              </router-link>
            </b-table-column>
            <b-table-column field="amount" label="Amount" v-slot="props" width="100" cell-class="link-cell">
              <router-link class="link" :to="itemPath(props.row)">
                <strong>{{ props.row.amount }}</strong>
              </router-link>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import EmptyListIndicator from '@/components/EmptyListIndicator'
import ItemList from '@/utils/mixins/ItemList'

export default {
  name: 'AccountUsage',
  components: { PageLayout, MainColumn, EmptyListIndicator },
  mixins: [ItemList],
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      items: []
    }
  },
  computed: {
    listPath () {
      return `/retailers/${this.retailerId}/account/usage`
    },
    listApiPath () {
      return `/retailers/${this.retailerId}/account/usage`
    },
    itemsKey () {
      return 'usage'
    },
    itemIdKey () {
      return 'billing_event_id'
    },
    itemName () {
      return 'Usage'
    },
    itemsName () {
      return 'Usage'
    },
    showEmptyList () {
      return this.items.length === 0
    },
    mainColumnContentClasses () {
      var base = ['main-column-content']
      if (this.showEmptyList) {
        return base.concat(['main-column-content-empty'])
      } else {
        return base
      }
    }
  },
  methods: {
    formatDateShort (date) {
      return nibble.timezoneManager.formatDate(date, 'DD/MM/YYYY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
