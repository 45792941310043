<template>
  <span class="nibble-icon material-icons-round" :class="iconClass">{{icon}}</span>
</template>

<script>
export default {
  name: 'MaterialIcon',
  props: {
    icon: {
      required: true,
      type: String
    },
    size: {
      required: false,
      type: String,
      default: 'medium'
    },
    statusClass: {
      required: false,
      type: String,
      default: null
    }
  },
  computed: {
    iconClass () {
      if (this.statusClass !== null) {
        return `icon-${this.size} icon-status-${this.statusClass}`
      } else {
        return `icon-${this.size}`
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
