<template>
  <nav class="breadcrumb is-small" aria-label="breadcrumbs">
    <ul>
      <li v-for="(crumb, crumbIndex) in crumbs" :key="crumbIndex" :class="{'is-active': crumb.active}">
        <router-link :to="crumb.link">{{crumb.title}}</router-link>
      </li>
    </ul>
  </nav>
</template>
<script>

export default {
  name: 'BreadcrumbList',
  props: ['breadcrumbs'],
  data () {
    var crumbs = [{ link: '/', title: 'Home' }]
    crumbs = crumbs.concat(this.breadcrumbs)
    crumbs[crumbs.length - 1].active = true
    return {
      crumbs: crumbs
    }
  },
  watch: {
    breadcrumbs () {
      var crumbs = [{ link: '/', title: 'Home' }]
      crumbs = crumbs.concat(this.breadcrumbs)
      crumbs[crumbs.length - 1].active = true
      this.crumbs = crumbs
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.breadcrumb {
  margin-bottom: 0;
  padding-bottom: 0.25rem;
  li + li::before {
    content: ">";
  }
  a:hover {
    text-decoration: underline;
  }
}
</style>
