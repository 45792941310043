<template>
    <div>
        <b-table v-if="selectedAddonItems.length > 0"
          :data="selectedAddonItems"
          hoverable
          draggable
          selectable
          @dragstart="dragstart"
          @drop="drop"
          @dragover="dragover"
          @dragleave="dragleave">
          <b-table-column field="priority" centered width="48">
            <template #header>
              <material-icon size="small" icon="swap_vert"/>
            </template>
            <template #default="props">
              <span class="priority-tag">
                {{ props.row.priority + 1 }}
              </span>
            </template>
          </b-table-column>
          <b-table-column field="name" label="Name">
            <template #default="props">
              <strong>
                {{ props.row.name }}
              </strong>
            </template>
          </b-table-column>
          <!-- <b-table-column field="synthetic_value" label="Synthetic Value" width="100">
            <template #default="props">
              {{ props.row.synthetic_value }}
            </template>
          </b-table-column> -->
          <b-table-column field="display_value" label="Display Value" width="100">
            <template #default="props">
              {{ props.row.display_value }}
            </template>
          </b-table-column>
          <!-- <b-table-column field="addon_type" label="Add-on Type" width="150">
            <template #default="props">
              {{ addonTypes[props.row.addon_type] }}
            </template>
          </b-table-column> -->
          <b-table-column label="" width="60" cellClass="has-text-right">
            <template #default="props">
              <b-button type="is-text is-icon" icon-left="close" @click="deleteItem(props.row)"/>
            </template>
          </b-table-column>
        </b-table>
        <div id="add-on-footer" class="column" v-if="unselectedAddonItems.length > 0">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <div id="add-on-select" class="column is-half">
              <ValidationProvider name="Add-on" v-slot="{ errors }">
                <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                  <b-select placeholder="Select an item" ref="addon_select" v-model="addon.addon_item_id" expanded>
                    <option v-for="item in unselectedAddonItems" :key="item.addon_item_id" :value="item.addon_item_id">{{ item.name }}</option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
            <div id="add-on-button" class="column is-half">
              <b-button type="is-primary is-rounded" @click="handleSubmit(addItem)">+ Add</b-button>
            </div>
          </ValidationObserver>
        </div>
    </div>
</template>

<script>
// import ItemList from '@/utils/mixins/ItemList'
import MaterialIcon from '@/components/MaterialIcon'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'AddonRuleList',
  // mixins: [ItemList],
  props: ['addon_items', 'rule_addon_items'],
  components: { MaterialIcon },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      draggingRow: null,
      draggingRowIndex: null,
      addon: {
        addon_item_id: null
      },
      addonItems: [],
      ruleAddonItems: [],
      selectedAddonItems: [],
      addonTypes: {
        sweetener: 'Gift With Purchase',
        bundle_item: 'Bundle Item',
        free_shipping: 'Free Shipping'
      }
    }
  },
  created () {
    this.addonItems = Object.freeze(this.addon_items)
    this.ruleAddonItems = Object.freeze(this.rule_addon_items)
    this.selectedAddonItems = JSON.parse(JSON.stringify(this.rule_addon_items)) // Copy
  },
  computed: {
    unselectedAddonItems () {
      return this.addonItems.filter(item => {
        return !this.fetchAddonIds(this.selectedAddonItems).includes(item.addon_item_id)
      })
    },
    changes () {
      var added = []
      var changed = []
      var deleted = []

      var ruleAddonIds = this.fetchAddonIds(this.ruleAddonItems)
      var selectedAddonIds = this.fetchAddonIds(this.selectedAddonItems)

      ruleAddonIds.forEach(function (id) {
        var ruleAddon = this.fetchAddonById(this.ruleAddonItems, id)
        if (!selectedAddonIds.includes(id)) {
          deleted.push(ruleAddon)
        }
      }, this)

      selectedAddonIds.forEach(function (id) {
        var selectedAddon = this.fetchAddonById(this.selectedAddonItems, id)
        if (ruleAddonIds.includes(id)) {
          var ruleAddon = this.fetchAddonById(this.ruleAddonItems, id)
          if (selectedAddon.priority !== ruleAddon.priority) {
            changed.push(selectedAddon)
          }
        } else {
          added.push(selectedAddon)
        }
      }, this)

      return { added: added, changed: changed, deleted: deleted }
    }
  },
  methods: {
    fetchAddonIds (addons) { return addons.map(item => { return item.addon_item_id }) },
    fetchAddonById (addons, id) { return addons.find(item => { return item.addon_item_id === id }) },
    addItem () {
      var id = this.addon.addon_item_id
      var addon = this.fetchAddonById(this.addonItems, id)

      // Set a null rule add-on ID (can only be determined when added to database)
      addon.rule_addon_item_id = null

      // Add the rule
      this.selectedAddonItems.push(addon)

      // Update the priorities
      this.selectedAddonItems.forEach(function (item, i, array) { array[i].priority = i })
      this.selectedAddonItems = this.selectedAddonItems.slice(0)

      // Reset selected addon
      this.addon.addon_item_id = null

      console.log(this.changes)
    },
    deleteItem (row) {
      var idx = this.selectedAddonItems.findIndex(item => {
        return item.addon_item_id === row.addon_item_id
      })

      // Delete the add-on
      this.selectedAddonItems.splice(idx, 1)

      // Update the priorities
      this.selectedAddonItems.forEach(function (item, i, array) { array[i].priority = i })
      this.selectedAddonItems = this.selectedAddonItems.slice(0)

      Toast.open({ message: `'${row.name}' add-on removed`, type: 'is-primary' })
      console.log(this.changes)
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index

      // Swap the two items
      var temp = this.selectedAddonItems[droppedOnRowIndex]
      this.selectedAddonItems[droppedOnRowIndex] = this.selectedAddonItems[this.draggingRowIndex]
      this.selectedAddonItems[this.draggingRowIndex] = temp

      // Update the priorities
      this.selectedAddonItems.forEach(function (item, i, array) { array[i].priority = i })
      this.selectedAddonItems = this.selectedAddonItems.slice(0)

      console.log(this.changes)
    }
  }
}
</script>

<style scoped lang="scss">
$grey-l90: #E0E6EB; // $grey-l90 from app.scss

.b-table {
  margin-bottom: 0 !important;
}

#add-on-footer {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  &> span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: top;
    #add-on-select {
      padding: 0px;
      margin-right: 8px;
    }
    #add-on-button {
      padding: 0px;
    }
  }
}
</style>
