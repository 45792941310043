<template>
  <div :class="billingNoticeClass" v-if="billingScheme">
    <span class="notice-summary">
      <strong>{{billingSchemeCount}}</strong>
      {{billingSchemeSummaryText}}
    </span>
    <router-link :to="retailerLink('account/plan')">{{managePlanText}}</router-link>
  </div>
</template>

<script>
export default {
  name: 'BillingNotice',
  props: {
    retailerId: {
      required: false,
      type: Number
    }
  },
  computed: {
    billingScheme () {
      if (this.$store.state.retailer != null) {
        return this.$store.state.retailer.billing_scheme
      } else {
        return null
      }
    },
    billingSchemeName () {
      if (this.billingScheme != null) {
        return this.billingScheme.name
      } else {
        return ''
      }
    },
    billingSchemeCount () {
      if (this.billingScheme == null || this.billingScheme.billing_cycle_max_nibbles == null) {
        return ''
      }
      if (this.billingScheme.status === 'pending_approval' || this.billingScheme.status === 'inactive' || this.billingScheme.status === 'cancelled') {
        return ''
      }
      var remaining = this.billingScheme.billing_cycle_max_nibbles - this.billingScheme.billing_cycle_used_nibbles_count
      if (remaining < 0) {
        return 0
      }
      return remaining
    },
    billingSchemeSummaryText () {
      if (this.billingScheme == null) {
        return ''
      } else if (this.billingScheme.status === 'pending_approval') {
        return 'Upgrade Pending Approval'
      } else if (this.billingScheme.status === 'inactive' || this.billingScheme.status === 'cancelled') {
        return 'Plan Expired'
      } else if (this.billingScheme.billing_cycle_max_nibbles == null) {
        return 'Unlimited Nibbles'
      }
      return 'Nibbles remaining'
    },
    managePlanText () {
      if (this.billingScheme == null) {
        return 'Upgrade Plan'
      } else if (this.billingScheme.status === 'pending_approval' || this.billingScheme.status === 'inactive' || this.billingScheme.status === 'cancelled' || this.billingSchemeName === 'ENTERPRISE' || this.billingSchemeName === 'PREMIUM') {
        return 'Manage Plan'
      } else {
        return 'Upgrade Plan'
      }
    },
    planIsExpired () {
      var expired = false
      if (this.billingScheme !== null) {
        // Check if the billing scheme is active
        expired ||= this.billingScheme.status !== 'active'
        // Check if the max usable Nibbles is exceeded (only if there is a max)
        if (this.billingScheme.billing_cycle_max_nibbles !== null) {
          expired ||= this.billingScheme.billing_cycle_used_nibbles_count > this.billingScheme.billing_cycle_max_nibbles
        }
      }
      return expired
    },
    billingNoticeClass () {
      var classes = ['notice']
      if (this.planIsExpired) {
        classes.push('is-danger')
      }
      return classes
    }
  },
  methods: {
    retailerLink (path) {
      return `/retailers/${this.retailerId}/${path}`
    }
  }
}
</script>
