<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-6-tablet is-5-desktop is-5-widescreen">
        <div class="card">
          <div class="card-content">
            <div class="notification is-danger is-light" v-if="error">
              {{error}}
            </div>
            <p v-else>Please wait, logging in...</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import nibble from '@/utils/nibble'

const WEB_CLIENT_ID = 'XO5QD20YN4T9GJTLWR38C2WFTF87WGG1H6IZKDL9'

export default {
  name: 'AutoLogin',
  components: { },
  data () {
    return {
      token: this.$route.params.token,
      error: null,
      loading: false
    }
  },
  mounted () {
    this.submitLogin()
  },
  methods: {
    submitLogin () {
      var params = {
        code: this.token,
        grant_type: 'authorization_code',
        client_id: WEB_CLIENT_ID
      }
      nibble.postRaw('oauth/token', params)
        .then((response) => {
          console.log('got auth response')
          if (response.data != null) {
            console.log('got response data')
            this.$store.commit('login', response.data)
            return this.$store.dispatch('ensureUserDataAvailable')
          }
        })
        .then(() => {
          if (this.$store.state.user != null) {
            console.log('Auto login update retailer')
            if (this.$store.state.user.retailer != null) {
              return this.$store.dispatch('ensureRetailerDataAvailable', this.$store.state.user.retailer.id)
            } else {
              return this.$store.dispatch('ensureRetailerDataAvailable', null)
            }
          }
        })
        .then(() => {
          if (this.$route.query.return_path != null) {
            this.$router.push({ path: this.$route.query.return_path, query: this.$route.query.return_query })
          } else {
            this.$router.push('/')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            if (error.response.data != null && error.response.data.error_message != null) {
              this.error = error.response.data.error_message
            } else {
              this.error = 'Incorrect login credentials, please try again.'
            }
          } else {
            this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            nibble.handleNetworkError(error, this.$store)
          }
          console.log(error)
        })
      console.log('submit')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
