<template>
  <iframe :id="id" :src="src" width="100%" height="100%" v-if="src"></iframe>
</template>

<script>
export default {
  name: 'IFrame',
  props: {
    src: {
      required: false,
      type: String,
      default: ''
    },
    id: {
      required: false,
      type: String,
      default: 'react-page-iframe'
    }
  },
  mounted () {
    window.addEventListener('message', this.handlePostMessage)
  },
  beforeDestroy () {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('message', this.handlePostMessage)
  },
  methods: {
    handlePostMessage (event) {
      /*
      if (event.origin !== 'https://example.com') {
        return;
      }
      */

      if (event.data.type === 'navigate') {
        const newUrl = this.formatUrl(event.data.url)
        this.$router.push(newUrl) // Using Vue Router to navigate
      }
    },
    formatUrl (url) {
      const frameDomain = new URL(this.src).hostname
      // const thisDomain = new URL(window.location.href).hostname
      let newUrl = url

      // remove from newUrl framedomain and thisdomain, so we can use it as a path
      newUrl = newUrl.replace(frameDomain, '')

      return newUrl
    }
  }
}
</script>

<style scoped>
iframe {
  border: none;
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
