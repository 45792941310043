<template>
  <div class="is-flex is-flex-direction-row mb-4">
    <div class="is-flex-grow-0 mr-4">
      <material-icon size="xxlarge" :icon="icon"></material-icon>
    </div>
    <div class="is-flex-grow-1">
      <h3 class="title is-3 mb-1">{{title}}</h3>
      <p>{{subtitle}}</p>
    </div>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'

export default {
  components: { MaterialIcon },
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    }
  }
}
</script>
