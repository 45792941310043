<template>
  <page-layout :retailer-id="retailerId" active-menu-item="retailer">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">
        <template #header-right>
          <p class="level-item buttons">
            <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
            <b-button type="is-primary is-rounded" @click="handleSubmit(submit)">Save</b-button>
          </p>
        </template>
        <b-tabs v-model="activeTab">
          <b-tab-item label="General settings">
            <div class="card mb-4">
            <div class="card-content">
              <h2 class="title is-2">General Settings</h2>
              <div class="columns">
                <div class="column">
                  <ValidationProvider rules="required" name="Retailer Name" v-slot="{ errors }">
                    <b-field label="Retailer Name" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-input v-model="item.name"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column">
                  <ValidationProvider rules="email" name="Email for Notifications" v-slot="{ errors }">
                    <b-field label="Email for Notifications" :message="errors != null ? '' : errors" :type="{ 'is-danger': errors[0] }">
                      <b-input v-model="item.notifications_email"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            </div>
            <div class="card">
              <div class="card-content">
                <h2 class="title is-2">Retargeting Email Settings</h2>
                <b-message type="is-info is-small" has-icon>
                  If you have Recovered Nibbles enabled for retargeting customers after failed negotiations, these settings will
                  customise the email that is sent to customers with the counter offer you approve.
                </b-message>
                <p class="has-text-lighter">
                  Email Banner Preview
                </p>
                <div class="banner-preview mb-4">
                  <img src="https://8465893.fs1.hubspotusercontent-na1.net/hubfs/8465893/logo.png" width="48" height="48" border="0" alt="" class="banner-preview-nibble"/>
                  <img v-if="item.email_banner_image"
                       :src="item.email_banner_image.image_url"
                       :width="item.email_banner_image.width"
                       :height="item.email_banner_image.height" class="banner-preview-logo">
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Banner Image">
                      <image-upload-field v-model="item.email_banner_image" :retailer-id="retailerId"></image-upload-field>
                    </b-field>
                  </div>
                </div>
                <b-notification :active="errorMessage != null" :message="errorMessage" type="is-danger" :closable="false"></b-notification>
              </div>
            </div>
            <br>
            <div class="card">
              <div class="card-content">
                <h2 class="title is-2">Terms &amp; Conditions</h2>
                <b-message type="is-warning is-small" has-icon>
                  Enabling this option will require all customers who successfully negotiate a deal to accept your Terms &amp; Conditions
                  before adding to basket or checking out. Only enable this option if you have a legal or business requirement to do so,
                  it can be a barrier to purchase.
                </b-message>
                <ValidationProvider name="Require Terms &amp; Conditions acceptance">
                  <b-field>
                    <b-checkbox v-model="item.terms_enabled">Require Terms &amp; Conditions acceptance before purchase</b-checkbox>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider v-if="item.terms_enabled" rules="url" name="Terms URL" v-slot="{ errors }">
                  <b-field label="Terms URL" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <b-input v-model="item.terms_url"
                             placeholder="Paste your Terms & Conditions URL here..."
                             ></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Brand Values" v-if="reasonsEditingEnabled">
            <div class="card">
              <div class="card-content">
                <h2 class="title is-2">Brand Values</h2>
                <div class="content">
                  <p>
                    Nibble can use your Brand Values during negotiations to give your customers reasons to buy from you or support your business.
                    You can enter up to 5 brand value statements below.
                  </p>
                  <h4 class="is-4">Guidelines</h4>
                  <ul>
                    <li>
                      Statements are chosen strategically and will not repeat in a single negotiation. It's recommended to have at least 3 Brand Value Statements for Nibble to choose from.
                    </li>
                    <li>
                      Nibble will say these statements mid-negotiation. Draw attention to your statements by beginning each statement with a phrase such as, "Remember&hellip;" or, "Don't forget&hellip;"
                    </li>
                    <li>
                      Ensure your statements are true and apply to every product Nibble can negotiate on.
                    </li>
                    <li>
                      Please avoid asking questions in your statements. Nibble will follow up each statement by asking the customer for an offer or encouraging them to accept an offer.
                    </li>
                    <li>
                      Please avoid using special characters like emojis in your statements.
                    </li>
                  </ul>
                </div>
                <ValidationProvider name="Statement text" v-slot="{ errors }" v-for="(reason, rindex) in item.reasons" :key="rindex">
                  <b-field :label="'Statement '+(rindex+1)" :message="errors" :type="{ 'is-danger': errors[0] }">
                    <input type="text" class="input" v-model="reason.text" :placeholder="reasonsPlaceholders[rindex]" maxlength="180" />
                  </b-field>
                  <div class="chars_limit">{{reason.text.length}}/180</div>
                  <input type="hidden" v-model="reason.reason_id" />
                </ValidationProvider>
                <br/>
              </div>
            </div>
            <div class="card mt-4" v-if="reasonsGenerationEnabled">
              <div class="card-content">
                <h2 class="title is-2">Use Generative AI to create Brand Value Statements</h2>
                  <ValidationProvider name="Brand Name" v-slot="{ errors }">
                    <b-field label="How do you want Nibble to refer to your brand?" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-input v-model="brandName"
                              placeholder="Your brand name"
                              ></b-input>
                    </b-field>
                  </ValidationProvider>
                  <br>
                  <p>
                    Paste in your 'About Us' page content, or any other content you have on the subject of your Brand Values. Nibble will
                    attempt to generate your brand value statements for you.
                  </p>
                  <br/>
                  <b-field label="Your content">
                    <b-input rows="10" type="textarea" v-model="brandContent" placeholder="Paste your brand content here"></b-input>
                  </b-field>
                  <b-button @click="generateReasons" type="is-rounded is-primary">Generate</b-button>
                  <br/>
              </div>
            </div>
          </b-tab-item>
      </b-tabs>
      </main-column>
    </validation-observer>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import ImageUploadField from '@/components/ImageUploadField'
import ItemEditor from '@/utils/mixins/ItemEditor'

export default {
  name: 'RetailerSettings',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, ImageUploadField },
  data () {
    return {
      activeTab: 0,
      reasonMax: 5,
      item: {
        name: '',
        email_banner_image: null,
        terms_enabled: false,
        terms_url: null,
        notifications_email: null,
        reasons: []
      },
      reasonsPlaceholders: [
        "Remember, you've got an easy 30-day free returns period you can use if needed.",
        'Keep in mind, these products are high quality and built to last.',
        "Don't forget, these products are ethically made and sustainably sourced, your support means a lot.",
        'Please consider, shipping is free!',
        "Remember, by shopping here you're supporting an independent business and a local community."
      ],
      brandName: '',
      brandContent: ''
    }
  },
  watch: {
    'item.terms_enabled' (val) {
      if (!val) {
        this.item.terms_url = null
      }
    }
  },
  computed: {
    listPath () {
      return `/retailers/${this.retailerId}/sessions`
    },
    breadcrumbs () {
      return [
        {
          link: this.currentPath,
          title: this.itemName
        }
      ]
    },
    newPath () {
      return null
    },
    itemPath () {
      return `/retailers/${this.retailerId}/settings`
    },
    listApiPath () {
      return null
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}`
    },
    itemsName () {
      return 'Settings'
    },
    itemName () {
      return 'Settings'
    },
    reasonsEditingEnabled () {
      return this.$store.state.retailer != null && this.$store.state.retailer.available_features.includes('reasons_editing')
    },
    reasonsGenerationEnabled () {
      return this.$store.state.retailer != null && this.$store.state.retailer.available_features.includes('reasons_generation')
    }
  },
  methods: {
    dataToItem (data) {
      if (data.image === null) {
        data.image = null
      }
      if (data.reasons === undefined || data.reasons == null) {
        data.reasons = []
      }
      if (data.reasons.length < this.reasonMax) {
        const add = this.reasonMax - data.reasons.length
        for (var i = 0; i < add; i++) {
          data.reasons.push({ text: '', reason_id: null })
        }
      }
      data.terms_enabled = data.terms_url != null && data.terms_url !== ''
      return data
    },
    generateReasons () {
      const params = {
        brand_name: this.brandName,
        content: this.brandContent
      }
      this.reasonsPlaceholder = 'Coming up with your brand statement, this may take a few seconds...'
      nibble.post('/reasons/generate', params, this.$store.getters.authHeaders)
        .then((response) => {
          for (var i = 0; i < Math.min(this.item.reasons.length, response.data.reasons.length); i++) {
            this.item.reasons[i].text = response.data.reasons[i]
          }
          this.brandContent = ''
          this.reasonsPlaceholder = 'Enter your brand statement here...'
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
          this.reasonsPlaceholder = 'Enter your brand statement here...'
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.banner-preview {
  border: 1px solid #E7EBEE;
  min-height: 50px;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    display: block;
    margin: 27px;
    &.banner-preview-nibble {

    }
    &.banner-preview-logo {
      max-width: max-content;
      max-height: 48px;
    }
  }
}
.chars_limit {
  color: #7990a6;
  text-align: right;
  font-size: 90%;
  margin-top: -0.33rem;
  padding-right: 10px;
}
</style>
