<template>
  <div>
    <!--DONE: Saved Retailer Selection in Table -->
    <div class="saved-retailers-table" v-if="retailers.length > 0">
      <h5 class="number-of-results">{{retailers.length}} Retailers</h5>

      <!-- INSERT TABLE -->
      <b-table
            :data="retailers"
            ref="table"
            detailed
            custom-detail-row
            detail-key="name"
            :show-detail-icon="false"
            icon-pack="mdi"
            custom-row-key="id">

            <b-table-column
              field="name"
              v-slot="props"
              visible
              label="Name">
              <template>
                <div class="columns is-variable is-8">
                  <div class="column" id="product-col-info">
                    <div class="content" id="product-col-content">
                      <p
                        class="title is-6">{{props.row.name != '' ? props.row.name : 'Retailer Name'}}</p>
                      <p class="subtitle is-7 has-text-lighter"></p>
                    </div>
                  </div>
                </div>
              </template>
            </b-table-column>

            <b-table-column
              v-slot="props">
                <b-button class="dropdown-icon" @click="deleteRetailer(props.row)" :disabled="disabled">
                  <b-icon class="table-chevron-icon" icon="close"></b-icon>
                </b-button>
            </b-table-column>
          </b-table>
      <!-- END OF RETAILER SELECTION TABLE -->

    </div>

    <!--DONE: Add Retailer Button -->
    <b-button
      type="is-primary is-rounded"
      @click="chooseretailers"
      icon-left="plus" v-if="!disabled">Add Retailer</b-button>

    <retailer-search-modal @select="addretailers" ref="searchModal"></retailer-search-modal>
  </div>
</template>

<script>
import RetailerSearchModal from '@/components/product-search/RetailerSearchModal.vue'

export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    RetailerSearchModal
  },
  data () {
    return {
      retailers: []
    }
  },
  watch: {
    value: function () {
      this.updateRetailers()
    }
  },
  created () {
    this.updateRetailers()
  },
  computed: {
  },
  methods: {
    updateRetailers () {
      const retailers = this.valueToRetailers(this.value)
      const r = []
      const n = []
      for (const retailer of retailers) {
        if (!n.includes(retailer.name)) {
          n.push(retailer.name)
          r.push(retailer)
        }
      }
      this.retailers = r
    },
    valueToRetailers (value) {
      // clone the list to ensure we don't leak data back to the parent unintentionally
      return [...value]
    },
    retailersToValue (retailers) {
      return [...retailers]
    },
    deleteRetailer (retailer) {
      this.retailers.splice(this.retailers.indexOf(retailer), 1)
      this.$emit('input', this.retailersToValue(this.retailers))
    },
    chooseretailers () {
      this.$refs.searchModal.show()
    },
    addretailers (retailers) {
      this.retailers.push(...retailers)
      this.$emit('input', this.retailersToValue(this.retailers))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 800px;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  padding: 0px;
  gap: 9px;
  height: 32px;
  background-color: #fff;
}

header h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.modal-content {
  max-width: 800px;
  height: 75%;
}

.modal-close-icon {
  color: #668099;
  background-color: #fff;
  border: none;
}

.close-btn {
  border: none;
}

.close-btn:focus {
  outline: none;
  box-shadow: none;
}

.search-field {
  box-sizing: border-box;
  width: 736px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #c2ccd6;
  border-radius: 24px;
}

.number-of-results {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1F262E;
  padding: 0px 0px 16px;
}

.product-image {
  background-color: rgb(240, 242, 245);
  border-radius: 8px;
  height: 56px;
  width: 56px;
}

.product-image img {
  object-fit: cover;
}

#product-col-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // padding-left: 2.2rem;
}

#product-col-content {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  row-gap: 0.35rem;
  white-space: nowrap;
}

#product-col-content .title {
  font-weight: 500;
  font-size: 1rem;
}

#product-col-content .subtitle {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}

.columns.is-mobile > .column.is-one-quarter {
  width: 14%;
}

.table-chevron-icon {
  color: #0B66C1;
  background-color: transparent;
  border: none;
}

.dropdown-icon {
  background-color: transparent;
  border: none;
  padding-left: 0px;
}

.dropdown-icon:focus {
  outline: none;
  box-shadow: none;
}

.subretailers-variants {
  display: flex;
  align-items: center;
  color: #0B66C1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.sub-retailers-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 46px;
  width: 712px;
  height: 72px;
  background: #FFFFFF;
  border: 1px solid #E0E6EB;
  border-radius: 8px;
}

.product-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
  width: 736px;
  height: 80px;
  background: #F9FAFB;
  border-radius: 16px;
  margin-bottom: 32px;
}
.lightbulb-icon {
  color: #1976D2;
}

.b-table::v-deep table.table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.b-table::v-deep table.table tbody {
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.b-table::v-deep .table .checkbox-cell .checkbox .check {
  border: 2px solid #668099;
}

.sub-sku {
  color: #668099;
}

.columns.is-variable.is-8::v-deep {
   --columnGap: 0rem;
}

.column.is-one-quarter::v-deep {
  width: 15%;
}

.b-table::v-deep table.table tbody tr td {
    border-bottom: 1px solid #D5DDE5;
    width: 100%;
    padding: 8px;
}

.b-table::v-deep table.table tr {
    height: 100%;
    width: 100%;
}

.b-table::v-deep table.table tbody tr.is-checked td {
    background-color: #ffffff;
    border-top: 1px solid #D5DDE5;
    border-bottom: 1px solid #D5DDE5;
}

.b-table::v-deep table.table tbody tr.is-checked td:last-child {
    border-right: 1px solid #D5DDE5;
}

.b-table::v-deep table.table tbody tr td:last-child {
    padding-right: 20px;
}

.b-table::v-deep  table.table tr.detailed-row {
  width: 96%;
  margin-left: 30px;
}

.b-table::v-deep table.table thead {
  display: flex;
  width: 100%;
  padding-right: 10px;
}

.b-table::v-deep table.table thead tr {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}

.b-table::v-deep table.table tbody tr td {
  width: 100%;
}
</style>
