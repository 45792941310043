<template>
    <div class="reaction-component-container" v-if="isVisible()" >
        <div class="reaction-icon">
            <span :class="iconClasses()"><span class="icon"></span></span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Reactions',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      valid_reactions: [],
      reaction: false
    }
  },
  mounted () {
    this.valid_reactions = ['sad', 'heart', 'angry', 'thinking']
    if (this.valid_reactions.includes(this.message.meta.user_reaction)) {
      this.reaction = this.message.meta.user_reaction
    }
  },
  computed: {
  },
  methods: {
    isVisible () {
      const visible = this.reaction !== false
      if (visible) {
        this.$emit('reaction-visible')
      }
      return visible
    },
    selectedReaction () {
      return this.reaction ? 'selected-reaction selected-reaction-' + this.reaction : ''
    },
    iconClasses () {
      var classes = ['reaction']
      classes.push(this.selectedReaction())
      return classes.join(' ')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
.message-container {
    .bot {
        .chat-message {
            position: relative;
            .reaction-component-container {
                line-height: 1px;
                font-size: 1px;
                position: absolute;
                width: 100%;
                right: 0px;
                bottom: 0px;
                box-sizing: border-box;
                .reaction-icon{

                    box-sizing: border-box;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    position: absolute;
                    right:  4px;
                    bottom:  -16px;
                    width: 26px;
                    height: 26px;

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    z-index: 2;
                    span {
                        box-sizing: border-box;
                        &.reaction {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            background-size: 100% 100%;
                            -webkit-background-size: 100% 100%;
                            -moz-background-size: 100% 100%;
                            -o-background-size: 100% 100%;
                            transition-duration: 0;
                            background-repeat: no-repeat;
                            background-image: none;
                            text-align: center;
                            vertical-align: middle;
                            .icon {
                                opacity: 0;
                                width: 50%;
                                height: 50%;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: 100% 100%;
                                -webkit-background-size: 100% 100%;
                                -moz-background-size: 100% 100%;
                                -o-background-size: 100% 100%;
                                transition-duration: 0.3s;
                                display: inline-block;
                                box-sizing: border-box;
                            }
                            &.selected-reaction {
                                cursor: auto;
                                -webkit-border-radius: 12px;
                                -moz-border-radius: 12px;
                                border-radius: 12px;
                                border: solid 1px #C2CCD6;
                                background-position: center;
                                background-color: #FFF;
                                background-image: none;
                                .icon {
                                    opacity: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                                &.selected-reaction-heart .icon {
                                    background-image: url('/images/reactions/heart_new.svg');
                                }
                                &.selected-reaction-angry .icon {
                                    background-image: url('/images/reactions/angry_new.svg');
                                }
                                &.selected-reaction-laughing .icon {
                                    background-image: url('/images/reactions/laughing_new.svg');
                                }
                                &.selected-reaction-sad .icon {
                                    background-image: url('/images/reactions/sad_new.svg');
                                }
                                &.selected-reaction-thinking .icon {
                                    background-image: url('/images/reactions/thinking_new.svg');
                                }
                                &.selected-reaction-sobbing .icon {
                                    background-image: url('/images/reactions/sob_new.svg');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.with-reactions {
        margin-bottom:  24px;
    }
}
@media only screen and (max-width : 1024px) {
}
</style>
