<template>
  <page-layout :retailer-id="navRetailerId" active-menu-item="newdashboard">
    <main-column content-class="main-column-sub-columns columns fullHeight">
    <i-frame :src="src"/>
    </main-column>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import IFrame from '@/components/IFrame'
import nibble from '@/utils/nibble'

export default {
  name: 'AdminNewDashboard',
  components: { PageLayout, MainColumn, IFrame },
  data () {
    return {
      src: null
    }
  },
  computed: {
    navRetailerId () {
      // Fetches :retailerId if the route matches /retailers/:retailerId/dashboard
      return this.$route.params.retailerId ? parseInt(this.$route.params.retailerId) : null
    },
    queryRetailerId () {
      // Returns the retailer ID if it is specified as a query parameter
      return this.$route.query.retailerId ? parseInt(this.$route.query.retailerId) : null
    }
  },
  created () {
  },
  mounted () {
    const params = {
      destination: 'app-react',
      where: 'dashboard'
    }
    nibble.post('/auth/handoff', params, this.$store.getters.authHeaders).then((response) => {
      const url = response.data.url
      this.src = url
    })
  },
  watch: {
  },
  methods: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    viewAsAdmin () {
      if (this.isAdmin()) {
        return this.navRetailerId == null
      } else {
        return false
      }
    },
    defaultDataQuality () {
      return this.viewAsAdmin() ? '3' : null
    },
    retailerId () {
      return this.viewAsAdmin() ? this.queryRetailerId : this.navRetailerId
    }
  }
}
</script>

<style>
body {
  overflow: hidden;
}
.fullHeight {
  height: 100%;
  min-height: 100%;
  position: relative;
}
.main-column-container {
  height: 100%;
  max-width: calc(100% - 10px);
  position: relative;
}
</style>
<style scoped>
.main-column-header {
  display: none;
}
</style>
