<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-6-tablet is-5-desktop is-5-widescreen">
        <form class="box" v-on:submit.prevent.stop="submit">
          <div class="block has-text-centered">
            <img src="/images/nibble-logo.png" alt="Nibble. Converse. Convert." width="168" height="50">
          </div>

          <p>
            Please choose your new password.
          </p>
          <hr>
          <div class="field">
            <label class="label">New Password</label>
            <div class="control">
              <input type="password" class="input" v-model.trim="user.newPassword" placeholder="">
            </div>
          </div>
          <div class="field">
            <label class="label">Confirm New Password</label>
            <div class="control">
              <input type="password" class="input" v-model.trim="user.confirmNewPassword" placeholder="">
            </div>
          </div>

          <div class="notification is-danger is-light" v-if="error">
            {{error}}
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control buttons">
              <router-link to="/" class="button is-text">Cancel</router-link>
              <button type="submit" class="button is-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </section>
</template>

<script>
import nibble from '@/utils/nibble'

export default {
  name: 'ForgotPassword',
  components: { },
  data () {
    return {
      token: this.$route.params.token,
      user: {
        newPassword: '',
        confirmNewPassword: ''
      },
      error: null
    }
  },
  computed: {
  },
  methods: {
    validate () {
      this.error = null
      if (this.user.newPassword === '') {
        this.error = 'Please enter your new password'
      }
      if (this.user.confirmNewPassword === '') {
        this.error = 'Please confirm your new password'
      }
      if (this.user.confirmNewPassword !== this.user.newPassword) {
        this.error = 'Please confirm your new password'
      }
    },
    submit (e) {
      this.validate()
      if (this.error != null) {
        return
      }
      this.submitPasswordReset()
    },
    submitPasswordReset () {
      this.loading = true
      var params = {
        token: this.token,
        newPassword: this.user.newPassword,
        confirmNewPassword: this.user.confirmNewPassword
      }
      nibble.put('/account/password', params, this.$store.getters.authHeaders)
        .then(response => this.updated())
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            if (error.response.data != null && error.response.data.error_message != null) {
              this.error = error.response.data.error_message
            } else {
              this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            }
          } else {
            this.error = 'Cannot contact the Nibble servers. Please check your network connection and try again.'
            nibble.handleNetworkError(error, this.$store)
          }
          console.log(error)
        })
    },
    updated () {
      this.$router.push('/session/new')
      this.$buefy.snackbar.open({
        message: 'Your password has been updated. You can now log in.',
        type: 'is-success',
        position: 'is-top',
        actionText: null
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
